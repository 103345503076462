/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
import React from "react";

import {
  fontLargeHeadline,
  fontP4Regular,
} from "src/design-system/styles/fonts";
import i18n from "src/locales";
import { trackPageBail } from "src/utils/monitoring/events/errors";
import { isInApp } from "src/utils/userSegment";
import { MainColumn } from "../core/BodyLayout";
import { Text } from "../core/Text";
import { Header } from "../header/Header";

export function ErrorPage({ message }: { message?: string }) {
  function tryAgain() {
    trackPageBail("reload");
    window.location.reload();
  }
  function backToHomepage() {
    trackPageBail("landing");
    // Intentionally doing direct navigation due to unknown app state.
    window.location.href = "/";
  }

  return (
    <MainColumn>
      <Header />
      <div css={{ margin: "auto" }}>
        <h1 css={fontLargeHeadline}>{i18n.t("errorPage:header")}</h1>
        <p css={fontP4Regular}>
          {message || i18n.t("errorPage:paragraph")}
          <br />
          {i18n.t("errorPage:actions")}
        </p>

        <ul css={fontP4Regular}>
          <li>
            <Text
              i18nKey="errorPage:tryAgain"
              components={{
                RefreshLink: (
                  <a href={window.location.href} onClick={tryAgain} />
                ),
              }}
            />
          </li>
          <li>
            <Text
              i18nKey="errorPage:supportLink"
              components={{
                SupportLink: <a href="/support/contact-support" />,
              }}
            />
          </li>
          {!isInApp() && (
            <li>
              <Text
                i18nKey="errorPage:homepage"
                components={{
                  HomepageLink: <a href="/" onClick={backToHomepage} />,
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </MainColumn>
  );
}
