import { appConfig } from "src/config";
import { getAccessToken } from "@utils/authCookieParser";

type PartnerUserMappingData = {
  accessCode: string;
  partnerSlug: string;
  partnerUserId?: string;
};

async function getPartnerMapping({
  accessCode,
  partnerSlug,
}: PartnerUserMappingData): Promise<PartnerUserMappingData> {
  const accessToken = getAccessToken();
  const result = await fetch(
    `${appConfig.COACH_SERVER_URL}/mappings/partnerMapping?accessCode=${accessCode}&partnerSlug=${partnerSlug}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (!result.ok) {
    throw result;
  }
  return result.json();
}

async function createPartnerMapping({
  accessCode,
  partnerSlug,
  partnerUserId,
}: PartnerUserMappingData): Promise<PartnerUserMappingData> {
  const accessToken = getAccessToken();

  const result = await fetch(
    `${appConfig.COACH_SERVER_URL}/mappings/partnerMapping`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ accessCode, partnerSlug, partnerUserId }),
    }
  );
  if (!result.ok) {
    throw result;
  }
  return result.json();
}

export async function getOrCreatePartnerMapping({
  accessCode,
  partnerSlug,
}: PartnerUserMappingData): Promise<PartnerUserMappingData> {
  try {
    return await getPartnerMapping({ accessCode, partnerSlug });
  } catch (e) {
    if (e.status === 404) {
      return createPartnerMapping({ accessCode, partnerSlug });
    }
    throw e;
  }
}
