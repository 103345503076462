import React from "react";
import { useSelector } from "react-redux";
import Button from "src/design-system/components/Button/Button";
import { Modal } from "src/design-system/components/Modal/Modal";
import {
  fontMediumHeadline,
  fontP2Regular,
} from "src/design-system/styles/fonts";
import i18n from "src/locales";
import { CoreReduxState } from "src/utils/redux/store";
import { trackEvent } from "src/utils/api/tracker";
import { isSessionStorageAvailable } from "src/utils/noomSessionStorage";
import { useOnce } from "src/hooks/lifecycle";

/**
 * A component that users should never see! If we got here, it means some aspect of session granting
 * has failed. In the normal course of things, if a user has an invalid session, Growth will return
 * a 403 and clear their session and _userId cookies. When they next load the page, they will be
 * granted a new _userId and a new session by buyflow-worker.
 *
 * The thunk in authStatus will automatically refresh the page when a 403 happens for a userData
 * call. However, if we have already refreshed and the session is still invalid, we don't want to
 * keep them in a loop. So we show this modal and ask them to clear their cookies manually.
 */
export function AuthProblemModal() {
  const { sessionInvalid, userDidRefresh } = useSelector(
    (state: CoreReduxState) => state.authStatus
  );
  const shouldShow = sessionInvalid && userDidRefresh;

  useOnce(() => {
    if (shouldShow) {
      trackEvent("On403SessionModal", {
        sessionStorageAvailable: isSessionStorageAvailable(),
      });
    }
  });

  const refresh = () => {
    window.location.reload();
  };
  return (
    shouldShow && (
      <Modal>
        <h1 css={fontMediumHeadline}>{i18n.t("authProblem:title")}</h1>
        <p css={fontP2Regular}>{i18n.t("authProblem:body")}</p>
        <Button onClick={refresh}>{i18n.t("authProblem:refresh")}</Button>
      </Modal>
    )
  );
}
