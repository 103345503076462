import Cookies from "js-cookie";
import { onLoadPhase } from "src/vendor";
import {
  consentFramework,
  getConsent,
  LibCategories,
  optOutStates,
  setConsent,
  shouldIOSUserSeeOptOut,
} from ".";
import { captureException } from "../error";

import { send } from "../fetch";
import { getSubdivision } from "../meristemContext";
import { applyLDUFlag } from "../pixels/publishers/facebook";
import getStore from "../redux/store";
import { isUS } from "../userSegment";
import { bootstrapOneTrust } from "./one-trust";

export const OPT_OUT_COOKIE_NAME = "ccpaOptOut";

type OptOutStatus = {
  optedOut: boolean;
};

export async function setOptOutEmail(newEmail: string) {
  if (
    (consentFramework() !== "optOut" && !shouldIOSUserSeeOptOut()) ||
    // Don't reset if the session is opted out
    Cookies.get(OPT_OUT_COOKIE_NAME) === "true"
  ) {
    return;
  }

  Cookies.remove(OPT_OUT_COOKIE_NAME);
  await ensureCookie(newEmail);
}

export async function bootstrapOptOut() {
  // Use load phase to sync with the redux store to ensure that we have
  // the complete user data before executing.

  onLoadPhase("after-context-init", async () => ensureCookie());
  await bootstrapOneTrust(getConsent());
  applyOptOut();
}

export async function bootstrapInAppIOS() {
  // simulate optout for eligible in-app users
  if (isUS() && optOutStates.includes(getSubdivision())) {
    onLoadPhase("after-context-init", async () => ensureCookie());
  }
  setConsent([LibCategories.strictlyNecessary]);
}

export function optOutAllowsCookies() {
  return Cookies.get(OPT_OUT_COOKIE_NAME) === "false";
}

export function userOptedOut() {
  return Cookies.get(OPT_OUT_COOKIE_NAME) === "true";
}

function applyOptOut() {
  // If opted out out or if there is no consent
  // set the consent to only strictly necessary cookies
  if (userOptedOut() || !getConsent()) {
    setConsent([LibCategories.strictlyNecessary]);
  }
}

export async function ensureCookie(
  email = getStore().getState().userData.email
) {
  const cookie = Cookies.get(OPT_OUT_COOKIE_NAME);
  // If the cookie is not set, attempt to determine from the server
  if (!cookie && email) {
    try {
      const optOutState = await fetchOptOutStatus(email);

      Cookies.set(OPT_OUT_COOKIE_NAME, optOutState.optedOut ? "true" : "false");
      applyOptOut();
    } catch (err) {
      captureException(err);
    }
  } else {
    if (!cookie && !email) {
      // We need to set this to show that we've evaluated the opt out status
      // Cookies will only load when cookie is explicitly false
      Cookies.set(OPT_OUT_COOKIE_NAME, "false");
    }
    applyOptOut();
  }
}

export function fetchOptOutStatus(email: string) {
  const formattedEmail = email.trim().toLowerCase();

  return send<OptOutStatus>(
    "GET",
    `/userdata/api/v4/user/ccpa/optOut/`,
    undefined,
    { params: new URLSearchParams({ email: formattedEmail }) }
  );
}
export function setOptOut(email: string) {
  const formattedEmail = email.trim().toLowerCase();

  Cookies.set(OPT_OUT_COOKIE_NAME, "true");

  // Set the LDU flag for the Facebook pixel.
  // Note that this is not considered compliant but we are
  // retaining as an extra layer of protection.
  applyLDUFlag();

  return send("POST", `/userdata/api/v4/user/ccpa/optOut/`, {
    email: formattedEmail,
  });
}
