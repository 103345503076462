import { genderConstants } from "src/utils/sexAndGender";
import { Offer } from "src/utils/redux/slices/serverContext";

export const isMealPlan = (offer: Offer | string) => {
  const offerId = typeof offer === "string" ? offer : offer.addon_product_id;
  return offerId.includes("meal-plan");
};

export const isWorkoutPlan = (offer: Offer | string) => {
  const offerId = typeof offer === "string" ? offer : offer.addon_product_id;
  return offerId.includes("workout-plan");
};

export const isMealWorkoutBundle = (offer: Offer | string) => {
  const offerId = typeof offer === "string" ? offer : offer.addon_product_id;
  return offerId.includes("meal-workout");
};

export const isMealOrWorkoutPlan = (offer: Offer | string) => {
  return (
    isMealPlan(offer) || isWorkoutPlan(offer) || isMealWorkoutBundle(offer)
  );
};

export const isWithingsProduct = (offer: Offer | string) => {
  const offerId = typeof offer === "string" ? offer : offer.addon_product_id;
  return offerId.startsWith("withings-");
};

export const isAshProduct = (offer: Offer | string) => {
  const offerId = typeof offer === "string" ? offer : offer.addon_product_id;
  return offerId.startsWith("ash-metabolism-");
};

export const isAshMetabolismKitRestrictedState = (state: string): boolean =>
  ["NY"].includes(state);

export const getAddOnPurchaseStatistic = (gender: string, age: string) => {
  switch (gender) {
    case genderConstants.female:
      switch (age) {
        case "20s":
          return "57,969";
        case "30s":
          return "155,252";
        case "40s":
          return "185,390";
        case "50s":
          return "175,025";
        case "60s":
          return "108,863";
        case "70s":
          return "44,559";
        default:
          return "";
      }
    case genderConstants.male:
      switch (age) {
        case "20s":
          return "16,157";
        case "30s":
          return "37,097";
        case "40s":
          return "41,598";
        case "50s":
          return "36,221";
        case "60s":
          return "23,021";
        case "70s":
          return "10,712";
        default:
          return "";
      }
    default:
      switch (age) {
        case "20s":
          return "74,126";
        case "30s":
          return "192,349";
        case "40s":
          return "226,988";
        case "50s":
          return "211,246";
        case "60s":
          return "131,884";
        case "70s":
          return "55,271";
        default:
          return "";
      }
  }
};
