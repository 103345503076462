import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ensurePageSetLoaded, getPageSet } from "src/pageDefinitions/pageSets";
import { PageSet } from "src/pageDefinitions/types";
import { getMeristemContext, setContextType } from "src/utils/meristemContext";
import { AppDispatch, GetAppState } from "../store";

export type LinearBuyflowState = {
  // TODO: Can we update this without breaking in the wild users?
  pageSetName: string;
  isPageSetInit: boolean;
  history: { layer: string; pageSetId: string; sourceId: string }[];
};

type SetActivePageSetParams = {
  pageSetId: string;
  sourceId?: string;
};

const linearBuyflow = createSlice({
  name: "linearBuyflow",
  initialState: {
    pageSetName: null,
    isPageSetInit: false,
    history: [],
  } as LinearBuyflowState,
  reducers: {
    setActivePageSet(
      state,
      { payload }: PayloadAction<SetActivePageSetParams | null>
    ) {
      const history = calculateHistory(state, payload);
      // Using Immer in this reducer. Mutations are safe
      Object.assign(state, {
        pageSetName: payload?.pageSetId,
        isPageSetInit: false,
        history,
      });
    },
    setPageSetInit(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isPageSetInit: action.payload,
      };
    },
  },
});

export const { setPageSetInit } = linearBuyflow.actions;

export function setActivePageSet(pageSet: PageSet, sourceId?: string) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    await ensurePageSetLoaded(pageSet, getState());

    dispatch(
      linearBuyflow.actions.setActivePageSet({
        pageSetId: pageSet.id,
        sourceId,
      })
    );

    // We are likely rerolling if we hit this point, but update just in case
    if (getMeristemContext().context_type !== pageSet.layer) {
      setContextType(pageSet.layer);
    }
  };
}
export function clearActivePageSet() {
  return linearBuyflow.actions.setActivePageSet(null);
}

function calculateHistory(
  state: LinearBuyflowState,
  payload: SetActivePageSetParams
) {
  if (!payload) {
    return [];
  }

  let { history = [] } = state;

  const activeIndex = history.findIndex(
    (entry) => entry.pageSetId === state.pageSetName
  );
  const requestedIndex = history.findIndex(
    (entry) => entry.pageSetId === payload.pageSetId
  );

  const hasRequestedIndex = requestedIndex >= 0;
  const requestedIndexInFarFuture = activeIndex + 1 < requestedIndex;

  if (!hasRequestedIndex || requestedIndexInFarFuture) {
    // New record doesn't exist or has records between here and there
    if (activeIndex >= 0) {
      // If we are pushing to somewhere in the middle,
      // cull any entries after the current pageSetId
      history = history.slice(0, activeIndex + 1);
    }

    const pageSet = getPageSet(payload.pageSetId);
    history.push({
      layer: pageSet.layer,
      pageSetId: payload.pageSetId,
      sourceId: payload?.sourceId,
    });
  } else if (payload?.sourceId) {
    // Next step, with changed source id. Update source id and leave the rest.
    history[requestedIndex].sourceId = payload?.sourceId;
  }
  // else: If we are returning to a prior pageset. NOP.

  return history;
}

export default linearBuyflow;
