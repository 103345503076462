import styled from "@emotion/styled";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { addMonths } from "@utils/datetime";
import React, { ReactNode } from "react";
import { useRecommendedPlan } from "src/hooks/recommended-plan";
import i18n from "src/locales";
import { calculateHalfwayWeightLoss } from "src/utils/calculateGoals";
import { getLocale } from "src/utils/meristemContext";
import { getPersonalizedRecommendedPlanDuration } from "src/utils/pace";
import { getIndexFromGraphId } from "src/utils/pace/graphIndex";
import { Text } from "../core/Text";
import { useSurveyAnswers } from "src/hooks/survey/answers";
import GreenCheckmark from "./images/checkmark_green.svg";

export type BrightSpotGraphStage =
  | "CHOOSE_PLAN"
  | "FIRST_UPDATE_GRAPH"
  | "SECOND_UPDATE_GRAPH";

const Container = styled.div`
  display: flex;
  text-align: left;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 16px;
  margin: 16px auto 0;

  li {
    background: url(${GreenCheckmark}) no-repeat left top;
    margin-bottom: 0.5rem;
    padding-left: 33px;
  }
`;

export function BrightSpots({
  stage,
  children,
}: {
  stage: BrightSpotGraphStage;
  children?: ReactNode;
}) {
  const surveyAnswers = useSurveyAnswers();

  return (
    <Container>
      <List>
        {getBrightSpots(stage, surveyAnswers).map((brightSpot: string) => {
          return <li key={brightSpot}>{brightSpot}</li>;
        })}
        {children}
      </List>
    </Container>
  );
}

export function WeightForecast({
  questionId,
  surveyAnswers,
}: {
  questionId: string;
  surveyAnswers: SurveyAnswersState;
}) {
  const recommendedPlan = useRecommendedPlan();
  const recommendedPlanDurationFromPace =
    getPersonalizedRecommendedPlanDuration(
      recommendedPlan.trial_duration,
      surveyAnswers
    );

  return (
    <li>
      <Text
        i18nKey="survey:questions:weightGraph:weightForecast"
        values={{
          halfwayAmount: calculateHalfwayWeightLoss(
            recommendedPlanDurationFromPace,
            getIndexFromGraphId(questionId),
            surveyAnswers
          ),
          halfwayDate: addMonths(
            new Date(),
            recommendedPlanDurationFromPace / 2
          ).toLocaleString(getLocale(), { month: "long" }),
        }}
        components={{
          halfway: <span css={{ fontWeight: "bold" }} />,
        }}
      />
    </li>
  );
}

// Based on the user's survey data and which weight graph the user is seeing, this function computes various "bright spots" that are meant
// to highlight the progress or direction of the user's weight loss.
// For each stage of the plan, the user will see a different bright spots on the graph.
function getBrightSpots(
  graphStage: BrightSpotGraphStage,
  surveyAnswers: SurveyAnswersState
) {
  if (Object.keys(surveyAnswers).length === 0) {
    return [];
  }
  const brightSpots: string[] = [];
  if (graphStage === "CHOOSE_PLAN") {
    if (surveyAnswers.targetBMI >= 20) {
      brightSpots.push(i18n.t("bright-spots:goodBMIGoal"));
    }
    const currentLifestyleEvaluationIResult =
      surveyAnswers.currentLifestyleEvaluationI?.[0];
    // Return default value of null if the user has not selected a survey answer that meets the criteria for the bright spot
    brightSpots.push(
      i18n.t(
        `bright-spots:currentLifeStyleEvaluationI:${currentLifestyleEvaluationIResult}`,
        { defaultValue: "" }
      )
    );
    const foodFrequencyResult = surveyAnswers.foodFrequency?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:foodFrequency:${foodFrequencyResult}`, {
        defaultValue: "",
      })
    );
  } else if (graphStage === "FIRST_UPDATE_GRAPH") {
    const historyResult = surveyAnswers.history?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:history:${historyResult}`, { defaultValue: "" })
    );
    const pastProgramResult = surveyAnswers.pastPrograms?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:pastPrograms:${pastProgramResult}`, {
        defaultValue: "",
      })
    );
  } else if (graphStage === "SECOND_UPDATE_GRAPH") {
    const workingOutResult = surveyAnswers.workingOut?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:workingOut:${workingOutResult}`, {
        defaultValue: "",
      })
    );
    const physicalLimitationsResult = surveyAnswers.physicalLimitations?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:physicalLimitations:${physicalLimitationsResult}`, {
        defaultValue: "",
      })
    );
    const lessonTimingResult = surveyAnswers.lessonTiming?.[0];
    if (lessonTimingResult) {
      brightSpots.push(i18n.t("bright-spots:lessonTimingPlan"));
    }

    const myLifestyleResult = surveyAnswers.myLifestyle?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:myLifestyle:${myLifestyleResult}`, {
        defaultValue: "",
      })
    );
  }
  // Always return a maximum of three bright spots and filter out null values
  return brightSpots.filter((value: string) => !!value).slice(0, 3);
}
