import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { fontLabel } from "src/design-system/styles/fonts";
import { flexMiddle } from "src/design-system/styles/layout";
import i18n from "src/locales";
import { compassColors } from "src/utils/styling";

const WeightGraphSummary = styled.div`
  ${flexMiddle};
  flex-direction: column;
  align-self: center;
  margin-top: 1.5em;

  @media ${breakpointMobileExtended} {
    margin-top: 0.5em;
  }
`;

const HeaderHighlight = styled.div`
  font-family: "Brown LL", sans-serif;
  font-size: 15px;
  color: ${compassColors.gold};
  margin: 0.5em 0 0.5em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const HeaderTextPersonalizedPace = styled.p`
  ${fontLabel}
  color: ${compassColors.grey4};
  margin: 0;
  margin-top: 0.5em;
  text-align: center;
  text-transform: uppercase;
`;

const WeightGraphHeadline = styled.div`
  display: flex;
  font-family: "Untitled Sans", sans-serif;
  font-size: 20px;
  color: ${compassColors.black};
  width: 100%;
  @media ${breakpointMobileExtended} {
    width: 50%;
  }
  padding-top: 0.5em;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  align-items: flex-end;
`;

const Value = styled.div`
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
`;

const LargeText = styled.div`
  font-family: "Untitled Sans", sans-serif;
  font-weight: 500;
  white-space: nowrap;
  display: block;
`;

const WeightGraphSummarySubtitle = styled.div`
  font-family: "Untitled Sans", sans-serif;

  font-size: 20px;
  color: ${compassColors.black};
  margin: 0;
  margin-top: 0.5em;
  text-align: center;

  // Add extra space between chart
  margin-bottom: 20px;
`;

export function WeightGraphTitle({
  highlight,
  header,
  targetWeight,
  date,
  subtitle,
}: {
  highlight?: string;
  header: string;
  targetWeight: ReactNode;
  date: ReactNode;
  subtitle?: string;
}) {
  return (
    <WeightGraphSummary>
      {highlight && <HeaderHighlight>{highlight}</HeaderHighlight>}
      <HeaderTextPersonalizedPace>{header}</HeaderTextPersonalizedPace>
      {targetWeight && date && (
        <WeightGraphHeadline>
          <Value>
            <LargeText>{targetWeight}</LargeText>
            <span css={{ fontWeight: "normal", whiteSpace: "nowrap" }}>
              &nbsp;{i18n.t("survey:questions:weightGraph:by")}&nbsp;
            </span>
          </Value>
          <Value>{date}</Value>
        </WeightGraphHeadline>
      )}
      {!!subtitle && (
        <WeightGraphSummarySubtitle>{subtitle}</WeightGraphSummarySubtitle>
      )}
    </WeightGraphSummary>
  );
}

export const WeightGraphAfterContent = styled.div`
  text-align: left;
  font-size: 1.25em;
  margin: 20px 0;
  font-size: 18px;
  line-height: 28px;
  font-family: "Untitled Sans", sans-serif;
  padding-bottom: 24px;
`;
