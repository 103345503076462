import { appConfig } from "src/config";
import { consentFramework, LibCategories } from "src/utils/consent";
import { getCountryCode, getSubdivision } from "src/utils/meristemContext";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  OneTrust?: {
    geolocationResponse: {
      stateCode: string;
      countryCode: string;
    };
    OnConsentChanged(
      callback: (event: { detail: LibCategories[] }) => void
    ): void;
  };
  OnetrustActiveGroups?: string;
};

let resolveFullyLoaded: () => void;
const fullyLoaded = new Promise<void>((_resolve) => {
  resolveFullyLoaded = _resolve;
});

if (!window.OneTrust) {
  // @ts-expect-error will be populated by one trust code
  window.OneTrust = {};
}

// Use our geolocation for OneTrust
window.OneTrust.geolocationResponse = {
  stateCode: getSubdivision(),
  countryCode: getCountryCode(),
};

(window as any).OptanonWrapper = function OptanonWrapper() {
  resolveFullyLoaded();
};

export async function loadOneTrust() {
  if (consentFramework() === "none") {
    return {};
  }

  await loadScript(
    // Must be kept in sync with onetrust-preload
    "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    [LibCategories.strictlyNecessary],
    {
      // They appear to support it but this breaks their geolocation API,
      // but in an inconsistent fashion. Disabling as they don't seem to
      // have sufficient QA in CORS mode.
      enableCORS: false,
      htmlAttr: {
        dataset: {
          domainScript: appConfig.ONE_TRUST_DOMAIN_ID,
        },
      },
    }
  );

  // Chaining promise here to allow upstream visibility should the first scrip call fail
  await fullyLoaded;

  return {
    OneTrust: window.OneTrust,
    ActiveGroups: window.OnetrustActiveGroups,
  };
}
