import { trackEvent } from "src/utils/api/tracker";
import { send } from "src/utils/fetch";
import { getLanguage } from "../meristemContext";
import { createAppAsyncThunk } from "../redux/async-thunk";

export interface FastForwardTrialRequest {
  noomPlanId: string;
  braintreeId: string;
  upid: string;
  trialFee: number;
  language: string;
  upsellDiscountCode?: string;
}

interface FastForwardTrialResponse {
  upid: string;
}

const FAST_FORWARD_TRIAL_URL = "/api/payment/v3/fast_forward_trial/";

export const requestFastForwardTrial = createAppAsyncThunk(
  "fastForwardTrial/request",
  async (
    params: {
      discountOffered: boolean;
      upsellDiscountCode?: string;
    },
    thunkApi
  ) => {
    const { discountOffered, upsellDiscountCode } = params;
    const planDiscountApplied = discountOffered ? "Yes" : "No";
    const { serverContext, recommendedPlan } = thunkApi.getState();

    const noomPlanId = discountOffered
      ? recommendedPlan.noom_plan_id
      : serverContext.minimal_discount_noom_plan_id;
    const braintreeId = discountOffered
      ? serverContext.fast_forward_trial_plan.braintree_id
      : serverContext.minimal_discount_fast_forward_trial_plan.braintree_id;
    const request: FastForwardTrialRequest = {
      noomPlanId,
      braintreeId,
      upid: serverContext.upid,
      trialFee: recommendedPlan.trial_fee,
      language: getLanguage(),
    };

    if (upsellDiscountCode) {
      request.upsellDiscountCode = upsellDiscountCode;
    }

    const response = await send<FastForwardTrialResponse>(
      "POST",
      FAST_FORWARD_TRIAL_URL,
      request
    );
    trackEvent("SkipTrialAccepted", { planDiscountApplied });
    return response;
  }
);
