import { ChangeEvent, useEffect, useRef, useState } from "react";
import { getPageSetSurvey } from "src/pageDefinitions/actions/survey/util";
import { trackBuyflowEvent } from "src/utils/api/tracker";
import {
  addSurveyAnswers,
  SurveyAnswersState,
} from "src/utils/redux/slices/surveyAnswers";
import { trackViewContent } from "src/utils/services/ConversionTracker";
import { useCallbackRef } from "../lifecycle";
import { useGotoOptions, usePageSet, usePageSetEvents } from "../pageSet";
import { useCoreStore } from "../redux";
import { executeActions } from "@pageDefinitions/goto/pageSet";
import { prepareAndTrackSurveyAnswers } from "@utils/mainSurvey";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "src/utils/noomSessionStorage";
import { recordQuestionHistory } from "src/utils/redux/slices/questionHistory";
import { usePageReplacement } from "../page-replace";
import { CoreReduxState } from "src/utils/redux/internal";
import { useSelector } from "react-redux";
import { getMeristemContext } from "src/utils/meristemContext";
import {
  getSurveyAnswers,
  useSurveyAnswers,
  getSessionAnswers,
} from "./answers";
import { updateCurrentAnswers } from "src/utils/redux/slices/currentAnswers";
import { SessionSurveyKey } from "src/pageDefinitions/types";

export type UseSurveyConfig<Q extends keyof SurveyAnswersState> = {
  /**
   * Custom validator. Used on init and when `canNowProceed` is
   * omitted from `addAnswers` called.
   *
   * Defaults to marking responses without explicit validation as invalid.
   */
  validate?: (
    answer: SurveyAnswersState[Q],
    answers: SurveyAnswersState
  ) => boolean;
  autoAdvance?: boolean;

  /**
   * Save answers for the current question to session
   * storage instead of the survey answers slice.
   * This is useful for certain HIPAA compliant questions where
   * we don't want to have the answer saved in the growth backend
   * or tracked in mixpanel.
   */
  useSessionStorageOnly?: boolean;
};

const LAST_SEEN_KEY = "lastQuestionSeen";

let lastQuestionSeen: keyof SurveyAnswersState =
  getNoomSessionStorage(LAST_SEEN_KEY);

export function useSurvey<Q extends keyof SurveyAnswersState>(
  questionId: Q,
  {
    validate = () => false,
    useSessionStorageOnly = false,
  }: UseSurveyConfig<Q> = {}
) {
  const store = useCoreStore();

  const surveyAnswers = useSurveyAnswers();

  const gotoOptions = useGotoOptions();

  const { activePageSet, activePage } = usePageSet();

  const validateRef = useCallbackRef(() => {
    const state = store.getState();
    const latestAnswers = useSessionStorageOnly
      ? getSessionAnswers()
      : getSurveyAnswers(state);
    return validate(latestAnswers[questionId], latestAnswers);
  });

  const [canProceed, setCanProceed] = useState(validateRef());
  const [isSending, setIsSending] = useState(false);
  const [sessionAnswers, setSessionAnswers] = useState(getSessionAnswers());

  const surveyBlock = getPageSetSurvey(activePageSet, activePage);

  const surveyName = surveyBlock?.name || "UnknownSurvey";
  const sessionSurveyKey =
    surveyBlock.sessionSurveyKey || SessionSurveyKey.sessionSurveyAnswers;

  const questionSetRef = useRef(null);

  const questionHistory = useSelector(
    (state: CoreReduxState) => state.questionHistory
  );

  // If the user has seen the question, potentially skip showing it.
  usePageReplacement(({ replaceWithNextPage }) => {
    if (surveyBlock.skipSeen) {
      const contextWithQuestion = Object.entries(
        questionHistory.questionContexts
      ).find(([, questionsSeenInContext]) =>
        questionsSeenInContext.includes(questionId)
      );

      // If another context had this answer, then we can skip
      // (prior-layer is a subset of prior-session, so no check here)
      if (
        contextWithQuestion &&
        contextWithQuestion[0] !== `${getMeristemContext().context_type}`
      ) {
        replaceWithNextPage();
      }
    }
  });

  useEffect(() => {
    // We want to only reset the proceed status if it has not been previously set
    // for the current question. This can happen if `addAnswers` is called
    // prior to this effect running.
    if (questionId !== questionSetRef.current) {
      setCanProceed(validateRef());
    }
    trackBuyflowEvent("BuyflowQuestionViewed", {
      surveyName,
      questionId: questionId as string,
      lastQuestionSeen: lastQuestionSeen as string,
    });
    trackViewContent();

    // Note this can cause questionId === lastQuestionSeen in the case of reload.
    lastQuestionSeen = questionId;
    setNoomSessionStorage(LAST_SEEN_KEY, lastQuestionSeen);
  }, [questionId, validateRef, surveyName]);

  usePageSetEvents(async (event) => {
    if (event === "before:goto") {
      setIsSending(false);

      const currentSurvey = getPageSetSurvey(activePageSet, activePage);

      // Ensure that we have the latests data and pull from the store
      // directly.
      const state = store.getState();

      // Tracking event and saving for each question in all surveys
      prepareAndTrackSurveyAnswers(
        questionId,
        // If we're saving to session storage, still track the event but don't send the answer to mixpanel
        useSessionStorageOnly ? {} : getSurveyAnswers(state)
      );

      await executeActions(
        activePageSet,
        activePage,
        currentSurvey?.onAnswered,
        gotoOptions,
        { questionId }
      );
    }
  });

  // Returns if the user can proceed, given their current answer selection.
  const addAnswers = useCallbackRef(
    (answers?: SurveyAnswersState, canNowProceed?: boolean) => {
      store.dispatch(recordQuestionHistory(questionId));

      if (answers) {
        if (useSessionStorageOnly) {
          const prevAnswers =
            getNoomSessionStorage(SessionSurveyKey[sessionSurveyKey]) || {};
          setNoomSessionStorage(SessionSurveyKey[sessionSurveyKey], {
            ...prevAnswers,
            ...answers,
          });

          // Need to refresh the session answers stored in state
          // to cause the hook to rerun and return the new answers
          setSessionAnswers(getSessionAnswers());
        } else {
          store.dispatch(addSurveyAnswers(answers));
          store.dispatch(
            updateCurrentAnswers({
              namespace: surveyBlock.surveyNameSpace,
              answers,
            })
          );
        }
      }
      questionSetRef.current = questionId;

      const newCanProceed =
        canNowProceed == null ? validateRef() : canNowProceed;
      setCanProceed(newCanProceed);
      return newCanProceed;
    }
  );

  /**
   * Helper for when the survey question is a single input, where the answer should equal
   * that input's value.
   */
  const handleChanged = useCallbackRef(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const answers: SurveyAnswersState = {
        [questionId]: e.target.value,
      };

      addAnswers(answers);
    }
  );

  const sendAnswers = useCallbackRef(() => {
    setIsSending(true);
  });

  const latestAnswers = useSessionStorageOnly ? sessionAnswers : surveyAnswers;
  return {
    questionId,

    canProceed,
    isSending,

    surveyName,
    surveyAnswers,
    sessionAnswers,
    latestAnswers,
    addAnswers,
    handleChanged,
    sendAnswers,
  };
}
