import { trackBuyflowEvent, trackEvent } from "src/utils/api/tracker";
import saveToBraze from "src/utils/brazeUploader";
import { getPatientIntakeSessionStorage } from "./usePatientIntakeSessionStorage";
import { SessionStorageKey } from "src/utils/constants";
import { getNoomSessionStorage } from "src/utils/noomSessionStorage";

/**
 * Telehealth event logging helpers
 */
export enum TelehealthSurveyNames {
  InsuranceIntake = "InsuranceIntake",
  PharmacyIntake = "PharmacyIntake",
  ClinicalIntake = "ClinicalIntake",
  LabsIntake = "LabsIntake",
  MedicalIntake = "MedicalIntake",
  ClinicalScheduling = "ClinicalScheduling",
  VideoVisitSurvey = "VideoVisitSurvey",
  FeedbackSurvey = "FeedbackSurvey",
  PatientIntakeSurvey = "PatientIntakeSurvey",
  MDIWhiteLabelIntake = "MDIWhiteLabelIntake",
}

export const finishedSurveyName = (name: TelehealthSurveyNames) => {
  return `Finished${name}`;
};

export const startedSurveyName = (name: TelehealthSurveyNames) => {
  return `Started${name}`;
};

export const TrackBuyflowEventStartedSurvey = (name: TelehealthSurveyNames) => {
  return trackBuyflowEvent(
    "TelehealthBuyflowSurveyStarted",
    {
      surveyName: name,
    },
    { blockRoutingToMixpanel: true }
  );
};

export const TrackBuyflowEventFinishedSurvey = (
  name: TelehealthSurveyNames
) => {
  return trackBuyflowEvent(
    "TelehealthBuyflowSurveyFinished",
    {
      surveyName: name,
    },
    { blockRoutingToMixpanel: true }
  );
};

export const TrackTelehealthBuyflowQuestionViewed = (
  name: TelehealthSurveyNames,
  currentQuestionName: string
) => {
  trackBuyflowEvent(
    "TelehealthBuyflowQuestionViewed",
    {
      surveyName: name,
      lastQuestionSeen: getNoomSessionStorage("lastQuestionSeen"),
      questionId: currentQuestionName,
    },
    { blockRoutingToMixpanel: true }
  );
};

/**
 * Getter methods for each of the properties needed to be sent to CRM
 * since we need to fetch them for multiple types of events
 */
const medicalIntakeSkippedOptionalQuestions = () => {
  const skippedBloodPressure =
    getPatientIntakeSessionStorage().bloodPressureSimple === "";
  const skippedHeartRate =
    getPatientIntakeSessionStorage().heartRateSimple === "";
  const skippedOtherSurgeriesList =
    getPatientIntakeSessionStorage().hasOtherSurgeries === "yes" &&
    getPatientIntakeSessionStorage().otherSurgeries === "";
  const skippedMedicationList =
    getPatientIntakeSessionStorage().useMedications === "yes" &&
    getPatientIntakeSessionStorage().medications === "";
  const skippedMedicationAllergiesList =
    getPatientIntakeSessionStorage().hasMedicationAllergies === "yes" &&
    getPatientIntakeSessionStorage().medicationAllergies === "";
  return (
    skippedBloodPressure ||
    skippedHeartRate ||
    skippedOtherSurgeriesList ||
    skippedMedicationList ||
    skippedMedicationAllergiesList
  );
};

const pharmacyPreferenceSet = () =>
  !!getPatientIntakeSessionStorage().choosePharmacyAddress;

const consentedToSms = () =>
  getNoomSessionStorage(SessionStorageKey.consentsForSms);

const skippedInsuranceIntake = () =>
  ["no", ""].includes(getPatientIntakeSessionStorage().insuranceCoverage);

const hasLabs = () => !!getPatientIntakeSessionStorage().hasLabs;

const uploadedLabs = () => !!getPatientIntakeSessionStorage().hasUploadedLabs;

const consentedToLabs = () => !!getPatientIntakeSessionStorage().hasOrderedLabs;

/**
 * Util methods that construct the property objects for each type of event
 */
const getClinicalSurveyFinishedProperties = (scheduledVisit: boolean) => ({
  medicalIntakeSkippedOptionalQuestions:
    medicalIntakeSkippedOptionalQuestions(),
  skippedInsuranceIntake: skippedInsuranceIntake(),
  hasLabs: hasLabs(),
  uploadedLabs: uploadedLabs(),
  consentedToLabs: consentedToLabs(),
  pharmacyPreferenceSet: pharmacyPreferenceSet(),
  consentedToSms: consentedToSms(),
  scheduledVisit,
});

const getMedicalSurveyFinishProperties = () => ({
  medicalIntakeSkippedOptionalQuestions:
    medicalIntakeSkippedOptionalQuestions(),
});

const getInsuranceSurveyFinishedProperties = () => ({
  skippedInsuranceIntake: skippedInsuranceIntake(),
});

const getLabsSurveyFinishedProperties = () => ({
  hasLabs: hasLabs(),
  uploadedLabs: uploadedLabs(),
  consentedToLabs: consentedToLabs(),
});

const getPharmacySurveyFinishedProperties = () => ({
  pharmacyPreferenceSet: pharmacyPreferenceSet(),
});

export const trackVideoVisitSurveyButtonClickEvent = (
  questionId: string,
  buttonName: string
) => {
  trackBuyflowEvent("TelehealthBuyflowButtonClicked", {
    buttonName,
    pageId: questionId,
    surveyName: TelehealthSurveyNames.VideoVisitSurvey,
  });
};

export const logClinicalSurveyStart = (ReasonForIntakeVisit: string) => {
  return Promise.all([
    TrackBuyflowEventStartedSurvey(TelehealthSurveyNames.ClinicalIntake),
    trackBuyflowEvent(
      "TelehealthBuyflowClinicalSurveyStart",
      { reason: ReasonForIntakeVisit },
      { blockRoutingToMixpanel: true }
    ),
    trackEvent(startedSurveyName(TelehealthSurveyNames.ClinicalIntake), {
      ReasonForIntakeVisit,
    }),
    saveToBraze({
      event: {
        event_name: startedSurveyName(TelehealthSurveyNames.ClinicalIntake),
        event_data: { ReasonForIntakeVisit },
      },
    }),
  ]);
};

export const logClinicalSurveyFinish = (scheduledVisit: boolean) => {
  const properties = getClinicalSurveyFinishedProperties(scheduledVisit);
  return Promise.all([
    TrackBuyflowEventFinishedSurvey(TelehealthSurveyNames.ClinicalIntake),
    trackBuyflowEvent(
      "TelehealthBuyflowClinicalSurveyFinish",
      { ...properties },
      { blockRoutingToMixpanel: true }
    ),
    trackEvent(
      finishedSurveyName(TelehealthSurveyNames.ClinicalIntake),
      properties
    ),
    saveToBraze({
      event: {
        event_name: finishedSurveyName(TelehealthSurveyNames.ClinicalIntake),
        event_data: properties,
      },
    }),
  ]);
};

export const logMedicalSurveyStart = () => {
  TrackBuyflowEventStartedSurvey(TelehealthSurveyNames.MedicalIntake);
  return trackEvent(startedSurveyName(TelehealthSurveyNames.MedicalIntake));
};

export const logMedicalSurveyFinish = () => {
  const properties = getMedicalSurveyFinishProperties();
  return Promise.all([
    TrackBuyflowEventFinishedSurvey(TelehealthSurveyNames.MedicalIntake),
    trackBuyflowEvent(
      "TelehealthBuyflowMedicalSurveyFinished",
      { ...properties },
      { blockRoutingToMixpanel: true }
    ),
    trackEvent(
      finishedSurveyName(TelehealthSurveyNames.MedicalIntake),
      properties
    ),
    saveToBraze({
      event: {
        event_name: finishedSurveyName(TelehealthSurveyNames.MedicalIntake),
        event_data: properties,
      },
    }),
  ]);
};

export const logScheduleSurveyStart = () => {
  TrackBuyflowEventStartedSurvey(TelehealthSurveyNames.ClinicalScheduling);
  return trackEvent(
    startedSurveyName(TelehealthSurveyNames.ClinicalScheduling)
  );
};

export const logScheduleSurveyFinish = () => {
  TrackBuyflowEventFinishedSurvey(TelehealthSurveyNames.ClinicalScheduling);
  return trackEvent(
    startedSurveyName(TelehealthSurveyNames.ClinicalScheduling)
  );
};

export const logInsuranceSurveyStart = () => {
  TrackBuyflowEventStartedSurvey(TelehealthSurveyNames.InsuranceIntake);
  return trackEvent(startedSurveyName(TelehealthSurveyNames.InsuranceIntake));
};

export const logInsuranceSurveyFinish = () => {
  const properties = getInsuranceSurveyFinishedProperties();
  return Promise.all([
    TrackBuyflowEventFinishedSurvey(TelehealthSurveyNames.InsuranceIntake),
    trackBuyflowEvent(
      "TelehealthBuyflowInsuranceSurveyFinished",
      { ...properties },
      { blockRoutingToMixpanel: true }
    ),
    trackEvent(
      startedSurveyName(TelehealthSurveyNames.InsuranceIntake),
      properties
    ),
    saveToBraze({
      event: {
        event_name: finishedSurveyName(TelehealthSurveyNames.InsuranceIntake),
        event_data: properties,
      },
    }),
  ]);
};

export const logLabsSurveyStart = () => {
  return Promise.all([
    TrackBuyflowEventStartedSurvey(TelehealthSurveyNames.LabsIntake),
    trackEvent(startedSurveyName(TelehealthSurveyNames.LabsIntake)),
    saveToBraze({
      event: {
        event_name: startedSurveyName(TelehealthSurveyNames.LabsIntake),
      },
    }),
  ]);
};

export const logLabsSurveyFinish = () => {
  const properties = getLabsSurveyFinishedProperties();
  return Promise.all([
    TrackBuyflowEventFinishedSurvey(TelehealthSurveyNames.LabsIntake),
    trackBuyflowEvent(
      "TelehealthBuyflowLabsSurveyFinished",
      { ...properties },
      { blockRoutingToMixpanel: true }
    ),
    trackEvent(
      finishedSurveyName(TelehealthSurveyNames.LabsIntake),
      properties
    ),
    saveToBraze({
      event: {
        event_name: finishedSurveyName(TelehealthSurveyNames.LabsIntake),
        event_data: properties,
      },
    }),
  ]);
};

export const logPharmacySurveyStart = () => {
  TrackBuyflowEventStartedSurvey(TelehealthSurveyNames.PharmacyIntake);
  return trackEvent(startedSurveyName(TelehealthSurveyNames.PharmacyIntake));
};

export const logPharmacySurveyFinish = () => {
  const properties = getPharmacySurveyFinishedProperties();
  return Promise.all([
    TrackBuyflowEventFinishedSurvey(TelehealthSurveyNames.PharmacyIntake),
    trackBuyflowEvent(
      "TelehealthBuyflowPharmacySurveyFinished",
      { ...properties },
      { blockRoutingToMixpanel: true }
    ),
    trackEvent(
      finishedSurveyName(TelehealthSurveyNames.PharmacyIntake),
      properties
    ),
    saveToBraze({
      event: {
        event_name: finishedSurveyName(TelehealthSurveyNames.PharmacyIntake),
        event_data: properties,
      },
    }),
  ]);
};
