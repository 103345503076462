import { useQuery } from "react-query";
import { send } from "src/utils/fetch";

export type WelcomeBackData = {
  hasValidEmail: boolean;
  displayName?: string;
};

export function useWelcomeBackData(userId: string) {
  const { data = { hasValidEmail: false }, ...rest } =
    useQuery<WelcomeBackData>({
      queryFn: () =>
        send("POST", `/userdata/api/v4/user/welcome-back/`, {
          target_user_id: userId,
        }),
      queryKey: ["welcome-back", userId],
      enabled: !!userId,
    });

  return {
    data,
    ...rest,
  };
}
