import { addSurveyAnswers } from "@utils/redux/slices/surveyAnswers";
import { ActionHandlerParams } from "..";
import { updateCurrentAnswers } from "src/utils/redux/slices/currentAnswers";
import { getPageSetSurvey } from "./util";
import {
  WdyhauOption,
  getWdyhauSurveyAnswersFromKey,
} from "src/components/refactored-survey/question-sets/wdyhau-questions/wdyhauOptions";

export const setZumbaWhereDidYouHear = ({
  store,
  pageSet,
  page,
}: ActionHandlerParams) => {
  const survey = getPageSetSurvey(pageSet, page);
  const answers = getWdyhauSurveyAnswersFromKey(WdyhauOption.zumba);
  store.dispatch(addSurveyAnswers(answers));
  store.dispatch(
    updateCurrentAnswers({
      namespace: survey.surveyNameSpace,
      answers,
    })
  );
};
