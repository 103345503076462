import { getSessionState } from "src/pageDefinitions/session";

export const wasReferredFromB2BSignup = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("isB2B") === "true";
};

export const isB2BPatient = () => {
  return (
    getSessionState("browser").isB2BPatientInternalOnly ||
    wasReferredFromB2BSignup()
  );
};
