import { updateUserContext } from "@utils/api/tracker";
import { ActionHandlerParams } from "..";
import {
  shouldShowNoomClinicalCheckoutOffer,
  isEligibleForPersonalizedPlanTimer,
} from "src/utils/userSegment/features";
import { clearPersonalizedPlanTimerExpirationCookie } from "src/utils/personalizedPlanTimer";
import saveToBraze from "@utils/brazeUploader";
import { getRouteId } from "@utils/meristemContext";
import { saveAnswers } from "./saveAnswers";

type Params = {
  lead?: string;

  dateKey?: string;
  extraProps?: JsonObject;
};

export const onCompletePaymentSurvey = async (
  actionParams: {
    params?: Params;
  } & ActionHandlerParams
) => {
  const { params = {} } = actionParams;

  if (isEligibleForPersonalizedPlanTimer()) {
    // Clear the checkout timer when we attempt to save answers to the database
    await clearPersonalizedPlanTimerExpirationCookie();
  }

  const currentAnswers = await saveAnswers({
    ...actionParams,
    params: {
      ...params,
      dateKey: params.dateKey || "purchaseSurveyCompleteDateISO",
    },
  });

  updateUserContext({
    telehealthEligible: shouldShowNoomClinicalCheckoutOffer(),
  });

  await saveToBraze({
    event: {
      event_name: "finished_payment_survey",
      event_data: { routeId: getRouteId(), ...currentAnswers },
    },
  });
};
