import invariant from "tiny-invariant";

import i18n from "src/locales";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { getLocale } from "src/utils/meristemContext";
import {
  isCountryImperial,
  isCountryBritishUnits,
} from "src/utils/userSegment";

import {
  convertCmToFt,
  convertCmToIn,
  convertFtToCm,
  convertFtToIn,
  convertInToCm,
  convertInToFt,
  convertKgToLb,
  convertKgToSt,
  convertLbToKg,
  convertLbToSt,
  convertStToKg,
  convertStToLb,
  FootValue,
  StoneValue,
} from "../primitive/UnitUtils";

export type UnitName =
  | "POUND"
  | "KILOGRAM"
  | "STONE"
  | "CENTIMETER"
  | "FEET"
  | "INCH";

export interface UnitType {
  id: UnitName;
  label: string;
  ariaLabel: string;
  switchLabel?: string;

  baseUnit?: UnitName;
  baseUnitRate?: number;
  baseUnitAriaLabel?: string;
  intlUnit: Intl.NumberFormatOptions["unit"];
}

export enum UnitLabel {
  kilograms = "kg",
  pounds = "lb",
  stones = "st.",
  centimeter = "cm",
  feet = "ft.",
  inch = "in.",
  stonePounds = "lb.",
}

const Unit: Record<string, UnitType> = {
  POUND: {
    id: "POUND",
    label: "lb.",
    ariaLabel: i18n.t("units:ariaLabelPounds"),

    intlUnit: "pound",
  },
  KILOGRAM: {
    id: "KILOGRAM",
    label: "kg",
    ariaLabel: i18n.t("units:ariaLabelKilograms"),
    switchLabel: i18n.t("units:switchToKilograms"),

    intlUnit: "kilogram",
  },
  STONE: {
    id: "STONE",
    label: "st.",
    ariaLabel: i18n.t("units:ariaLabelStones"),
    switchLabel: i18n.t("units:switchToStone"),

    baseUnit: "POUND",
    baseUnitRate: 14,
    baseUnitAriaLabel: i18n.t("units:ariaLabelPounds"),
    intlUnit: "stone",
  },
  CENTIMETER: {
    id: "CENTIMETER",
    label: "cm",
    ariaLabel: i18n.t("units:ariaLabelCentimeters"),

    intlUnit: "centimeter",
  },
  FEET: {
    id: "FEET",
    label: "ft.",
    ariaLabel: i18n.t("units:ariaLabelFeet"),

    baseUnit: "INCH",
    baseUnitRate: 12,
    baseUnitAriaLabel: i18n.t("units:ariaLabelInches"),
    intlUnit: "foot",
  },
  INCH: {
    id: "INCH",
    label: "in.",
    ariaLabel: i18n.t("units:ariaLabelInches"),

    intlUnit: "inch",
  },
};
export default Unit;

export function formatWeight(
  weightKg: number,
  surveyAnswers = getSurveyAnswers()
) {
  const weightDisplayUnit = getWeightDisplayUnit(surveyAnswers);
  return formatUnit(
    convertUnits({ mainUnitValue: weightKg }, Unit.KILOGRAM, weightDisplayUnit),
    weightDisplayUnit,
    true
  );
}

/**
 * Determines rendered height unit based on user pref and locale.
 */
export function getWeightDisplayUnit(surveyAnswers = getSurveyAnswers()) {
  const userSelectedUnit =
    surveyAnswers.idealWeightUnit || surveyAnswers.weightUnit;
  if (userSelectedUnit && Unit[userSelectedUnit]) {
    return Unit[userSelectedUnit];
  }
  if (isCountryBritishUnits()) {
    return Unit.STONE;
  }
  if (isCountryImperial()) {
    return Unit.POUND;
  }
  return Unit.KILOGRAM;
}

/**
 * Determines rendered height unit based on user pref and locale.
 */
export function getHeightDisplayUnit(surveyAnswers = getSurveyAnswers()) {
  const userSelectedUnit = surveyAnswers.heightUnit;
  if (userSelectedUnit && Unit[userSelectedUnit]) {
    return Unit[userSelectedUnit];
  }
  if (isCountryImperial() || isCountryBritishUnits()) {
    return Unit.FEET;
  }
  return Unit.CENTIMETER;
}

/**
 * Converts a unit into a string value with the correct unit abbreviation.
 * @param value Weight value
 * @param unit The unit being used
 * @returns user facing string value
 */
export function formatUnit(
  value: { mainUnitValue: number; secondaryUnitValue?: number },
  unit: UnitType,
  roundToInteger = false
) {
  try {
    if (unit.baseUnit) {
      const mainUnitValueFormatted = value.mainUnitValue
        ? new Intl.NumberFormat(getLocale(), {
            maximumFractionDigits: 0,
            style: "unit",
            unit: unit.intlUnit,
          }).format(
            roundToInteger
              ? Math.round(value.mainUnitValue)
              : value.mainUnitValue
          )
        : "";

      let secondaryUnitValueFormatted = "";
      if (value.secondaryUnitValue) {
        secondaryUnitValueFormatted = ` ${formatUnit(
          { mainUnitValue: value.secondaryUnitValue },
          Unit[unit.baseUnit],
          roundToInteger
        )}`;
      }

      return `${mainUnitValueFormatted}${secondaryUnitValueFormatted}`;
    }

    return new Intl.NumberFormat(getLocale(), {
      maximumFractionDigits: 1,
      style: "unit",
      unit: unit.intlUnit,
    }).format(
      roundToInteger ? Math.round(value.mainUnitValue) : value.mainUnitValue
    );
  } catch (e) {
    // Old iOS devices don't support unit formats.
    return `${Math.round(value.mainUnitValue)} ${unit.label}${
      unit.baseUnit && value.secondaryUnitValue
        ? ` ${Math.round(value.secondaryUnitValue)} ${
            Unit[unit.baseUnit].label
          }`
        : ""
    }`;
  }
}

/**
 * Simple function to subtract two unit values (value1 - value2) and return
 * the result formatted according desired weight display unit.
 */
export function subtractUnits(
  value1: number,
  value2: number,
  weightUnit: UnitType,
  weightDisplayUnit: UnitType
) {
  return convertUnits(
    {
      mainUnitValue: value1 - value2,
    },
    weightUnit,
    weightDisplayUnit
  );
}

/**
 * A helper function to convert a value in one unit to another (i.e. converting kilogram to pounds).
 */
export function convertUnits(
  value: { mainUnitValue: number; secondaryUnitValue?: number },
  fromUnit: UnitType,
  toUnit: UnitType
): { mainUnitValue: number; secondaryUnitValue?: number } {
  // Kg <> Lb.
  if (fromUnit === Unit.KILOGRAM && toUnit === Unit.POUND) {
    return convertKgToLb(value);
  }
  if (fromUnit === Unit.POUND && toUnit === Unit.KILOGRAM) {
    return convertLbToKg(value);
  }

  // Kg <> St.
  if (fromUnit === Unit.KILOGRAM && toUnit === Unit.STONE) {
    return convertKgToSt(value);
  }
  if (fromUnit === Unit.STONE && toUnit === Unit.KILOGRAM) {
    return convertStToKg(value as StoneValue);
  }

  // Lb <> St.
  if (fromUnit === Unit.POUND && toUnit === Unit.STONE) {
    return convertLbToSt(value);
  }
  if (fromUnit === Unit.STONE && toUnit === Unit.POUND) {
    return convertStToLb(value as StoneValue);
  }

  // Cm <> In.
  if (fromUnit === Unit.CENTIMETER && toUnit === Unit.INCH) {
    return convertCmToIn(value);
  }
  if (fromUnit === Unit.INCH && toUnit === Unit.CENTIMETER) {
    return convertInToCm(value);
  }

  // Cm <> Ft.
  if (fromUnit === Unit.CENTIMETER && toUnit === Unit.FEET) {
    return convertCmToFt(value);
  }
  if (fromUnit === Unit.FEET && toUnit === Unit.CENTIMETER) {
    return convertFtToCm(value);
  }

  // Ft <> In.
  if (fromUnit === Unit.FEET && toUnit === Unit.INCH) {
    return convertFtToIn(value as FootValue);
  }
  if (fromUnit === Unit.INCH && toUnit === Unit.FEET) {
    return convertInToFt(value);
  }

  // Same unit.
  invariant(
    fromUnit === toUnit,
    `Invalid unit conversion from ${fromUnit} to ${toUnit}`
  );
  return value;
}
