import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { send } from "src/utils/fetch";
import { captureException } from "src/utils/error";
import { createAppAsyncThunk } from "../async-thunk";

export interface ActiveSubscriptionDataState {
  hasActiveSubscription: boolean;
  subscriptionId?: string;
  isInTrialPeriod?: boolean;
  /**
   * This value is +1 compared to the value that we usually communicate to our users except for subscriptions with no trial
   * - no trial would have a value of 0
   * - a 7 days trial would have a value of 8
   * - a 14 days trial would have a value of 15
   */
  trialPeriodInDays?: number;
  planId?: string;
  nextChargeAmount?: number;
  expiryTime?: string;
  activeSubscriptionCurriculumSlug?: string;
  autoRenewalEnabled?: boolean;
  isIAPsubscription?: boolean;
  billingCycleInterval?: string;
  billingCycleIntervalCount?: number;
  accessCode?: string;
  batchId?: number;
}

const activeSubscriptionDataSlice = createSlice({
  name: "activeSubscriptionData",
  initialState: { hasActiveSubscription: false } as ActiveSubscriptionDataState,
  reducers: {
    setActiveSubscriptionData(
      state,
      action: PayloadAction<ActiveSubscriptionDataState>
    ) {
      // Does not support partial updates
      return { ...action.payload };
    },
  },
});

export const { setActiveSubscriptionData } =
  activeSubscriptionDataSlice.actions;

export default activeSubscriptionDataSlice;

const memo: Record<string, Promise<ActiveSubscriptionDataState>> = {};

/**
 * Fetches the active subscription data of an user using the email
 * TODO: Email parameter can be removed since the endpoint uses _userId identification
 */
export const loadActiveSubscriptionDataState = createAppAsyncThunk(
  "activeSubscriptionData/loadActiveSubscriptionDataState",
  async ({ email }: { email?: string }, thunkAPI) => {
    if (!memo[email]) {
      const params = new URLSearchParams();
      if (email) {
        params.set("email", email);
      }

      const url = `/visitor/api/v1/getVisitorSubscriptionStatus/?${params}`;

      let activeSubscriptionData = {
        hasActiveSubscription: false,
      };

      memo[email] = send("GET", url)
        .then((data) => {
          activeSubscriptionData = data;
        })
        .catch((e) => {
          captureException(e, "loadActiveSubscriptionDataState");

          // Delete from memo if API call fails so we can retry
          delete memo[email];
        })
        .then(() => {
          thunkAPI.dispatch(setActiveSubscriptionData(activeSubscriptionData));
          return activeSubscriptionData;
        });
    }
    return memo[email];
  }
);
