import { LibCategories, waitForConsent } from "./consent";
import { NoomError } from "./error/NoomError";
import { trackEventIfNotUnloading } from "./lifecycle";
import { trackTaskDelay } from "./monitoring/events/tasks";

const loaderCache: Record<string, Promise<void>> = {};

class ScriptLoadError extends NoomError {
  constructor(public readonly src: string) {
    super(`Failed to load script: ${src}`);
  }

  override trackMetric() {
    trackEventIfNotUnloading("BuyflowTaskFailed", {
      taskName: "ScriptLoad",
      src: this.src,
    });
    return true;
  }
}

type HtmlAttrTypes = Partial<HTMLScriptElement>;

export const pendingScripts = new Set<string>();

export async function loadScript(
  src: string,
  categories: [LibCategories, ...LibCategories[]],
  {
    enableCORS = true,
    htmlAttr: { dataset, ...htmlAttributes } = {},
  }: {
    enableCORS?: boolean;
    htmlAttr?: HtmlAttrTypes;
  } = {}
): Promise<void> {
  pendingScripts.add(src);
  await waitForConsent(categories);

  if (!loaderCache[src]) {
    loaderCache[src] = new Promise((resolve, reject) => {
      const cancelDelayTracker = trackTaskDelay("LoadScript", 5000, { src });

      const script = document.createElement("script");
      if (htmlAttributes) {
        Object.assign(script, htmlAttributes);
      }
      if (dataset) {
        Object.assign(script.dataset, dataset);
      }

      // Before setting src
      script.onload = () => {
        cancelDelayTracker();
        pendingScripts.delete(src);

        resolve();
      };

      script.onerror = () => {
        cancelDelayTracker();
        pendingScripts.delete(src);

        // Purge cache to allow retry
        delete loaderCache[src];

        reject(new ScriptLoadError(src));
      };

      script.async = true;
      if (enableCORS) {
        script.crossOrigin = "anonymous";
      }

      // Do these last
      script.src = src;
      document.body.appendChild(script);
    });
  }
  return loaderCache[src];
}
