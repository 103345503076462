import { getNoomSessionStorage } from "@utils/noomSessionStorage";
import { SessionStorageKey } from "@utils/constants";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import {
  QNamesToQText,
  QTypes,
  whyDidYouSelectOptionsGoodExperience,
  whyDidYouSelectOptionsPoorExperience,
} from "src/pages/telehealth/feedback/feedbackSurveyEnums";
import { trackEvent } from "@utils/api/tracker";
import { sendBeacon } from "@utils/fetch";
import uuid from "uuid";
import {
  TelehealthSurveyNames,
  TrackBuyflowEventFinishedSurvey,
} from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/telehealthCrmUtils";
import { sendTelehealthFeedbackSurveyResponse } from "src/funnels/insurance-reimbursement/requests/sendTelehealthFeedbackSurveyResponse";

// Expand multiple choice options to save their full text form
const expandTextOptions = (
  source: Record<string, string>,
  options?: string[] | object[]
): string[] | undefined => {
  return options
    ? options.map((v) => (v?.other !== undefined ? v.other : source[v]))
    : undefined; // Returning undefined
};

const conditionalProperty = (key: string, value: any) => {
  return key ? value : {};
};

export const onCompleteTelehealthFeedbackSurvey = async () => {
  const {
    howWasYourVisit,
    whyRating,
    whyDidYouSelectLow,
    whyDidYouSelectHigh,
  } = getSurveyAnswers();

  const consultId = getNoomSessionStorage(
    SessionStorageKey.consultGuidFinishedOrScheduled
  );

  if (!consultId) {
    return;
  }

  sendTelehealthFeedbackSurveyResponse(consultId, "Survey_V2", {
    howWasYourVisit: {
      text: QNamesToQText.howWasYourVisit,
      type: QTypes.singleChoice,
      answer: howWasYourVisit[0], // value is single element array
    },
    // spread (...) operator used to only send payloads that we have answers for
    ...conditionalProperty(whyRating, {
      whyRating: {
        text: QNamesToQText.whyRating,
        type: QTypes.openText,
        answer: whyRating, // value is single element array
      },
    }),
    ...conditionalProperty(whyDidYouSelectLow, {
      whyDidYouSelectLow: {
        text: QNamesToQText.whyDidYouSelectLow,
        type: QTypes.multipleChoiceWithOtherText,
        answer: expandTextOptions(
          whyDidYouSelectOptionsPoorExperience,
          whyDidYouSelectLow
        ), // ["a", "b", ... {"other": "other text"}]
      },
    }),
    ...conditionalProperty(whyDidYouSelectHigh, {
      whyDidYouSelectHigh: {
        text: QNamesToQText.whyDidYouSelectHigh,
        type: QTypes.multipleChoiceWithOtherText,
        answer: expandTextOptions(
          whyDidYouSelectOptionsGoodExperience,
          whyDidYouSelectHigh
        ), // ["a", "b", ... {"other": "other text"}]
      },
    }),
  });

  // The "Finished{SurveyName} event is usually logged when "saveAnswers" is called.
  // However, we avoid that call to avoid saving data to the userData blob.
  // Product would still like an event along the same lines with question answers
  // as properties. Log it, however scrubbed of open-text responses.
  //
  // Referrer tracks how the user entered the CSAT survey.
  const referrer = new URLSearchParams(window.location.search).get("referrer");
  const properties = {
    howWasYourVisit,
    ...conditionalProperty(whyDidYouSelectLow, {
      whyDidYouSelectLow: whyDidYouSelectLow?.filter((v) => !v?.other),
    }),
    ...conditionalProperty(whyDidYouSelectHigh, {
      whyDidYouSelectHigh: whyDidYouSelectHigh?.filter((v) => !v?.other),
    }),
    whyRating: whyRating ? "REDACTED" : "SKIPPED",
    patientType: getNoomSessionStorage(SessionStorageKey.patientType),
    referrer,
  };
  trackEvent("FinishedTelehealthFeedbackSurvey", { ...properties });
  TrackBuyflowEventFinishedSurvey(TelehealthSurveyNames.FeedbackSurvey);
  sendBeacon("/pixel/v1/i/dataStreamEventTracking/", {
    sharedProperties: {
      eventTimestamp: new Date(),
      eventUuid: uuid.v4(),
    },
    feedbackSurveyFinished: {
      ...properties,
    },
  });
};
