import { CHART_WIDTH_TICKS, getNormalizedValueFromTick } from "./ticks";

// Utilities mapping chart x-coordinate [ticks](./ticks.js) into y coordinate values

export function getTickFromTargetWeight(
  startWeight: number,
  endWeight: number,
  targetWeight: number
) {
  const points = getRealPoints(CHART_WIDTH_TICKS);

  const yCoord =
    (1 - (targetWeight - startWeight) / (endWeight - startWeight)) * 100;

  // Walk the curve values until we find the closest match.
  for (let i = 0; i < points.length; i++) {
    if (yCoord >= points[i]) {
      return i;
    }
  }
  return 0;
}

export function getRealPoints(numPoints) {
  const dataPoints = new Array(numPoints)
    .fill("")
    .map((_, i) => i)
    .map((num) => calculateYCoords(num));
  return dataPoints;
}

export function getVisualPoints(numPoints) {
  const dataPoints = new Array(numPoints)
    .fill("")
    .map((_, i) => i)
    .map((num) => calculateYCoords(num, visualFormula));
  return dataPoints;
}

function calculateYCoords(tick, formula = realFormula) {
  const weightedTick = getNormalizedValueFromTick(tick);
  let value;
  if (weightedTick < 0) {
    value = 1;
  } else if (weightedTick > 1) {
    value = 0;
  } else {
    value = formula(weightedTick);
  }
  return value * 100;
}

// Domain x [0, 1]
// This formula represents the weight loss curve generated using actual data. This formula
// should be used for any calculations involving weight loss amounts at certain dates or in
// any situation where we would be displaying exact values to the user

export function realFormula(x) {
  return 1 - x;
}

// Domain x [0, 1]
// This formula is a slightly tweaked version of the above formula so that it looks better when
// plotted on the weight loss graph (mainly making sure that hard angles are smoothed out a bit).
// This should only be used to calculate the points needed to plot the weight loss graph.
export function visualFormula(x) {
  return (
    (-1.188506 + (1 - -1.188506) / (1 + (x / 1.121455) ** 1.506598)) * 0.6 +
    (1 - x) * 0.4
  );
}
