import * as Sentry from "@sentry/browser";
import type { Context, Transport } from "@sentry/types";
import {
  getActiveConfigurationExperiments,
  getCountryCode,
  getExperimentState,
  getLanguage,
  getMeristemContext,
  getMeristemState,
  getSha,
  getSubdivision,
} from "../meristemContext";
import { appConfig } from "src/config";
import { NoomError } from "./NoomError";
import { beforeSend } from "./before-send";
import { addMonitoringEventHandler } from "../monitoring/bus";
import { USER_IS_BOT } from "../botDetector";

let sentryInit = false;

addMonitoringEventHandler((event) => {
  if (event.type === "errorMessage") {
    captureMessageWithExtras(event);
  } else if (event.type === "exception") {
    captureException(event.exception, event.fingerprint, event.extras);
  }
});

/**
 * Wrap the sentry initialization logic so we can place it where we'd like.
 */
export function initializeSentry(
  /** for testing */ transport?: () => Transport
) {
  if (sentryInit || USER_IS_BOT) {
    return;
  }

  sentryInit = true;

  Sentry.init({
    dsn: appConfig.SENTRY_PROJECT_DSN_JS,
    release: getSha(),
    environment: appConfig.NOOM_ENV,
    transport,
    allowUrls: [
      __NODE_ENV__ !== "production" && /localhost:8080/,
      __NODE_ENV__ !== "production" && /sentry.test/,
      /webpack-internal:\/\//,
      /noom\.com/,
      /buyflow-lambda\.(dev|test)\.wsli\.dev/,
      /buyflow-web-assets\.test\.wsli\.dev/,
    ].filter(Boolean),
    ignoreErrors: [
      "Failed to fetch",
      "The operation couldn’t be completed. Software caused connection abort",
      "cancelled",
      "cancelado",
      "The request timed out.",
      "The Internet connection appears to be offline.",
      "The network connection was lost.",
      "Object Not Found Matching Id:",
      "document.getElementsByTagName('video')[0].webkitExitFullScreen",
      "setIOSParameters",

      // Seen in global ignore lists.
      "ceCurrentVideo.currentTime",
      "instantSearchSDKJSBridgeClearHighlight",
    ],
    beforeSend,
    normalizeDepth: 4,
  });

  // Add context to the error to help pinpoint SHA and experiment.
  try {
    const meristemContext = getMeristemContext();

    const context: Context = {
      sha: getSha(),
      city_name: meristemContext.city_name,
      country_code: getCountryCode(),
      postal_code: meristemContext.postal_code,
      subdivision: getSubdivision(),
      language_code: getLanguage(),
      meristemStateId: `${getMeristemState().id}`,
    };

    getExperimentState().forEach((experiment) => {
      if (experiment.shaOverride) {
        context.shaOverride = experiment.shaOverride;
      } else {
        context[
          `Experiment ${experiment.experimentName}`
        ] = `${experiment.variationName}`;

        Sentry.setTag(`Experiment`, experiment.experimentName);
        Sentry.setTag(`Variation`, experiment.variationName);
      }
    });

    getActiveConfigurationExperiments().forEach((experiment) => {
      context[
        `Experiment ${experiment.experimentName}`
      ] = `${experiment.variationName}`;

      Sentry.setTag(`Experiment`, experiment.experimentName);
      Sentry.setTag(`Variation`, experiment.variationName);
    });

    Sentry.setContext("Meristem Context", context);
  } catch (err) {
    /** NOP */
  }
}

/**
 * Small utility to set the user id in the sentry scope whenever we assign the
 * user an id.
 */
export function setUserIdInSentryScope(userId: string) {
  if (!userId) return;
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: userId,
    });
  });
}

/**
 * Capture an exception and send it to Sentry. Optionally set a fingerprint
 * so errors of the same type are grouped together even if different browsers
 * throw different errors.
 */
function captureException(
  e: (Error & { error?: unknown }) | NoomError | any,
  fingerprint?: string[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  extras?: {}
) {
  initializeSentry(); // In case Sentry is not initialized yet

  if (__NODE_ENV__ !== "production" && !appConfig.SENTRY_PROJECT_DSN_JS) {
    if (e.trackMetric?.()) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error("captureException", e, fingerprint);
    return;
  }

  if (!e) {
    Sentry.captureException(new Error("No error provided"));
    return;
  }

  Sentry.withScope((scope) => {
    if (fingerprint) {
      scope.setFingerprint(fingerprint);
    }

    if (extras) {
      Object.keys(extras).forEach((key) => {
        scope.setExtra(key, extras[key]);
      });
    }

    try {
      if ("error" in e) {
        scope.setExtra("error", e.error);
      }
    } catch (err) {
      /* NOP */
    }

    Sentry.captureException(e);
  });
}

/**
 * Send a custom message to Sentry with attached properties.
 */
function captureMessageWithExtras({
  message,
  extras,
  fingerprint,
}: {
  message: string;
  extras?: PropertyBag;
  fingerprint?: string[];
}) {
  Sentry.captureMessage(message, {
    extra: extras,
    fingerprint,
  });
}
