import { sendBeacon } from "../fetch";
import { getRequestMetadata } from "../meristemContext";
import { addMonitoringEventHandler } from "./bus";
import { mapEventName } from "./event-map";
import { maybeTrackExperimentStarted } from "./experiment-started";
import { generateMonitoringProps } from "./prop-registry";

addMonitoringEventHandler((event) => {
  if (event.type === "people_set") {
    pixelPeopleSet(event.properties);
  } else if (event.type === "track") {
    pixelTrackEvent(event.eventName, event.properties);
  } else if (event.type === "flush") {
    processQueueBatchedEvents();
  }
});

interface TrackPixelEvent {
  eventName: string;
  eventProperties: Record<string, JsonObject>;
}

/**
 * Set people properties using the growth pixel.
 */
function pixelPeopleSet(properties: JsonObject) {
  const people = JSON.stringify(properties);
  if (people === "{}") return;

  sendPixelRequest("people_set", {
    people,
  });
}

/**
 * Track events using the growth pixel.
 */
function pixelTrackEvent(name: string, properties: JsonObject) {
  const { meristemTrackingProperties } = getRequestMetadata();

  const eventProperties = {
    ...meristemTrackingProperties,
    ...generateMonitoringProps(),
    ...properties,
  };

  maybeTrackExperimentStarted(name, eventProperties);

  const trackingEvent: TrackPixelEvent = {
    eventName: mapEventName(name),
    eventProperties,
  };
  sendTrackingBatchedPixelEvent(trackingEvent);
}

function sendPixelRequest(
  requestType: string,
  requestBody: JsonObject,
  version = "v2"
) {
  if (typeof Cypress !== "undefined") {
    return;
  }
  // Remove Google Go's read aloud bot from traffic as this results in overcounting for traffic, without conversions.
  // Based on the evidence we could put together, this is run in conjunction with the actual browser, which itself
  // reports correctly.
  if (navigator.userAgent.includes("Google-Read-Aloud")) {
    return;
  }

  sendBeacon(`/pixel/${version}/i/${requestType}/`, requestBody);
}

function sendTrackingBatchedPixelEvent(trackingEvent: TrackPixelEvent) {
  if (typeof Cypress !== "undefined") {
    return;
  }
  // Remove Google Go's read aloud bot from traffic as this results in overcounting for traffic, without conversions.
  // Based on the evidence we could put together, this is run in conjunction with the actual browser, which itself
  // reports correctly.
  if (navigator.userAgent.includes("Google-Read-Aloud")) {
    return;
  }

  debounceSendBeacon(trackingEvent, 150);
}

const trackingEventsQueue: JsonObject[] = [];
let debounceTimeout;

/**
 * Method used for processing the batched events from the queue.
 * This will be used when we need to force the queue processing. For example, before rerolling to another context
 */
function processQueueBatchedEvents() {
  try {
    const currentUrl = document.URL;
    if (trackingEventsQueue.length > 0) {
      sendBeacon(`/pixel/v3/i/track/batch/`, {
        events: trackingEventsQueue,
        currentUrl,
      });
      trackingEventsQueue.length = 0;
    }
  } catch (e) {
    /* NOP */
  }
}

function debounceSendBeacon(trackingEvent: JsonObject, delay = 150) {
  trackingEventsQueue.push(trackingEvent);

  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(processQueueBatchedEvents, delay);
}
