import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";
import { JsonObject } from "type-fest";

declare const UET: any;
declare const window: Window & {
  uetq: any;
};

window.uetq = window.uetq || [];
let bingInit = false;

export function loadBing() {
  return loadScript("//bat.bing.com/bat.js", [LibCategories.targetingCookies], {
    enableCORS: false,
  }).then(
    () => {
      if (!bingInit) {
        try {
          const o = { ti: "5725697", q: window.uetq };
          window.uetq = new UET(o);
          window.uetq.push("pageLoad");
          bingInit = true;
        } catch (err) {
          /* NOP */
        }
      }
      return window.uetq as JsonObject[];
    },
    () => {
      // To the ether...
      return window.uetq as JsonObject[];
    }
  );
}
