// March 2021: As a temporary solution we will adjust eLTV Value Passback to FB on the growth side.
// Below we have the adjustment rates for multiple campaigns and countries.

import { CountryCode } from "../meristemContext";

const TUBE_SCIENCE_AD_NAME = "TS";
const NARRATIVE = "NRV";

// TubeScience(TS) Media Fee Adjustment.
export const ELTV_ADJUSTMENT_VALUES_TS: Record<string, number> = {
  US: 6.42,
  CA: 6.42,
  GB: 4.22,
  UK: 4.22,
  IE: 4.21,
  AU: 5.29,
  NZ: 5.37,
};

// Narrative (NRV) Media Fee Adjustment.
export const ELTV_ADJUSTMENT_VALUES_NRV: Record<string, number> = {
  US: 5.08,
  CA: 5.47,
  GB: 3.91,
  UK: 3.91,
  IE: 3.35,
  AU: 5.72,
  NZ: 5.77,
};

export function extractAdName() {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  return searchParams.get("ad_name");
}

export function shouldAdjustELTV() {
  const adName = extractAdName();
  return (
    !!adName &&
    (adName.startsWith(TUBE_SCIENCE_AD_NAME) || adName.startsWith(NARRATIVE))
  );
}

export function adjustELTV(value: number, country: CountryCode) {
  const adName = extractAdName();
  if (
    !!adName &&
    adName.startsWith(TUBE_SCIENCE_AD_NAME) &&
    ELTV_ADJUSTMENT_VALUES_TS[country]
  ) {
    return value - ELTV_ADJUSTMENT_VALUES_TS[country];
  }
  if (
    !!adName &&
    adName.startsWith(NARRATIVE) &&
    ELTV_ADJUSTMENT_VALUES_NRV[country]
  ) {
    return value - ELTV_ADJUSTMENT_VALUES_NRV[country];
  }
  return value;
}
