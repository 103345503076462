import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { convertLbToKg } from "src/primitive/UnitUtils";
import { getSurveyAnswers } from "src/hooks/survey/answers";

const MIN_PACE_PER_GRAPH_LBS = [0.73, 0.78, 0.83, 1];
const MAX_PACE_PER_GRAPH_LBS = [0.85, 0.9, 0.95, 1];

function toKg(lbs: number) {
  return convertLbToKg({ mainUnitValue: lbs }).mainUnitValue;
}

export function getNursingPace(pace, graphIndex) {
  const normalizedPace = normalizePace(pace);
  const minPace = toKg(MIN_PACE_PER_GRAPH_LBS[graphIndex]);
  const maxPace = toKg(MAX_PACE_PER_GRAPH_LBS[graphIndex]);

  const paceRatio = (normalizedPace - toKg(1)) / (toKg(2) - toKg(1));

  return minPace + (maxPace - minPace) * paceRatio;
}

function normalizePace(pace: number): number {
  if (pace <= toKg(1)) {
    return toKg(1);
  }
  if (pace >= toKg(2)) {
    return toKg(2);
  }
  return pace;
}

export function getAdjustedTimeNursing(
  pace: number,
  graphIndex: number,
  targetInKg: number
) {
  const days = (targetInKg / pace) * 7;
  return Math.round(days);
}

export function isNursing(
  surveyAnswers: SurveyAnswersState = getSurveyAnswers()
) {
  return surveyAnswers?.pregnant?.includes("noButNursing");
}
