import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { getMeristemContext } from "src/utils/meristemContext";

type QuestionHistoryState = {
  questionContexts: {
    [context: string]: (keyof SurveyAnswersState)[];
  };
};

// TODO: Seed this from prior session data?

// Records when a particular question was answered in the current session.
const questionHistorySlice = createSlice({
  name: "questionHistory",
  initialState: { questionContexts: {} } as QuestionHistoryState,
  reducers: {
    recordQuestionHistory(
      state,
      action: PayloadAction<keyof SurveyAnswersState>
    ) {
      const answeredQuestion = action.payload;
      const contextType = getMeristemContext().context_type;

      const currentContext = state.questionContexts[contextType];
      if (currentContext && currentContext.includes(answeredQuestion)) {
        return state;
      }

      return {
        ...state,
        questionContexts: {
          ...state.questionContexts,
          [contextType]: [
            ...(state.questionContexts[contextType] || []),
            answeredQuestion,
          ],
        },
      };
    },
    resetQuestionHistory(state) {
      return {
        ...state,
        questionContexts: {},
      };
    },
  },
});

export const { recordQuestionHistory, resetQuestionHistory } =
  questionHistorySlice.actions;

export default questionHistorySlice;
