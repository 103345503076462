import { getAccessToken, getInAppAutoCookie } from "../authCookieParser";
import { sendBeacon } from "../fetch";
import { addMonitoringEventHandler } from "./bus";
import uuid from "uuid";
import { appConfig } from "src/config";
import {
  MMPEventProto_MMPPlatform,
  MMPEventProto_SharedPropertiesProto,
} from "@noom/noom-contracts/noom_contracts/events/mobile_measurement_partner/mmp_event";
import getStore from "../redux/store";
import { singularSdk } from "singular-sdk";
import { getRequestMetadata } from "../meristemContext";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "../noomSessionStorage";
import { SessionStorageKey } from "../constants";
import { getOrCreatePartnerMapping } from "../api/partnerMappings";
import { captureException, captureMessageWithExtras } from "../error";
import invariant from "tiny-invariant";
import { LibCategories, waitForConsent } from "../consent";

const SINGULAR_PARTNER_SLUG = "SINGULAR";

async function getSingularPartnerUserId(): Promise<string> {
  // If the customUserId is set in the in-app cookie, use that
  const inAppAuthCookie = getInAppAutoCookie();
  if (inAppAuthCookie?.customUserId) {
    return inAppAuthCookie.customUserId;
  }

  // If the partnerUserId is set in session storage, use that
  const partnerUserIdFromSession = getNoomSessionStorage(
    SessionStorageKey.singularPartnerUserId
  );
  if (partnerUserIdFromSession) {
    return partnerUserIdFromSession;
  }

  const accessToken = getAccessToken();
  const accessCode = getStore().getState().userData.access_code;
  if (!accessToken || !accessCode) {
    return null;
  }
  // If not, fetch it / generate it from the server
  try {
    const partnerMapping = await getOrCreatePartnerMapping({
      accessCode,
      partnerSlug: SINGULAR_PARTNER_SLUG,
    });
    invariant(partnerMapping.partnerUserId, "SINGULAR partnerUserId not found");
    setNoomSessionStorage(
      SessionStorageKey.singularPartnerUserId,
      partnerMapping.partnerUserId
    );
    return partnerMapping.partnerUserId;
  } catch (e) {
    captureException(e);
    return null;
  }
}

addMonitoringEventHandler((event) => {
  if (event.type === "mmpEvent") {
    sendMmpEvent(event.eventName, event.properties);
  }
});

function getSessionId(mmpContext): string {
  if (mmpContext) {
    return undefined; // It means the user is in a webview, so we can discard this field
  }

  if (appConfig.NOOM_ENV !== "production") {
    // Hardcoded ID for testing used in non-production env
    return "DFC5A647-9043-4699-B2A5-76F03A97064B";
  }

  /* eslint-disable-next-line no-underscore-dangle */
  if (!singularSdk._isInitialized) {
    captureMessageWithExtras("Error: Singular SDK could not be initialized.", {
      isKeyFalsy: !appConfig.SINGULAR_SDK_KEY,
      isSecretFalsy: !appConfig.SINGULAR_SDK_SECRET,
      isProductIdFalsy: !appConfig.SINGULAR_SDK_PRODUCT_ID,
    });
    return null;
  }

  const deviceId = singularSdk.getSingularDeviceId(); // This might return null if the SDK hasn't finished initializing yet
  if (!deviceId) {
    captureMessageWithExtras(
      "Error: even though the Singular SDK is in fact initialized, it could not get the device ID (session ID).",
      { deviceId }
    );
  }

  return deviceId;
}

function getPlatform(mmpContext): MMPEventProto_MMPPlatform {
  if (mmpContext) {
    if (!mmpContext.platform) {
      captureMessageWithExtras(
        "Error: required field 'platform' is invalid or undefined",
        { mmpContext }
      );
      return "PLATFORM_UNKNOWN";
    }
    return mmpContext.platform.toUpperCase();
  }

  return "WEB";
}

// Note (Pedro): here we want different values for when the user is coming through
// a webview (has the in-app cookie set from mobile) or a regular web page,
// which then should use info from the Singular SDK & related sources.
async function buildSharedProperties() {
  const mmpContext = getInAppAutoCookie();

  const { userIp } = getRequestMetadata();

  const platform = getPlatform(mmpContext);

  const props: MMPEventProto_SharedPropertiesProto = {
    customUserId: await getSingularPartnerUserId(),
    growthUserId: getStore().getState().userData.user_id,
    platform,
    packageId: mmpContext?.packageId ?? appConfig.SINGULAR_SDK_PRODUCT_ID,
    ip: mmpContext?.deviceIP ?? userIp ?? "0.0.0.0", // Note(Pedro): default to 0.0.0.0 just so the event doesn't fail since it's a required field
    googleAdId: platform === "ANDROID" ? mmpContext.idForAdvertiser : undefined,
    androidId: platform === "ANDROID" ? mmpContext.androidId : undefined,
    appSetId: platform === "ANDROID" ? mmpContext.appSetId : undefined,
    iosAdId: platform === "IOS" ? mmpContext.iosAdId : undefined,
    identifierForVendor:
      platform === "IOS" ? mmpContext.idForVendor : undefined,
    sessionId: getSessionId(mmpContext),
    osVersion: mmpContext?.osVersion,
    attAuthorizationStatus:
      platform === "IOS" ? Number(mmpContext.attStatus) : undefined,
    limitDataSharing:
      mmpContext?.limitDataSharing === "true" ? true : undefined,
    appVersion: mmpContext?.appVersion,
  };

  return props;
}

async function sendMmpEvent(eventName: string, properties: JsonObject) {
  // Note (pedroa): we should only fire MMP events once the user consents
  // with targeting cookies. Even though they are not being sent directly
  // to Singular, the mmp-connector will get them and send them to Singular
  // regardless of user consent.
  waitForConsent([LibCategories.targetingCookies]).then(async () => {
    const uncapitalize = (str: string) =>
      str.charAt(0).toLocaleLowerCase() + str.slice(1);
    const eventNameKey = uncapitalize(eventName);
    const sharedProperties = await buildSharedProperties();

    sendBeacon("/pixel/v1/i/dataStreamEventTracking/", {
      sharedProperties: {
        eventTimestamp: new Date(),
        eventUuid: uuid.v4(),
        blockRoutingToMixpanel: true,
      },
      mmpEvent: {
        [eventNameKey]: {
          ...properties,
        },
        sharedProperties,
      },
    });
  });
}
