import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServerContextInit } from "./serverContext";
import { isTrialFeeWaived } from "src/utils/userSegment/features";

export interface TrialState {
  trialFee?: number;
  trialFeeWaived: boolean;
  hasTrialFee: boolean;
}

const initialState: TrialState = {
  trialFeeWaived: false,
  trialFee: undefined,
  hasTrialFee: false,
};

const trialSlice = createSlice({
  name: "trial",
  initialState,
  reducers: {
    updateTrialFromServerContext: {
      reducer: (state, action: PayloadAction<TrialState>) => {
        return { ...state, ...action.payload };
      },
      prepare: (serverContext: ServerContextInit) => {
        let trialFee = 0;
        if (serverContext?.plan) {
          trialFee = serverContext.plan.trial_fee;
        }
        return {
          payload: {
            trialFee,
            trialFeeWaived: isTrialFeeWaived(),
            hasTrialFee: !isTrialFeeWaived() && trialFee > 0,
          },
        };
      },
    },
  },
});

const { actions } = trialSlice;

export const { updateTrialFromServerContext } = actions;

export default trialSlice;
