import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProgramSwitchErrorDetailsState {
  code: string;
  message: string;
  status: number;
}

const programSwitchErrorDetailsSlice = createSlice({
  name: "programSwitchErrorDetails",
  initialState: {} as ProgramSwitchErrorDetailsState,
  reducers: {
    setProgramSwitchErrorDetails(
      state,
      action: PayloadAction<ProgramSwitchErrorDetailsState>
    ) {
      // Does not support partial updates
      return { ...action.payload };
    },
  },
});

export const { setProgramSwitchErrorDetails } =
  programSwitchErrorDetailsSlice.actions;

export default programSwitchErrorDetailsSlice;
