import { logInfo } from "src/utils/monitoring/logging";
import { CoreReduxState } from "src/utils/redux/store";
import { getPageSet, getPageSetFromPath } from ".";
import { emitPageSetEvents } from "./bus";

import type { Location } from "history";

export async function resolveParamPageSet(
  state: CoreReduxState,
  location: Location
) {
  const persistedPageSet = getPageSet(state.linearBuyflow.pageSetName);
  // Urls set on query params override current
  const discoveredPageSet = await getPageSetFromPath(location.pathname, state);
  if (discoveredPageSet && discoveredPageSet !== persistedPageSet) {
    logInfo(`Setting active page set to ${discoveredPageSet.id}`);
    emitPageSetEvents("set:page-set", {
      activePageSet: discoveredPageSet,

      // These aren't available yet so make the listener figure it out.
      history: null,
      store: null,
    });
    return discoveredPageSet;
  }
  return undefined;
}
