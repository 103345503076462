import invariant from "tiny-invariant";
import { ReadonlyDeep } from "type-fest";
import { trackBuyflowEvent, trackEvent } from "../api/tracker";
import { getStoredExperiments } from "../experiment";
import {
  getActiveConfigurationExperiments,
  getMeristemState,
  MeristemExperiment,
  Targeting,
} from "../meristemContext";
import { mapEventName } from "./event-map";
import camelCase from "lodash/camelCase";

export function trackExperimentStartedEvent(
  experimentName: string,
  variationName: string
) {
  trackEvent("$experiment_started", {
    "Experiment name": `Meristem ${experimentName}`,
    "Variant name": variationName,
  });
  trackBuyflowEvent(
    "ExperimentStarted",
    {
      experimentName: `Meristem ${experimentName}`,
      variantName: variationName,
    },
    { blockRoutingToMixpanel: true }
  );
}

/**
 * Determine if we should fire off an Experiment Started tracking event based on the currently
 * enrolled experiment(s), eventName and properties
 */
export function maybeTrackExperimentStarted(
  eventName: string,
  properties: JsonObject
) {
  // Experiments that the user is currently enrolled in
  const experimentStates = getStoredExperiments();
  if (experimentStates) {
    experimentStates.forEach((experimentState) => {
      const { experimentName, variationName } = experimentState;
      const resolvedEventName = mapEventName(eventName) || "";
      // Look for start event belonging to our current experiment that matches our eventName
      const startEvent = extractStartEvent(experimentName, resolvedEventName);
      const shouldFire = matchesStartEventFilters(
        { experimentName, variationName },
        properties,
        startEvent
      );
      if (shouldFire) {
        trackExperimentStartedEvent(experimentName, variationName);
      }
    });
  }

  const activeConfigurationExperiments = getActiveConfigurationExperiments();
  (activeConfigurationExperiments ?? []).forEach(
    (activeConfigurationExperimentDefinition) => {
      const { experimentName, variationName, experimentData } =
        activeConfigurationExperimentDefinition;
      const resolvedEventName = mapEventName(eventName);
      // Look for start event belonging to our current experiment that matches our eventName
      const startEvent = extractStartEventFromExperiment(
        experimentData,
        resolvedEventName
      );
      const shouldFire = matchesStartEventFilters(
        { experimentName, variationName },
        properties,
        startEvent
      );
      if (shouldFire) {
        trackExperimentStartedEvent(experimentName, variationName);
      }
    }
  );
}

export function extractStartEvent(experimentName: string, eventName: string) {
  const meristemExperiment = getMeristemState()?.experiments?.find(
    (experiment) => experiment.name === experimentName
  );
  return extractStartEventFromExperiment(meristemExperiment, eventName);
}

export function extractStartEventFromExperiment(
  meristemExperiment: ReadonlyDeep<MeristemExperiment>,
  eventName: string
) {
  if (meristemExperiment && meristemExperiment.startEvents) {
    const lowerCaseEventName = eventName.toLowerCase();
    // For Noom Events, event names are in snake_case but the class/message names are in PascalCase.
    // Convert to camelCase before making lowercase to support either form of event name.
    const startEvent = meristemExperiment.startEvents.find(
      (needle) => camelCase(needle.name).toLowerCase() === lowerCaseEventName
    );
    if (startEvent) {
      return startEvent;
    }
  }
  return null;
}

/**
 * Determines if the provided properties matches the given startEvent
 * and its filters (if there are any)
 */
export function matchesStartEventFilters(
  experiment: { experimentName: string; variationName: string },
  properties: JsonObject,
  startEvent: ReadonlyDeep<{ filters?: Targeting[] }>
) {
  let startEventValid = false;
  if (startEvent) {
    startEventValid = true;
    // Check if the properties passed in passes the provided filters
    // Each filter behaves as an additional AND conditional
    if (startEvent.filters) {
      for (let i = 0; i < startEvent.filters.length; i += 1) {
        const { key, operator, value } = startEvent.filters[i];
        // For Noom Events, in noom-contracts properties are specified in snake_case,
        // but the JSON serialization format uses camelCase instead. Normalizing to camelCase
        // ensures that filters written with snake_case work correctly.
        // We do this normalization regardless of the event source because it's essentially
        // a no-op for Mixpanel events.
        const normalizedKey = camelCase(key);
        if (operator === "IS SET") {
          startEventValid = Object.prototype.hasOwnProperty.call(
            properties,
            normalizedKey
          );
        } else if (operator === "EQUALS") {
          startEventValid = filterValueEquals(
            experiment,
            properties[normalizedKey],
            value
          );
        } else if (operator === "IN") {
          invariant(Array.isArray(value), "value must be an array");
          startEventValid = value.some((needle) =>
            filterValueEquals(experiment, properties[normalizedKey], needle)
          );
        } else if (operator === "NOT EQUALS") {
          startEventValid = !filterValueEquals(
            experiment,
            properties[normalizedKey],
            value
          );
        } else if (operator === "NOT IN") {
          invariant(Array.isArray(value), "value must be an array");
          startEventValid = !value.some((needle) =>
            filterValueEquals(experiment, properties[normalizedKey], needle)
          );
        }
        if (!startEventValid) {
          // Break early if a filter does not match
          break;
        }
      }
    }
  }
  return startEventValid;
}

function filterValueEquals(
  experiment: { experimentName: string; variationName: string },
  value: any,
  filterValue: any
) {
  // Note: The filter value in Meristem is stored as a string for older
  // experiments, but an array (of a single item) for newer ones.
  let actualFilterValue;
  if (typeof filterValue === "string") {
    actualFilterValue = filterValue;
  } else if (Array.isArray(filterValue) && filterValue.length === 1) {
    actualFilterValue = filterValue[0];
  } else {
    const { experimentName, variationName } = experiment;
    trackEvent("TooManyOptionsForEquals", {
      numProperties: filterValue.length,
      value: JSON.stringify(filterValue),
      experiment: `${experimentName}.${variationName}`,
    });
  }

  if (actualFilterValue === "true") {
    return value === true;
  }
  if (actualFilterValue === "false") {
    return value === false;
  }
  return value === actualFilterValue || +actualFilterValue === value;
}
