import {
  HOLIDAY_PROMO_CODE,
  HOLIDAY_PROMO_CONFIG,
  HOLIDAY_PROMO_TYPE,
  HolidayPromoConfig,
  MonthsOffPromoConfig,
  PROMO_BANNER_ACTIVATION_TYPE,
  PROMO_TYPE,
  discountedPlanMapping,
  priceMapping,
  replacementPromoCodeMapping,
} from "./holidayPromoConstants";
import { getRouteId } from "./meristemContext";
import { getUserCurrency } from "./plans";
import { formatPrice } from "./priceUtils";
import { getURLParams } from "./urlParams";

export function isHolidayPromotion() {
  return getCurrentHolidayPromoType() !== undefined;
}

export function getHolidayPromoCode() {
  const holidayPromoConfig = getCurrentHolidayPromoConfig();
  return holidayPromoConfig?.uiPromoCode;
}

export function shouldAutoApplyHolidayPromoCode() {
  const holidayPromoConfig = getCurrentHolidayPromoConfig();
  return holidayPromoConfig?.autoApplyPromoCode;
}

export function getCurrentHolidayPromoType(promoCode?: HOLIDAY_PROMO_CODE) {
  return promoCode
    ? getHolidayPromoTypeForPromoCode(promoCode)
    : getEligibleHolidayPromoType();
}

export function getCurrentHolidayPromoConfig(promoCode?: HOLIDAY_PROMO_CODE) {
  const promoType = getCurrentHolidayPromoType(promoCode);
  return HOLIDAY_PROMO_CONFIG[promoType];
}

export function isFreeMonthsPromoConfig(
  promoConfig: HolidayPromoConfig
): promoConfig is MonthsOffPromoConfig {
  return promoConfig.promoType === PROMO_TYPE.MONTHS_OFF;
}

export function isHolidayPromoCode(
  initialPromoCode: string
): initialPromoCode is HOLIDAY_PROMO_CODE {
  return initialPromoCode in HOLIDAY_PROMO_CODE;
}

export function canApplyHolidayPromoCode(promoCode: HOLIDAY_PROMO_CODE) {
  const holidayPromoConfig = getCurrentHolidayPromoConfig(promoCode);
  if (holidayPromoConfig) {
    return (
      holidayPromoConfig.isUserSegmentEligible() &&
      holidayPromoConfig.eligibleRoutes.includes(getRouteId())
    );
  }

  return false;
}

export function getEligibleHolidayPromoType(): HOLIDAY_PROMO_TYPE {
  const urlParams = getURLParams();
  const promo = urlParams?.promo;

  const eligiblePromoType = Object.keys(HOLIDAY_PROMO_TYPE).find(
    (promoType) => {
      const config = HOLIDAY_PROMO_CONFIG[promoType];
      const baseEligibilityCheck =
        config.isUserSegmentEligible() &&
        config.eligibleRoutes.includes(getRouteId());
      if (
        config.bannerActivationType === PROMO_BANNER_ACTIVATION_TYPE.DATE_RANGE
      ) {
        const currentTime = new Date();
        return (
          baseEligibilityCheck &&
          currentTime >= config.activeFrom &&
          currentTime < config.activeTo
        );
      }
      return baseEligibilityCheck && config.urlPromoValue === promo;
    }
  );

  return eligiblePromoType as HOLIDAY_PROMO_TYPE;
}

export function getHolidayPromoTypeForPromoCode(
  promoCode: HOLIDAY_PROMO_CODE
): HOLIDAY_PROMO_TYPE {
  const matchingPromoType = Object.keys(HOLIDAY_PROMO_TYPE).find(
    (promoType) => {
      const config = HOLIDAY_PROMO_CONFIG[promoType];
      return config.uiPromoCode === promoCode;
    }
  );

  return matchingPromoType as HOLIDAY_PROMO_TYPE;
}

export function getHolidayPromoForcedPlanDuration(
  promoCode?: HOLIDAY_PROMO_CODE
): number | undefined {
  const promoConfig = getCurrentHolidayPromoConfig(promoCode);

  return isFreeMonthsPromoConfig(promoConfig)
    ? promoConfig.forcedPlanDuration
    : undefined;
}

export function getHolidayPromoNumberOfMonthsOff(
  promoCode?: HOLIDAY_PROMO_CODE
): number | undefined {
  const promoConfig = getCurrentHolidayPromoConfig(promoCode);

  return isFreeMonthsPromoConfig(promoConfig)
    ? promoConfig.numberOfMonthsOff
    : undefined;
}

export function getHolidayPromoForcedPlanPrice() {
  const currency = getUserCurrency();
  const promoConfig = getCurrentHolidayPromoConfig();

  let mapping = discountedPlanMapping;
  let planDuration = 12;

  if (isFreeMonthsPromoConfig(promoConfig)) {
    mapping = priceMapping;
    planDuration = promoConfig.forcedPlanDuration;
  }

  const rawPrice = mapping[currency]?.[planDuration];
  return formatPrice(rawPrice, currency);
}

export function getHolidayReplacementPromoCode(
  promoCode: HOLIDAY_PROMO_CODE,
  currency: string
) {
  const planDuration = getHolidayPromoForcedPlanDuration(promoCode);
  const holidayPromoType = getCurrentHolidayPromoType(promoCode);
  return replacementPromoCodeMapping[currency]?.[holidayPromoType]?.[
    planDuration
  ];
}
