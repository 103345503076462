import React, {
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Theme, css } from "@emotion/react";
import styled, { Interpolation } from "@emotion/styled";
import { Skrim } from "./Skrim";
import { fixedFullSize, flexMiddle } from "src/design-system/styles/layout";
import { zIndexModal } from "src/design-system/styles/zIndices";
import { breakpointMobileOnly } from "src/design-system/styles/breakpoints";
import { compassColors } from "src/utils/styling";
import { VScroller } from "src/components/core/BodyLayout";
import { useCallbackRef, useOnce } from "src/hooks/lifecycle";
import { useGlobalEventListener, useTrapKeyboardFocus } from "src/hooks/dom";

const Wrapper = styled.div<{ blur?: boolean }>`
  ${fixedFullSize};
  ${zIndexModal};
  ${flexMiddle};
  ${({ blur }) => blur && `backdrop-filter: blur(8px);`}
  max-height: -webkit-fill-available;
`;

const formatWidth = (width: string | number): string => {
  if (typeof width === "number") {
    return `${width}px`;
  }
  return width;
};
const Body = styled.div<{ width: string | number }>`
  ${flexMiddle};
  flex-direction: column;
  background: ${compassColors.white};
  border-radius: 8px;
  width: ${({ width }) => formatWidth(width)};
  box-sizing: border-box;
  padding: 16px 16px 24px;
  @media ${breakpointMobileOnly} {
    width: 100%;
    margin: 0 16px;
  }
  max-height: 100vh;
  max-height: -webkit-fill-available;
`;

const ContentHead = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  margin: 5px 0 3px;
`;

const IconButton = styled.button<{ addMargin: boolean }>`
  ${flexMiddle};
  background: none;
  border: none;
  ${(props) =>
    props.addMargin &&
    css`
      margin-bottom: 10px;
    `}
`;

const ContentBody = styled.div`
  ${flexMiddle};
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function CloseButton({
  onClose,
  closeButtonColor = compassColors.black,
  addMargin = false,
}: {
  onClose: (event) => void;
  closeButtonColor?: string;
  addMargin?: boolean;
}) {
  return (
    <IconButton
      addMargin={addMargin}
      type="button"
      onClick={onClose}
      title="Close"
      css={{ padding: "6px" }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 15L15 1" stroke={closeButtonColor} strokeWidth="2" />
        <path d="M1 1L15 15" stroke={closeButtonColor} strokeWidth="2" />
      </svg>
    </IconButton>
  );
}

type Props = PropsWithChildren<{
  className?: string;
  // Function invoked when the close "x" button is clicked or the user presses "Escape"
  onClose?: (e: MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
  // Function invoked when the click outside the modal body is registered.
  onClickOutside?: (e: MouseEvent<HTMLDivElement>) => void;
  // Used to include a close "x" button that appears in the top right of the modal
  includeCloseButton?: boolean;
  closeButtonColor?: string;
  closeButtonContainerStyles?: Interpolation<Theme>;
  // Used to control whether or not to close modal when a click is registered outside the modal body
  clickOutsideClosesModal?: boolean;
  width?: string | number;
  skrimColor?: string;
  skrimOpacity?: number;
  blurredBackground?: boolean;
  returnFocus?: boolean;
}>;

export function Modal({
  className,
  children,
  onClose: onCloseProp,
  onClickOutside: onClickOutsideProp,
  includeCloseButton = false,
  closeButtonColor,
  closeButtonContainerStyles,
  clickOutsideClosesModal = false,
  width = "400px",
  skrimColor,
  skrimOpacity,
  blurredBackground,
  returnFocus = true,
}: Props) {
  const [shouldShow, setShouldShow] = useState(true);
  // Type coercion so we can use .focus()
  const returnFocusRef = useRef(document.activeElement as HTMLElement);

  // When the modal closes, return focus to the element that was focused when it was triggered.
  useOnce(() => () => returnFocus && returnFocusRef?.current?.focus());

  const onClose = useCallback(
    (e: MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
      setShouldShow(false);
      onCloseProp?.(e);
    },
    [onCloseProp]
  );

  const modalRef = useTrapKeyboardFocus();

  const checkIfClickedOutside = (e) => {
    // If the modal is open and the clicked target is not within the modal,
    // then close the modal
    if (
      shouldShow &&
      modalRef.current &&
      !modalRef.current.contains(e.target)
    ) {
      if (clickOutsideClosesModal) {
        setShouldShow(false);
      }
      onClickOutsideProp?.(e);
    }
  };

  const clickOutsideCallBackRef = useCallbackRef(checkIfClickedOutside);
  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideCallBackRef);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", clickOutsideCallBackRef);
    };
  }, [clickOutsideCallBackRef]);

  useGlobalEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Escape" && (clickOutsideClosesModal || includeCloseButton)) {
      onClose(e);
    }
  });

  return (
    shouldShow && (
      <>
        <Skrim color={skrimColor} opacity={skrimOpacity} />
        <Wrapper blur={blurredBackground}>
          <Body
            role="dialog"
            aria-modal="true"
            aria-describedby="Modal_ContentBody"
            ref={modalRef}
            className={className}
            width={width}
          >
            <VScroller>
              <ContentBody id="Modal_ContentBody">
                {includeCloseButton && (
                  <ContentHead css={closeButtonContainerStyles}>
                    <FlexEnd>
                      <CloseButton
                        data-cy="modal-close"
                        onClose={onClose}
                        closeButtonColor={closeButtonColor}
                        addMargin
                      />
                    </FlexEnd>
                  </ContentHead>
                )}
                {children}
              </ContentBody>
            </VScroller>
          </Body>
        </Wrapper>
      </>
    )
  );
}
