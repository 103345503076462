import { ActionHandler, ActionHandlerParams } from "..";
import { saveAnswers } from "./saveAnswers";
import { trackLead } from "src/utils/services/ConversionTracker";
import { trackGotEmail } from "src/utils/api/tracker";
import saveToBraze, { flushCRMQueue } from "src/utils/brazeUploader";
import {
  isMHMDAState,
  requiresDoubleOptInForEmail,
} from "src/utils/userSegment/features";
import { getAndMaybeSavePendingDoubleOptInStatus } from "src/utils/gdpr";
import { SessionStorageKey } from "src/utils/constants";
import { getNoomSessionStorage } from "src/utils/noomSessionStorage";

type Params = {
  lead?: string;

  dateKey?: string;
  extraProps?: JsonObject;
};

export const onCompleteMainSurvey: ActionHandler = async (
  actionParams: {
    params: Params;
  } & ActionHandlerParams
): Promise<void> => {
  const surveyAnswers = await saveAnswers(actionParams);

  if (surveyAnswers.gdprConsent && requiresDoubleOptInForEmail()) {
    await getAndMaybeSavePendingDoubleOptInStatus();
  }

  if (isMHMDAState()) {
    const mhmdaAnswer = getNoomSessionStorage(SessionStorageKey.mhmdaAnswer);
    if (mhmdaAnswer === true || mhmdaAnswer === false) {
      await saveToBraze({ user: { mhmdaOptIn: mhmdaAnswer } });
    }
  }

  await flushCRMQueue();

  // Send the user chosen first name to Braze as well for some extra DRIP customization
  const { firstName } = surveyAnswers;
  if (firstName) {
    await saveToBraze({ user: { firstName } });
  }

  const email = surveyAnswers.email || "";
  trackLead(email);
  if (email) {
    trackGotEmail(email);
  }
};
