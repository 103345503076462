import { css, Global } from "@emotion/react";
import React from "react";
import { zIndex } from "src/design-system/styles/zIndices";

// Ensure that the one trust banner does not cover any of our main content
const oneTrustLayout = css`
  #onetrust-consent-sdk {
    position: sticky;
    bottom: 0;
    z-index: ${zIndex.oneTrust};
  }
  #onetrust-banner-sdk.otFlat {
    position: static !important;
  }
`;

export function GlobalStyles() {
  return (
    <Global
      styles={css`
        ${oneTrustLayout};
      `}
    />
  );
}
