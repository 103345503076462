import { useOnce } from "src/hooks/lifecycle";
import { useHistory } from "react-router-dom";
import { scrollToTop } from "src/utils/scroll";
import { getActivePage } from "src/pageDefinitions/pageSets";
import { useStore } from "react-redux";
import { isGoing } from "src/pageDefinitions/components/PageSetRouter";
import { CoreReduxStore } from "src/utils/redux/internal";

/*
NOTE(kevinh): This component used to be called within getLazyComponentWrapper in src/router.
However, this wasn't actually working due to history.listen being a trailing operation and
it was moved up to PageSetRouter instead.

Issue was called out here: https://noomhq.atlassian.net/browse/GT-1565
*/
export function ScrollToTop() {
  const history = useHistory<{ pageSetId: string }>();
  const store: CoreReduxStore = useStore();

  useOnce(() => {
    return history.listen((newLocation, action) => {
      // Evaluating directly vs. using usePageSet to ensure that
      // we don't hit any hook update timing issues.
      const { activePage } = getActivePage(
        newLocation.pathname,
        store.getState(),
        newLocation
      );

      // LBF Pages flagged with retainScroll will not scroll to top
      // all other pages, LBF or not, will scroll to the top.
      if (activePage?.props?.retainScroll) {
        return;
      }

      // Let the browser manage if we are not going to a new page.
      // It will attempt to restore scroll based on the previous
      // location.
      if (action === "POP" && !isGoing()) {
        return;
      }

      scrollToTop();
    });
  });

  return null as JSX.Element;
}

export default ScrollToTop;
