import { registerMonitorProvider } from "../prop-registry";
import { getTrafficType, getContextType } from "@noom/meristem-context";
import { getRouteId, getMeristemContext } from "src/utils/meristemContext";

registerMonitorProvider("both", () => {
  const routeId = getRouteId();
  const contexType = getMeristemContext().context_type;

  return {
    trafficType: getTrafficType(routeId),
    condensedContextType: getContextType(contexType),
  };
});
