import { deviceOS, routeConstants } from "@utils/constants";
import { checkSegments } from "./util";
import { isEmailMain, isEmailWinback } from "./email";
import { isEnLanguage, isIntl, isUS } from "./locale";
import getStore, { CoreReduxState } from "../redux/store";
import { getMeristemContext, getRouteId } from "../meristemContext";
import { isAppBaseRoute } from "./in-app";

export interface SegmentFunction {
  (state?: CoreReduxState): boolean;
  load?: (store?: CoreReduxState) => Promise<void>;
}

// Baseline/control routes
export const isStrictCore = checkSegments({
  oneOf: [routeConstants.exsf01, routeConstants.exsf01d, routeConstants.direct],
});

export const isCore = checkSegments({
  oneOf: [
    routeConstants.exsf01,
    routeConstants.exsf01d,
    routeConstants.awsem,
    routeConstants.direct,
    routeConstants.appEmail,
  ],
});

export const isCoreDirect = checkSegments({
  oneOf: [routeConstants.direct, routeConstants.baseline],
});

export const isDirect = checkSegments({
  oneOf: [routeConstants.direct],
});

export const isBaseline = checkSegments({
  oneOf: [routeConstants.baseline],
});

export const isCorePaid = checkSegments({
  oneOf: [routeConstants.exsf01, routeConstants.exsf01d, routeConstants.awsem],
});

export const isCorePaidWithoutAddWordsSEM = checkSegments({
  oneOf: [routeConstants.exsf01, routeConstants.exsf01d],
});

export const isAffiliateWithoutTrial = checkSegments({
  oneOf: [
    routeConstants.affiliateNoTrial,
    routeConstants.affiliateNoTrialPromo,
  ],
});

export const isAffiliateWithTrial = checkSegments({
  oneOf: [routeConstants.affiliateTrialNPB],
});

export const isAffiliate = checkSegments({
  oneOf: [isAffiliateWithoutTrial, isAffiliateWithTrial],
});

export const isReferralsRoute = checkSegments({
  oneOf: [routeConstants.referrals],
});

export const isSupportersRoute = checkSegments({
  oneOf: [routeConstants.supporters],
});

// This is any route that exists under /s/foo, where social-foo is the routeId.
export const isSocialSharedRoute = () => {
  return getRouteId().startsWith("social-");
};

export const isReferralsBuyflow = checkSegments({
  oneOf: [isReferralsRoute, isSupportersRoute, isSocialSharedRoute],
});

export const isWinback = checkSegments({
  oneOf: [routeConstants.winback],
});

export const isWebWinback = checkSegments({
  oneOf: [isEmailWinback, isWinback],
});

export const isInAppEmployerSurvey = checkSegments({
  oneOf: [routeConstants.appEmployerSurvey],
});

export const isWebClinicalRoute = checkSegments({
  oneOf: [routeConstants.clinical],
});

export const isFreemiumClinicalRoute = checkSegments({
  oneOf: [routeConstants.freemiumClinical],
});

export const isClinicalRoute = checkSegments({
  oneOf: [isWebClinicalRoute, isFreemiumClinicalRoute],
});

export const isFreemiumWeightRoute = checkSegments({
  oneOf: [routeConstants.freemiumWeight],
});

export const isFreemiumUpsellRoute = checkSegments({
  oneOf: [routeConstants.freemiumClinical, routeConstants.freemiumWeight],
});

export const isLegacyAffiliateTrialRoute = checkSegments({
  oneOf: [routeConstants.affiliate],
});

export const isCoachReferralsRoute = checkSegments({
  oneOf: [routeConstants.coachReferrals],
});

export const isFreemiumIdentifiedRoute = checkSegments({
  oneOf: [routeConstants.appFreemiumIU],
});

export const isFreemiumQuizRoute = checkSegments({
  oneOf: [routeConstants.appFreemiumQuiz],
});

// Set of routes that share the same buyflow survey experience as Core
export const isMantle = checkSegments({
  oneOf: [
    isCore,
    isAffiliate,
    isAppBaseRoute,
    isReferralsBuyflow,
    isWebWinback,
    isEmailMain,
    isClinicalRoute,
    isCoachReferralsRoute,
    isFreemiumIdentifiedRoute,
    isFreemiumWeightRoute,
  ],
});

export const isUSMantle = checkSegments({
  allOf: [isUS],
  oneOf: [isMantle],
});

export const isIntlMantle = checkSegments({
  allOf: [isIntl],
  oneOf: [isMantle],
});

export const isEnMantle = checkSegments({
  allOf: [isEnLanguage, isMantle],
});

export const isAdWordsSEM = checkSegments({
  oneOf: [routeConstants.awsem],
});
export const isUSAdWordsSEM = checkSegments({
  allOf: [isUS],
  oneOf: [isAdWordsSEM],
});

const isHMByRoutes = checkSegments({
  oneOf: [
    routeConstants.hmDirect,
    routeConstants.hmExsf01,
    routeConstants.hmExsf01d,
    routeConstants.hmPaid,
    routeConstants.hmAwsem,
    routeConstants.hmEmail,
    routeConstants.hmReferrals,
  ],
});

export const isHM = (state = getStore().getState()) => {
  const { serverContext } = state;

  // Routes don't carry over to the counteroffer flow, so we need to check
  // the subscription_is_hm property instead if it's available in serverContext
  if ("subscription_is_hm" in serverContext) {
    return serverContext.subscription_is_hm;
  }
  return isHMByRoutes();
};

// Checks if the user is on the diabetes prevention program route
export const isDPP = checkSegments({
  oneOf: [routeConstants.ndpp],
});

export const isPreSignup = () =>
  [
    "landing",
    "main-survey",
    "payment",
    "payment-survey",
    "payment-survey-supporters",
    "payment-survey-referrals",
    "payment-survey-nm-bundle",
    "payment-results",
    "payment-survey-employer",
    "payment-checkout",
  ].includes(getMeristemContext().context_type);

export const isMobile = () => {
  return !!getMeristemContext().device_is_mobile;
};

export const isIOS = () => {
  return getMeristemContext().device_os === deviceOS.iOS;
};

// Old style
/**
 * Verifies if current user is on winback route and is from US
 */
export const isUSWinback = checkSegments({
  allOf: [isUS],
  oneOf: [isWinback],
});

export * from "./locale";
export * from "./demographics";
export * from "./email";
export * from "./in-app";
export * from "./counter-offer";
export * from "./subdivisions";
