import { trackEvent } from "src/utils/api/tracker";
import goto from ".";

export default {
  async exitTrialCheckIn() {
    const questionId = window.location.pathname;
    await trackEvent("ExitTrialCheckIn", {
      questionId,
    });
    if (!goto.nativeWebviewExit()) {
      goto.landing();
    }
  },
};
