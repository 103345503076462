export function classNames(...args: string[]) {
  return args.filter(Boolean).join(" ");
}

// classname does not record anything
export const fullStoryExclude = "fs-exclude";

// classname replaces text with irreversibly-transformed placeholder
export const fullStoryMask = "fs-mask";

// classname unmasks elements within an masked element
export const fullStoryUnmask = "fs-unmask";

export enum compassColors {
  // Project Compass Colors
  // Reds/Oranges
  cherry = "#5f110e",
  cinnamon = "#aa3521",
  tarocco = "#fb513b",
  salmon = "#f4beae",
  papaya = "#ff774b",
  taroccoShade1 = "#c9412f",

  // Purples/Pinks
  plum = "#671d48",
  berry = "#a82f67",
  blossom = "#f2aacd",
  conch = "#ebccd9",

  // Greens
  pine = "#2b4010",
  kale = "#316700",
  avocado = "#799f2d",
  sprout = "#a9d15a",
  mint = "#c8e3b1",
  mintLight = "#e3f1d8",

  // Blues
  darkBlueberry = "#15333D",
  blueberry = "#1d3a44",
  lagoon = "#05727a",
  lagoon20Percent = "rgba(5, 114, 122, 0.2)",
  lagoonTint1 = "#378e95",
  lagoonShade1 = "#045B62",
  stream = "#6cc1b6",
  sky = "#b4e0d8",
  denim = "#2457c5",
  skyLite = "#dcf5f0",

  // Yellows
  yolk = "#eaaf00",
  sunflower = "#f6cf03",

  // Tans
  gold = "#ad925e",
  premiumBrown = "#887249",
  highlighter = "#fff5d8",
  sandStone = "#D3C7B4",
  sand2 = "#d3c9b4",
  sand1 = "#e4dece",
  offWhite = "#f6f4ee",

  // Whites/Greys/Blacks
  white = "#ffffff",
  grey1 = "#dedede",
  grey2 = "#aaaaaa",
  grey3 = "#666666",
  grey4 = "#444444",
  grey5 = "#857B73",
  black = "#191717",
  lightGrey = "#f4f4f4",
  cream = "#F1EDE8",
}

export enum legacyColors {
  NOOM_AZURE400_2019 = "#2196f3",
  NOOM_WATERMELON_2019 = "#f75462",
  NOOM_ORANGE_2019 = "#fe7351",
  NOOM_ORANGE_2019_2 = "#ed7b5b",
  NOOM_CERULEAN_2019 = "#3cb8c7",
  NOOM_GREEN_APPLE_2019 = "#16aa00",
  NOOM_BLACK_2019 = "#333",
  NOOM_LIGHT_GREY_2019 = "#cacaca",
  NOOM_DARK_GREY_2019 = "#4d4d4d",
  NOOM_WHITE_2019 = "#fff",
}
