import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, GetAppState } from "../store";
import { captureException } from "src/utils/error";
import { FailedResponse, send } from "src/utils/fetch";
import { trackEvent } from "src/utils/api/tracker";
import { getCountryCode, getLanguage } from "src/utils/meristemContext";
import { logDebug } from "src/utils/monitoring/logging";

type WinbackStatus = "NONE" | "TRIAL" | "ADVANCED";
export type WinbackInfoState = {
  winbackStatus?: WinbackStatus;
  willReup?: boolean;
  previousWinbackStatus?: WinbackStatus;
};

const initialState: WinbackInfoState = {
  winbackStatus: "NONE",
  willReup: false,
  previousWinbackStatus: null,
};

const winbackInfoSlice = createSlice({
  name: "winbackInfo",
  initialState,
  reducers: {
    updateWinbackInfo(state, action: PayloadAction<WinbackInfoState>) {
      const previousWinbackStatus = {
        previousWinbackStatus: state.winbackStatus,
      };

      return { ...state, ...action.payload, ...previousWinbackStatus };
    },
  },
});

const { updateWinbackInfo } = winbackInfoSlice.actions;

let pendingLoad: Promise<void | WinbackInfoState>;

export function loadWinbackInfo(forceLoad = false) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    if (forceLoad) {
      pendingLoad = null;
    }
    if (pendingLoad) {
      return pendingLoad;
    }

    logDebug("Updating winback info");
    const { recommendedPlan } = getState();

    // NOTE(norbert): From the backend it seems that the consumer batch symbol is tied to the curriculum that a plan has.
    // e.g. So if a user is recommended a HW plan, we should compare winback eligibility against a HW batch symbol
    const consumerBatchSymbol = recommendedPlan?.curriculum || "HW";

    const queryParams = new URLSearchParams({
      consumerBatchSymbol,
      language: getLanguage(),
      country: getCountryCode(),
    });
    pendingLoad = send("GET", `/visitor/api/v1/winbackState/?${queryParams}`)
      .then(({ winback_status, will_reup }) => {
        trackEvent("WinbackInfoFetched", {
          winbackStatus: winback_status,
          willReup: will_reup,
        });
        dispatch(
          updateWinbackInfo({
            winbackStatus: winback_status,
            willReup: will_reup,
          })
        );
      })
      .catch((e) => {
        if (e instanceof FailedResponse && e.response?.status === 404) {
          // 404 is a successful response for new users
          trackEvent("WinbackInfoNotFound");
        } else {
          // Hard error here. Allow for retry on future call.
          pendingLoad = null;
          captureException(e, "updateWinbackInfo");
        }
      })
      .then(() => getState().winbackInfo);

    return pendingLoad;
  };
}

export default winbackInfoSlice;
