import { getSessionState } from "src/pageDefinitions/session";

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    glp1FollowUpAnswer?: "yes" | "no";
  }
}

export function hasAnsweredToGLP1FollowUpInCurrentSession(
  expectedAnswer: "yes" | "no"
) {
  const { glp1FollowUpAnswer } = getSessionState("browser");
  return glp1FollowUpAnswer === expectedAnswer;
}
