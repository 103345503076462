import { MED_SKU } from "src/utils/medEligibility";
import {
  isBrandedPlanIneligible,
  isCompoundedPlanIneligible,
  isEligibleForClinicalBuyflow,
  isOralsMedPaidTraffic,
  isOralsPlanIneligible,
} from "src/utils/userSegment/features";

export function getMedRecommendedPlanTier(): MED_SKU | undefined {
  if (!isEligibleForClinicalBuyflow()) {
    return undefined;
  }

  if (!isOralsPlanIneligible() && isOralsMedPaidTraffic()) {
    return MED_SKU.ORALS_METFORMIN;
  }
  if (!isCompoundedPlanIneligible()) {
    return MED_SKU.COMPOUNDED;
  }
  if (!isOralsPlanIneligible()) {
    return MED_SKU.ORALS_METFORMIN;
  }
  if (!isBrandedPlanIneligible()) {
    return MED_SKU.BRANDED;
  }

  return undefined;
}
