import { consentFramework } from "src/utils/consent";
import { registerMonitorProvider } from "../prop-registry";

declare global {
  interface Navigator {
    globalPrivacyControl?: boolean;
  }
}

/**
 * Collect client specific properties.
 */
registerMonitorProvider("monitoring", () => {
  let clientProperties: JsonObject = {};
  if (typeof window !== "undefined") {
    clientProperties.$screen_width = window.screen.width;
    clientProperties.$screen_height = window.screen.height;
  }
  clientProperties = {
    ...clientProperties,
    globalPrivacyControlExists: navigator.globalPrivacyControl != null,
    globalPrivacyControl: !!navigator.globalPrivacyControl,
    consentFramework: consentFramework(),
  };
  return clientProperties;
});
