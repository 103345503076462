import { shift_minutes } from "src/utils/datetime";
import getStore from "@utils/redux/store";
import {
  setPurchaseSurveyAnswers,
  setUserProperties,
} from "@utils/redux/slices/userData";
import {
  getBaselineTimerDuration,
  getRemainingDuration,
  getRemainingDurationInSeconds,
} from "./checkoutTimer";
import { SessionStorageKey } from "src/utils/constants";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "src/utils/noomSessionStorage";
import { shouldHideExistingPurchaseSurveyAnswers } from "./userSegment/features";

const personalizedPlanTimestampField =
  "checkoutPersonalizedPlanTimerExpirationTimestamp";

// Checks to see that the timestamp saved in the db exists and that the current date
// is past the timestamp
export const isPersonalizedPlanTimerExpired = (
  state = getStore()?.getState()
) => {
  const userData = state?.userData;
  const timestamp = userData?.checkoutPersonalizedPlanTimerExpirationTimestamp;
  return timestamp && Date.now() >= timestamp;
};

// Helpers for getting and setting a value that is true if the user has been kicked
// back to landing page after the timer has expired on the checkout page
export const setPersonalizedPlanTimerExpired = (val: boolean) => {
  setNoomSessionStorage(SessionStorageKey.checkoutTimerExpired, val);
};

export const getPersonalizedPlanTimerExpired = () => {
  return getNoomSessionStorage(SessionStorageKey.checkoutTimerExpired);
};

export const trySetPersonalizedPlanTimerExpirationCookie = async () => {
  const store = getStore();
  const { userData } = store.getState();
  if (shouldHideExistingPurchaseSurveyAnswers()) {
    // Delete purchase survey answers in DB
    await store.dispatch(setPurchaseSurveyAnswers({}));
  }
  if (
    !userData.checkoutPersonalizedPlanTimerExpirationTimestamp ||
    getPersonalizedPlanTimerExpired()
  ) {
    setPersonalizedPlanTimerExpired(false);
    await store.dispatch(
      setUserProperties({
        checkoutPersonalizedPlanTimerExpirationTimestamp: shift_minutes(
          getBaselineTimerDuration()
        ).getTime(),
      })
    );
  }
};

export const clearPersonalizedPlanTimerExpirationCookie = () => {
  setPersonalizedPlanTimerExpired(false);
  const store = getStore();

  const { userData } = store.getState();
  if (userData.checkoutPersonalizedPlanTimerExpirationTimestamp) {
    return store.dispatch(
      setUserProperties({
        checkoutPersonalizedPlanTimerExpirationTimestamp: false,
      })
    );
  }
  return Promise.resolve();
};

export const getPersonalizedPlanTimerRemainingDuration = () => {
  return getRemainingDuration(personalizedPlanTimestampField);
};

export const getPersonalizedPlanTimerRemainingDurationInSeconds = () => {
  return getRemainingDurationInSeconds(personalizedPlanTimestampField);
};
