/* eslint-disable prefer-rest-params */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  GlobalSnowplowNamespace?: any;
  nf0?: any;
};

if (!window.nf0) {
  window.GlobalSnowplowNamespace = window.GlobalSnowplowNamespace || [];
  window.GlobalSnowplowNamespace.push("nf0");
  window.nf0 = function hrzn() {
    (window.nf0.q = window.nf0.q || []).push(arguments);
  };
  window.nf0.q = window.nf0.q || [];
  window.nf0("newTracker", "nf0", "l.hrzn-nxt.com", {
    appId: "noom-9c32533d-fbf7-4114-86f1-f2952cbc06be",
    cookieDomain: null,
    cookieName: "_nxt_",
    stateStorageStrategy: "localStorage",
    forceSecureTracker: true,
    discoverRootDomain: true,
    eventMethod: "get",
    sessionCookieTimeout: 1800,
  });
}

export async function loadHorizon() {
  return loadScript(
    "https://c.hrzn-nxt.com/js/v2.12.0/connect.min.js",
    [LibCategories.targetingCookies],
    { enableCORS: false }
  );
}
