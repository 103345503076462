import type { History } from "history";
import {
  Action,
  Block,
  Direction,
  Page,
  PageListEntry,
  PageSet,
} from "src/pageDefinitions/types";
import getStore, { CoreReduxStore } from "src/utils/redux/store";
import ACTIONS, { ActionHandler } from "../actions";
import {
  getPage,
  getPageAtLocation,
  getPageLocation,
  getPageSet,
  getPageSetForLayer,
  PageLocation,
} from "../pageSets";
import invariant from "tiny-invariant";
import { setActivePageSet } from "src/utils/redux/slices/linearBuyflow";
import { logDebug } from "src/utils/monitoring/logging";
import { typedKeys } from "src/utils/typeWrappers";
import { findCommonAncestors } from "src/utils/arrayMethods";
import {
  emitPageSetEvents,
  isInitialPageInit,
  isPageSetInit,
  addPageSetEventHandler,
} from "../pageSets/bus";
import {
  findFuturePageIndex,
  resolvePage,
} from "src/pageDefinitions/pageSets/eval";
import goto from ".";
import { ConditionalKeys } from "type-fest";
import {
  getHistory,
  PageSetHistoryState,
  reroll,
} from "../components/PageSetRouter";
import {
  createForwardParams,
  getSearchQuery,
  updateForwardedParams,
} from "src/utils/urlParams";
import { captureMessage } from "@sentry/browser";
import {
  findEntryPointLocation,
  findSourceLocation,
} from "../actions/survey/util";
import { resetSingleUseFooter } from "@components/footer/SingleUseFooter";

export type GoToPageOptions = {
  history: History<PageSetHistoryState>;
  store: CoreReduxStore;
};

export function staticGoToOptions(): GoToPageOptions {
  return {
    store: getStore(),
    history: getHistory() as History<PageSetHistoryState>,
  };
}

function causesNavigation(page: Page) {
  return page.pathname != null || !!page.goto;
}

export async function executeActions(
  activePageSet: PageSet,
  page: PageListEntry,
  actions: ReadonlyArray<Action>,
  gotoOptions: GoToPageOptions,
  extraParams?: JsonObject
) {
  for (const action of actions || []) {
    invariant(ACTIONS[action.type], `Unknown action type: ${action.type}`);
    // eslint-disable-next-line no-await-in-loop
    await (ACTIONS[action.type] as ActionHandler)({
      pageSet: activePageSet,
      page,
      params: { ...action.params, ...extraParams },
      ...gotoOptions,
    });
  }
}

async function execBlocks(
  activePageSet: PageSet,
  location: PageLocation = [],
  commonAncestors: PageLocation,
  list: ConditionalKeys<Block, ReadonlyArray<Action>>,
  gotoOptions: GoToPageOptions
) {
  const currentLocation = commonAncestors.slice();
  const diffBlocks = location.slice(commonAncestors.length);
  for (const blockIndex of diffBlocks) {
    currentLocation.push(blockIndex);
    const block = getPageAtLocation(activePageSet, currentLocation);
    // Ignore pages that are iterated through at the end
    if ("block" in block) {
      // eslint-disable-next-line no-await-in-loop
      await executeActions(activePageSet, block, block[list], gotoOptions);
    }
  }
}

/**
 * Ensures that appropriate goto actions are done on initial page load. This
 * should be kept in sync with the actions performed in gotoPage below.
 */
export function ensureGotoSetup(
  activePageSet: PageSet,
  activePage: Page,
  gotoOptions: GoToPageOptions
) {
  // We we just entered the page set, run any actions for this location.
  if (activePageSet && !isPageSetInit()) {
    emitPageSetEvents("enter:page-set", {
      ...gotoOptions,
      activePageSet,
    });
  }

  // On load run actions for the current page. Unlike above, this case will
  // run if the page set is already assigned before the page is loaded.
  if (activePageSet && activePage && !isInitialPageInit()) {
    emitPageSetEvents("before:goto", {
      activePageSet,
      fromPage: undefined,
      toPage: activePage,
      ...gotoOptions,
    });

    // Also execute any
    executeActions(activePageSet, activePage, activePage.actions, gotoOptions);

    // Trigger after goto here even though we were already "here".
    emitPageSetEvents("after:goto", {
      activePageSet,
      fromPage: undefined,
      toPage: activePage,
      ...gotoOptions,
    });
  }
}

let gotoPageActive = false;

/**
 * Function that is passed into pool pages that the pages trigger to progress
 * a user forwards or backwards.
 */
export async function gotoPage(
  activePageSet: PageSet,
  gotoOptions: GoToPageOptions,
  replace?: boolean,
  pagePosition = getPageLocation(
    activePageSet,
    gotoOptions.history.location.pathname
  )
): Promise<void> {
  if (gotoPageActive) {
    logDebug(`[LBF] gotoPage called concurrently.`);
    return;
  }

  try {
    gotoPageActive = true;

    const { history, store } = gotoOptions;

    if (replace) {
      resetSingleUseFooter();
    }

    // Keep track of the pages/blocks we go by in this `goto`
    const traversePath: PageListEntry[] = [];

    const toPageIndex = findFuturePageIndex(
      Direction.FORWARD,
      activePageSet,
      gotoOptions,
      pagePosition,
      traversePath
    );
    if (!toPageIndex) {
      logDebug(`[LBF] gotoPage called. No toPage found.`);
      return;
    }

    const fromPage = getPageAtLocation(activePageSet, pagePosition);
    const toPage = getPageAtLocation(activePageSet, toPageIndex);

    const resolvedPage = resolvePage(
      toPage,
      store.getState(),
      Direction.FORWARD
    );

    invariant(!("block" in resolvedPage), "Expected page to not be a block.");

    if (causesNavigation(resolvedPage)) {
      logDebug(
        `[LBF] Goto page: ${
          resolvedPage.pathname ??
          (resolvedPage.goto && JSON.stringify(resolvedPage.goto))
        }`,
        resolvedPage
      );

      await emitPageSetEvents("before:goto", {
        activePageSet,
        fromPage,
        toPage,
        ...gotoOptions,
      });
    } else {
      logDebug(
        `[LBF] Skip page: ${
          resolvedPage.pathname ??
          (resolvedPage.goto && JSON.stringify(resolvedPage.goto))
        }`,
        resolvedPage
      );
    }

    // Execute actions for all blocks that we may have entered
    const commonAncestors = findCommonAncestors(pagePosition, toPageIndex);

    // Exit old blocks
    await execBlocks(
      activePageSet,
      pagePosition,
      // On complete should run if we are going to exit via a goto target
      resolvedPage.goto && !resolvedPage.continueBlock ? [] : commonAncestors,
      "onComplete",
      gotoOptions
    );

    // Enter new blocks
    await execBlocks(
      activePageSet,
      toPageIndex,
      commonAncestors,
      "actions",
      gotoOptions
    );

    // Execute `onSkip` actions of the passed-over pages/blocks, if any
    await Promise.all(
      traversePath
        .filter((item) => !!item.onSkip)
        .map((item) =>
          executeActions(activePageSet, item, item.onSkip, gotoOptions)
        )
    );

    // Execute actions on the current page
    await executeActions(
      activePageSet,
      resolvedPage,
      resolvedPage.actions,
      gotoOptions
    );

    if (causesNavigation(resolvedPage)) {
      // istanbul ignore else
      if (resolvedPage.pathname != null) {
        history[replace ? "replace" : "push"]({
          pathname: `/${resolvedPage.pathname}`,
          search: getSearchQuery(createForwardParams()),
          state: { pageSetId: activePageSet.id },
        });
      } else if (resolvedPage.goto) {
        const [name] = typedKeys(resolvedPage.goto);
        goto[name](...resolvedPage.goto[name]);
      }

      await emitPageSetEvents("after:goto", {
        activePageSet,
        fromPage,
        toPage,
        ...gotoOptions,
      });
    } else {
      gotoPageActive = false;
      logDebug(`[LBF] No navigation: ${toPageIndex}`);

      // No navigation, go to the next page in this direction.
      await gotoPage(activePageSet, gotoOptions, replace, toPageIndex);
    }
  } finally {
    gotoPageActive = false;
  }
}

let beforeGotoSeen = false;
addPageSetEventHandler(async (event, params) => {
  if (event === "enter:page-set") {
    // The above conditional is true when a user directly navigates to a path in the pageset
    const { activePageSet, history } = params;
    const pagePosition = getPageLocation(
      activePageSet,
      history.location.pathname
    );

    logDebug(`[LBF] Entering page set at ${pagePosition}`);

    // Only enter new blocks if this wasn't the result of an explicit goto operation
    // If this was the result of an explicit goto operation (in the case of normal navigation),
    // then blocks would have  been already added due to usePageSet being invoked earlier,
    // thus avoid adding new blocks here to avoid double firing events
    if (!beforeGotoSeen) {
      await execBlocks(activePageSet, pagePosition, [], "actions", params);
    }
  } else if (event === "before:goto") {
    beforeGotoSeen = true;
  }
});

export function getActiveHistoryIndex(state = getStore().getState()) {
  const { pageSetName, history } = state.linearBuyflow;
  const activeHistoryIndex = history?.findIndex(
    (item) => item.pageSetId === pageSetName
  );
  return activeHistoryIndex;
}

export default {
  async pageSet(pageSetId: string, pathname?: string) {
    const pageSet = getPageSet(pageSetId);
    invariant(pageSet, `Page set "${pageSetId}" not found.`);

    await getStore().dispatch(setActivePageSet(pageSet));

    const gotoOptions = staticGoToOptions();
    if (!pathname) {
      await gotoPage(pageSet, gotoOptions);
    } else {
      if (!getPage(pageSet, pathname, getStore().getState())) {
        logDebug(
          `[LBF] Page "${pathname}" not found in page set "${pageSetId}".`
        );
      }

      gotoOptions.history.push({
        pathname,
        search: getSearchQuery(createForwardParams()),
        state: { pageSetId },
      });
    }
  },

  async entryPoint(layerName: string, entryPoint: string) {
    return goto.layer(layerName, undefined, false, undefined, entryPoint);
  },

  async layer(
    layerName: string,
    sourceId?: string,
    replaceHistory?: boolean,
    routeId?: string,
    entryPoint?: string
  ) {
    // (Alin)Remove this clause entirely when releasing the noom-premium -> noom-premium-survey
    if (layerName === "noom-premium-survey") {
      goto.pushLayer(layerName, sourceId, replaceHistory);
    } else {
      const forwardParams = createForwardParams();
      if (sourceId) {
        forwardParams.set("sourceId", sourceId);
      }
      if (routeId) {
        forwardParams.set("route", routeId);
      }
      if (entryPoint) {
        forwardParams.set("entryPoint", entryPoint);
      }
      if (replaceHistory) {
        resetSingleUseFooter();
      }
      reroll(`/ps/${layerName}`, forwardParams, replaceHistory);
    }
  },

  async pushLayer(
    layerName: string,
    sourceId?: string,
    replaceHistory?: boolean,
    entryPoint?: string
  ) {
    updateForwardedParams({ sourceId: null });
    updateForwardedParams({ entryPoint: null });

    if (sourceId && entryPoint) {
      throw new Error(
        `Can't perform both sourceId and entryPoint navigation at the same time`
      );
    }

    const pageSet = await getPageSetForLayer(layerName, getStore().getState());
    if (!pageSet) {
      throw new Error(`${layerName} pageSet not found`);
    }

    await getStore().dispatch(setActivePageSet(pageSet, sourceId || layerName));

    // If a source id was passed, jump to that call before moving to the next
    const sourceLocation = findSourceLocation(pageSet, sourceId);

    // If an entry point was passed, attempt to find it in the current pageset and navigate to it
    const entryPointLocation = findEntryPointLocation(pageSet, entryPoint);

    await gotoPage(
      pageSet,
      staticGoToOptions(),
      replaceHistory,
      sourceLocation || entryPointLocation
    );
  },

  async exitLayer({
    noReroll,
    failover,
  }: {
    /** prevent reroll to avoid refetching data (eg. servercontext) */ noReroll?: boolean;
    /** layer that we will navigate to if no history is found */ failover?: string;
  }) {
    const { pageSetName, history } = getStore().getState().linearBuyflow;
    const activeHistoryIndex = getActiveHistoryIndex();

    if (activeHistoryIndex < 1) {
      if (failover) {
        captureMessage("exitLayer Failover", {
          contexts: {
            "Exit Layer": {
              pageSetName,
              failover,
              history,
            },
          },
        });

        goto.layer(failover);
        return;
      }

      // Should not occur with destructuring forcing object
      throw new Error('"exitLayer" called with no history.');
    }

    // Reroll and the ps page will handle the book keeping and the final routing
    const historyTarget = history[activeHistoryIndex - 1];
    const historyActive = history[activeHistoryIndex];

    if (noReroll) {
      goto.pushLayer(historyTarget.layer, historyActive.sourceId);
    } else {
      goto.layer(historyTarget.layer, historyActive.sourceId);
    }
  },
};
