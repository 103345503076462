import styled from "@emotion/styled";
import React from "react";
import { fontTitle } from "src/design-system/styles/fonts";
import { flexMiddle } from "src/design-system/styles/layout";
import { compassColors } from "src/utils/styling";

type Props = { email?: string; className?: string; color?: compassColors };

const Circle = styled.div<{ color?: compassColors }>`
  ${flexMiddle};
  ${fontTitle};
  font-size: 16px;

  width: 32px;
  height: 32px;
  flex: 0;

  border-radius: 32px;
  background-color: ${compassColors.sand2};
  color: ${(props) => (props.color ? props.color : compassColors.white)};
`;

export function Avatar({ email, className, color }: Props) {
  let firstLetter = "?";
  if (email) {
    firstLetter = email.trim().charAt(0).toUpperCase();
  }

  return (
    <Circle color={color} className={className} aria-hidden>
      {firstLetter}
    </Circle>
  );
}
