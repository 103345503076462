import { send } from "src/utils/fetch";
import { captureException } from "src/utils/error";
import {
  setNoomSessionStorage,
  getNoomSessionStorage,
} from "src/utils/noomSessionStorage";
import { trackEvent } from "./tracker";
import { getURLParams } from "../urlParams";

export interface PopupDictionary {
  id: number;
  image_name: any;
  is_grey_cross?: boolean;
  mobile_image_name: any;
  name: string;
  text: string;
  title: string;
}

export interface CampaignState {
  popup?: PopupDictionary;
  promocode?: { active: boolean; code: string };
  expiry?: string;
}

/**
 * Fetches the active campaign data and stores it in session storage. Used primarily for referrals.
 */
export const loadCampaign = async () => {
  const params = new URLSearchParams(getURLParams());
  // Campaigns can be run on path or query string, capture both in the call to backend
  const path = `/api/campaigns/v1/getCampaign${window.location.pathname}`;
  const campaignUrl = `${path}?${params}`;
  let campaignState: CampaignState = {};
  try {
    campaignState = await send("GET", campaignUrl);
  } catch (e) {
    captureException(e, "loadCampaignState");
  }
  if (campaignState.promocode) {
    trackEvent("RetrievedCampaignPromoCode", {
      code: campaignState.promocode.code,
    });
  }
  if (campaignState.popup) {
    trackEvent("RetrievedCampaignPopup", {
      popupName: campaignState.popup.name,
    });
  }
  saveCampaignToStorage(campaignState);
  return campaignState;
};

const storage_key = "campaign_promo";

/**
 * Store the promo code so we can read it later
 */
function saveCampaignToStorage(campaign: CampaignState) {
  const { promocode } = campaign;
  if (promocode?.active) {
    setNoomSessionStorage(storage_key, { code: promocode.code });
  } else {
    setNoomSessionStorage(storage_key, {});
  }
}

export function getCampaignFromStorage(): string {
  const storagePromo = getNoomSessionStorage(storage_key);
  const storageCode = storagePromo?.code;
  return storageCode || "";
}
