import { Global } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { createPortal } from "react-dom";
import { zIndex } from "src/design-system/styles/zIndices";

const Content = styled.div`
  width: 100%;
  z-index: ${zIndex.modal};
`;

/**
 * Simple page-level sticky footer container.
 */
export function OverlayFooter({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <OverlayFootPortal>
      <Global
        styles={`
        #overlay-foot {
          position: sticky;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: ${zIndex.modal};
        }
      `}
      />
      <Content className={className}>{children}</Content>
    </OverlayFootPortal>
  );
}

function OverlayFootPortal({ children }: { children: ReactNode }) {
  return createPortal(children, document.getElementById("overlay-foot"));
}
