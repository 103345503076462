import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Plan } from "./plans";

export type PlanOptionType = "preSelected" | "middle" | "monthly" | "premium";

export type PlanOptions = {
  [key in PlanOptionType]?: Plan;
};

const initialState: PlanOptions = {};

const planOptionsSlice = createSlice({
  name: "planOptions",
  initialState,
  reducers: {
    addPlanOption(state, action: PayloadAction<PlanOptions>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { addPlanOption } = planOptionsSlice.actions;

export default planOptionsSlice;
