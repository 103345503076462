/* eslint-disable prefer-rest-params */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  pintrk?: any;
};

if (!window.pintrk) {
  window.pintrk = function pintrk() {
    window.pintrk.queue.push(Array.prototype.slice.call(arguments));
  };
  const n = window.pintrk;
  n.queue = [];
  n.version = "3.0";
}

const { pintrk } = window;
pintrk("load", "2620804621622");
pintrk("page", { page_name: "Noom", page_category: "health" });
pintrk("track", "pagevisit");

export function loadPinterest() {
  return loadScript("https://s.pinimg.com/ct/core.js", [
    LibCategories.targetingCookies,
  ]);
}
