import { useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { CampaignState, loadCampaign } from "src/utils/api/campaign";
import { getPromoCodeState } from "src/utils/redux/slices/promoCode";
import { AppDispatch, CoreReduxState } from "src/utils/redux/store";
import { useOnce } from "./lifecycle";

export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}

// Load and return campaign
export function useCampaign() {
  const [state, load] = useState<CampaignState>({});
  useOnce(() => {
    loadCampaign().then(load);
  });
  return state;
}

export function useCoreStore() {
  return useStore<CoreReduxState>();
}

export function useCoreState() {
  return useSelector((state: CoreReduxState) => state);
}

export function useServerContext() {
  return useSelector((state: CoreReduxState) => state.serverContext);
}

export function usePlans() {
  return useSelector((state: CoreReduxState) => state.plans);
}

export function useUserData() {
  return useSelector((state: CoreReduxState) => state.userData);
}

export function useNoomTogether() {
  const { noomTogetherState } = useUserData();
  return noomTogetherState || {};
}

export function useActiveSubscriptionData() {
  return useSelector((state: CoreReduxState) => state.activeSubscriptionData);
}

export function useCheckout() {
  return useSelector((state: CoreReduxState) => state.checkout);
}

export function useCheckoutPhysicalAddress() {
  return useSelector((state: CoreReduxState) => state.checkoutPhysicalAddress);
}

export function useOffers() {
  return useSelector((state: CoreReduxState) => state.offers);
}

// Use this to access upid in components. Do NOT use the query params -- that pattern is deprecated
export function useUpid() {
  return useSelector((state: CoreReduxState) => state.upid);
}

export function usePremiumCounteroffer() {
  return useSelector((state: CoreReduxState) => state.premiumCounteroffer);
}

export function usePromoCode() {
  return useSelector((state: CoreReduxState) => getPromoCodeState(state));
}

export function usePaymentAddon() {
  return useSelector((state: CoreReduxState) => state.paymentAddon);
}

export function useCounterOffer() {
  return useSelector((state: CoreReduxState) => state.counterOffer);
}

export function useAddOns() {
  return useSelector((state: CoreReduxState) => state.addOns);
}

export function useKudos() {
  return useSelector((state: CoreReduxState) => state.kudos);
}

export function useMedEntitlements() {
  return useSelector((state: CoreReduxState) => state.medEntitlements);
}
