import getStore from "@utils/redux/store";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { isCountryImperial } from "./userSegment";

export function canRetrieveWeight(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  const hasKgWeights =
    !!surveyAnswers?.weightKg && !!surveyAnswers?.idealWeightKg;
  if (isCountryImperial()) {
    // NOTE(kevinh): We are now requiring that imperial countries have weights in kg as well
    // since that is required to calculate personalized pace information
    return (
      hasKgWeights && !!surveyAnswers?.weight && !!surveyAnswers?.idealWeight
    );
  }
  return hasKgWeights;
}
