import { countryHasRegionField } from "src/components/core/AddressForm/utils";
import {
  calculateAddonSalesTax,
  calculateRecommendedPlanSalesTax,
} from "src/utils/api/purchase/checkout";
import { debouncePromise } from "src/utils/debounce-promise";
import {
  CheckoutState,
  paymentTypes,
  updateCheckoutState,
} from "src/utils/redux/slices/checkout";
import { typedKeys } from "src/utils/typeWrappers";
import { isValidZipCode } from "src/utils/validate";
import { useCallbackRef, useOnce } from "./lifecycle";
import {
  useAppDispatch,
  useCheckout,
  useCoreStore,
  usePromoCode,
} from "./redux";
import getStore, { CoreReduxStore } from "src/utils/redux/store";
import { useEnrollmentForm } from "./enrollment";
import { errorConstants } from "src/utils/services/PurchaseErrorMessages";
import { selectAllPaymentAddons } from "src/utils/redux/slices/paymentAddon";
import { isShowablePaymentAddon } from "src/utils/paymentAddon";

const isFieldValid = (field: string) => !!field;

export const calculateSalesTax = debouncePromise(
  (store: CoreReduxStore = getStore()) => {
    const paymentAddons = selectAllPaymentAddons(
      store.getState().paymentAddon
    ).filter(isShowablePaymentAddon);

    return Promise.all([
      store.dispatch(calculateRecommendedPlanSalesTax()),
      Boolean(paymentAddons.length) && store.dispatch(calculateAddonSalesTax()),
    ]);
  },
  { timeout: 300 }
);

export function useBillingForm() {
  const store = useCoreStore();
  const {
    billingName,
    billingAddress,
    billingAddressErrors = {
      ADDRESS1: true,
      CITY: true,
      REGION: true,
      ZIPCODE: true,
      COUNTRY: true,
    },

    salesTaxAmount,
    salesTaxRate,
    salesTaxState,

    // Non-form fields
    paymentType,
    paypalAccount,
    walletPay,
    showPaymentInfoErrors,
  } = useCheckout();

  const promoCode = usePromoCode();
  const dispatch = useAppDispatch();
  const { enrollmentInfo, updateEnrollmentInfo } = useEnrollmentForm();

  const isBillingAddressNeeded =
    !promoCode.promoCodeIsVip &&
    paymentType === paymentTypes.CREDIT_CARD &&
    !walletPay;
  const hasBillingAddressErrors = typedKeys(billingAddressErrors).some(
    (key) => billingAddressErrors[key]
  );

  // Set default values
  useOnce(() => {
    const userDataName = store.getState().userData.name;
    if (billingName == null && userDataName) {
      dispatch(updateCheckoutState({ billingName: userDataName }));
    }
  });

  function updateBillingName(
    newBillingName: string,
    userDirectlyEntered = true
  ) {
    if (userDirectlyEntered) {
      dispatch(
        updateCheckoutState({
          userStartedEnteringPaymentInfo: true,
          billingName: newBillingName,
        })
      );
    } else {
      dispatch(updateCheckoutState({ billingName: newBillingName }));
    }
    if (!enrollmentInfo.nameChangeMade) {
      updateEnrollmentInfo(
        {
          ...enrollmentInfo,
          name: newBillingName,
        },
        false
      );
    }
  }

  async function updateBillingAddress(
    {
      country,
      city,
      region,
      address1,
      address2,
      zipcode,
    }: Partial<CheckoutState["billingAddress"]>,
    overrideErrors?: CheckoutState["billingAddressErrors"]
  ) {
    const newBillingAddress = {
      country: country ?? billingAddress?.country,
      city: city ?? billingAddress?.city,
      region: region ?? billingAddress?.region,
      address1: address1 ?? billingAddress?.address1,
      address2: address2 ?? billingAddress?.address2,
      zipcode: zipcode?.replace(" ", "") ?? billingAddress?.zipcode,
    };

    const newBillingAddressErrors = overrideErrors || {
      COUNTRY: !isFieldValid(newBillingAddress.country),
      CITY: !isFieldValid(newBillingAddress.city),
      // Region is not shown in the GB, IE, or NZ forms
      REGION:
        countryHasRegionField(newBillingAddress.country) &&
        !isFieldValid(newBillingAddress.region),
      ADDRESS1: !isFieldValid(newBillingAddress.address1),
      ZIPCODE: !isValidZipCode(
        newBillingAddress.zipcode,
        newBillingAddress.country
      ),
    };

    dispatch(
      updateCheckoutState({
        billingAddress: newBillingAddress,
        billingAddressErrors: newBillingAddressErrors,
      })
    );

    // Calc sales tax if values have changed
    if (
      (region != null && billingAddress?.region !== region) ||
      (zipcode != null && billingAddress?.zipcode !== zipcode)
    ) {
      await calculateSalesTax(store);
    }
  }

  function validateForm() {
    const errors = {};
    if (
      paymentType === paymentTypes.PAYPAL &&
      !paypalAccount &&
      !promoCode.promoCodeIsVip
    ) {
      Object.assign(errors, {
        paypalAccount: errorConstants.errorPaypalAccount,
      });
      dispatch(
        updateCheckoutState({ paypalError: errorConstants.errorPaypalAccount })
      );
    }
    if (isBillingAddressNeeded && hasBillingAddressErrors) {
      Object.assign(errors, billingAddressErrors);
    }
    return Object.keys(errors).length === 0 ? undefined : errors;
  }

  function hasVisibleError(
    fieldKey: keyof CheckoutState["billingAddressErrors"]
  ) {
    return showPaymentInfoErrors && !!billingAddressErrors?.[fieldKey];
  }

  return {
    billingName,
    updateBillingName: useCallbackRef(updateBillingName),

    ...billingAddress,

    isBillingAddressNeeded,
    updateBillingAddress: useCallbackRef(updateBillingAddress),

    salesTaxAmount,
    salesTaxRate,
    salesTaxState,
    // isTaxInclusive,

    validateForm: useCallbackRef(validateForm),
    hasVisibleError: useCallbackRef(hasVisibleError),
    billingAddressErrors,
    hasBillingAddressErrors,
  };
}
