import { captureException } from "src/utils/error";
import {
  getAndroidMessageHandlers,
  getIOSMessageHandlers,
} from "src/utils/in-app/messageHandlers";
import { isInsideAndroidWebView, isInsideIOSWebKit } from "./utils";

export default {
  nativeProgramSwitchComplete() {
    if (isInsideIOSWebKit()) {
      try {
        const messageHandlers = getIOSMessageHandlers();
        messageHandlers?.programSwitchComplete?.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeProgramSwitchComplete.ios");
      }
    }
    if (isInsideAndroidWebView()) {
      try {
        const messageHandlers = getAndroidMessageHandlers();
        messageHandlers.programSwitchComplete();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeProgramSwitchComplete.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativeProgramSwitchComplete: no message handler found");

    return false;
  },
};
