/* eslint-disable */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  dataLayer: any;
};

window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  "gtm.start": new Date().getTime(),
  event: "gtm.js",
});

export function loadGoogleTagManager() {
  return Promise.all([
    // <!-- Global site tag (gtag.js) - Google AdWords: AW-783925782 -->
    loadScript(
      "https://www.googletagmanager.com/gtm.js?id=GTM-PR66JM7R",
      [LibCategories.targetingCookies],
      { enableCORS: false }
    ),
  ]).then(() => {
    const noscript = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-PR66JM7R";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  });
}
