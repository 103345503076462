import { useEffect, useState } from "react";

export function useStepper({
  steps,
  stepSize,
  duration = 5000,
}: {
  steps: number;
  stepSize?: number;
  duration?: number;
  minInterval?: number;
}) {
  const [currentStep, setCurrentStep] = useState(0);

  const numberOfSteps = Math.ceil(steps / stepSize);
  const updateInterval = Math.max(duration / numberOfSteps, 300);

  useEffect(() => {
    setCurrentStep(0);

    const interval = setInterval(() => {
      setCurrentStep((value) => {
        if (value >= steps) {
          clearInterval(interval);
          return steps;
        }
        return Math.min(value + stepSize, steps);
      });
    }, updateInterval);

    return () => clearInterval(interval);
  }, [steps, stepSize, updateInterval]);

  return { currentStep };
}
