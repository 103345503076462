import { useEffect, useRef } from "react";
import { useCallbackRef } from "./lifecycle";

export function useGlobalEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  listener: (ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
) {
  const callbackRef = useCallbackRef(listener);

  useEffect(() => {
    window.addEventListener(eventName, callbackRef, options);
    return () => {
      window.removeEventListener(eventName, callbackRef);
    };
  }, [eventName, callbackRef, options]);

  return () => window.removeEventListener(eventName, callbackRef);
}

/** Hook for trapping keyboard navigation within a specific part of the DOM.
 * Most common use-case is modals.
 *
 * This also automatically focuses on one of the items within the trap
 * so that the user's focus doesn't get stuck outside of it. The default behavior
 * is to use the first focusable element. This can be overridden by adding the
 * `data-receive-focus` attribute to the desired element.
 *
 * @returns ref for the focus trap container
 */
export function useTrapKeyboardFocus() {
  const focusTrapRef = useRef(null);

  const focusableElements =
    "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])";

  useEffect(() => {
    let listener;

    if (focusTrapRef.current) {
      // get list of all possible focasable elements within the container.
      const focusableElementsWithin =
        focusTrapRef.current.querySelectorAll(focusableElements);

      // determine the element within the trap to set initial focus.
      // cast to HTMLElement so we can use .focus()
      const dataReceiveFocus = document.querySelector(
        "[data-receive-focus]"
      ) as HTMLElement;

      // check if data-receive-focus is in use
      if (dataReceiveFocus) {
        dataReceiveFocus.focus();
      } else {
        // otherwise use first enabled element
        let initialFocusEl = null;

        for (let i = 0; i < focusableElementsWithin?.length; i++) {
          if (!focusableElementsWithin?.[i].hasAttribute("disabled")) {
            initialFocusEl = focusableElementsWithin?.[i] as HTMLElement;
            break;
          }
        }

        initialFocusEl?.focus();
      }

      listener = (e: KeyboardEvent) => {
        if (e.key !== "Tab") {
          return;
        }

        // from our intitial list of focusable elements, we need to determine the
        // first and last that can actually be focused on at the present moment.
        // this means ignoring any that are currently disabled.
        let firstEnabledElement = null;
        let lastEnabledElement = null;

        focusableElementsWithin?.forEach((el) => {
          if (!el.hasAttribute("disabled")) {
            if (!firstEnabledElement) {
              firstEnabledElement = el as HTMLElement;
            }
            lastEnabledElement = el as HTMLElement;
          }
        });

        const isTabbingForward = !e.shiftKey;

        if (isTabbingForward && document.activeElement === lastEnabledElement) {
          firstEnabledElement.focus();
          e.preventDefault();
        } else if (
          !isTabbingForward &&
          document.activeElement === firstEnabledElement
        ) {
          lastEnabledElement.focus();
          e.preventDefault();
        }
      };

      document.addEventListener("keydown", listener);
    }

    return () => document.removeEventListener("keydown", listener);
  }, [focusTrapRef]);

  return focusTrapRef;
}
