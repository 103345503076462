import { css } from "@emotion/react";
import { compassColors } from "src/utils/styling";

export const textTruncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const responsiveImage = css`
  width: 100%;
  height: auto;
  object-fit: scale-down;
`;

/**
 * Visually hide an element, but leave it available for screen readers
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 * @link https://github.com/h5bp/main.css/issues/12#issuecomment-321106995
 */
export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

/**
 * For indicating an element is currently focused
 * @link https://dockyard.com/blog/2020/04/28/designing-for-accessibility-focus-states
 */
export const focusOutline = css`
  outline: 2px solid ${compassColors.denim};
  outline-offset: 2px;
`;
