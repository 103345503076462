import throttle from "lodash/throttle";
import { trackEvent } from "../api/tracker";
import { captureException } from "../error";
import { serialize as recommendedPlanSerialize } from "./slices/recommendedPlan";
import { serialize as promoCodeSerialize } from "./slices/promoCode";
import { CoreReduxStore, ReducerStore } from "./internal";
import { typedKeys } from "../typeWrappers";

export type Serializer<
  K extends keyof ReducerStore = keyof ReducerStore,
  T = ReducerStore[K]
> = {
  storage: "session" | "local";
  save: (state: T) => T;
  load: (storedState: T) => T;
};

const persistSerializers: Partial<
  Record<keyof ReducerStore, "session" | "local" | Serializer>
> = {
  surveyAnswers: "local",
  currentAnswers: "session",
  linearBuyflow: "local",
  surveyProgress: "session",
  questionHistory: "session",
  activeSubscriptionData: "local",
  addOns: "session",
  promoCode: promoCodeSerialize,
  planOptions: "session",
  upid: {
    storage: "local",
    load: (persistedValue) => {
      // Give priority to the URL params over the persisted value.
      // This isn't really the place to do this, but we need to ensure this value is correctly
      // set before the first context call (ie, before any Redux actions have happened).
      const urlParams = new URLSearchParams(window.location.search);
      const paramUpid = urlParams.get("upid");
      return paramUpid || persistedValue;
    },
    save: (value) => value,
  },
  recommendedPlan: recommendedPlanSerialize,
  multiUserPlan: "session",
  counterOffer: "session",
  fallbackPromotionalOffer: "session",
  purchasedNoomClinical: "local",
  animationsState: "session",
  premiumCounteroffer: "session",
  authStatus: {
    storage: "session",
    load: (persistedValue) => {
      return {
        sessionInvalid: false,
        userDidRefresh: persistedValue?.userDidRefresh,
      };
    },
    save: (value) => ({ userDidRefresh: value?.userDidRefresh }),
  },
  kudos: "local",
  visitorStatus: "session",
  persistentBrowserFlags: "local",
  medEntitlements: "session",
};

// To maintain backwards-compatibility with redux-persist, use the same key.
const PERSIST_KEY = "persist:root";

export function rehydrateStore() {
  const state = {};
  let localStoredValue: JsonObject = {};
  let sessionStoredValue: JsonObject = {};
  try {
    localStoredValue = JSON.parse(localStorage.getItem(PERSIST_KEY)) || {};
    sessionStoredValue = JSON.parse(sessionStorage.getItem(PERSIST_KEY)) || {};
  } catch (expectedError) {
    try {
      trackEvent("onPersistReadError", { error: expectedError.message });
    } catch (unexpectedError) {
      captureException(unexpectedError);
    }
  }

  // Again, maintaining backward-compat with redux-persist, which stringified the individual
  // keys as well
  typedKeys(persistSerializers).forEach((key) => {
    try {
      const serializer = persistSerializers[key];
      const location =
        typeof serializer === "string" ? serializer : serializer.storage;
      const storage =
        location === "local" ? localStoredValue : sessionStoredValue;

      if (storage[key]) {
        state[key] = JSON.parse(storage[key]);
      }
      if (typeof serializer === "object") {
        state[key] = serializer.load(state[key]);
      }
    } catch (err) {
      // Not ideal, but error on the side of attempted recovery.
      captureException(err);
    }
  });

  return state;
}

export function subscribePersistence(store: CoreReduxStore, throttleTime = 0) {
  store.subscribe(
    throttle(() => {
      const localToBeStored = {};
      const sessionToBeStored = {};
      try {
        typedKeys(persistSerializers).forEach((key) => {
          const serializer = persistSerializers[key];
          const location =
            typeof serializer === "string" ? serializer : serializer.storage;
          const storage =
            location === "local" ? localToBeStored : sessionToBeStored;

          let valueToSerialized = store.getState()[key];
          if (typeof serializer === "object") {
            valueToSerialized = serializer.save(valueToSerialized);
          }
          storage[key] = JSON.stringify(valueToSerialized);
        });
        localStorage.setItem(PERSIST_KEY, JSON.stringify(localToBeStored));
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(sessionToBeStored));
      } catch (e) {
        // This can happen on in-app webviews that don't have access to localStorage.
        trackEvent("onPersistWriteError", { error: e.message });
        // If this happens while developing, more likely to be an unserializable value in the store;
        // explode.
        /* istanbul ignore next sanity */
        if (process.env.NODE_ENV !== "production") {
          throw new Error("Unserializable value in store");
        }
      }
    }, throttleTime)
  );
}
