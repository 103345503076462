// WARN: Imports in this file should be kept to the minimum to
// avoid re-introducing circular dependencies in the monitoring
// layer.
import { JsonObject } from "type-fest";
import { logDebug } from "./logging";

const monitorProviders: (() => JsonObject)[] = [];
const errorProviders: (() => JsonObject)[] = [];

/**
 * Register a property provider for different monitoring operations.
 * The return value of `cb` will be merged with any properties sent
 * to Sentry/Mixpanel/etc when events are emitted to that tier.
 *
 * Different sections of the application are encouraged to register
 * providers here when they need to inject custom tagging for all
 * monitoring on the site.
 */
export function registerMonitorProvider(
  type: "monitoring" | "error" | "both",
  cb: () => JsonObject
) {
  if (type === "monitoring" || type === "both") {
    monitorProviders.push(cb);
  }
  if (type === "error" || type === "both") {
    errorProviders.push(cb);
  }
}

/**
 * Evaluates all providers and returns a merged object.
 */
export function generateMonitoringProps(
  type: "monitoring" | "error" = "monitoring"
) {
  const providers = type === "error" ? errorProviders : monitorProviders;

  return providers.reduce((acc, curr) => {
    try {
      const currProps = curr();
      return { ...acc, ...currProps };
    } catch (err) {
      // Unsafe to push into sentry. Could result in infinite loop.
      logDebug(err);
      return acc;
    }
  }, {});
}
