import { useCallback, useState } from "react";

/**
 * Provides a callback that lets async calls within components to still use error boundaries.
 *
 * const asyncError = useAsyncError();
 * myBrokenPromise.catch(asyncError);
 *
 * This magic via blog, via Abramov comment in github.
 * https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
 */
export function useAsyncError() {
  const [, setError] = useState();
  return useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
}
