import { Opaque } from "type-fest";

enum US_STATES {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  DC = "District of Columbia",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export const US_STATES_BY_FULL_NAME = new Map<string, string>(
  Object.keys(US_STATES).map((key) => [
    (US_STATES[key] as string).toLowerCase(),
    key as string,
  ])
);

export function getStateAbbreviation(state?: string) {
  if (!state) {
    return null;
  }
  const abbreviation = US_STATES_BY_FULL_NAME.get(state.toLowerCase());
  return abbreviation || state;
}

export type StateCode = Opaque<string>;

/**
 * A typed wrapper of `getStateAbbreviation`.
 * @param state Long state name, eg "New York"
 * @returns state abbreviation, eg "NY"
 */
export function getStateCode(state: string): StateCode {
  return getStateAbbreviation(state) as StateCode;
}

export function getFullState(stateCode: StateCode) {
  return US_STATES[stateCode] || stateCode;
}

export default US_STATES;
