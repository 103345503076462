import {
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";
import getStore from "./redux/store";
import { getURLParams } from "./urlParams";
import { isFallbackPromotionalOfferEligible } from "./userSegment/features";
import { isPromoCodeValid } from "./api/purchase/checkout";
import { queryClient } from "./fetch";
import { RecommendedPlanState } from "./redux/slices/recommendedPlan";
import { trackTask } from "./monitoring/events/tasks";

type FallbackPromotionalOfferConfiguration = {
  initialPlanID: string;
  targetPlanID: string;
  previouslySentPromo: string;
};

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    fallbackPromotionalOfferConfiguration?: FallbackPromotionalOfferConfiguration;
  }
}

function loadFallbackPromotionalOfferConfiguration() {
  const { recommendedPlan } = getStore().getState();
  const { plan_id_for_promo, previously_sent_promo } = getURLParams();
  if (plan_id_for_promo && previously_sent_promo) {
    updateSessionState("browser", {
      fallbackPromotionalOfferConfiguration: {
        initialPlanID: recommendedPlan.noom_plan_id,
        targetPlanID: plan_id_for_promo,
        previouslySentPromo: previously_sent_promo.toUpperCase(),
      },
    });
  }
}

/**
 * Returns the configuration used for giving users a valid promo code
 * In case the configuration is not saved in session storage we load it from query params
 */
export function getFallbackPromotionalOfferConfiguration() {
  if (!isFallbackPromotionalOfferEligible()) {
    return null;
  }

  const configuration =
    getSessionState("browser").fallbackPromotionalOfferConfiguration;
  if (configuration) {
    return configuration;
  }

  loadFallbackPromotionalOfferConfiguration();
  return getSessionState("browser").fallbackPromotionalOfferConfiguration;
}

export function hasFallbackPromotionalOfferConfiguration() {
  return !!getFallbackPromotionalOfferConfiguration();
}

const getRootPromoCode = () => {
  if (!hasFallbackPromotionalOfferConfiguration()) {
    return null;
  }

  const promoCode =
    getFallbackPromotionalOfferConfiguration().previouslySentPromo;

  const task = trackTask("ExtractingRootPromoCode", { promoCode });
  task.start();

  const promoCodeParts = promoCode.split("-");
  if (promoCodeParts?.length !== 2) {
    task.failed();
    return "";
  }

  task.success();
  return promoCodeParts[0];
};

const getQueryCacheKey = (noomPlanId: string, rootPromoCode: string) => [
  "fallbackPromotionalOfferRootValidity",
  rootPromoCode,
  noomPlanId,
];

export async function prefetchFallbackPromotionalOfferRootValidity(
  recommendedPlan: RecommendedPlanState
) {
  if (hasFallbackPromotionalOfferConfiguration()) {
    await queryClient.prefetchQuery(
      getQueryCacheKey(recommendedPlan.noom_plan_id, getRootPromoCode()),
      () => isPromoCodeValid(recommendedPlan, getRootPromoCode())
    );
  }
}

export async function fetchFallbackPromotionalOfferRootValidity(
  recommendedPlan: RecommendedPlanState
) {
  if (hasFallbackPromotionalOfferConfiguration()) {
    return queryClient.fetchQuery(
      getQueryCacheKey(recommendedPlan.noom_plan_id, getRootPromoCode()),
      () => isPromoCodeValid(recommendedPlan, getRootPromoCode())
    );
  }
  return false;
}
