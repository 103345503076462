import { getNormalizedValueFromTick } from "src/utils/weight/ticks";
import { MONTH_NAMES_ABBR } from "src/graphs/constants";
import { shift_days, getDayDelta } from "@utils/datetime";

export function getTickIndexesForMonthLabelsInRange(
  today,
  targetDate,
  maxRange,
  endTick,
  shouldSkipEveryOtherTick
) {
  const tickIndexesForMonthLabels = [];
  const labels = Array(maxRange).fill("");
  const targetMonth = targetDate.getMonth();
  const dayDelta = getDayDelta(today, targetDate);
  let currentDate = shift_days(getNormalizedValueFromTick(0) * dayDelta, today);
  for (let i = 1; i < endTick; i += 1) {
    const previousDate = currentDate;
    currentDate = shift_days(getNormalizedValueFromTick(i) * dayDelta, today);
    const currentMonth = currentDate.getMonth();
    if (currentMonth !== previousDate.getMonth()) {
      if (!shouldSkipEveryOtherTick || currentMonth % 2 === targetMonth % 2) {
        // Check currentMonth % 2 === targetMonth % 2 to ensure that targetMonth is always displayed on the graph
        labels[i] = MONTH_NAMES_ABBR[currentMonth];
        tickIndexesForMonthLabels.push(Math.round(i));
      }
    }
  }

  return { tickIndexesForMonthLabels, labels };
}
