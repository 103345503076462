import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import { ReadonlyDeep } from "type-fest";

/**
 * Generates a simple state context provider. Allows for simple state
 * that spans multiple components/lifecycles.
 *
 * const MyContext = createStateContext<MyType>();
 *
 * <MyContext.Provider>
 *
 *    const [ myState, updateMyState ] = useContext(MyContext.Context);
 *
 * </MyContext.Provider>
 */
export function createStateContext<T>(defaultInitialValue?: T) {
  type ContextType = [ReadonlyDeep<T>, Dispatch<SetStateAction<Partial<T>>>];
  const Context = createContext<ContextType>(null);

  type ProviderProps = { initialValue?: T; children: ReactNode };

  return {
    Context,
    Provider({ initialValue, children }: ProviderProps) {
      const [state, setState] = useState<T>(
        initialValue || defaultInitialValue
      );

      const context = useMemo(
        (): ContextType => [
          state as ReadonlyDeep<T>,
          (newValues) =>
            setState((currentState) => ({ ...currentState, ...newValues })),
        ],
        [state]
      );

      return <Context.Provider value={context}>{children}</Context.Provider>;
    },
  };
}
