/* eslint-disable no-underscore-dangle */
import { roundRect, triangle } from "@utils/shapes.js";

/*
  x, y - Coordinates of the tip of the triangle
*/
export function drawLabelBox({
  ctx,
  text,
  x,
  y,
  color,
  fontSize = 12,
  padding = 8,
  extraPaddingTop = 0,
  triangleHeight = 12,
  textLineMargin = 4,
  radius = 10,
}: {
  ctx: any;
  text: string[];
  x: number;
  y: number;
  color: string;
  fontSize?: number;
  padding?: number;
  extraPaddingTop?: number;
  triangleHeight?: number;
  textLineMargin?: number;
  radius?: number;
}) {
  // Calculate dimensions
  ctx.font = `${fontSize}pt 'Brown LL', sans-serif`;
  const textMeasurements = text.map((line) => ctx.measureText(line));
  const rectangleWidth =
    Math.max(...textMeasurements.map((m) => m.width)) + padding * 2;
  const textHeight = Math.max(
    ...textMeasurements.map(
      (m) => m.actualBoundingBoxAscent + m.actualBoundingBoxDescent
    )
  );
  const rectangleHeight =
    textHeight * text.length +
    textLineMargin * (text.length - 1) +
    padding * 2 +
    extraPaddingTop;
  // Calculate corner positions
  const x0 = x - rectangleWidth / 2;
  const y1 = y - triangleHeight;
  const y0 = y1 - rectangleHeight;
  const textX0 = x0 + rectangleWidth / 2;
  const textY0 = y0 + padding + extraPaddingTop;

  // Draw bubble rectangle
  roundRect(ctx, x0, y0, rectangleWidth, rectangleHeight, color, radius, false);
  // Draw bubble triangle
  triangle(
    ctx,
    x0 + rectangleWidth / 2 - triangleHeight * 0.8,
    y1 - 1,
    triangleHeight * 1.6,
    triangleHeight + 1,
    color,
    false
  );
  // Draw text
  ctx.fillStyle = "#FFFFFF";
  text.forEach((line, idx) => {
    ctx.fillText(
      line,
      textX0,
      textY0 + textHeight + (textHeight + textLineMargin) * idx
    );
  });
}
