import { useEffect } from "react";
import { trackEvent } from "src/utils/api/tracker";
import { disableBodyScroll, enableBodyScroll } from "src/utils/scroll";
import { useGlobalEventListener } from "./dom";

/** Hook for disabling and re-enabling page scroll, such as with a modal. */
export function useDisableScroll(active = true) {
  useEffect(() => {
    if (active) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
    return () => enableBodyScroll();
  }, [active]);
}

/** Track if/when the user first scrolls on the current page. */
export function useTrackScroll(eventName: string) {
  const removeListener = useGlobalEventListener("scroll", () => {
    trackEvent(eventName);
    removeListener();
  });
}
