import { isEmailBlast } from "@utils/userSegment";
import { DiscountInfo, Plan } from "src/utils/redux/slices/plans";

/**
 * Determine promo code is available in query string for blast users
 */
export const hasPromoCodeAvailable = () => {
  const promoCode =
    new URLSearchParams(window.location.search).get("display_promo") || null;
  return isEmailBlast() && !!promoCode;
};

/** Calculate promocode discount percentage relative to the actual plan price,
 * instead of relative to the inflated "regular" price. */
export const calculatePromoDiscountPercentage = (
  plan: Partial<Plan>,
  promoDiscountAmount?: number
) => {
  if (!promoDiscountAmount || !plan.plan_price) return 0;

  return Math.round((promoDiscountAmount / plan.plan_price) * 100);
};

/** Calculates price after a promo discount percentage is applied */
export const getPriceAfterDiscount = (
  price: number,
  discountPercentage: number
) => {
  return price * (1 - discountPercentage / 100);
};

/** Handles applying both types(fixed amount and percentage) of promo discounts to a plan */
export function calculateDiscountedPlanPrice(
  plan: Plan,
  discountInfo: DiscountInfo
) {
  if (!discountInfo) {
    return plan.plan_price;
  }
  if (discountInfo.discountType === "PERCENT") {
    return getPriceAfterDiscount(plan.plan_price, discountInfo.discountPercent);
  }
  return plan.plan_price - discountInfo.discountAmount;
}
