import { getSha } from "src/utils/meristemContext";
import { registerMonitorProvider } from "../prop-registry";

/**
 * Collect properties about this build of the code.
 */
registerMonitorProvider("monitoring", () => {
  return {
    gitSha: getSha(),
  };
});
