import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import goto from "src/pageDefinitions/goto";
import { useCallbackRef } from "./lifecycle";
import { usePageSet } from "./pageSet";
import { resetSingleUseFooter } from "@components/footer/SingleUseFooter";

interface ChildrenProps {
  children?: React.ReactNode;
}

class ReplacePageError extends Error {
  constructor(readonly pageReplacer: () => void) {
    super("ReplaceWithNextPage");
  }
}

type ReplacementOperators = {
  replaceWithNextPage: () => void;
  replaceWithLandingPage: () => void;
  replaceWithLayer: (layer: string) => void;
};

/**
 * Allows for inline replacement of pages that have not passed preconditions.
 *
 * This is intended primarily as a mechanism to prevent render flashing when
 * a page redirect needs to occur. It's not intended to be a general use API.
 */
export function usePageReplacement(
  cb: (replacer: ReplacementOperators) => void
) {
  const { gotoNextPage } = usePageSet(true);

  function replaceWithNextPage(): never {
    throw new ReplacePageError(() => gotoNextPage(true));
  }
  function replaceWithLandingPage(): never {
    throw new ReplacePageError(() => {
      resetSingleUseFooter();
      goto.landing();
    });
  }
  function replaceWithLayer(layer: string): never {
    throw new ReplacePageError(() => {
      goto.layer(layer, undefined, true);
    });
  }

  // Note that we are intentionally creating this side effect in the render loop
  // since it will throw when the side effect actually occurs, this should be
  // safe.
  cb({
    replaceWithNextPage: useCallbackRef(replaceWithNextPage),
    replaceWithLandingPage: useCallbackRef(replaceWithLandingPage),
    replaceWithLayer: useCallbackRef(replaceWithLayer),
  });
}

const PageReplaceBoundary = withRouter(
  class extends React.Component<
    ChildrenProps & RouteComponentProps,
    {
      hideChildren?: boolean;
    }
  > {
    constructor(props) {
      super(props);
      this.state = {};

      this.props.history.listen(() => {
        this.setState({ hideChildren: false });
      });
    }

    static getDerivedStateFromError() {
      return { hideChildren: true };
    }

    override componentDidCatch(error: Error) {
      const { pageReplacer } = error as ReplacePageError;
      if (typeof pageReplacer === "function") {
        setTimeout(() => {
          pageReplacer();
        }, 0);
      } else {
        // Rethrow the error and let upstream handle.
        // TODO: Check to see what we need from errorInfo, etc
        throw error;
      }
    }

    override render() {
      const { children } = this.props;
      const { hideChildren } = this.state;

      return hideChildren ? null : children;
    }
  }
);

export function PageReplaceProvider({ children }: ChildrenProps) {
  return <PageReplaceBoundary>{children}</PageReplaceBoundary>;
}
