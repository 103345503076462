import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FallbackPromotionalOffer = {
  hasUserAcceptedOffer: boolean;
};

const initialState: FallbackPromotionalOffer = {
  hasUserAcceptedOffer: false,
};

const fallbackPromotionalOfferSlice = createSlice({
  name: "fallbackPromotionalOffer",
  initialState,
  reducers: {
    updateFallbackPromotionalOffer(
      state,
      action: PayloadAction<FallbackPromotionalOffer>
    ) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateFallbackPromotionalOffer } =
  fallbackPromotionalOfferSlice.actions;

export default fallbackPromotionalOfferSlice;
