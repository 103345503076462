import { loadOneTrust } from "src/vendor/onetrust";
import { trackEvent } from "../api/tracker";
import { getConsent, LibCategories, setConsent } from ".";
import { getMeristemContext } from "../meristemContext";

const localLoadTime = Date.now();

export function bootstrapOneTrust(
  priorConsent: readonly LibCategories[] | undefined
) {
  return loadOneTrust().then(({ OneTrust, ActiveGroups }) => {
    try {
      const onetrustConsent = ActiveGroups?.split(/,/)
        ?.filter(Boolean)
        .sort() as LibCategories[];

      // Reset consent state to the one returned by OneTrust
      // Always do this to ensure that incorrect storage is cleared.
      if (
        ActiveGroups != null &&
        !getMeristemContext().is_forced_country_code
      ) {
        setConsent(onetrustConsent);
      }

      const sortedPriorConsent = priorConsent?.slice().sort();

      trackEvent("OneTrustLoaded", {
        consentMatches:
          sortedPriorConsent &&
          `${sortedPriorConsent}` === `${onetrustConsent}`,
        localConsent: priorConsent,
        onetrustConsent,
        ActiveGroups,
        onetrustLoadTime: Date.now() - localLoadTime,
      });
    } catch (err) {
      /* NOP */
    }

    // Keep consent list in sync on any future consent updates.
    OneTrust?.OnConsentChanged((event) => {
      setConsent(event.detail);

      trackEvent("CookieConsentProvided", {
        details: event.detail,
        categories: getConsent(),
      });
    });
  });
}
