import { createCallbackList } from "src/utils/callback-list";
import { setPageSetInit } from "src/utils/redux/slices/linearBuyflow";
import getStore from "src/utils/redux/store";
import invariant from "tiny-invariant";
import type { GoToPageOptions } from "../goto/pageSet";
import type { Page, PageSet } from "../types";

/**
 * Event hooks for pageset lifecycle. Use sparingly.
 *
 * set:page-set
 *  Called when the user navigates directly to a page set by a pathname.
 *  This may not be called if the user navigates into a page set within the SPA.
 * enter:page-set
 *  Called when the user enters a page set.
 * before:goto
 *  Called before navigating to page.
 * after:goto
 *  Called after goto operation has completed.
 */
type PageSetEvent =
  | "set:page-set"
  | "enter:page-set"
  | "before:goto"
  | "after:goto";

export type PageSetEventHandler = (
  event: PageSetEvent,
  params: GoToPageOptions & {
    activePageSet: PageSet;
    fromPage?: Page;
    toPage?: Page;
  }
) => void;

const pageSetCallbacks = createCallbackList<Parameters<PageSetEventHandler>>();

// Event bus for allowing hooks on goto actions.
export const addPageSetEventHandler = pageSetCallbacks.add;

export const emitPageSetEvents = pageSetCallbacks.emit;

// Tracks if the initial page for the site has been initialized.
let initialPageInit = false;
export function isPageSetInit() {
  return getStore().getState().linearBuyflow.isPageSetInit;
}
export function isInitialPageInit() {
  return initialPageInit;
}

export function clearPageInitFlag() {
  invariant(__NODE_ENV__ === "test", "clearPageInitFlag is only for test");
  initialPageInit = false;
}

addPageSetEventHandler(async (event) => {
  if (event === "enter:page-set") {
    getStore().dispatch(setPageSetInit(true));
  } else if (event === "set:page-set") {
    getStore().dispatch(setPageSetInit(false));
  } else if (event === "before:goto") {
    initialPageInit = true;
  }
});
