import { hasBeenVisible, isUnloading, isVisible } from "src/utils/lifecycle";
import { registerMonitorProvider } from "../prop-registry";

registerMonitorProvider("both", () => {
  return {
    hasBeenVisible: hasBeenVisible(),
    isVisible: isVisible(),
    isUnloading: isUnloading(),
  };
});
