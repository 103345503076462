import { MutableRefObject, useEffect, useRef, useState } from "react";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { trackEvent } from "src/utils/api/tracker";
import { useGlobalEventListener } from "./dom";
import { useCallbackOnce, useCallbackRef, useOnce } from "./lifecycle";

export function useInViewport<T extends HTMLElement>(
  waypointRef: MutableRefObject<T>,
  /** Override starting state value if the element is initially outside the viewport. */
  startsInViewport = true,
  intersectionThreshold = 0.25
) {
  const [inViewport, setInViewport] = useState(startsInViewport);

  const handleScroll = useCallbackRef(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      setInViewport(entry.isIntersecting);
    }
  );

  const [observer] = useState(
    () =>
      new IntersectionObserver(handleScroll, {
        threshold: intersectionThreshold,
      })
  );
  useOnce(() => () => observer.disconnect());

  const waypointEl = waypointRef.current;
  useEffect(() => {
    if (!waypointEl) {
      return () => {};
    }

    observer.observe(waypointEl);
    return () => observer.unobserve(waypointEl);
  }, [observer, waypointEl]);

  return inViewport;
}

export function useBreakpointScreenCheck(breakpoint: string) {
  const [isBreakpoint, setIsBreakpoint] = useState(true);

  useEffect(() => {
    const mql = window.matchMedia(breakpoint);
    setIsBreakpoint(mql.matches);

    function handleMediaChange(e: MediaQueryListEvent) {
      setIsBreakpoint(e.matches);
    }

    try {
      mql.addEventListener("change", handleMediaChange);
    } catch {
      // Support for Legacy browsers since addEventListener only works for MediaQueryList on recent browser versions
      // See: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
      mql.addListener(handleMediaChange);
    }

    return function cleanup() {
      try {
        mql.removeEventListener("change", handleMediaChange);
      } catch {
        // Support for Legacy browsers, same as above comment
        mql.removeListener(handleMediaChange);
      }
    };
  }, [breakpoint]);

  return isBreakpoint;
}

export function useSmallScreenCheck() {
  return useBreakpointScreenCheck(breakpointMobileExtended);
}

export const useTrackEventOnceWhenBottomReached = (
  eventName: string,
  conditionToTrack: () => boolean = () => true
) => {
  const reachedBottomCallback = useCallbackOnce(() => {
    if (conditionToTrack()) {
      trackEvent(eventName, {});
    }
  });

  useGlobalEventListener("scroll", () => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      reachedBottomCallback();
    }
  });
};

export function useIntersectionRatio<T extends HTMLElement>(
  ref: MutableRefObject<T>
) {
  const [intersectionRatio, setIntersectionRatio] = useState<number>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([element]) => {
      setIntersectionRatio(element.intersectionRatio);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref, setIntersectionRatio]);

  return { intersectionRatio };
}

/** Track when an element is visible. Will fire more than once if the component re-renders. */
export function useTrackVisibleRef(eventName: string, props?: JsonObject) {
  const observer = useRef<IntersectionObserver>();
  return (node) => {
    if (observer.current) {
      observer.current.disconnect();
      observer.current = null;
    }

    if (node) {
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          trackEvent(eventName, props);
          observer.current.disconnect();
        }
      });
      observer.current.observe(node);
    }
  };
}

/** Track when an element enters the viewport. Only fires the first
 * time the element becomes visible, including on page load.
 */
export function useTrackInViewport<T extends HTMLElement>(
  ref: MutableRefObject<T>,
  eventName: string,
  intersectionThreshold = 0.25
) {
  // Determine programmatically if the element startsInViewport,
  // using the same threshold value as useInViewport.
  const { intersectionRatio } = useIntersectionRatio(ref);
  const isInViewport = useInViewport(
    ref,
    intersectionRatio >= intersectionThreshold,
    intersectionThreshold
  );
  const [trackedInViewport, setTrackedInViewport] = useState(false);

  useEffect(() => {
    if (isInViewport && !trackedInViewport) {
      trackEvent(eventName);
      setTrackedInViewport(true);
    }
  }, [isInViewport, trackedInViewport, eventName]);
}

export function useWindowInnerHeight(minHeightPx = 150) {
  const [windowInnerHeight, setWindowInnerHeight] = useState(
    window.innerHeight
  );

  useGlobalEventListener("resize", () => {
    // min height just in case innerHeight goes to 0
    setWindowInnerHeight(Math.max(window.innerHeight, minHeightPx));
  });

  return windowInnerHeight;
}
