import React, { createContext, ReactNode, useContext } from "react";

export const appInitContext = createContext(false);

export function AppInitProvider({
  children,
  value,
}: {
  children: ReactNode;
  value: boolean;
}) {
  return (
    <appInitContext.Provider value={value}>{children}</appInitContext.Provider>
  );
}

export function AppInitGate({ children }: { children: ReactNode }) {
  const isLoaded = useContext(appInitContext);
  return isLoaded ? <>{children}</> : null;
}
