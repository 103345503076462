/* eslint-disable no-underscore-dangle */
import React from "react";
import { Helmet } from "react-helmet";
import i18n from "src/locales";
import { getLanguage } from "src/utils/meristemContext";
import { isHM } from "src/utils/userSegment";
import OpenGraph from "./metas/OpenGraph";
import Schema from "./metas/Schema";

export function CommonHead() {
  const title = isHM()
    ? i18n.t("healthyMindSite:title")
    : i18n.t("healthyWeightSite:title");
  const description = isHM()
    ? i18n.t("healthyMindSite:description")
    : i18n.t("healthyWeightSite:description");

  return (
    <>
      <Helmet>
        <html lang={getLanguage()} />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link rel="shortcut icon" href="/static/favicon.ico" />

        {/* Viewport + Mobile Compat */}
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        {/* Google verification meta tag */}
        <meta
          name="google-site-verification"
          content="1s3J5mLUF-OMyvEBMV0NVL4-sYgshiMOlP1XMqRrIeU"
        />

        {/* Default to no index. Landing pages and other SEO targets can opt in */}
        <meta name="robots" content="noindex" />
      </Helmet>

      {/* The O.G. */}
      <OpenGraph />
      <Schema />
    </>
  );
}
