/**
 * Provides a common logging layer for console logs.
 *
 * Utilizing these methods over direct console log calls allows for
 * centralized logging and easy toggling of logging in tests, etc.
 */
export function logInfo(...args: any[]) {
  // eslint-disable-next-line no-console
  console.info(...args);
}

export function logDebug(...args: any[]) {
  // Push to debug in development for visibility, info in production
  // for monitoring tools to pickup.
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.debug(...args);
  } else {
    logInfo(...args);
  }
}
