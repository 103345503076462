import { routeConstants } from "./constants";
import { isCA, isEnLanguage, isUK, isUS } from "./userSegment";
import { appConfig } from "src/config";

export enum HOLIDAY_PROMO_CODE {
  BLACKFRIDAY6 = "BLACKFRIDAY6",
  BLACKFRIDAY25 = "BLACKFRIDAY25",
  NEWYEAR6 = "NEWYEAR6",
  NEWYEAR25 = "NEWYEAR25",
  SUMMER6 = "SUMMER6",
  NEWYEAR10 = "NEWYEAR10",
}

export enum HOLIDAY_PROMO_TYPE {
  BLACK_FRIDAY_6_MONTHS_OFF = "BLACK_FRIDAY_6_MONTHS_OFF",
  BLACK_FRIDAY_25_PERCENT_OFF = "BLACK_FRIDAY_25_PERCENT_OFF",
  NEW_YEAR_6_MONTHS_OFF = "NEW_YEAR_6_MONTHS_OFF",
  NEW_YEAR_6_MONTHS_OFF_INTL_DIRECT = "NEW_YEAR_6_MONTHS_OFF_INTL_DIRECT",
  NEW_YEAR_25_PERCENT_OFF = "NEW_YEAR_25_PERCENT_OFF",
  SUMMER_SALE_6_MONTHS_OFF = "SUMMER_SALE_6_MONTHS_OFF",
  NEW_YEAR_10_PERCENT_OFF = "NEW_YEAR_10_PERCENT_OFF",
}

export enum PROMO_BANNER_ACTIVATION_TYPE {
  URL_PARAM = "URL_PARAM",
  DATE_RANGE = "DATE_RANGE",
}

type BaseHolidayPromoConfig = {
  eligibleRoutes: routeConstants[];
  urlPromoValue?: string;
  uiPromoCode: HOLIDAY_PROMO_CODE;
  isUserSegmentEligible: () => boolean;
  translationKey: string;
  promoType: PROMO_TYPE;
  productTypeTranslationKey: PROMO_PRODUCT_TYPE;
  bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE;
  autoApplyPromoCode: boolean;
  activeFrom?: Date;
  activeTo?: Date;
};

export type MonthsOffPromoConfig = BaseHolidayPromoConfig & {
  promoType: PROMO_TYPE.MONTHS_OFF;
  forcedPlanDuration: number;
  numberOfMonthsOff: number;
};

type PercentOffPromoConfig = BaseHolidayPromoConfig & {
  promoType: PROMO_TYPE.PERCENTAGE_OFF;
};

export type HolidayPromoConfig = MonthsOffPromoConfig | PercentOffPromoConfig;

const BASE_PROMO_ROUTES = [
  routeConstants.exsf01,
  routeConstants.exsf01d,
  routeConstants.direct,
];

export enum PROMO_TYPE {
  MONTHS_OFF = "MONTHS_OFF",
  PERCENTAGE_OFF = "PERCENTAGE_OFF",
}

export enum PROMO_PRODUCT_TYPE {
  NOOM_WEIGHT = "noomWeight",
  NOOM_MED = "noomMed",
}

export const HOLIDAY_PROMO_CONFIG: Record<
  HOLIDAY_PROMO_TYPE,
  HolidayPromoConfig
> = {
  [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_6_MONTHS_OFF]: {
    eligibleRoutes: [...BASE_PROMO_ROUTES, routeConstants.affiliateTrialNPB],
    urlPromoValue: "6MonthBFCM2024",
    uiPromoCode: HOLIDAY_PROMO_CODE.BLACKFRIDAY6,
    forcedPlanDuration: 12,
    numberOfMonthsOff: 6,
    isUserSegmentEligible: () => true,
    translationKey: "blackFridayBannerMonthsOff",
    promoType: PROMO_TYPE.MONTHS_OFF,
    productTypeTranslationKey: PROMO_PRODUCT_TYPE.NOOM_WEIGHT,
    bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE.URL_PARAM,
    autoApplyPromoCode: true,
  },
  [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_25_PERCENT_OFF]: {
    eligibleRoutes: [...BASE_PROMO_ROUTES, routeConstants.affiliateTrialNPB],
    urlPromoValue: "25BFCM2024",
    uiPromoCode: HOLIDAY_PROMO_CODE.BLACKFRIDAY25,
    isUserSegmentEligible: () => isEnLanguage(),
    translationKey: "blackFridayBannerPercentageOff",
    promoType: PROMO_TYPE.PERCENTAGE_OFF,
    productTypeTranslationKey: PROMO_PRODUCT_TYPE.NOOM_WEIGHT,
    bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE.URL_PARAM,
    autoApplyPromoCode: true,
  },
  [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF]: {
    eligibleRoutes: [...BASE_PROMO_ROUTES, routeConstants.affiliateTrialNPB],
    urlPromoValue: "6MonthNYE2024",
    uiPromoCode: HOLIDAY_PROMO_CODE.NEWYEAR6,
    forcedPlanDuration: 12,
    numberOfMonthsOff: 6,
    isUserSegmentEligible: () => true,
    translationKey: "newYearBannerMonthsOff",
    promoType: PROMO_TYPE.MONTHS_OFF,
    productTypeTranslationKey: PROMO_PRODUCT_TYPE.NOOM_WEIGHT,
    bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE.URL_PARAM,
    autoApplyPromoCode: true,
  },
  [HOLIDAY_PROMO_TYPE.NEW_YEAR_25_PERCENT_OFF]: {
    eligibleRoutes: [...BASE_PROMO_ROUTES, routeConstants.affiliateTrialNPB],
    urlPromoValue: "25NYE2024",
    uiPromoCode: HOLIDAY_PROMO_CODE.NEWYEAR25,
    isUserSegmentEligible: () => isEnLanguage(),
    translationKey: "newYearBannerPercentageOff",
    promoType: PROMO_TYPE.PERCENTAGE_OFF,
    productTypeTranslationKey: PROMO_PRODUCT_TYPE.NOOM_WEIGHT,
    bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE.URL_PARAM,
    autoApplyPromoCode: true,
  },
  [HOLIDAY_PROMO_TYPE.SUMMER_SALE_6_MONTHS_OFF]: {
    eligibleRoutes: BASE_PROMO_ROUTES,
    urlPromoValue: "6MonthSummer2024",
    uiPromoCode: HOLIDAY_PROMO_CODE.SUMMER6,
    forcedPlanDuration: 12,
    numberOfMonthsOff: 6,
    isUserSegmentEligible: () => isEnLanguage() && !isUS(),
    translationKey: "summerBanner",
    promoType: PROMO_TYPE.MONTHS_OFF,
    productTypeTranslationKey: PROMO_PRODUCT_TYPE.NOOM_WEIGHT,
    bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE.URL_PARAM,
    autoApplyPromoCode: true,
  },
  [HOLIDAY_PROMO_TYPE.NEW_YEAR_10_PERCENT_OFF]: {
    eligibleRoutes: [routeConstants.clinical],
    urlPromoValue: "10NYE2024",
    uiPromoCode: HOLIDAY_PROMO_CODE.NEWYEAR10,
    isUserSegmentEligible: () => isEnLanguage() && isUS(),
    translationKey: "newYearMedBannerPercentageOff",
    promoType: PROMO_TYPE.PERCENTAGE_OFF,
    productTypeTranslationKey: PROMO_PRODUCT_TYPE.NOOM_MED,
    bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE.URL_PARAM,
    autoApplyPromoCode: true,
  },
  [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF_INTL_DIRECT]: {
    eligibleRoutes: [routeConstants.direct],
    uiPromoCode: HOLIDAY_PROMO_CODE.NEWYEAR6,
    forcedPlanDuration: 12,
    numberOfMonthsOff: 6,
    isUserSegmentEligible: () => isEnLanguage && (isCA() || isUK()),
    translationKey: "newYearBannerMonthsOff",
    promoType: PROMO_TYPE.MONTHS_OFF,
    productTypeTranslationKey: PROMO_PRODUCT_TYPE.NOOM_WEIGHT,
    bannerActivationType: PROMO_BANNER_ACTIVATION_TYPE.DATE_RANGE,
    autoApplyPromoCode: false,
    activeFrom: new Date(appConfig.INTL_NYE_HOLIDAY_PROMO_BANNER_START_TIME),
    activeTo: new Date(appConfig.INTL_NYE_HOLIDAY_PROMO_BANNER_END_TIME),
  },
};

export const replacementPromoCodeMapping = {
  USD: {
    [HOLIDAY_PROMO_TYPE.SUMMER_SALE_6_MONTHS_OFF]: {
      12: "SUMMERUSD6",
    },
    [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_6_MONTHS_OFF]: {
      12: "BFCMUSD6",
    },
    [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF]: {
      12: "NEWYEARUSD6",
    },
  },
  CAD: {
    [HOLIDAY_PROMO_TYPE.SUMMER_SALE_6_MONTHS_OFF]: {
      12: "SUMMERCAD6",
    },
    [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_6_MONTHS_OFF]: {
      12: "BFCMCAD6",
    },
    [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF]: {
      12: "NEWYEARCAD6",
    },
  },
  NZD: {
    [HOLIDAY_PROMO_TYPE.SUMMER_SALE_6_MONTHS_OFF]: {
      12: "SUMMERNZD6",
    },
    [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_6_MONTHS_OFF]: {
      12: "BFCMNZD6",
    },
    [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF]: {
      12: "NEWYEARNZD6",
    },
  },
  AUD: {
    [HOLIDAY_PROMO_TYPE.SUMMER_SALE_6_MONTHS_OFF]: {
      12: "SUMMERAUD6",
    },
    [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_6_MONTHS_OFF]: {
      12: "BFCMAUD6",
    },
    [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF]: {
      12: "NEWYEARAUD6",
    },
  },
  GBP: {
    [HOLIDAY_PROMO_TYPE.SUMMER_SALE_6_MONTHS_OFF]: {
      12: "SUMMERGBP6",
    },
    [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_6_MONTHS_OFF]: {
      12: "BFCMGBP6",
    },
    [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF]: {
      12: "NEWYEARGBP6",
    },
  },
  EUR: {
    [HOLIDAY_PROMO_TYPE.SUMMER_SALE_6_MONTHS_OFF]: {
      12: "SUMMEREUR6",
    },
    [HOLIDAY_PROMO_TYPE.BLACK_FRIDAY_6_MONTHS_OFF]: {
      12: "BFCMEUR6",
    },
    [HOLIDAY_PROMO_TYPE.NEW_YEAR_6_MONTHS_OFF]: {
      12: "NEWYEAREUR6",
    },
  },
};

// Hardcoded post-discount prices to avoid having
// to use an api request to load the forced plan before checkout
export const priceMapping = {
  USD: {
    6: 159,
    12: 179,
  },
  CAD: {
    6: 129,
    12: 199,
  },
  NZD: {
    6: 169,
    12: 209,
  },
  AUD: {
    6: 149,
    12: 199,
  },
  GBP: {
    6: 59,
    12: 114,
  },
  EUR: {
    6: 99,
    12: 119,
  },
};

export const discountedPlanMapping = {
  USD: {
    12: 156.75,
  },
  CAD: {
    12: 201.75,
  },
  NZD: {
    12: 201.75,
  },
  AUD: {
    12: 201.75,
  },
  GBP: {
    12: 119.25,
  },
  EUR: {
    12: 123,
  },
};
