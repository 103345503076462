import React from "react";
import { createBrowserHistory, History, LocationListener } from "history";
import { setActivePageSet } from "src/utils/redux/slices/linearBuyflow";
import getStore from "src/utils/redux/store";
import { getPage, getPageSet } from "../pageSets";
import { Router } from "react-router";
import { captureException } from "src/utils/error";
import { getSearchQuery } from "src/utils/urlParams";
import { PageSet } from "../types";
import { appendPiiParams } from "src/utils/urlParamsPii";
import { flushMonitoring } from "@utils/monitoring/bus";

export type PageSetHistoryState = { pageSetId: string };

const history = createPageSetAwareHistory();

let runningNav = false;

/**
 * Signals when we are part of an intentional navigation. This lets
 * us differentiate between desired navigation and back/forward+reloads.
 */
export function isGoing() {
  return !!runningNav;
}

export function reroll(
  url: string,
  queryParams = new URLSearchParams(),
  replace = false
) {
  /* istanbul ignore next */
  if (__NODE_ENV__ !== "production" && !url) {
    throw new Error(`Unknown url passed to reroll`);
  }

  flushMonitoring();

  // the URL string may also contain query params; parse them out and combine
  const tempUrl = new URL(url, "https://www.unused-url.com"); // Base here does not matter
  const combinedQueryParams = new URLSearchParams({
    ...Object.fromEntries(queryParams),
    ...Object.fromEntries(tempUrl.searchParams),
  });

  appendPiiParams(combinedQueryParams);

  runningNav = true;
  const href = `${tempUrl.pathname}${getSearchQuery(combinedQueryParams)}${
    tempUrl.hash
  }`;

  if (replace) {
    window.location.replace(href);
  } else {
    window.location.href = href;
  }
}

// Note that we are doing direct to API access here to allow us to work with any]
// type of router that we might be using. After we move completely away from the
// hash router, we can return to using the router history directly
export function globalPush(url: string) {
  /* istanbul ignore next */
  if (__NODE_ENV__ !== "production" && !url) {
    throw new Error(`Unknown url passed to pushState`);
  }

  const { pathname: oldPathname, hash: oldHash } = window.location;

  // HACK: KILL THIS CODE WHEN WE HAVE A SINGLE ROUTER - KD 7/7/2021
  window.history.pushState(null, null, url);

  const { pathname: newPathname, hash: newHash } = window.location;

  runningNav = true;
  // This notifies history routers that we want to re-render the page.
  if (oldPathname !== newPathname) {
    const popState = new CustomEvent("popstate");
    (popState as any).state = {};
    window.dispatchEvent(popState);
  }
  if (oldHash !== newHash) {
    // This notifies hash routers that we want to re-render the page.
    // Note that this will cause a double render on IE.
    window.dispatchEvent(new CustomEvent("hashchange"));
  }
  runningNav = false;
}

export function getHistory<T = PageSetHistoryState>() {
  return history as History<T>;
}

/**
 * Ensures that the history state is setup for proper backwards navigation.
 * This is needed for direct path navigation cases as there is no history
 * state in those cases.
 */
export function ensurePageSetHistoryState(acceptedPageSet: PageSet) {
  const { location } = getHistory();
  if (location.state?.pageSetId !== acceptedPageSet.id) {
    // Note using DOM API directly to avoid the replace triggering the router
    window.history.replaceState(
      {
        // This mirrors the behavior of the history module. It's a hack, but we need to
        // specifically have a replace without router navigation here to avoid impacting
        // network requests.
        // https://github.com/remix-run/history/blob/v4.10.1/modules/createBrowserHistory.js#L229
        ...window.history.state,
        state: {
          ...location.state,
          pageSetId: acceptedPageSet.id,
        },
      },
      undefined,
      window.location.href
    );
  }
}

// Implements custom history, allowing for us to manage
// LBF state prior to any rerendering.
export function PageSetRouter({ children }) {
  return <Router history={history}>{children}</Router>;
}

/**
 * Factory to create customized history for page sets.
 *
 * This little screaming bundle of joy wraps the core browser history instance
 * with a customized listener lifecycle. This allows us to defer render updates
 * from back+forward navigation until the page set has been properly
 * initialized.
 *
 * This wrapper is needed as:
 * 1. The router binds itself as the first listener, meaning that by the time
 *   we get to our listeners passed to their APIs, the router has already
 *   rendered the page, leading to inconsistent pageset state, which will
 *   likely be fatal.
 *
 * 2. The navigation management APIs in the native history API implementation do not
 *   allow for async blocking of request or any sort of chaining of listeners.
 *   Since pagesets need async to init, we need to take a more active role in
 *   the onpopstate lifecycle.s
 */
function createPageSetAwareHistory(): History<unknown> {
  const browserHistory = createBrowserHistory<{ pageSetId: string }>();

  const downstreamListeners = new Set<LocationListener>();

  function listen(listener: LocationListener) {
    downstreamListeners.add(listener);
    return () => {
      downstreamListeners.delete(listener);
    };
  }

  const overrides = {
    listen,
  };
  ["push", "replace", "go", "goBack", "goForward"].forEach((method) => {
    overrides[method] = (...args: any[]) => {
      runningNav = true;
      browserHistory[method](...args);
    };
  });

  let eventSequence = 0;
  browserHistory.listen(async (newLocation, action) => {
    eventSequence += 1;
    const currentEvent = eventSequence;

    // Only need to reload page set on back/forward navigation.
    if (action === "POP") {
      const store = getStore();
      const state = store.getState();
      const statePageSet = getPageSet(newLocation.state?.pageSetId);
      const statePage = getPage(
        statePageSet?.pages,
        newLocation.pathname,
        state
      );

      // If the state for the new location defines a pageset different than
      // the current, then pause the (react) navigation until we're loaded.
      if (statePage && statePageSet.id !== state.linearBuyflow.pageSetName) {
        await store.dispatch(setActivePageSet(statePageSet));

        // Delay here should be small as the page set should have already
        // been primed by this point. There is a slight chance that a race
        // occurs here if there are multiple back to back navigation
        // events. When this occurs, avoid sending out of date events to
        // the router.
        if (eventSequence !== currentEvent) {
          if (statePageSet.id !== browserHistory.location.state.pageSetId) {
            // This could be an expected error, if there is still a concurrent
            // set call in flight. Logging for now and we can examine prevalence
            // after.
            captureException(
              new Error(
                `Navigation occurred with pending pageset. Set pageset: ${statePageSet.id} Active pageset: ${browserHistory.location.state.pageSetId}`
              )
            );
          }
          return;
        }
      }
    }

    // Propagate to react router, etc.
    downstreamListeners.forEach((listener) => listener(newLocation, action));
    runningNav = false;
  });

  // Lets over overwrite only the listen function, while still
  // retaining any field mutations that history may have.
  return new Proxy(browserHistory, {
    get(target, prop) {
      if (prop in overrides) {
        return overrides[prop];
      }
      if (prop === "type") {
        return "pageSet";
      }
      return target[prop];
    },
  });
}
