import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Offer } from "./serverContext";
import {
  getAMCoursepackId,
  getMovementCoursepackId,
  getGlp1CoursepackId,
  getMWCoursepackId,
  getSleepCoursepackId,
  AM_PRODUCT_ID,
  SLEEP_PRODUCT_ID,
  MOVEMENT_PRODUCT,
  getMWPaidCoursepackId,
} from "src/utils/paymentAddon";

export type PaymentAddon = Partial<Offer> & {
  sales_tax_amount?: number;
  sales_tax_rate?: number;
  is_tax_inclusive?: boolean;
};

const paymentAddonAdapter = createEntityAdapter({
  selectId: (addOn: PaymentAddon) => addOn.addon_product_id,
});

const initialState = paymentAddonAdapter.getInitialState();

export type PaymentAddonState = typeof initialState;

const paymentAddonSlice = createSlice({
  name: "paymentAddon",
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    insertPaymentAddons(state, action: PayloadAction<PaymentAddon[]>) {
      paymentAddonAdapter.setMany(
        state,
        action.payload.map((addon) => ({
          sales_tax_amount: 0,
          sales_tax_rate: 0,
          ...addon,
        }))
      );
    },
    updatePaymentAddon(state, action: PayloadAction<PaymentAddon>) {
      const addOn = action.payload;
      paymentAddonAdapter.updateOne(state, {
        id: addOn.addon_product_id,
        changes: addOn,
      });
    },
    /* eslint-enable no-param-reassign */
  },
});

export const getAddOnTotalPrice = (paymentAddon?: PaymentAddon) => {
  if (!paymentAddon?.price) {
    return 0;
  }

  return paymentAddon.is_tax_inclusive
    ? paymentAddon.price
    : paymentAddon.price * (1 + (paymentAddon.sales_tax_rate || 0));
};

export const getTotalPriceWithPlan = (
  paymentAddons: PaymentAddon[],
  planPrice: number
) => {
  return paymentAddons.reduce(
    (total, addOn) => total + getAddOnTotalPrice(addOn),
    planPrice
  );
};

export const getTotalSalesTaxWithPlan = (
  paymentAddons: PaymentAddon[],
  planSalesTaxAmount: number
) => {
  return paymentAddons.reduce(
    (total, addOn) => total + (addOn.sales_tax_amount || 0),
    planSalesTaxAmount
  );
};

export const getAddonTrialDuration = (addon?: PaymentAddon) => {
  if (addon?.trial_items?.length > 0) {
    return addon.trial_items[0].trial_duration;
  }
  return 0;
};

export const getAddonTrialPrice = (addon?: PaymentAddon) => {
  if (addon?.trial_items?.length > 0) {
    return addon.trial_items[0].trial_price;
  }
  return 0;
};

export const {
  selectAll: selectAllPaymentAddons,
  selectById: selectPaymentAddonById,
} = paymentAddonAdapter.getSelectors();

export const { insertPaymentAddons, updatePaymentAddon } =
  paymentAddonSlice.actions;

export default paymentAddonSlice;

export function selectMWCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, getMWCoursepackId());
}

export function selectPaidMWCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, getMWPaidCoursepackId());
}

export function selectGlp1Coursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, getGlp1CoursepackId());
}

export function selectAMCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, getAMCoursepackId());
}

export function selectPaidAMCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, AM_PRODUCT_ID);
}

export function selectMovementCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, getMovementCoursepackId());
}

export function selectPaidMovementCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, MOVEMENT_PRODUCT);
}

export function selectSleepCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, getSleepCoursepackId());
}

export function selectPaidSleepCoursepack(state: PaymentAddonState) {
  return selectPaymentAddonById(state, SLEEP_PRODUCT_ID);
}
