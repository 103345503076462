import React from "react";
import styled from "@emotion/styled";
import { fontP1Medium } from "src/design-system/styles/fonts";
import { compassColors } from "src/utils/styling";
import { useCountdown } from "src/hooks/countdown";
import { pad } from "src/utils/datetime";

function getElapsedTimeInSeconds(expirationTimestamp: number) {
  const ref = Date.now();
  return Math.round((expirationTimestamp - ref) / 1000);
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Digits = styled.span`
  ${fontP1Medium};
  color: ${compassColors.tarocco};
  font-family: "Untitled Sans", sans-serif;
  background-color: ${compassColors.offWhite};
  border-radius: 8px;
  padding: 3px;
  width: 28px;
  height: 28px;
  text-align: center;
`;

const Colon = styled.span`
  margin: 0 3px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export function CountdownTimer({
  expirationTimestamp,
  onClose,
}: {
  expirationTimestamp: number;
  onClose: () => void;
}) {
  const { minutes, seconds } = useCountdown({
    durationSeconds: getElapsedTimeInSeconds(expirationTimestamp),
    active: true,
    onComplete: () => {
      onClose();
    },
  });

  return (
    <Container>
      <Digits>{pad(minutes, 2)}</Digits>
      <Colon>:</Colon>
      <Digits>{pad(seconds, 2)}</Digits>
    </Container>
  );
}
