import React, { ComponentProps } from "react";
import { appConfig } from "src/config";
import { getLanguage } from "src/utils/meristemContext";
import { Text } from "../core/Text";

const baseDisclaimerLinks = {
  TocLink: `${appConfig.BLOG_DOMAIN}/terms-and-conditions-of-use/`,
  PrivacyLink: `${appConfig.BLOG_DOMAIN}/noom-privacy-policy/`,
  ResearchLink: `${appConfig.BLOG_DOMAIN}/research-policy/`,
  GooglePrivacyLink: "https://policies.google.com/privacy",
  HIPAALink: `${appConfig.BLOG_DOMAIN}/hipaa-notice/`,
  GoogleTocLink: "https://policies.google.com/terms",
  SupportLink: `${appConfig.BLOG_DOMAIN}/support/contact-support`,
  EmailHMSupportLink: "mailto:hmsupport@noom.com",
  EmailSupportLink: "mailto:programsupport@noom.com",
  FsaHsaLink: "https://www.irs.gov/publications/p969",
};
const localeSpecificDisclaimerLinks = {
  es: {
    TocLink: "https://www.noom.com/terms-and-conditions-of-use/spanish/",
    PrivacyLink: "https://www.noom.com/privacy-policy-spanish/",
    ResearchLink: "https://www.noom.com/es/research-policy/",
    SupportLink: "https://www.noom.com/es/support/contact-support/",
    EmailSupportLink: "mailto:programsupport-es@noom.com",
  },
  de: {
    TocLink: "https://web2.noom.com/de/de-tos/",
    PrivacyLink: "https://web2.noom.com/de/de-privacy-policy/",
    ResearchLink: "https://web2.noom.com/de/research-policy-german/",
    SupportLink:
      "https://web2.noom.com/de/support/kundenservice-premium-funktionen/kontaktiere-support/",
    EmailSupportLink: "mailto:support-de@noom.com",
  },
};

export function getDisclaimerUrls() {
  // Merge locale-specific links with default links
  return {
    ...baseDisclaimerLinks,
    ...localeSpecificDisclaimerLinks[getLanguage() as "de" | "es"],
  };
}

export function DisclaimerLinks(props: ComponentProps<typeof Text>) {
  const disclaimerUrls = getDisclaimerUrls();

  // Generate a map of DisclaimerLinks for convenient interpolation of disclaimer links in a <Text /> component.
  const disclaimerLinks: Record<string, typeof DisclaimerLink> = Object.entries(
    disclaimerUrls
  ).reduce((map, [linkName, href]) => {
    // eslint-disable-next-line no-param-reassign
    map[linkName] = <DisclaimerLink href={href} />;
    return map;
  }, {});

  return <Text components={disclaimerLinks} {...props} />;
}

export function DisclaimerLink({
  href,
  children,
}: {
  href: string;
  children?: React.ReactNode;
}) {
  return (
    <a href={href} css={{ color: "inherit" }} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}
