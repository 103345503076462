import { getCountryCode, getMeristemContext } from "@utils/meristemContext";
import getStore from "../redux/store";
import { appConfig } from "src/config";

export default function getApiDomain() {
  const env = appConfig.NOOM_ENV;
  switch (env) {
    case "staging":
      return ".wsli.dev";
    case "production":
      return ".noom.com";
    default:
      return "localhost";
  }
}

/**
 * Injects common API parameters that are required/helpful for all API requests.
 */
export function prepareGrowthAPIParameters(parameters = new URLSearchParams()) {
  try {
    const { upid } = getStore().getState();
    // If we have a upid available, also send that along
    if (upid) {
      parameters.set("upid", upid);
    }
  } catch (e) {
    /* NOP */
    // Why the try/catch at all? This function might be called before store has initialized.
    // For example, from error paths that attempt to report the error via `trackEvent`.
  }

  // get_geo_details parameters reads this for most requests
  if (getMeristemContext().is_forced_country_code) {
    parameters.set("fcc", getCountryCode());
  }

  // Filter out legacy/local only parameters
  parameters.delete("idm");
  parameters.delete("sourceId");
  parameters.delete("entryPoint");

  // Prevent nuid parameter forwarding from authenticating the user on endpoints that havent
  // been updated to use the new auth flow.
  parameters.delete("nuid");

  return parameters;
}
