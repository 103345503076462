import { trackEvent } from "./api/tracker";
import { logInfo } from "./monitoring/logging";

// Track the initial state
let hasBeenVisibleState = isVisible();
let hasUnloaded = false;

document.addEventListener("visibilitychange", () => {
  // Track visibility changes
  logInfo("visibilitychange");
  hasBeenVisibleState =
    hasBeenVisibleState || document.visibilityState === "visible";
});

window.addEventListener("pagehide", () => {
  logInfo("pagehide");
  hasUnloaded = true;
});

export function hasBeenVisible() {
  return hasBeenVisibleState;
}

export function isVisible() {
  // Assume we are visible for browsers that don't support the visibility API
  // otherwise used the state the browser provides
  return (
    !("visibilityState" in document) || document.visibilityState === "visible"
  );
}

export function isUnloading() {
  return hasUnloaded;
}

/**
 * Tracks events, with an attempt to filter those that are expected
 * in response to page unload.
 */
export function trackEventIfNotUnloading(
  ...args: Parameters<typeof trackEvent>
) {
  setTimeout(() => {
    if (!isUnloading()) {
      trackEvent(...args);
    }
  }, 500);
}
