import React from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
export const FadeIn = styled.div<{
  duration?: number;
  delay?: number;
  skip?: boolean;
}>`
  ${(props) =>
    !props.skip &&
    css`
      opacity: 0;
      animation-name: ${fadeIn};
      animation-duration: ${props.duration || 2}s;
      animation-delay: ${props.delay !== undefined ? props.delay : 1}s;
      animation-fill-mode: forwards;
    `}
`;

export const delayedFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInAndOut = keyframes`
  from {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const FadeInAndOut = styled.div<{ duration?: number; delay?: number }>`
  opacity: 0;
  animation-name: ${fadeInAndOut};
  animation-duration: ${(props) => props.duration || 3}s;
  animation-delay: ${(props) => props.delay || 0}s;
  animation-fill-mode: forwards;
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}`;

export const fadeOutAnimation = css`
  opacity: 1;
  animation: ${fadeOut} 3s ease-in;
`;

export const FadeOut = styled.div<{ duration?: number; delay?: number }>`
  animation-name: ${fadeOut};
  animation-duration: ${(props) => props.duration || 2}s;
  animation-delay: ${(props) => (props.delay !== undefined ? props.delay : 1)}s;
  animation-fill-mode: forwards;
`;

function getMoveKeyframes(initialTransform: string) {
  return keyframes`
    from {
      transform: ${initialTransform};
    }
    to {
      transform: translateY(0);
    }
  `;
}

export const MoveUp = styled.div<{
  duration?: number;
  delay?: number;
  skip?: boolean;
  initialTransform: string;
}>`
  ${({ skip, duration, delay, initialTransform }) => {
    return (
      !skip &&
      css`
        transform: ${initialTransform};
        animation-name: ${getMoveKeyframes(initialTransform)};
        animation-duration: ${duration || 1}s;
        animation-delay: ${delay !== undefined ? delay : 0.5}s;
        animation-fill-mode: forwards;
      `
    );
  }}
`;

export function MoveUpAndFadeIn({
  children,
  duration,
  delay,
  skip,
  initialTransform,
}: {
  children: React.ReactNode;
  duration?: number;
  delay?: number;
  skip?: boolean;
  initialTransform: string;
}) {
  return (
    <MoveUp
      duration={duration}
      delay={delay}
      initialTransform={initialTransform}
      skip={skip}
    >
      <FadeIn duration={duration} delay={delay} skip={skip}>
        {children}
      </FadeIn>
    </MoveUp>
  );
}
export const FadeInUp = keyframes`
  from {
    transform: translateY(16px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const DelayBetweenOptions = 0.05;

export const getOptionAnimationCss = (index: number) => {
  return css`
    animation: ${FadeInUp} 0.6s cubic-bezier(0, 0, 0.4, 1);
    animation-delay: ${0.1 + index * DelayBetweenOptions}s;
    opacity: 0;
    animation-fill-mode: forwards;
  `;
};
