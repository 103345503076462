/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { isUS } from "@utils/userSegment";
import { DisclaimerLinks } from "./DisclaimerLinks";
import styled from "@emotion/styled";
import { compassColors } from "src/utils/styling";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { css } from "@emotion/react";

type Props = {
  contactTranslationKey?: string;
};

const Footer = styled.footer`
  margin: 0 auto;
  padding: 1rem 32px 3rem 32px;
  max-width: 780px;
  font-size: 11px;
  font-family: "Untitled Sans", sans-serif;
  color: ${compassColors.black};
  margin-top: 30px;

  @media ${breakpointMobileExtended} {
    text-align: center;
    padding: 24px;
  }
`;

/**
 * Footer shown on the Payment page
 */
function PaymentFooter({ contactTranslationKey = "contact" }: Props) {
  if (isUS()) {
    // Use copy without additional line breaks for contactWithoutTC only
    const translationKeyUSCore =
      contactTranslationKey === "contactWithoutTC"
        ? "contactWithoutTCCompact"
        : contactTranslationKey;
    return (
      // Use different styling when smartphone req footer is enabled
      <Footer
        css={css`
          margin-top: 25px;

          @media ${breakpointMobileExtended} {
            margin-top: 0px;
          }
        `}
      >
        <DisclaimerLinks
          parent="div"
          i18nKey={`paymentFooter:${translationKeyUSCore}`}
        />
      </Footer>
    );
  }
  return (
    <Footer>
      <DisclaimerLinks
        parent="div"
        i18nKey={`paymentFooter:${contactTranslationKey}`}
      />
    </Footer>
  );
}

export default PaymentFooter;
