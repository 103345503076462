import { ReadonlyDeep } from "type-fest";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "./noomSessionStorage";
import { QueryParametersState, updateURLParams } from "./urlParams";

const URL_PARAMS_PII_KEY = "urlpSession";

// List of parameters that can contain PII and need to be hidden from pixels and third parties.
// These will be re-appended for context calls, API calls, and page rerolls, since they can be
// important for backend handling.
const paramDenylist = [
  // Used by updateUserDataSlice in init
  "name",
  "email",

  // Referrals
  "ref_email",

  // UTM params that can contain PII.
  "utm_term",
  "utm_content",
] as const;

let urlParamsPiiSessionStore: QueryParametersState =
  getNoomSessionStorage(URL_PARAMS_PII_KEY) || {};

export function getUrlParamsPii(): ReadonlyDeep<QueryParametersState> {
  return urlParamsPiiSessionStore;
}

function setUrlParamsPii(params: QueryParametersState) {
  urlParamsPiiSessionStore = params;
  setNoomSessionStorage(URL_PARAMS_PII_KEY, urlParamsPiiSessionStore);
}

// Modifies the current URL to hide params. Should be called before any third-party code (pixels)
// is loaded.
export function init() {
  const urlParams = new URLSearchParams(window.location.search);
  const prevHiddenParams = getUrlParamsPii();
  const { hidden } = categorizeParams(urlParams);
  // Keys set to null are removed from the URL
  updateURLParams(
    Object.keys(hidden).reduce((ret, key) => {
      return {
        ...ret,
        [key]: null,
      };
    }, {})
  );
  setUrlParamsPii({ ...prevHiddenParams, ...hidden });
}
// Determines which params need to be hidden and which can remain.
export function categorizeParams(params: URLSearchParams) {
  const paramObj = Object.fromEntries(params.entries());
  const hiddenParams = {};
  paramDenylist.forEach((deny) => {
    if (paramObj[deny]) {
      hiddenParams[deny] = paramObj[deny];
      delete paramObj[deny];
    }
  });
  return {
    params: paramObj,
    hidden: hiddenParams,
  };
}

// Modifies the given params by adding the hidden params to it.
export function appendPiiParams(params: URLSearchParams) {
  const piiParams = getUrlParamsPii();
  Object.keys(piiParams).forEach((key) => {
    if (piiParams[key]) {
      params.set(key, `${piiParams[key]}`);
    }
  });
}
