import goto from "src/pageDefinitions/goto";
import { updateForwardedParams } from "src/utils/urlParams";

export const TELEHEALTH_FREE_PLAN_ID = "option_k_20210608_ex680";

export function removeTelehealthQueryParams() {
  // (justin) params not necessary if not in telehealth flow
  updateForwardedParams({
    noomPlanId: null,
    userId: null,
  });
}

// exit the telehealth flow for a user that has ineligible insurance or opts out
export const exitTelehealthBuyflow = (): Promise<void> => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const noomPlanId = urlSearchParams.get("noomPlanId");

  removeTelehealthQueryParams();
  return goto.chooseTrialPrice(noomPlanId);
};

export const continueToTelehealthPayment = (): Promise<void> => {
  removeTelehealthQueryParams();

  return goto.telehealthPaymentDetails();
};
