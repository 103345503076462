import { matchPath } from "react-router";
import { ActionHandler, ActionHandlerParams } from ".";
import { pageMatchesPath } from "../pageSets";
import { updateSessionState } from "../session";

type Params = {
  type: Parameters<typeof updateSessionState>[0];
  layer?: string;
  key: string;
  value: JsonObject;
};

export const saveToSession: ActionHandler = async ({
  pageSet,
  page,
  history,
  params = {},
}: {
  params: Params;
} & ActionHandlerParams) => {
  let { value } = params;

  // Extract params from aliases matches
  if (typeof value === "string" && value.startsWith(":")) {
    let { pathname } = history.location;

    pathname = pathname.replace(/^\//, "");
    const pathVarName = value.slice(1);
    const routerPath = pageMatchesPath(page, pathname);

    if (routerPath) {
      const match = matchPath(pathname, { exact: true, path: routerPath });
      value = match.params[pathVarName];
    }
  }

  value = { [params.key]: value };
  if (params.type === "pageSet") {
    updateSessionState(params.type, pageSet.layer, value);
  } else {
    updateSessionState(params.type, value);
  }
};
