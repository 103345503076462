import { singularSdk, SingularConfig } from "singular-sdk";
import { appConfig } from "src/config";
import { getInAppAutoCookie } from "src/utils/authCookieParser";
import { LibCategories, waitForConsent } from "src/utils/consent";

export function loadSingular() {
  if (getInAppAutoCookie()) {
    // Singular SDK should not initialize if in-app auto-login cookie is set.
    return Promise.resolve();
  }

  // Note (pedroa): we should only initialize the SDK if the user consents with targeting cookies,
  // since Singular will automatically emit a PAGE_VISIT event when we initialize it.
  return waitForConsent([LibCategories.targetingCookies]).then(() => {
    const singularConfig = new SingularConfig(
      appConfig.SINGULAR_SDK_KEY,
      appConfig.SINGULAR_SDK_SECRET,
      appConfig.SINGULAR_SDK_PRODUCT_ID
    );

    singularSdk.init(singularConfig);

    return Promise.resolve();
  });
}
