/* eslint-disable */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  TiktokAnalyticsObject: string;
  ttq: any;
};

window.TiktokAnalyticsObject = "ttq";

export const ttq = window.ttq || [];

// Tag setup
window.ttq = ttq;
ttq.methods = [
  "page",
  "track",
  "identify",
  "instances",
  "debug",
  "on",
  "off",
  "once",
  "ready",
  "alias",
  "group",
  "enableCookie",
  "disableCookie",
];
ttq.setAndDefer = function (t, e) {
  t[e] = function () {
    t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
  };
};
for (let i = 0; i < ttq.methods.length; i++) {
  ttq.setAndDefer(ttq, ttq.methods[i]);
}
ttq.instance = function (t) {
  const e = ttq._i[t] || [];
  for (let n = 0; n < ttq.methods.length; n++)
    ttq.setAndDefer(e, ttq.methods[n]);
  return e;
};

//Second id added as part of https://noom.slack.com/archives/CA0LA9V6J/p1679584105030759
export const identifier = "C6P4NB0394AQ13GJQN40";
const identifierv2 = "CGERVK3C77U35EGH0IFG";

const baseUrl = "https://analytics.tiktok.com/i18n/pixel/events.js";
ttq._i = ttq._i || {};
ttq._i[identifier] = [];
ttq._i[identifier]._u = baseUrl;
ttq._i[identifierv2] = [];
ttq._i[identifierv2]._u = baseUrl;
ttq._t = ttq._t || {};
ttq._t[identifier] = +new Date();
ttq._t[identifierv2] = +new Date();
ttq._o = ttq._o || {};
ttq._o[identifier] = {}; // originally `n || {};` n seems undefined
ttq._o[identifierv2] = {};

export async function loadTikTok() {
  await loadScript(baseUrl + "?sdkid=" + identifier + "&lib=" + "ttq", [
    LibCategories.targetingCookies,
  ]);
  ttq.page();
  await loadScript(baseUrl + "?sdkid=" + identifierv2 + "&lib=" + "ttq", [
    LibCategories.targetingCookies,
  ]);
  ttq.page();
}
