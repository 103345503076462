import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ShippingInfo = {
  firstName: string;
  lastName: string;
  phone: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
  };
};

export type ShippingStatus = {
  buyDisabled: boolean;
};

export type ShippingInfoError = Partial<
  Replace<
    Omit<ShippingInfo, "address"> & ShippingInfo["address"],
    keyof (Omit<ShippingInfo, "address"> & ShippingInfo["address"]),
    boolean
  >
>;
export type Offers = {
  shippingInfo: ShippingInfo;
  shippingInfoError: ShippingInfoError;
  shippingStatus: ShippingStatus;
  purchasedPlans: Array<string>;
};

const initialState: Offers = {
  shippingInfo: {
    firstName: "",
    lastName: "",
    phone: "",
    address: {
      street: "",
      city: "",
      state: "AK",
      zipcode: "",
      country: "US", // Default to US since physical addons can only be purchased in US
    },
  },
  shippingInfoError: {},
  shippingStatus: {
    buyDisabled: false,
  },
  purchasedPlans: [],
};

const offersShipping = createSlice({
  name: "offers",
  initialState,
  reducers: {
    updateShippingInfo(state, action: PayloadAction<ShippingInfo>) {
      return { ...state, shippingInfo: action.payload };
    },
    updateShippingInfoError(state, action: PayloadAction<ShippingInfoError>) {
      return { ...state, shippingInfoError: action.payload };
    },
    updateShippingStatus(state, action: PayloadAction<ShippingStatus>) {
      return { ...state, shippingStatus: action.payload };
    },
    updatePurchasedPlans(state, action: PayloadAction<Array<string>>) {
      return { ...state, purchasedPlans: action.payload };
    },
  },
});

export const {
  updateShippingInfo,
  updateShippingInfoError,
  updateShippingStatus,
  updatePurchasedPlans,
} = offersShipping.actions;

export default offersShipping.reducer;
