import { send } from "@utils/fetch";
import { captureException } from "@utils/error";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "./noomSessionStorage";

const CRM_QUEUE_KEY = "crmQueue";

type CRM_PAYLOAD_DATA = {
  user?: JsonObject;
  event?: { event_name: string; event_data?: JsonObject };
};

/**
 * Send UserData, EventData or both to Iterable.
 * Using this method before the user data was saved (usually at the end of the main survey) will result in an error.
 */
function saveToBraze({
  user = null,
  event = null,
}: CRM_PAYLOAD_DATA): Promise<void> {
  if (!user && !event) {
    captureException(new Error("saveToBraze: No data to send to Braze"));
    return Promise.resolve();
  }

  const payload: CRM_PAYLOAD_DATA = {
    user,
    event,
  };

  return send("POST", "/userdata/api/v4/user/braze/save/", payload).catch(
    (err) => {
      captureException(err);
    }
  );
}

/**
 * Queues UserData, EventData or both for CRM upload at a later point.
 * Should be used for cases when the user data was not yet saved (prior to main survey completion)
 * The queue will get flushed at the end of the main survey after the user data was saved.
 */
export function addDataToCRMQueue({
  user = null,
  event = null,
}: CRM_PAYLOAD_DATA) {
  let crmQueue: CRM_PAYLOAD_DATA[] = getNoomSessionStorage(CRM_QUEUE_KEY);

  if (!crmQueue || !(crmQueue instanceof Array)) {
    crmQueue = [{ user, event }];
  } else {
    crmQueue.push({ user, event });
  }

  setNoomSessionStorage(CRM_QUEUE_KEY, crmQueue);
}

/**
 * Flushes the CRM upload queue.
 */
export function flushCRMQueue() {
  const crmQueue: CRM_PAYLOAD_DATA[] = getNoomSessionStorage(CRM_QUEUE_KEY);
  if (!crmQueue || !(crmQueue instanceof Array)) {
    return Promise.resolve();
  }
  return Promise.all(crmQueue.map((payload) => saveToBraze(payload))).then(
    () => {
      setNoomSessionStorage(CRM_QUEUE_KEY, []);
    }
  );
}

export default saveToBraze;
