import { PageList, PageListEntry, PageSet } from "../types";

type VisitorCallback = (data: {
  parent?: PageListEntry;
  entry: PageListEntry;
  depth: number;
}) => void;

/**
 * Implements visitor iterator for page sets
 */
export function visitPageSet(
  pages: PageSet | PageList,
  visitor: VisitorCallback,
  depth?: number
) {
  const pageList = "pages" in pages ? pages.pages : pages;

  for (const entry of pageList) {
    visitor({ entry, depth });

    if ("switch" in entry) {
      for (const casePage of Object.values(entry.cases)) {
        visitor({
          parent: entry,
          entry: casePage,
          depth: depth + 1,
        });
      }
    }

    if ("block" in entry) {
      visitPageSet(
        entry.block,
        ({ entry: childEntry, depth: childDepth, parent }) => {
          visitor({
            entry: childEntry,
            depth: childDepth,
            parent: parent || entry,
          });
        },
        depth + 1
      );
    }
  }

  return undefined;
}
