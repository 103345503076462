import React, { useState } from "react";
import styles from "./CollapsibleHeader.module.less";
import Star from "./images/Star.svg";
import { classNames } from "@utils/styling";
import { Text } from "src/components/core/Text";
import { useOnce } from "src/hooks/lifecycle";
import { updateForwardedParams } from "src/utils/urlParams";

export const CHECK_EMAIL_REMINDER_PARAM = "showCheckEmailReminder";

function shouldShowCollapsibleHeader() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(CHECK_EMAIL_REMINDER_PARAM);
}

// Note(Pratik): Creating dict without object prototype so that
// accidental url param entries like 'constructor' won't break the
// user experience or cause security holes.
const translationKeyMappings = Object.create(null);

translationKeyMappings.noomPremium = {
  title: "collapsibleHeader:noomPremium:title",
  body: "collapsibleHeader:noomPremium:body",
};

translationKeyMappings.noomSummit = {
  title: "collapsibleHeader:noomSummit:title",
  body: "collapsibleHeader:noomSummit:body",
};

translationKeyMappings.snackDelivery = {
  title: "collapsibleHeader:snackDelivery:title",
  body: "collapsibleHeader:snackDelivery:body",
};

function getI18nKeys() {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get(CHECK_EMAIL_REMINDER_PARAM);
  return translationKeyMappings[type];
}

type Props = {
  i18nKeys?: {
    title: string;
    body: string;
  };
};

const CollapsibleHeader = ({ i18nKeys }: Props) => {
  const [isCollapsed, setCollapsed] = useState(false);

  useOnce(() => {
    setTimeout(() => setCollapsed(true), 500);

    // Note: (Pratik) Clears the url param once the notification has rendered one time
    // This prevents the notification from displaying in subsequent pagesets.
    updateForwardedParams({
      [CHECK_EMAIL_REMINDER_PARAM]: null,
    });
  });

  if (!shouldShowCollapsibleHeader()) {
    return null;
  }

  const translationKeys = i18nKeys || getI18nKeys();

  if (!translationKeys) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.collapsableHeader,
        isCollapsed && styles.collapsed
      )}
    >
      <div className={styles.headerHeader}>
        <img className={styles.image} src={Star} alt="" />
        <div className={styles.headerText}>
          <Text i18nKey={translationKeys.title} />
        </div>
      </div>
      <p>
        <Text i18nKey={translationKeys.body} />
      </p>
    </div>
  );
};

export default CollapsibleHeader;
