import React from "react";

// An svg downloaded from Figma, wrapped in a React component
// Rendering this component results in an <svg /> on the DOM, so that attributes like stroke color can be styled using CSS
// (Rendering a non-React component svg through <img src={icon.svg} /> results in an img, which can't have svg attributes styled)
export default function IconError() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9 13H11V15H9V13ZM9 5H11V11H9V5Z"
        fill="#FB513B"
      />
    </svg>
  );
}
