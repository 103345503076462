import { send } from "../fetch";
import { UserModelProto } from "@noom/noom-contracts/noom_contracts/usermodel/usermodel";

const PUBLISH_SURVEY_ANSWERS_API_ENDPOINT =
  "api/usermodel/v1/publishBuyflowSurveyAnswersInUserModel/";

export function publishBuyflowSurveyAnswersInUserModel() {
  return send("POST", PUBLISH_SURVEY_ANSWERS_API_ENDPOINT);
}

export function patchUserModelForUser(
  accessCode: string,
  patchData: UserModelProto
) {
  return send("PATCH", `api/usermodel/v2/${accessCode}`, patchData);
}
