import type { Contexts } from "@sentry/types";
import { isHipaa } from "../userSegment/features";

const SCRUBBED_FIELDS = ["userId", "insuranceInfo"];
const HIPAA_SCRUBBED_FIELDS = [];

/**
 * Special case scrubber for sentry top level contexts
 */
export function scrubContexts(contexts: Contexts) {
  if (!contexts) {
    return contexts;
  }

  const ret = {};
  Object.keys(contexts).forEach((key) => {
    ret[key] = scrubData(contexts[key]);
  });
  return ret;
}

export function scrubData(object: AnyJson) {
  if (typeof object === "object") {
    return JSON.parse(
      JSON.stringify(object, (key, value) => {
        if (
          SCRUBBED_FIELDS.includes(key) ||
          (isHipaa() && HIPAA_SCRUBBED_FIELDS.includes(key))
        ) {
          return `[redacted] ${typeof value}`;
        }

        return value;
      })
    );
  }
  return object;
}
