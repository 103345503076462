import styled from "@emotion/styled";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { fontTitle } from "src/design-system/styles/fonts";
import { HeaderToolbar } from "../core/HeaderToolbar";

export const HeadlineHeader = styled(HeaderToolbar)`
  ${fontTitle};
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;
  padding: 22px 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media ${breakpointMobileExtended} {
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
