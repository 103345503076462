import { useQuery } from "react-query";
import {
  getSubscriptionStatus,
  SubscriptionStatusData,
} from "@utils/api/subscriptionStatus";
import { queryClient } from "@utils/fetch";

const queryOptions = {
  queryFn: () => getSubscriptionStatus(),
  queryKey: ["userSubscriptionStatus"],
};

export function useSubscriptionStatus() {
  const {
    status,
    isLoading,
    data: subscriptionStatus,
  } = useQuery(queryOptions);

  return { status, isLoading, subscriptionStatus };
}

export async function loadSubscriptionStatus() {
  return queryClient.fetchQuery(queryOptions);
}

export function getLoadedSubscriptionStatus() {
  return queryClient.getQueryData<SubscriptionStatusData>(
    queryOptions.queryKey
  );
}
