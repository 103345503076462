/* eslint-disable */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  twq: TwqFunction;
};

interface TwqFunction extends Function {
  exe?: any;
  queue?: any;
  version?: string;
}

export function loadTwitter() {
  if (!window.twq) {
    const twq: TwqFunction = (window.twq = function () {
      if (twq.exe) {
        twq.exe.apply(twq, arguments);
      } else {
        twq.queue.push(arguments);
      }
    });
    twq.version = "1.1";
    twq.queue = [];
    window.twq = twq;
  }

  return loadScript(
    "https://static.ads-twitter.com/uwt.js",
    [LibCategories.targetingCookies],
    {
      enableCORS: false,
    }
  ).then(() => {
    window.twq("config", "ny4pj");
  });
}
