import React from "react";
import BackArrow from "@components/refactored-survey/images/back-arrow.svg";
import styled from "@emotion/styled";
import i18n from "src/locales";
import { BackButton } from "./BackButtonWithText";
import { fontTitle } from "src/design-system/styles/fonts";

const ReversedImage = styled.img`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

const SkipButton = styled(BackButton)`
  justify-content: flex-end;
`;

export const SkipButtonText = styled.span`
  margin-right: 7px;
  margin-top: 2px;
  ${fontTitle}
`;

type Props = {
  onClickSkip: () => void;
  text?: string;
};

export default function SurveySkipButton({
  onClickSkip,
  text = i18n.t("survey:btnSkipShort"),
}: Props) {
  return (
    <SkipButton onClick={onClickSkip}>
      <SkipButtonText>{text}</SkipButtonText>
      <ReversedImage
        src={BackArrow}
        alt="" // image is supplementary to the text
      />
    </SkipButton>
  );
}
