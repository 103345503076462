import { getDateFromTick } from "./ticks";
import { getTickFromTargetWeight, realFormula } from "./points";
import { getDayDelta } from "src/utils/datetime";
import Unit, { UnitType, convertUnits } from "src/utils/Unit";

export function getDateFromWeight(
  startWeight: number,
  endWeight: number,
  targetWeight: number,
  today: Date,
  endDate: Date
) {
  const tick = getTickFromTargetWeight(startWeight, endWeight, targetWeight);
  return getDateFromTick(tick, today, endDate);
}

export function getWeightFromDate(
  startWeight: number,
  idealWeight: number,
  targetDate: Date,
  endDate: Date
) {
  if (targetDate > endDate) {
    return idealWeight;
  }

  const todayTimeStamp = new Date().getTime();
  const endOfPlanTimeStamp = endDate.getTime();
  const targetDateTimeStamp = targetDate.getTime();

  const weightedXCoord =
    (targetDateTimeStamp - todayTimeStamp) /
    (endOfPlanTimeStamp - todayTimeStamp);

  const finalWeight = realFormula(weightedXCoord);
  const finalWeightAboveIdealWeight = finalWeight * (startWeight - idealWeight);

  return idealWeight + Math.round(finalWeightAboveIdealWeight);
}

// Returns the weight loss pace in lbs/week given a weight loss value and a target date
export function getPaceFromWeightLoss(
  weightLoss: number,
  unit: UnitType,
  targetDate: Date
) {
  const dayDelta = getDayDelta(new Date(), targetDate);

  return convertUnits(
    { mainUnitValue: weightLoss / (dayDelta / 7) },
    unit,
    Unit.POUND
  ).mainUnitValue;
}
