import { trackEvent } from "./api/tracker";

export function getPaintTimings() {
  const ret: Record<string, number> = {};

  if (window.performance?.getEntriesByType) {
    const { performance } = window;
    const performanceEntries = performance.getEntriesByType("paint");
    performanceEntries?.forEach((performanceEntry) => {
      const cameledName = performanceEntry.name.replace(/-[a-z]/g, (val) =>
        val.slice(1).toUpperCase()
      );
      ret[cameledName] = performanceEntry.startTime;
    });
  }

  return ret;
}

export function getNavType() {
  try {
    const navigationTiming = performance.getEntriesByType(
      "navigation"
    )[0] as PerformanceNavigationTiming;
    return navigationTiming.type;
  } catch (e) {
    /* NOP */
    return undefined;
  }
}

export type GatedPromise<T> = {
  resolve: (result: T | PromiseLike<T>) => void;
  reject: (error: unknown) => void;
  promise: Promise<T>;
};
export function gatedPromise<T>(): GatedPromise<T> {
  let resolve: (result: T | PromiseLike<T>) => void;
  let reject: (error: unknown) => void;

  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  return {
    promise,
    resolve,
    reject,
  };
}

export function awaitableSleep(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
export function awaitableNavSleep(ms: number) {
  return new Promise<void>((resolve) => {
    setNavTimeout(() => {
      resolve();
    }, ms);
  });
}

export function isNavShortened() {
  const { barTime } = window as any;
  return barTime != null;
}

export function navTimeoutDuration(timeout: number): number {
  const { barTime } = window as any;
  return barTime != null ? barTime : timeout;
}

export function setNavTimeout(cb: () => void, timeout: number) {
  const id = setTimeout(cb, navTimeoutDuration(timeout));
  return () => clearTimeout(id);
}

export function awaitNavTimeout(timeout: number) {
  return new Promise<void>((resolve) => setNavTimeout(resolve, timeout));
}

export function markTime(name: string) {
  try {
    if (window.performance?.mark) {
      return window.performance.mark(name);
    }
  } catch (err) {
    /* NOP */
  }
  return undefined;
}

export function measureTime(
  ...args: Parameters<typeof window.performance.measure>
) {
  try {
    if (window.performance?.measure) {
      return window.performance.measure(...args);
    }
  } catch (err) {
    /* NOP */
  }
  return undefined;
}

export function measureTask<T>(taskName: string, task: () => Promise<T>) {
  markTime(`bf-${taskName}-start`);
  function end() {
    markTime(`bf-${taskName}-end`);
    measureTime(`bf-${taskName}`, `bf-${taskName}-start`, `bf-${taskName}-end`);
  }
  return task().then(
    (data) => {
      end();
      return data;
    },
    (err) => {
      end();
      throw err;
    }
  );
}

export function perfTrack() {
  const perfData = {
    firstByte: undefined,
    domContentLoaded: undefined,
    appInit: undefined,
    appInitEnd: undefined,
    userData: undefined,
    userDataEnd: undefined,
    firstContentfulPaint: undefined,
    serverContextLoaded: undefined,
    unloadEvent: undefined,
  };
  // Guard against unsupported browsers or browsers using an older version of the API
  if (!window.performance || !window.performance.getEntries) {
    return Promise.resolve();
  }

  // Grab nav timings -- dom content loaded and calculate time to first byte
  const navEntries = window.performance.getEntriesByType(
    "navigation"
  ) as PerformanceNavigationTiming[];
  const firstNav = navEntries?.[0];
  if (firstNav) {
    perfData.domContentLoaded = firstNav.domContentLoadedEventEnd;
    perfData.firstByte = firstNav.responseStart - firstNav.requestStart;
    perfData.unloadEvent = firstNav.unloadEventEnd - firstNav.unloadEventStart;
  }

  // Grab FCP (first contentful paint) -- this is when the user starts seeing some stuff
  const fcpEntries = window.performance.getEntriesByName(
    "first-contentful-paint"
  ) as PerformancePaintTiming[];
  perfData.firstContentfulPaint = fcpEntries?.[0]?.startTime || -1;

  // We can also check how long it took to load server context by finding the associated fetch
  const xhrEntries = window.performance.getEntriesByType(
    "resource"
  ) as PerformanceResourceTiming[];
  const serverContextEntries = xhrEntries?.filter((entry) =>
    entry.name.includes("/api/context/v2")
  );
  perfData.serverContextLoaded = serverContextEntries?.[0]?.responseEnd;

  const measures = window.performance.getEntriesByType("measure");
  measures?.forEach((measure) => {
    if (measure.name.startsWith("bf-")) {
      perfData[`${measure.name.replace(/^bf-/, "")}Duration`] =
        measure.duration;
      perfData[`${measure.name.replace(/^bf-/, "")}End`] =
        measure.startTime + measure.duration;
    }
  });

  trackEvent("OnPerfMetricsAvailable", perfData);
  return Promise.resolve();
}
