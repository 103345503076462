import { typedKeys } from "src/utils/typeWrappers";
import { registerMonitorProvider } from "../prop-registry";

const REFERRER_TO_SEARCH_ENGINE_MAPPING = {
  ".*duckduckgo\\..*": "duckduckgo",
  ".*yahoo\\..*": "yahoo",
  ".*yandex\\..*": "yandex",
  ".*baidu\\..*": "baidu",
  ".*naver\\..*": "naver",
  ".*google\\..*": "google",
  ".*bing\\..*": "bing",
};
const REFERRER_IGNORE_REGEX = ".*(app|email|gclid|paid).*";

/**
 * Collect SEO properties(search engine extracted from document.referrer)
 * We also should return empty dict if referrer contains certain words
 */
registerMonitorProvider("monitoring", () => {
  const { referrer } = document;
  let searchEngine = null;
  if (
    referrer &&
    referrer.length > 0 &&
    !referrer.match(REFERRER_IGNORE_REGEX)
  ) {
    searchEngine =
      REFERRER_TO_SEARCH_ENGINE_MAPPING[
        typedKeys(REFERRER_TO_SEARCH_ENGINE_MAPPING).find((key) =>
          referrer.match(key)
        )
      ];
  }
  if (searchEngine) return { searchEngine };
  return {};
});
