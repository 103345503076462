import { trackBuyflowEvent, updateUserContext } from "./api/tracker";
import { SurveyAnswersState } from "./redux/slices/surveyAnswers";
import { isDevMode } from "./userSegment/features";
import { handleTelehealthAnswers } from "src/pages/telehealth/feedback/feedbackSurveyEnums";
import { getNoomSessionStorage } from "./noomSessionStorage";
import getStore from "./redux/store";
import { currentSurvey } from "src/hooks/survey/answers";
import { NoomBuyflowEvents } from "./monitoring/events";

const LAST_SEEN_KEY = "lastQuestionSeen";

const basicWeightKeys = [
  "weightKg",
  "weightImp",
  "weight",
  "weightUnit",
  "currentBMI",
];

const heightWeightKeys = {
  basicHeight: ["heightFeet", "heightInch", "heightCm", "heightUnit"],
  basicWeight: basicWeightKeys,
  verifyWeight: [
    ...basicWeightKeys,
    "idealWeight",
    "idealWeightKg",
    "targetBMI",
    "previousWeight",
    "previousIdealWeight",
    "confirmedWeight",
    "confirmedIdealWeight",
  ],
};

export function generateBuyflowEventProperties(
  questionId: keyof SurveyAnswersState,
  answers: SurveyAnswersState
) {
  const lastQuestionSeen: keyof SurveyAnswersState =
    getNoomSessionStorage(LAST_SEEN_KEY);
  const currentAnswersNamespace =
    currentSurvey(getStore().getState())?.name || "UnknownSurvey";

  const properties: Partial<NoomBuyflowEvents["buyflowQuestionAnswered"]> = {
    surveyName: currentAnswersNamespace,
    questionId: questionId as string,
    lastQuestionSeen: lastQuestionSeen as string,
  };

  Object.keys(answers).forEach((key) => {
    // if no answers are stored, i.e. for interstitials, do nothing.
    if (!answers[key]) {
      return;
    }
    // question answer(s) stored under the questionId - default for most cases.
    if (key === questionId) {
      // most common case: answers stored in an array.
      if (Array.isArray(answers[questionId])) {
        answers[questionId].forEach((answer) => {
          if (typeof answer === "object") {
            // for questions with "other" free text inputs, which are stored as objects.
            properties.answerParts = {
              ...(properties.answerParts || {}),
              ...answer,
            };
          } else {
            properties.answers = [
              ...(properties.answers || []),
              answer.toString(),
            ];
          }
        });
      } else {
        // fallback in case answers are not stored in an array.
        properties.answers = [
          ...(properties.answers || []),
          answers[questionId].toString(),
        ];
      }
    } else if (answers[key]) {
      // for any additional keys that don't match the question ID
      // (i.e. the height and weight values from the basic questions)
      properties.answerParts = {
        ...(properties.answerParts || {}),
        [key]: answers[key].toString(),
      };
    }
  });

  return properties;
}

export function prepareAndTrackSurveyAnswers(
  currentQuestionId: keyof SurveyAnswersState,
  surveyAnswers: SurveyAnswersState
) {
  let currentAnswers: SurveyAnswersState = {};
  switch (currentQuestionId) {
    case "basic": {
      const combinedKeys = heightWeightKeys.basicHeight.concat(
        heightWeightKeys.basicWeight
      );
      Object.keys(surveyAnswers).forEach((key) => {
        if (combinedKeys.includes(key)) {
          currentAnswers[key] = surveyAnswers[key];
        }
      });
      break;
    }
    case "basicHeight":
    case "basicWeight":
      heightWeightKeys[currentQuestionId].forEach((key) => {
        if (surveyAnswers[key] !== undefined) {
          currentAnswers[key] = surveyAnswers[key];
        }
      });
      break;
    case "idealWeight":
      currentAnswers.idealWeight = surveyAnswers.idealWeight;
      currentAnswers.idealWeightKg = surveyAnswers.idealWeightKg;
      currentAnswers.targetBMI = surveyAnswers.targetBMI;
      break;
    case "idealWeightKg":
      currentAnswers.idealWeightKg = surveyAnswers.idealWeightKg;
      currentAnswers.targetBMI = surveyAnswers.targetBMI;
      break;
    case "idealWeightImp":
      currentAnswers.idealWeightKg = surveyAnswers.idealWeightKg;
      currentAnswers.idealWeightUnit = surveyAnswers.idealWeightUnit;
      currentAnswers.targetBMI = surveyAnswers.targetBMI;
      break;
    case "verifyWeight":
      heightWeightKeys.verifyWeight.forEach((key) => {
        if (surveyAnswers[key] !== undefined) {
          currentAnswers[key] = surveyAnswers[key];
        }
      });
      break;
    case "gender":
      currentAnswers.gender = [surveyAnswers.gender?.[0]];
      if (surveyAnswers.gender?.[0] === "selfIdentify") {
        currentAnswers.selfIdentify = surveyAnswers.gender[1];
      }
      break;
    case "importantDateDetails":
      currentAnswers.importantDateDetails = surveyAnswers.importantDateDetails;
      if (surveyAnswers.importantDate) {
        currentAnswers.importantDate = surveyAnswers.importantDate;
      }
      break;
    case "importantDateTime":
      currentAnswers.importantDateTimeISO = surveyAnswers.importantDateTimeISO;
      break;
    case "appointmentScheduler":
      currentAnswers[currentQuestionId] = surveyAnswers[currentQuestionId];
      currentAnswers.appointmentSchedulerNoShowConsent =
        surveyAnswers.appointmentSchedulerNoShowConsent;
      break;
    case "healthInsuranceProvider":
      currentAnswers[currentQuestionId] = surveyAnswers[currentQuestionId];
      currentAnswers.healthInsuranceFull = surveyAnswers.healthInsuranceFull;
      currentAnswers.mcoId = surveyAnswers.mcoId;
      break;
    case "doctorPracticeReferral":
      currentAnswers.hyhauFollowUpDoctorName =
        surveyAnswers.hyhauFollowUpDoctorName;
      currentAnswers.hyhauFollowUpPracticeName =
        surveyAnswers.hyhauFollowUpPracticeName;
      break;
    default:
      currentAnswers[currentQuestionId] = surveyAnswers[currentQuestionId];
  }
  // Scrub Telehealth questions open-text responses that may contain health related information
  // as well, add patient type
  currentAnswers = handleTelehealthAnswers(currentQuestionId, currentAnswers);
  trackSurveyAnswers(currentQuestionId, currentAnswers);
}

function trackSurveyAnswers(
  questionId: keyof SurveyAnswersState,
  answers: SurveyAnswersState
) {
  trackBuyflowEvent(
    "BuyflowQuestionAnswered",
    generateBuyflowEventProperties(questionId, answers)
  );

  updateUserContext(answers);
}

export function areSurveyAnswersDefined(
  surveyAnswers: SurveyAnswersState,
  properties: (keyof SurveyAnswersState)[]
) {
  const invalidProperties = properties.filter(
    (property: string) => surveyAnswers[property] == null
  );

  return {
    areValid: invalidProperties.length === 0,
    invalidProperties,
  };
}

export function areMainSurveyAnswersValid(surveyAnswers: SurveyAnswersState) {
  // If we're in development mode, automatically validate survey answers.
  if (isDevMode()) {
    return { areValid: true, invalidProperties: [] };
  }

  return areSurveyAnswersDefined(surveyAnswers, [
    "weightKg",
    "idealWeightKg",
    "heightCm",
  ]);
}
