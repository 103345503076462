import { TOptions } from "i18next";
import React from "react";
import i18n from "src/locales";
import { isDeLanguage, isEsLanguage } from "./userSegment";

/**
 * Wraps the given string in locale specific quotation marks.
 */
export const wrapStringInLocalizedQuotes = (str: string) => {
  if (isDeLanguage()) {
    return `„${str}“`;
  }
  if (isEsLanguage()) {
    return `«${str}»`;
  }
  // For all other cases use regular quotation marks
  return `"${str}"`;
};

/**
 * Component that wraps the string in HTML Entity locale specific quotation marks.
 */
export const QuotedText = (props: { str: string }): JSX.Element => {
  if (isDeLanguage()) {
    return <>&bdquo;{props.str}&rdquo;</>;
  }
  if (isEsLanguage()) {
    return <>&laquo;{props.str}&raquo;</>;
  }
  // For all other cases use regular quotation marks
  return <>&quot;{props.str}&quot;</>;
};

/**
 * Formats a number to given number of decimal places (default 1).
 * If a number is non decimal number it will also strip out any trailing zeros.
 */
export const formatToDecimal = (num: number, decimalPlaces = 1) => {
  return num.toFixed(decimalPlaces).replace(/\.[0]+$/, "");
};

/**
 * Formats float to percent string
 */
export function formatPercentage(value: number, decimalPlaces?: number) {
  const percent = value * 100;
  let percentString: string;
  if (typeof decimalPlaces === "number") {
    percentString = formatToDecimal(percent, decimalPlaces);
  } else {
    percentString = percent.toString();
  }

  return `${percentString}%`;
}

export function getTranslatedStringFromTKey<
  T extends string | string[] = string
>(key: string, options: TOptions = {}): T {
  let text: any = key;
  if (key.startsWith("t:")) {
    text = i18n.t(key.substring(2), {
      defaultValue: key,
      ...options,
    });
  }
  return text;
}
