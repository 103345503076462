/**
 * Provides a typed version of `Object.keys`. This is useful when
 * working with object literals in noImplicitAny.
 */
export function typedKeys<T>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

/**
 * Provides a typed version of `Object.entries`. This is useful when
 * working with object literals in noImplicitAny.
 */
export function typedEntries<T>(obj: T) {
  type EntryRecord = [keyof T, T[keyof T]];
  return Object.entries(obj) as EntryRecord[];
}

export const typedRecord =
  <TValue>() =>
  <T extends Record<PropertyKey, TValue>>(v: T): { [K in keyof T]: TValue } =>
    v;
