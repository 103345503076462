import goto from "src/pageDefinitions/goto";
import { trackEvent } from "src/utils/api/tracker";
import { useOnce } from "./lifecycle";
import { useUserData, useServerContext } from "./redux";

export function pathIsInSubscriptionCancelContext(
  path: string = window.location.pathname
) {
  const subscriptionContextRegex =
    /\/(subscription|ps)\/(hm-)?cancel-(trial|subscription)/;
  return subscriptionContextRegex.test(path);
}

export function useLegacyCounterOfferContextRedirect() {
  const serverContext = useServerContext();
  const userData = useUserData();

  return useOnce(() => {
    trackEvent("RedirectLegacyCounterOffer", {
      isInTrial: serverContext.is_in_trial,
      isHM: serverContext.subscription_is_hm,
      isInUpsEx233: !!userData.ups_ex233,
    });
    goto.cancelSubscription(
      serverContext.subscription_is_hm,
      serverContext.is_in_trial,
      !!userData.ups_ex233
    );
  });
}
