import saveToBraze from "@utils/brazeUploader";
import { getMeristemExperimentsString } from "@utils/experiment";
import { getRouteId } from "@utils/meristemContext";
import { send } from "@utils/fetch";

type Props = {
  userEmail: string;
  userName: string;
  supporterEmail: string;
  supporterName?: string;
  addSupporterAsMember?: boolean;
  [key: string]: any;
};

export async function submitSupporterEmail({
  userEmail,
  userName,
  supporterName,
  supporterEmail,
  addSupporterAsMember = false,
  ...extraEventParams
}: Props) {
  const isOwnEmail =
    userEmail?.trim().toLowerCase() === supporterEmail.trim().toLowerCase();

  const eventParams: Record<string, any> = {
    name: userName,
    user_email: userEmail,
    supporter_email: supporterEmail,
    supporter_name: supporterName,
    isOwnEmail,
    routeId: getRouteId(),
    experiment: "baseline",
    experiments: getMeristemExperimentsString(),
  };
  await Promise.all([
    saveToBraze({
      event: {
        event_name: "SubmittedSupporterPlanEmail",
        event_data: { ...eventParams, ...extraEventParams },
      },
    }),
    addSupporterAsMember &&
      send("POST", "/api/noom_together/v1/add_member", {
        placeholder_name: supporterName,
        placeholder_email: supporterEmail,
        member_type: "SUPPORTER",
      }),
  ]);
}
