import { getSurveyAnswers } from "src/hooks/survey/answers";
import getStore from "./redux/store";

export const getEnrollmentParams = () => {
  const state = getStore().getState();
  const { userData, recommendedPlan } = state;

  const { name, email, weight, heightFeet, heightInch } = userData;

  const surveyAnswers = getSurveyAnswers(state);

  return {
    name,
    email,
    planId: recommendedPlan.noom_plan_id,
    weight_lbs: surveyAnswers.weight || weight,
    height_ft: surveyAnswers.heightFeet || heightFeet,
    height_in: surveyAnswers.heightInch || heightInch,
    gender:
      surveyAnswers.gender && surveyAnswers.gender.length > 0
        ? surveyAnswers.gender[0].toUpperCase()
        : undefined,
  };
};
