import {
  isSubscriptionPremiumTier,
  isSubscriptionMedTier,
} from "@utils/userSegment/features";
import { trackEvent } from "@utils/api/tracker";
import { isSubscriptionInvalid } from "@utils/userSegment";

export const enum TIER {
  PREMIUM = "PREMIUM",
  MED = "MED",
  MAIN = "MAIN",
}

const getCurrentTier = () => {
  if (isSubscriptionPremiumTier()) {
    return TIER.PREMIUM;
  }

  if (isSubscriptionMedTier()) {
    return TIER.MED;
  }

  return TIER.MAIN;
};

export function trackCurrentTierEvent() {
  if (!isSubscriptionInvalid()) {
    trackEvent("OnCurrentTierIdentified", {
      currentTier: getCurrentTier(),
    });
  }
}
