import React, { useState } from "react";
import styled from "@emotion/styled";

import LoggedInHeader from "@components/header/LoggedInHeader";
import Footer from "@components/footer/FooterPayment";

import { FlexFrame, MainColumn } from "src/components/core/BodyLayout";
import { fontLargeHeadline } from "src/design-system/styles/fonts";
import { usePageView } from "src/hooks/tracking";
import { useOnce } from "src/hooks/lifecycle";
import AccountSignup from "./AccountSignup";
import { getNoomSessionStorage } from "@utils/noomSessionStorage";
import { SessionStorageKey } from "@utils/constants";

type Props = {
  onComplete: () => void;
};

export const Body = styled.div`
  margin: 24px 0;
`;

export const Title = styled.h1`
  ${fontLargeHeadline};
  margin: 0;
  font-weight: normal;
`;

export default function Account({ onComplete = () => {} }: Props) {
  // Default loading to true to avoid DOM flashing on initial render.
  const [loading, setLoading] = useState(true);

  usePageView("account");

  useOnce(() => {
    const alreadyConfirmedIdentity = getNoomSessionStorage(
      SessionStorageKey.userConfirmedIdentity
    );
    if (alreadyConfirmedIdentity) {
      // User already confirmed their identity with VerifyUser,
      // don't show account page again and pass them along.
      onComplete();
    } else {
      setLoading(false);
    }
  });

  return (
    <FlexFrame>
      <LoggedInHeader />
      <MainColumn margin={24} maxWidth={600} data-cy="account-entry">
        <Body>{!loading && <AccountSignup onComplete={onComplete} />}</Body>
        <Footer />
      </MainColumn>
    </FlexFrame>
  );
}
