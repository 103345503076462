import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { shouldHideExistingPurchaseSurveyAnswers } from "@utils/userSegment/features";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getPageSetSurvey } from "src/pageDefinitions/actions/survey/util";
import { getPageAtPath, getPageSet } from "src/pageDefinitions/pageSets";
import { CoreReduxState } from "src/utils/redux/internal";
import getStore from "src/utils/redux/store";
import { isHM, isUS } from "src/utils/userSegment";
import { useUserData } from "../redux";
import { getNoomSessionStorage } from "src/utils/noomSessionStorage";
import { SessionSurveyKey } from "src/pageDefinitions/types";

/**
 * Provides a unified object of all known survey answers, including
 * current answers in addition to user data answers.
 */
export function useSurveyAnswers() {
  // Warn: Avoid collapsing these. Distinct hooks to load each dramatically reduces
  // the number of render cycles that this hook will have.
  const userData = useUserData();
  const currentAnswers = useSelector(
    (state: CoreReduxState) => state.currentAnswers
  );
  const linearBuyflow = useSelector(
    (state: CoreReduxState) => state.linearBuyflow
  );

  // Memoizing to avoid render thrashing as getSurveyAnswers generates a new
  // object every time.
  return useMemo(
    () => getSurveyAnswers({ userData, currentAnswers, linearBuyflow }),
    [userData, currentAnswers, linearBuyflow]
  );
}

export function getSurveyAnswers(
  state: Pick<CoreReduxState, "userData" | "currentAnswers" | "linearBuyflow">,
  healthyMind?: boolean
): SurveyAnswersState;
export function getSurveyAnswers(): SurveyAnswersState;
export function getSurveyAnswers(
  state = getStore().getState(),
  healthyMind = isHM()
): SurveyAnswersState {
  const { userData, currentAnswers } = state;
  const currentAnswersNamespace = currentSurvey(state)?.surveyNameSpace;
  const summitAnswers = userData.summitSurvey || {};

  if (healthyMind) {
    const surveyAnswers = {
      ...userData.personaSurveyHM,
      ...userData.purchaseSurveyAnswersHM,
      ...summitAnswers,
      ...currentAnswers.questionAnswers[currentAnswersNamespace],
    };
    return surveyAnswers;
  }

  const mainSurveyNameSpace = isUS() ? "personaSurveyUS" : "personaSurveyNonUS";
  const personaSurveyAnswers =
    userData[mainSurveyNameSpace] ||
    userData.personaSurveyUS ||
    userData.personaSurveyNonUS ||
    userData.personaSurveyAnswers ||
    {};

  const { purchaseSurveyAnswers } = userData;
  const surveyAnswers = {
    ...personaSurveyAnswers,
    ...(shouldHideExistingPurchaseSurveyAnswers() ? {} : purchaseSurveyAnswers),
    ...summitAnswers,
    ...currentAnswers.questionAnswers[currentAnswersNamespace],
  };
  return surveyAnswers;
}

export function getSessionAnswers() {
  return Object.assign(
    {},
    ...Object.values(SessionSurveyKey).map(
      (storageKey) => getNoomSessionStorage(SessionSurveyKey[storageKey]) || {}
    )
  );
}

// This ensures that we always load answers from the current survey namespace. The
// previous implementation used a stateful namespace and that caused issues with
// backward navigation.
export function currentSurvey(state: Pick<CoreReduxState, "linearBuyflow">) {
  const reduxPageset = state.linearBuyflow.pageSetName;

  const activePageSet = getPageSet(reduxPageset);
  const { page: activePage } =
    getPageAtPath(activePageSet, window.location.pathname) || {};

  const survey = getPageSetSurvey(activePageSet, activePage);
  return survey;
}
