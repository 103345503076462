/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, GetAppState } from "../internal";
import { captureException } from "src/utils/error";
import { trackEvent } from "src/utils/api/tracker";
import { isSessionStorageAvailable } from "src/utils/noomSessionStorage";

const authStatusSlice = createSlice({
  name: "authStatus",
  initialState: {
    sessionInvalid: false,
    userDidRefresh: false,
  },
  reducers: {
    setSessionInvalid: (state, action) => {
      state.sessionInvalid = action.payload;
    },
    setUserDidRefresh: (state, action) => {
      state.userDidRefresh = action.payload;
    },
  },
});

export const { setSessionInvalid, setUserDidRefresh } = authStatusSlice.actions;

export function handleHttpForbidden() {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    const { authStatus } = getState();
    // If this triggers multiple times in a row, don't do anything for the subsequent triggers.
    if (authStatus.sessionInvalid) {
      return;
    }
    trackEvent("OnSessionExpired");
    dispatch(setSessionInvalid(true));
    // If the user already refreshed the page, they may be stuck.
    // We don't want to keep refreshing the page, so we'll just show an error.
    if (authStatus.userDidRefresh) {
      captureException(
        new Error("User stuck with invalid session"),
        "userData-stuck403Session"
      );
      return;
    }
    // This bit of state is persisted in sessionStorage, so we can see the value across refreshes.
    dispatch(setUserDidRefresh(true));

    // ...That's assuming sessionStorage is available. If not, don't refresh so there's no risk of
    // a loop. Instead they will see the AuthProblemModal.
    if (!isSessionStorageAvailable()) {
      return;
    }

    // If the user hasn't refreshed the page, we'll refresh it for them.
    // This will trigger a new request to the server, which will return a new session.
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };
}

export default authStatusSlice;
