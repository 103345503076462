/* eslint-disable no-param-reassign */
import type { Event, EventHint } from "@sentry/types";
import { trackEvent } from "@utils/api/tracker";
import { isChunkLoadError } from ".";
import { trackEventIfNotUnloading } from "../lifecycle";
import { scrubContexts, scrubData } from "../monitoring/data-scrubber";
import { generateMonitoringProps } from "../monitoring/prop-registry";
import getStore from "../redux/store";
import { NoomError } from "./NoomError";

/**
 * Sentry error filter/re-mapper
 */
export function beforeSend(event: Event, hint: EventHint) {
  if (navigator.userAgent.includes("Google-Read-Aloud")) {
    return null;
  }

  try {
    const err: any = hint.originalException;

    if (err instanceof NoomError) {
      if (err.trackMetric()) {
        return null;
      }
    }

    if (filterObjectWithKeys(event, err)) {
      return null;
    }
    if (filterChunkLoad(event, err)) {
      return null;
    }

    // Enhance with our tags, etc
    addMixPanelDistinctId(event);
    event.tags = Object.assign(
      event.tags || {},
      generateMonitoringProps("error")
    );
    if (!event.contexts) {
      event.contexts = {};
    }
    if (err instanceof NoomError) {
      err.extendSentry(event, hint);
    }

    // Filter out any data that might be sensitive
    event.tags = scrubData(event.tags);
    event.contexts = scrubContexts(event.contexts);
    event.extra = scrubData(event.extra);

    // Emit to mixpanel to tie these to session and global analysis
    trackEvent("OnException", {
      error: err.message,
      ...event.extra,
    });
  } catch (e) /* istanbul ignore next Fatal error handler. Abandon all hope ye who enters here */ {
    // Don't want to risk infinite loops. Log to console for some visibility.
    // eslint-disable-next-line no-console
    console.error("Sentry processing error", e);
    // eslint-disable-next-line no-console
    console.error(hint);
  }

  return event;
}

function filterObjectWithKeys(event: Event, err: any) {
  const finalMessage =
    event.exception.values[event.exception.values.length - 1].value;

  const objectWithKeys =
    /^Non-Error promise rejection captured with keys:\s*(.+)/.exec(
      finalMessage
    );
  if (objectWithKeys) {
    const keys = objectWithKeys[1];
    // Thrown by the shift lib when progress bar is stopped before
    // completion and no catch in the progress.js lib.
    if (keys === "offset") {
      return true;
    }

    // 3rd party promise rejection
    // > Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target
    if (err.type === "unhandledrejection" && !err.detail) {
      return true;
    }
  }
  return false;
}

function filterChunkLoad(event: Event, err: any) {
  // ChunkLoadErrors are not actionable -- can happen if user has bad network, for example. Simply
  // report to mixpanel instead.
  if (isChunkLoadError(err)) {
    trackEventIfNotUnloading("BuyflowTaskFailed", {
      taskName: "ChunkLoad",
      error: err.message,
      code: err.code,
      name: err.name,
    });
    return true;
  }
  return false;
}

function addMixPanelDistinctId(event: Event) {
  try {
    const { userData } = getStore().getState();
    const userId = userData.user_id;
    if (userId) {
      event.contexts.Mixpanel = { distinctId: userId };
    }
  } catch {
    /* NOP */
  }
}
