import { getDayDelta, shift_days } from "../datetime";

/**
 * Evenly divides the chart's x-axis. Different plan durations will be normalized
 * to the same number of ticks corvering the entire duration.
 */
export const CHART_WIDTH_TICKS = 200;

export const START_TICK = 10; // Tick that represents current date (also represents length of flat head)
export const END_TICK = CHART_WIDTH_TICKS - 20; // Tick that represents target date (also represents the beginning of the flat tail)

// Function that takes a tick and normalizes it so that the tick at START_TICK has value 0
// and tick at END_TICK has value 1
export function getNormalizedValueFromTick(tick: number) {
  return (tick - START_TICK) / (END_TICK - START_TICK);
}

export function getTickFromNormalizedValue(value: number) {
  return Math.ceil(value * (END_TICK - START_TICK) + START_TICK);
}

export function getDateFromTick(tick, today, endDate) {
  const dayDelta = getDayDelta(today, endDate);
  return shift_days(getNormalizedValueFromTick(tick) * dayDelta, today);
}
