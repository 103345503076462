import { createSlice } from "@reduxjs/toolkit";

export type AnimationState = {
  loopCountForFreeMWCPBanner: number;
  slidedInForFreeMWCPBanner: boolean;
};

// Adding this state in order to execute animations accross buyflow pages.
const initialState: AnimationState = {
  loopCountForFreeMWCPBanner: 2, // animate 3 times
  slidedInForFreeMWCPBanner: false, // slide in banner only once per session
};

const animationStateSlice = createSlice({
  name: "animationState",
  initialState,
  reducers: {
    decreaseLoopCountForFreeMWCPBanner(state) {
      return {
        ...state,
        loopCountForFreeMWCPBanner: state?.loopCountForFreeMWCPBanner - 1,
      };
    },
    setSlidedInForFreeMWCPBanner(state) {
      return {
        ...state,
        slidedInForFreeMWCPBanner: true,
      };
    },
  },
});

export const {
  decreaseLoopCountForFreeMWCPBanner,
  setSlidedInForFreeMWCPBanner,
} = animationStateSlice.actions;

export default animationStateSlice;
