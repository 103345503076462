import React from "react";
import styled from "@emotion/styled";
import { fixedFullSize } from "src/design-system/styles/layout";
import { zIndex } from "src/design-system/styles/zIndices";
import { useDisableScroll } from "src/hooks/scroll";

type Props = {
  color?: string;
  opacity?: number;
};

const StyledSkrim = styled.div<{ color?: string; opacity?: number }>`
  ${fixedFullSize};
  z-index: ${zIndex.modal - 1};
  background-color: ${({ color }) => color || `#191717`};
  opacity: ${({ opacity }) => opacity || 0.6};
`;

export function Skrim({ color, opacity }: Props) {
  useDisableScroll();

  return <StyledSkrim color={color} opacity={opacity} />;
}
