import { useReferralCode } from "src/hooks/referrals";
import { getReferralLinkWithTracking } from "@utils/referrals/shareSourceTracking";
import i18n from "i18next";
import { trackBuyflowEvent, trackEvent } from "src/utils/api/tracker";
import { useUserData } from "./redux";
import uuid from "uuid";
import { useCallbackRef } from "./lifecycle";
import { NoomBuyflowEvents } from "src/utils/monitoring/events";

interface AndroidShareResult {
  shareId?: string;
  error?: string;
  targetComponent?: string;
  success?: boolean;
}

interface ShareRequest {
  text: string;
  title?: string;
}

/**
 * This interface will be injected into all web views opened by our Android app.
 * Since the navigator share api is not available [https://caniuse.com/?search=navigator.share] on Android web views,
 * this injected interface will allow us to use a native share function
 */
interface NativeAndroidSharingInterface {
  /**
   *
   * @param request Request containing a text to share and an optional title. Those will be used by the native share api
   *                The request needs to be a json stringified instance of ShareRequest
   * @param id Optional token used to listen to async response from the device.
   */
  share(request: string, id?: string): void;
}

async function shareAndroid(
  shareRequest: ShareRequest
): Promise<AndroidShareResult> {
  const shareId = uuid.v4();

  // Injected into Android web views
  const androidSharing = (window as any)
    .mobileSharingInterface as NativeAndroidSharingInterface;

  if (!androidSharing) {
    return Promise.resolve({
      shareId,
      success: false,
      error: "Android sharing is unavailable",
    });
  }

  return new Promise<AndroidShareResult>((resolve) => {
    function shareResultHandler(ev: MessageEvent<string>) {
      const result = JSON.parse(ev.data) as AndroidShareResult;
      if (result.shareId === shareId) {
        window.removeEventListener("message", shareResultHandler);
        resolve(result);
      }
    }

    window.addEventListener("message", shareResultHandler);

    androidSharing.share(JSON.stringify(shareRequest), shareId);
  });
}

function getNativeShareObject(referralCode: string): ShareRequest {
  const referralLink = getReferralLinkWithTracking(referralCode, "shareApi");
  const shareMessage = i18n.t("referral:share:smsMessage", { referralLink });

  return {
    text: shareMessage,
  };
}

export function canUseAndroidShare(): boolean {
  return "mobileSharingInterface" in window;
}

async function shareWithAndroid(shareRequest: ShareRequest) {
  const shareResult = await shareAndroid(shareRequest);

  if (shareResult.error) {
    // This is an actual error thrown from the mobile app that occurred during sharing
    trackEvent("OnFailedToUseShareApi", {
      errorMessage: shareResult.error,
    });
    return false;
  }
  if (!shareResult.success) {
    // This happens when the user does not choose a sharing surface
    // or aborts the operation after from within the chosen surface.
    trackEvent("OnShareAborted", {
      channel: shareResult.targetComponent,
    });
    return false;
  }
  trackEvent("OnSharedWithChannel", {
    channel: shareResult.targetComponent,
  });
  return true;
}

export function canUseNavigatorShare(): boolean {
  return (
    "canShare" in navigator &&
    navigator.canShare({ text: "anything", url: "anything" }) &&
    "share" in navigator
  );
}

async function shareWithNavigator(shareRequest: ShareRequest) {
  try {
    await navigator.share(shareRequest);
    return true;
  } catch (e) {
    trackEvent("OnFailedToUseShareApi", { errorMessage: e.message });
    return false;
  }
}

export function useSharingApi(
  referralEntryPoint: NoomBuyflowEvents["referralSubmitted"]["referralEntryPoint"]
) {
  const { referralCode } = useReferralCode();
  const { user_id: userId, access_code: accessCode } = useUserData();

  const shareViaShareApi = async () => {
    const shareRequest = getNativeShareObject(referralCode);

    const sharingFunction =
      (canUseNavigatorShare() && shareWithNavigator) ||
      (canUseAndroidShare() && shareWithAndroid);

    if (!sharingFunction) {
      trackEvent("OnShareApiUnavailable");
      return false;
    }

    const success = await sharingFunction(shareRequest);

    if (success) {
      trackBuyflowEvent("ReferralSubmitted", {
        referrerGrowthUserId: userId,
        referrerAccessCode: accessCode,
        referralMethod: "SHAREAPI",
        referralEntryPoint,
      });
    }

    return success;
  };

  return {
    shareViaShareApi: useCallbackRef(shareViaShareApi),
  };
}
