/**
 * Utility to detect bots.
 *
 * We discovered that the Google Ads-bot was causing our experiment allocation
 * script to favor one variation over another. These bots also cause junk
 * data to be added to mixpanel. We want to keep from tracking bot users.
 *
 * Copyright (C) 2019 Noom, Inc.
 * Author: Patrick
 */
import { getRequestMetadata } from "./meristemContext";

/**
 * Determine if the current browsing session is a bot.
 */
export const USER_IS_BOT =
  // If the server has disabled mixpanel entirely, then
  // we assume this is a bot.
  !getRequestMetadata()?.meristemTrackingProperties ||
  getRequestMetadata()?.meristemTrackingProperties.userIsBot ||
  //
  // Not necessarily bots, but exclude old unsupported browsers from
  // tracking.
  !Object.fromEntries ||
  typeof ResizeObserver === "undefined";
