import { captureMessageWithExtras } from "@utils/error";
import { FailedResponse } from "../fetch";
import { planDurationMonthsToTelehealthNoomPlanId } from "@utils/plansMapping";
import { TELEHEALTH_FREE_PLAN_ID } from "src/funnels/payment-en/pages/telehealthRedirects";
import getStore from "@utils/redux/store";
import { calculateRecommendedPlan } from "@utils/calculateGoals";

const TELEHEALTH_ERROR_PREFIX = "[HC-TH-Error]";

// send sentry alerts for telehealth related server/network errors
export const handleTelehealthRequestError = (
  apiName: string,
  error: any
): void => {
  if (error instanceof FailedResponse) {
    if (error.response.status === 404) {
      captureMessageWithExtras(
        `${TELEHEALTH_ERROR_PREFIX} Network request received 404 for ${error.request.url}`,
        {
          error,
        },
        [TELEHEALTH_ERROR_PREFIX, apiName, "404"]
      );
      return;
    }

    captureMessageWithExtras(
      `${TELEHEALTH_ERROR_PREFIX} Internal exception received for ${error.request.url}`,
      { error },
      [TELEHEALTH_ERROR_PREFIX, apiName, "exception"]
    );
  }
};

export const getTelehealthPlanId = (
  surveyAnswers = getStore().getState().surveyAnswers
): string => {
  const { recommendedPlanDuration } = calculateRecommendedPlan(surveyAnswers);
  const telehealthPlanId =
    planDurationMonthsToTelehealthNoomPlanId[recommendedPlanDuration];
  if (!telehealthPlanId) {
    captureMessageWithExtras(
      "[HC-TH-Error] No telehealth plan mapping found for duration specified.",
      { recommendedPlanDuration }
    );
  }
  return telehealthPlanId ?? TELEHEALTH_FREE_PLAN_ID;
};
