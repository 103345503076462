import React from "react";
import { captureException, isChunkLoadError } from "src/utils/error";
import { trackEventIfNotUnloading } from "src/utils/lifecycle";
import { ErrorPage } from "./ErrorPage";

interface Props {
  onErrorRender?: (error: Error) => React.ReactNode;
  children: React.ReactNode;
}

interface State {
  error: Error | null;
}

/**
 * Implements common error handler for our react components.
 *
 * An error boundary is installed in the root of the vDOM providing a default error page.
 * Additional instances of this error boundary with customized onErrorRender props can
 * be added lower down to implement error pages specific to a particular section of the site.
 */
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    captureException(error, undefined, errorInfo);

    // Track critical crashers separately in mixpanel to help differentiate
    // between background and foreground errors.
    trackEventIfNotUnloading("OnBSOD", {
      error: isChunkLoadError(error) ? "ChunkLoadError" : error.message,
    });
  }

  override render() {
    const { children, onErrorRender } = this.props;
    const { error } = this.state;

    if (error) {
      return onErrorRender ? onErrorRender(error) : <ErrorPage />;
    }

    return children;
  }
}
