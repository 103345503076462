import React, { lazy, Suspense } from "react";
import { appConfig } from "src/config";
import { getURLParams } from "src/utils/urlParams";

// WARN: This is in the application bundle. Do not import any quick silver
// modules directly.

const LazyQuickSilver = lazy(
  () => import(/* webpackChunkName: "quicksilver" */ ".")
);

const urlParams = getURLParams();
const isExcludingQuicksilver = urlParams.excludeQuicksilver === "true";
const isIncludingQuicksilver = urlParams.qs === "true";

const isDevOrStaging = ["staging", "development", "local"].includes(
  appConfig.NOOM_ENV
);

// Disable QS when running in Cypress
declare const Cypress: any;
const isInCypress = typeof Cypress !== "undefined";

export function QuickSilver() {
  if (
    !isIncludingQuicksilver &&
    (isInCypress || !isDevOrStaging || isExcludingQuicksilver)
  ) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyQuickSilver />
    </Suspense>
  );
}
