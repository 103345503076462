/* eslint-disable no-restricted-globals */
import { CHECK_EMAIL_REMINDER_PARAM } from "@components/collapsible-header/CollapsibleHeader";
import { getEnrollmentParams } from "src/utils/enrollment";

import { isHM } from "src/utils/userSegment";
import { setParam } from "./utils";
import { CollapsibleHeaderUrlParams } from "@pageDefinitions/goto/offers";
import { reroll } from "../components/PageSetRouter";
import { getCountryCode, getLanguage } from "src/utils/meristemContext";
import { getForwardParams } from "src/utils/urlParams";

export type GotoEnrollmentParams = CollapsibleHeaderUrlParams & {
  name?: string;
  email?: string;
  planId?: string;
  paymentMethod?: string;
  piv?: boolean;
};

function enrollmentBase() {
  return isHM() ? "/enrollment-hm" : "/enrollment";
}

export default {
  enrollmentNoParams() {
    this.enrollment(getForwardParams());
  },
  enrollment({ showEmailReminder, ...overrides }: GotoEnrollmentParams) {
    // FIXME (cory): I'm honestly not sure if these are at all necessary.
    const params = {
      ...getEnrollmentParams(),
      ...overrides,
    };
    const languageCode = getLanguage();
    const countryCode = getCountryCode();
    localStorage.setItem(
      "enrollmentData",
      JSON.stringify({
        ...params,
        lc: languageCode,
        cc: countryCode,
      })
    );

    const queryParams = new URLSearchParams();
    setParam(queryParams, "lc", languageCode);
    setParam(queryParams, "cc", countryCode);

    const currentQueryParams = new URLSearchParams(location.search);
    setParam(queryParams, "route", currentQueryParams.get("route"));
    setParam(queryParams, "experiment", currentQueryParams.get("experiment"));
    setParam(queryParams, CHECK_EMAIL_REMINDER_PARAM, showEmailReminder);
    reroll(enrollmentBase(), queryParams);
  },
};
