import getStore from "./redux/store";

/**
 * Counter-offer specific method
 * subscription_id is the id of the subscription for which we open the counter offer for
 * subscritpions requires to have at least the subscription matching the subscription_id
 * @returns Subscription object
 */
export function extractSubscriptionFromServerContext(
  state = getStore().getState()
) {
  const { subscriptions, subscription_id } = state.serverContext;
  const subscriptionsArr = JSON.parse(subscriptions);
  return subscriptionsArr.find((s: any) => s.id === subscription_id);
}
