import React from "react";

import BackArrow from "@components/refactored-survey/images/back-arrow.svg";
import styled from "@emotion/styled";
import { fontTitle } from "src/design-system/styles/fonts";
import { Text } from "src/components/core/Text";
import { resetFormElement } from "src/design-system/styles/resets";

export const BackButton = styled.button`
  ${resetFormElement}
  display: flex;
  align-items: center;
  line-height: 0;
  height: 16px;
  margin: 0;
`;

export const BackButtonText = styled.span`
  margin-left: 7px;
  margin-top: 2px;
  ${fontTitle}
`;

type Props = {
  onClickBack: () => void;
  disabled?: boolean;
  text: string;
};

export default function BackButtonWithText({
  text,
  disabled,
  onClickBack,
}: Props) {
  if (disabled) {
    return null;
  }

  return (
    <BackButton onClick={onClickBack}>
      <img src={BackArrow} alt="" data-cy="back-button" />
      <BackButtonText>
        <Text i18nKeyOrText={text} />
      </BackButtonText>
    </BackButton>
  );
}
