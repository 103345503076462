import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecommendedPlanState } from "./recommendedPlan";

export interface MultiUserPlanState {
  originalRecommendedPlan?: RecommendedPlanState;
  multiUserPlan?: RecommendedPlanState;
}

export const initialState: MultiUserPlanState = {
  originalRecommendedPlan: null,
  multiUserPlan: null,
};

const multiUserPlanSlice = createSlice({
  name: "multiUserPlan",
  initialState,
  reducers: {
    updateMultiUserPlanState(
      state,
      action: PayloadAction<Partial<MultiUserPlanState>>
    ) {
      return { ...state, ...action.payload };
    },
    resetMultiUserPlanState() {
      return initialState;
    },
  },
});

export const { updateMultiUserPlanState, resetMultiUserPlanState } =
  multiUserPlanSlice.actions;

export default multiUserPlanSlice;
