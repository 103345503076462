import { CoreReduxState } from "src/utils/redux/store";
import * as UserSegments from "@utils/userSegment";
import * as FeatureSegments from "@utils/userSegment/features";
import {
  isCountryImperial,
  isCountryBritishUnits,
  isUS,
  isHM,
} from "src/utils/userSegment";
import invariant from "tiny-invariant";
import { captureException } from "src/utils/error";
import { getCountryCode, getRouteId } from "@utils/meristemContext";
import { getNoomSessionStorage } from "src/utils/noomSessionStorage";
import { SessionStorageKey } from "src/utils/constants";
import { getURLParams } from "src/utils/urlParams";
import { getSessionState } from "./session";
import { getPageSet } from "./pageSets";
import { appConfig } from "src/config";
import { getSurveyAnswers, getSessionAnswers } from "src/hooks/survey/answers";

export type JsonScalar = number | string | boolean | null;

export function keyHasLoader(key: string) {
  const segmentFn = getSegmentFn(key, false);
  if (segmentFn) {
    return !!segmentFn.load;
  }
  return false;
}
export async function loadValue(key: string, state: CoreReduxState) {
  try {
    const segmentFn = getSegmentFn(key, false);
    if (segmentFn) {
      return await segmentFn.load?.(state);
    }
  } catch (err) {
    // We're erroring on the side of attempting to complete the survey without the value.
    // Segments that need to load data should fail safe if data is not available.
    captureException(err);
  }
  return undefined;
}

export function resolveValue(key: string, state: CoreReduxState) {
  const resolvers = {
    // Session states
    urlParams: () => getURLParams(),
    browser: () => getSessionState("browser"),
    pageSet: () =>
      getSessionState(
        "pageSet",
        getPageSet(state.linearBuyflow.pageSetName)?.layer
      ),

    // Meristem State
    meristemConfig: () => appConfig.meristemConfig,
    countryUnits: () => {
      if (isCountryImperial()) {
        return "imperial";
      }
      if (isCountryBritishUnits()) {
        return "stones";
      }
      return "metric";
    },
    country: getCountryCode,
    routeId: getRouteId,

    // Surveys
    mainSurvey: () => {
      const { surveyNameSpace, userData } = state;
      if (UserSegments.isHM()) {
        return userData.personaSurveyHM;
      }
      const defaultNamespacedStore = isUS()
        ? userData.personaSurveyUS
        : userData.personaSurveyNonUS;
      return userData[surveyNameSpace] || defaultNamespacedStore;
    },
    surveyAnswers() {
      return getSurveyAnswers(state, isHM(state));
    },
    sessionAnswers() {
      return getSessionAnswers();
    },
    insuranceSurvey: () => {
      return getNoomSessionStorage(SessionStorageKey.insuranceSurvey);
    },
    serverContext: () => {
      return state.serverContext;
    },
  };

  if (key) {
    const [rootKey] = extractKeyPath(key);
    if (rootKey in resolvers) {
      const resolverData = resolvers[rootKey]();
      return resolveNestedValue(key, { [rootKey]: resolverData });
    }
  }

  return resolveNestedValue(key, state) ?? resolveSegment(key, state);
}

function resolveSegment(name: string, state: CoreReduxState): boolean {
  const segmentFn = getSegmentFn(name, false);
  if (segmentFn) {
    return segmentFn(state);
  }
  return undefined;
}

function extractKeyPath(key: string) {
  return key.split(".");
}

function resolveNestedValue(key: string, object: JsonObject): AnyJson {
  if (!key) {
    return null;
  }

  const tokens = extractKeyPath(key);
  let currentObject = object;
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (currentObject[token] == null) {
      return null;
    }
    currentObject = currentObject[token];
  }
  return currentObject;
}
export function resolveScalar(object: AnyJson): JsonScalar {
  if (Array.isArray(object)) {
    return object[0];
  }
  return object;
}

export function getSegmentFn(
  name: string,
  assertMissing = true
): UserSegments.SegmentFunction {
  // WARN: Must use null change vs. 'in' to work with storybook mock
  if (UserSegments[name] != null) {
    return UserSegments[name];
  }
  if (FeatureSegments[name] != null) {
    return FeatureSegments[name];
  }
  if (assertMissing) {
    invariant(false, `Unknown segment ${name}`);
  }
  return undefined;
}
