/* eslint-disable func-names, vars-on-top, no-var */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

export const NOOM_YELP_ID = "mp7mx01";

declare const TTDUniversalPixelApi: any;
declare function ttd_dom_ready(callback: () => void): void;

export function loadYelp() {
  return loadScript(
    "https://js.adsrvr.org/up_loader.1.1.0.js",
    [LibCategories.targetingCookies],
    {
      enableCORS: false,
    }
  ).then(() => {
    ttd_dom_ready(function () {
      if (typeof TTDUniversalPixelApi === "function") {
        var universalPixelApi = new TTDUniversalPixelApi();
        universalPixelApi.init(
          NOOM_YELP_ID,
          ["ilfd4oq"],
          "https://insight.adsrvr.org/track/up"
        );
      }
    });
  });
}
