import type { CoreReduxStore } from "./internal";

export type {
  CoreReduxStore,
  CoreReduxState,
  AppDispatch,
  GetAppState,
} from "./internal";

export default function getStore(): CoreReduxStore {
  // Defer load to avoid init circular dependencies.
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require("./internal").dangerousInternalStore;
}
