import { ActionHandler, ActionHandlerParams } from "..";
import { trackNoomVibeOfferViewed, NoomVibeOfferId } from "src/utils/noomVibe";

type Params = {
  offerId: NoomVibeOfferId;
};

export const trackVibeOfferViewed: ActionHandler = (
  actionParams: {
    params: Params;
  } & ActionHandlerParams
): void => {
  trackNoomVibeOfferViewed(actionParams?.params?.offerId);
};
