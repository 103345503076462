import { ActionHandler, ActionHandlerParams } from ".";
import save from "src/utils/brazeUploader";

type Params = {
  lead?: string;

  dateKey?: string;
  extraProps?: JsonObject;
};

export const SaveEventToBraze: ActionHandler = async ({
  params = {},
}: {
  params: Params;
} & ActionHandlerParams) => {
  save({ event: params });
};
