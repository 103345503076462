import { CountryCode } from "@utils/meristemContext";

export const EN_COURSE_PACK_ELIGIBLE_COUNTRIES: CountryCode[] = [
  "US",
  "CA",
  "AU",
  "GB",
  "ZA",
  "NL",
  "AE",
  "SG",
  "DK",
  "IT",
  "IS",
  "SE",
  "IN",
  "IL",
  "FI",
  "PT",
  "GR",
  "RO",
  "BE",
  "PL",
  "CZ",
  "TR",
  "NO",
  "NZ",
  "IE",
  "FR",
];

export const DE_COURSE_PACK_ELIGIBLE_COUNTRIES: CountryCode[] = [
  "DE",
  "AT",
  "CH",
];
