import React from "react";
import { useSelector } from "react-redux";
import goto from "src/pageDefinitions/goto";
import { CoreReduxState } from "src/utils/redux/store";
import { useOnce } from "./lifecycle";

export function useRecommendedPlan() {
  return useSelector((state: CoreReduxState) => state.recommendedPlan);
}

type RecommendedPlanRequiredProps = {
  children: React.ReactNode;
  onBail?: () => void;
};

export function RecommendedPlanRequired({
  children,
  onBail = () => goto.bail("choosePlan", [], "RecommendedPlanRequired"),
}: RecommendedPlanRequiredProps) {
  const recommendedPlan = useRecommendedPlan();

  useOnce(() => {
    if (!recommendedPlan?.noom_plan_id) {
      onBail();
    }
  });

  if (!recommendedPlan?.noom_plan_id) {
    return null;
  }

  return <>{children}</>;
}
