/**
 * Implements a debounce call pattern with Promise-based resolution.
 *
 * Any calls during the debounce period will join the wait for the pending
 * promise.
 */
export function debouncePromise<R>(
  cb: (...args) => Promise<R>,
  { timeout = 300 } = {}
) {
  let runningPromise: Promise<R>;
  let resolvePromise: (value?: R | PromiseLike<R>) => void;
  let runningTimeout: ReturnType<typeof setTimeout>;

  return (...args) => {
    if (!runningPromise) {
      runningPromise = new Promise((resolve) => {
        resolvePromise = resolve;
      });
    }
    clearTimeout(runningTimeout);

    runningTimeout = setTimeout(() => {
      resolvePromise(
        cb(...args).then(
          (result) => {
            runningPromise = undefined;
            return result;
          },
          (err) => {
            runningPromise = undefined;
            throw err;
          }
        )
      );
    }, timeout);

    return runningPromise;
  };
}
