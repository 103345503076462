import React, { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import { REGION_IDS } from "./LiveRegions";

export type AnnouncementStyle = "polite" | "assertive" | "alert";
const ANNOUCEMENT_TIME_OUT = 7000;

// Announces children content to screen readers
export const Announcer = ({
  children,
  announcementStyle = "polite",
}: {
  children: ReactNode;
  announcementStyle?: AnnouncementStyle;
}) => {
  const liveRegion = document.getElementById(REGION_IDS[announcementStyle]);
  if (!liveRegion) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      {ReactDOM.createPortal(children, liveRegion)}
    </>
  );
};

const appendAnnouncementNode = (
  node: HTMLElement,
  announcementStyle: AnnouncementStyle = "polite"
) => {
  const liveRegion = document.getElementById(REGION_IDS[announcementStyle]);
  if (!liveRegion) {
    return;
  }
  liveRegion.appendChild(node);

  // Clear node after some time so that screen readers don't accidentally target it
  setTimeout(() => {
    node.remove();
  }, ANNOUCEMENT_TIME_OUT);
};

/**
 * Announces the content of an HTML element to screen readers
 */
export const announceNode = (
  node: HTMLElement,
  announcementStyle: AnnouncementStyle = "polite"
) => {
  const announcementNode = document.createElement("div");
  announcementNode.setAttribute("aria-atomic", "true");
  announcementNode.appendChild(node.cloneNode(true));
  announcementNode.appendChild(document.createElement("br"));

  appendAnnouncementNode(announcementNode, announcementStyle);
};

/**
 * Announce message to screen readers
 */
export const announceMessage = (
  message: string,
  announcementStyle: AnnouncementStyle = "polite"
) => {
  const node = document.createElement("div");
  node.textContent = message;
  appendAnnouncementNode(node, announcementStyle);
};

/**
 * Announces the message to screen readers. Useful for pages where we want more control over what is announced, such for transition
 * and animation pages.
 */
export const useAnnouncer = (
  message: string,
  announcementStyle: AnnouncementStyle = "polite"
) => {
  const filteredMessage = message.replace(/(<([^>]+)>)/gi, ""); // Remove any html tags that could be in the string

  useEffect(() => {
    announceMessage(filteredMessage, announcementStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMessage]);
};
