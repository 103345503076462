/* eslint-disable no-param-reassign */
import invariant from "tiny-invariant";
import { Mutable } from "type-fest";
import { Location } from "history";
import { CoreReduxState } from "src/utils/redux/store";

import {
  Direction,
  Page,
  PageList,
  PageListEntry,
  PageSet,
  SwitchCondition,
} from "../types";
import { conditionsPass, loadKeys } from "../conditions";
import {
  findLoadableConditionKeys,
  findLoadablePageSetKeys,
  resolvePage,
} from "./eval";
import { matchPath } from "react-router";
import { isValidLocale } from "src/locales";
import { logDebug } from "src/utils/monitoring/logging";
import { PageSetHistoryState } from "../components/PageSetRouter";

const PAGE_SETS: Record<string, PageSet> = {};

export type PageLocation = number[];

function normalizePath(pathname: string) {
  const pathSansRoot = pathname?.replace(/^\/|\/$/g, "");

  // Strip known locales from prefix. This lets us avoid having aliases like "/:locale" which
  // are very broad and could create conflicts.
  const match = pathSansRoot?.match(/^([a-z]{2}(?:-[A-Z]{2})?)\/?(.*)$/);
  if (match && isValidLocale(match[1])) {
    return match[2];
  }

  return pathSansRoot;
}
export function pageMatchesPath(page: Page, needle: string) {
  if (page.pathname === needle) {
    return needle;
  }

  if (page.aliases) {
    return (
      page.aliases.find((alias) =>
        matchPath(needle, { exact: true, path: alias })
      ) ?? false
    );
  }
  return false;
}

function expandSwitchCases(switchCondition: Mutable<SwitchCondition>) {
  const cases: Mutable<SwitchCondition["cases"]> = {};

  Object.keys(switchCondition.cases).forEach((caseName) => {
    const values = caseName.split("|");
    const casePage = switchCondition.cases[caseName];

    invariant(
      !casePage.props?.progressBar,
      `Progress bar is not supported in switch case ${casePage.pathname}. Define on top switch page for all cases.`
    );

    values.forEach((value) => {
      cases[value] = {
        ...casePage,
        pathname: normalizePath(casePage.pathname),
        aliases: casePage.aliases?.map(normalizePath),
        actions: [
          ...(switchCondition.actions || []),
          ...(casePage.actions || []),
        ],
        props: {
          ...switchCondition.props,
          ...casePage.props,
        },
      };
    });
  });

  switchCondition.cases = cases;
}

export async function ensureLayerConditionsLoaded(
  layer: string,
  state: CoreReduxState
) {
  const layerPageSets = Object.values(PAGE_SETS).filter(
    (pageSet) => pageSet.layer === layer
  );
  return ensurePageSetConditionsLoaded(layerPageSets, state);
}
export async function ensurePageSetConditionsLoaded(
  pageSets: PageSet[],
  state: CoreReduxState
) {
  const conditionKeys = new Set<string>();

  pageSets.forEach((pageSet) => {
    const pageSetConditionKeys = findLoadableConditionKeys(pageSet.conditions);
    pageSetConditionKeys.forEach((key) => {
      conditionKeys.add(key);
    });
  });

  return loadKeys(Array.from(conditionKeys), state);
}
export async function ensurePageSetLoaded(
  pageSet: PageSet,
  state: CoreReduxState
) {
  const loadableConditionKeys = findLoadablePageSetKeys(pageSet.pages);
  return loadKeys(loadableConditionKeys, state);
}
export async function ensurePageLoaded(page: Page, state: CoreReduxState) {
  const loadableConditionKeys = findLoadablePageSetKeys([page]);
  return loadKeys(loadableConditionKeys, state);
}

export function getPageSet(pageSetId: string): PageSet | undefined {
  return PAGE_SETS[pageSetId];
}

export async function getPageSetFromPath(
  pathname: string,
  state: CoreReduxState
) {
  // Find all page sets with the path, regardless of conditions
  // This lets us reduce the number of page sets we need to load
  // conditions for
  const pageSetsWithPage = Object.values(PAGE_SETS)
    .map((pageSet) => {
      return {
        pageSet,
        page: getPageAtPath(pageSet, pathname)?.page,
      };
    })
    .filter(({ page }) => !!page);

  await ensurePageSetConditionsLoaded(
    pageSetsWithPage.map(({ pageSet }) => pageSet),
    state
  );

  const scoredPageSets = pageSetsWithPage
    .map(({ pageSet, page }) => {
      return {
        pageSet,
        page,
        ...conditionsPass(pageSet.conditions, state),
      };
    })
    .filter(({ score }) => score)
    .sort((a, b) => b.score - a.score);

  const pendingPageSets = scoredPageSets.map(async ({ pageSet, page }) => {
    await ensurePageLoaded(page, state);

    if (conditionsPass(pageSet.conditions, state).score) {
      return pageSet;
    }
    return undefined;
  });

  const candidatePageSets = await Promise.all(pendingPageSets);
  return candidatePageSets.find(Boolean);
}

export function getPageAtLocation(
  activePageSet: PageSet,
  location: PageLocation
): PageListEntry {
  if (!location) {
    return undefined;
  }

  let page = activePageSet.pages[location[0]];

  for (let i = 1; i < location.length; i++) {
    if (!page) {
      return undefined;
    }
    invariant("block" in page, `Expected page a depth ${i} to be block.`);
    page = page.block[location[i]];
  }

  return page;
}

export function getActivePage(
  pathname: string,
  state: CoreReduxState,
  location?: Location<PageSetHistoryState>
) {
  const reduxPageset = state.linearBuyflow.pageSetName;

  let activePageSet = getPageSet(reduxPageset);
  let activePage = getPage(activePageSet?.pages, pathname, state);

  // There's a timing issue when user navigates backwards across pagesets.
  // Due to redux update occurring in an effect, there is a brief window
  // where we might mis-identify the active page. Attempt to load from
  // the history state, if provided.
  if (!activePage && location?.state?.pageSetId) {
    activePageSet = getPageSet(location?.state?.pageSetId);
    activePage = getPage(activePageSet?.pages, pathname, state);
  }

  return { activePageSet, activePage };
}

/**
 * Returns the resolved page matching the given block name. I.e.
 * switch and block statements will be evaluated and child returned.
 */
export function getPage(
  pages: PageList | PageSet,
  pathname: string,
  state: CoreReduxState
) {
  if (!pages) {
    return undefined;
  }

  const needle = normalizePath(pathname);
  function checkPage(page: Page) {
    const firstPage = resolvePage(page, state, Direction.FORWARD);

    if (pageMatchesPath(firstPage, needle) === false) {
      return false;
    }

    const evaled = conditionsPass(firstPage.conditions, state);
    if (!evaled.score) {
      logDebug(
        `[LBF] Page ${firstPage.pathname} matched path, but failed conditions.`,
        { ...evaled, conditions: firstPage.conditions }
      );
    }
    return !!evaled.score;
  }

  const pageList = "pages" in pages ? pages.pages : pages;

  let ret: Page;
  pageList?.find((page) => {
    if ("switch" in page) {
      return Object.values(page.cases).find((casePage) => {
        if (checkPage(casePage)) {
          ret = casePage;
          return true;
        }
        return false;
      });
    }
    if ("block" in page) {
      const blockChild = getPage(page.block, pathname, state);
      if (blockChild) {
        ret = blockChild;
      }
      return !!blockChild;
    }
    if (checkPage(page)) {
      ret = page;
      return true;
    }
    return false;
  });
  return ret;
}

export function getPageLocation(
  pages: PageList | PageSet,
  pathnameOrPage: string | PageListEntry
): PageLocation {
  return getPageAtPath(pages, pathnameOrPage)?.location;
}

/**
 * This is used to lookup a page by pathname independent of the state.
 * If the page exists in the page set, it will be returned, regardless
 * of whether it passes conditions.
 */
export function getPageAtPath(
  pages: PageList | PageSet,
  pathnameOrPage: string | PageListEntry
): { page: Page; location: PageLocation } {
  if (!pages || pathnameOrPage == null) {
    return undefined;
  }

  const needle =
    typeof pathnameOrPage === "string" && normalizePath(pathnameOrPage);
  function checkPage(page: Page) {
    if (typeof pathnameOrPage === "string") {
      return pageMatchesPath(page, needle) !== false;
    }
    return page === pathnameOrPage;
  }

  const pageList = "pages" in pages ? pages.pages : pages;
  for (let i = 0; i < pageList.length; i++) {
    const page = pageList[i];
    if (page === pathnameOrPage) {
      return { page, location: [i] };
    }
    if ("switch" in page) {
      if (Object.values(page.cases).find(checkPage)) {
        return { page, location: [i] };
      }
    } else if ("block" in page) {
      const child = getPageAtPath(page.block, pathnameOrPage);
      if (child) {
        return {
          page: child.page,
          location: [i].concat(child.location),
        };
      }
    } else if (checkPage(page)) {
      return { page, location: [i] };
    }
  }

  return undefined;
}

export function registerPageSet(pageSet: PageSet) {
  invariant(
    !PAGE_SETS[pageSet.id] || PAGE_SETS[pageSet.id] === pageSet,
    `Different PageSet already registered with id ${pageSet.id}`
  );

  function normalizePages(pages: PageList) {
    pages.forEach((page) => {
      if ("switch" in page) {
        expandSwitchCases(page);
      } else if ("block" in page) {
        normalizePages(page.block);
      } else {
        (page as Mutable<typeof page>).pathname = normalizePath(page.pathname);
        if (page.aliases) {
          (page as Mutable<typeof page>).aliases =
            page.aliases.map(normalizePath);
        }
      }
    });
  }

  // Normalize all saved paths to avoid annoyances
  normalizePages(pageSet.pages);

  PAGE_SETS[pageSet.id] = pageSet;
}

function registerAll(r: any) {
  r.keys().forEach((key: string) => {
    registerPageSet(r(key) as PageSet);
  });
}
registerAll(require.context("./", true, /\.json$/));

export async function getPageSetForLayer(layer: string, state: CoreReduxState) {
  await ensureLayerConditionsLoaded(layer, state);

  const matchingSets = Object.values(PAGE_SETS)
    .map((pageSet) => {
      if (pageSet.layer !== layer) {
        return null;
      }
      const { score } = conditionsPass(pageSet.conditions, state);
      if (!score) {
        return null;
      }
      return { pageSet, score };
    })
    .filter(Boolean)
    .sort((a, b) => b.score - a.score);

  return matchingSets[0]?.pageSet;
}

export default PAGE_SETS;
