import React from "react";
import { Helmet } from "react-helmet";

const Schema = () => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org/",
        "@type": "Organization",
        name: "Noom",
        legalName: "Noom, Inc.",
        description: "Noom: Lose Weight for Good",
        disambiguatingDescription:
          "Lose weight and keep it off for good with a customized course that uses psychology and small goals to change your habits. Noom is the last weight loss program you’ll ever need and has helped over 45 million people transform their lives. Find out how much weight you'll lose with Noom’s free 30-second online evaluation.",
        url: "https://www.noom.com/",
        foundingDate: "2008",
        address: {
          "@type": "PostalAddress",
          addressCountry: "USA",
          addressLocality: "New York",
          addressRegion: "NY",
          postalCode: "10001",
          streetAddress: "450 W 33rd Street, Floor 11",
        },
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "customer support",
          email: "support@noom.com",
          telephone: "+13476878522",
        },
        founders: [
          {
            "@type": "Person",
            name: "Artem Petakov",
          },
          {
            "@type": "Person",
            name: "Saeju Jeong",
          },
        ],
        logo: "https://www.noom.com/static/noom.png",
        sameAs: [
          "https://www.facebook.com/noom/",
          "https://twitter.com/noom",
          "https://www.instagram.com/noom/",
          "https://www.linkedin.com/company/noom-inc/about/",
          "https://www.youtube.com/NoomInc",
        ],
      })}
    </script>
  </Helmet>
);

export default Schema;
