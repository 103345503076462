import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router";
import { zIndex } from "src/design-system/styles/zIndices";
import { useOnce } from "src/hooks/lifecycle";
import {
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";
import { trackEvent } from "src/utils/api/tracker";
import { isInApp } from "src/utils/userSegment";
import { Opaque } from "type-fest";
import { FlexFrame } from "../core/BodyLayout";
import FooterLandingSmall from "./FooterLandingSmall";

// Make the session externally readonly
type PathString = Opaque<string>;

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    privacyPolicyPagePath?: PathString;
  }
}

const nopContext = {
  footerDisplayed() {},
};
const Context = createContext(nopContext);

export function useFooterOverride(enabled = true) {
  const context = useContext(Context);
  if (enabled) {
    context.footerDisplayed();
  }
}

/**
 * Resets the privacyPolicyPagePath session, so the Single use footer
 * can be displayed on the next page.
 * Used for immediate client side redirects
 */
export function resetSingleUseFooter() {
  const { privacyPolicyPagePath } = getSessionState("browser");

  if (window.location.pathname === privacyPolicyPagePath) {
    updateSessionState("browser", {
      privacyPolicyPagePath: null,
    });
  }
}

function Tracker() {
  useOnce(() => {
    trackEvent("OnSingleUseFooter");
  });
  return null;
}

export function SingleUseFooter({ children }: { children: ReactNode }) {
  const [footerVisible, setFooterVisible] = useState<boolean>(null);
  const customFooterDisplayed = useRef(false);

  const location = useLocation();

  const { privacyPolicyPagePath } = getSessionState("browser");

  // Defer potential render until children have a chance to render.
  useOnce(() => {
    // Flag as ready to render unless it's already been disabled.
    setFooterVisible((prev) => prev ?? true);

    if (!privacyPolicyPagePath) {
      updateSessionState("browser", {
        privacyPolicyPagePath: location.pathname as PathString,
      });
    }
  });

  const context = useMemo(
    () => ({
      footerDisplayed() {
        if (!customFooterDisplayed.current) {
          // Doing a bit of juggling with refs and states here to ensure
          // that we don't flash the default footer on pages that implement
          // their own.
          //
          // To avoid timing issues that calling footerDisplay in an effect
          // could introduce, we're intentionally calling this in the render
          // loop.
          //
          // The ref here allows for safe side effects in the render loop
          // (a normal set call would cause infinite re-renders if called
          // in the render loop).
          //
          // The set call is used only to trigger a render at this point.
          customFooterDisplayed.current = true;

          // Dump off the render loop to rerender.
          setTimeout(() => {
            setFooterVisible(false);
          }, 0);
        }
      },
    }),
    []
  );

  const isFirstPage =
    !privacyPolicyPagePath || location.pathname === privacyPolicyPagePath;
  const routeNeedsFooter = !isInApp();

  return (
    <Context.Provider value={context}>
      <FlexFrame
        css={{
          // Pushes the footer below the fold. 100vh value for webkit browsers
          // includes the space behind the navigation bar, -webkit-fill-available
          // should be used instead.
          minHeight: ["100vh"],
          // this prevents desktop chrome from using -webkit-fill-available which it does
          // not use properly.
          "@supports (-webkit-touch-callout: none)": {
            minHeight: "-webkit-fill-available",
          },
        }}
      >
        {children}
      </FlexFrame>

      {footerVisible &&
        !customFooterDisplayed.current &&
        isFirstPage &&
        routeNeedsFooter && (
          // Disable useFooterOverride if we are rendering the footer ourselves.
          <Context.Provider value={nopContext}>
            <div css={{ zIndex: zIndex.footer }}>
              <Tracker />
              <FooterLandingSmall />
            </div>
          </Context.Provider>
        )}
    </Context.Provider>
  );
}
