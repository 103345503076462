import { send } from "@utils/fetch";
import { captureException } from "@utils/error";
import { NoomError } from "../error/NoomError";
import { logInfo } from "../monitoring/logging";

function createPostRequest(
  action: "create" | "update",
  requestBody: JsonObject
) {
  return send("POST", `/visit/v1/${action}/`, requestBody)
    .then((text: string) => {
      logInfo(`${text}! User Id: ${requestBody.id} `);
    })
    .catch((e: any) => {
      captureException(
        new NoomError(`Visit ${action} failed`, {
          cause: e,
          delegateMetric: true,
        })
      );
    });
}

async function apiCreateVisit(requestBody: JsonObject) {
  return createPostRequest("create", requestBody);
}

async function apiUpdateVisit(requestBody: JsonObject) {
  return createPostRequest("update", requestBody);
}

export { apiCreateVisit, apiUpdateVisit };
