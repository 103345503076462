import { send } from "@utils/fetch";
import {
  shouldApplyLDUFlag,
  FacebookPixelParameters,
} from "@utils/pixels/publishers/facebook";
import Cookies from "js-cookie";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { LibCategories, waitForConsent } from "../consent";
import { toSha256 } from "../cryptography";
import { trackEventIfNotUnloading } from "../lifecycle";
import {
  getCity,
  getCountryCode,
  getPostalCode,
  getSubdivision,
} from "../meristemContext";
import { trackEvent } from "./tracker";

type ActionSource = "website" | "email";
type DataProcessingOption = "LDU";

interface FBCapiUserData extends FBCapiOptionalUserData {
  external_id: string;
  email: string;
  phone: string;
  fbc?: string;
  fbp?: string;
}

interface FBCapiOptionalUserData {
  state?: string;
  zipcode?: string;
  firstName?: string;
  gender?: string;
  city?: string;
}

interface FBCapiCustomData {
  content_category: string;
  content_ids: string;
  content_type: string;
  value: number;
  currency: string;
  order_id: string;
  custom_properties: Record<string, any>;
}

interface FBCapiEventData {
  event_id: string;
  event_name: string;
  event_source_url: string;
  pixel_id: string;
  action_source: ActionSource;
  user_data: FBCapiUserData;
  custom_data: FBCapiCustomData;

  data_processing_options?: DataProcessingOption[];
  data_processing_options_country?: number;
  data_processing_options_state?: number;
  opt_out?: boolean;

  // Used only for debugging/testing
  test_event_code?: string;
}

interface FBCapiPayload extends FacebookPixelParameters {
  eventID?: string;
  customProperties?: Record<string, any>;
  email?: string;
  phone?: string;
}

export async function fireFacebookCapi(
  pixelId: string,
  eventName: string,
  payload: FBCapiPayload
) {
  const optionalUserData: FBCapiOptionalUserData =
    await extractAvailableUserData();
  const userData: FBCapiUserData = {
    external_id: payload.external_id,
    email: payload.email,
    phone: payload.phone,
    ...optionalUserData,
  };
  const { fbc, fbp } = getFacebookProperties();
  if (fbc) {
    userData.fbc = fbc;
  }
  if (fbp) {
    userData.fbp = fbp;
  }

  const customData: FBCapiCustomData = {
    content_category: payload.content_category,
    content_ids: payload.content_ids,
    content_type: payload.content_type,
    value: payload.value,
    currency: payload.currency,
    order_id: payload.order_id,
    custom_properties: payload.customProperties,
  };

  const eventData: FBCapiEventData = {
    event_id: payload.eventID,
    event_name: eventName,
    event_source_url: window.location.href,
    action_source: "website",
    user_data: userData,
    custom_data: customData,
    pixel_id: pixelId,
  };

  if (shouldApplyLDUFlag()) {
    Object.assign(eventData, {
      data_processing_options: ["LDU"],
      data_processing_options_country: 1,
      data_processing_options_state: 1000,
      opt_out: true,
    });
  }

  const testEventCode = getTestEventCodeUrlParameter();
  if (testEventCode) {
    Object.assign(eventData, {
      test_event_code: testEventCode,
    });
  }

  return waitForConsent([LibCategories.targetingCookies])
    .then(() => {
      trackEvent("BuyflowTaskStarted", {
        taskName: "FacebookCapi",
        eventName,
        lduSet: shouldApplyLDUFlag(),
      });
      return send("POST", `/pixel/v1/i/facebookCapiPixel/`, eventData);
    })
    .catch((err) => {
      trackEventIfNotUnloading("BuyflowTaskFailed", {
        taskName: "FacebookCapi",
        error: err.message,
      });
    });
}

/**
 * Extracts the value for testEventCode URL query param will be send further to the CAPI server
 * Only used for testing
 */
function getTestEventCodeUrlParameter() {
  const urlParams = new URLSearchParams(window.location.search);
  const testEventCode = urlParams.get("testEventCode");

  return testEventCode || null;
}

/**
 *
 * Extracts the fbc (facebook click id) and fbp (facebook browser id) signals from cookies
 */
function getFacebookProperties(): {
  fbc: string | undefined;
  fbp: string | undefined;
} {
  const fbc = Cookies.get("_fbc");
  const fbp = Cookies.get("_fbp");
  return { fbc, fbp };
}
async function extractAvailableUserData(): Promise<FBCapiOptionalUserData> {
  const data: FBCapiOptionalUserData = {};
  // location data
  const state = getSubdivision();
  const country = getCountryCode();
  const zipcode = getPostalCode();
  const city = getCity();
  data.state = hashState(state, country);
  data.city = hashCity(city);
  data.zipcode = hashZip(zipcode);

  // survey data
  const { sex, firstName } = getSurveyAnswers();
  data.gender = hashGenderAnswer(sex);
  data.firstName = await hashFirstName(firstName);

  return data;
}

function hashGenderAnswer(answer: Array<string>): string | null {
  if (!answer || answer.length === 0) {
    return null;
  }
  const normalizedGender = answer[0].trim().toLowerCase();
  if (normalizedGender === "female") {
    return "f";
  }
  if (normalizedGender === "male") {
    return "m";
  }
  return null;
}

export async function hashFirstName(answer: string): Promise<string | null> {
  if (!answer) {
    return null;
  }
  const normalizedFirstName = answer.trim().toLowerCase();
  return toSha256(normalizedFirstName);
}

export function hashState(state: string, country: string): string | null {
  if (!state) {
    return null;
  }

  const normalizedState = state.toLowerCase();

  if (country === "US") {
    if (normalizedState.length === 2) {
      return normalizedState;
    }
    return null;
  }
  // Normalize non-US states: lowercase, no punctuation, special characters, or spaces
  const normalizedNonUSState = normalizedState.replace(/[^a-z0-9]/g, "");
  return normalizedNonUSState;
}

export function hashZip(zipCode: string | null) {
  if (!zipCode) {
    return null;
  }
  // Return the original zip code without spaces or dashes
  return zipCode.toLowerCase().replace(/\s+/g, "").replace(/-/g, "");
}

export function hashCity(city: string | null) {
  if (!city) {
    return null;
  }

  // Remove any characters that are not alphanumeric (\w) or underscores (_)
  const cleanedCity = city.replace(/[^\w]|_/g, "").toLowerCase();

  // Return the cleaned city without spaces
  return cleanedCity.replace(/\s+/g, "");
}
