import { isHM } from "../userSegment";

// For HM and HW components that have been consolidated together
// we sometimes need to use this mapping to track the legacy HM event names
const HMEventMapping = {
  OnSignUpFormCompleted: "OnSignUpFormCompletedHM",
  OnChooseTrialPriceFinalize: "OnChooseTrialPriceFinalizeHM",
  OnChooseTrialPriceUpdate: "OnChooseTrialPriceUpdateHM",
  clickedSaveProfile: "ClickedSaveProfileHM",
  SubmittedStartSubscriptionRequest: "SubmittedStartSubscriptionRequestHM",
  FailedToStartSubscription: "FailedToStartSubscriptionHM",
  OnSignedUp: "OnSignedUpHM",
  ValidationError: "ValidationErrorHM",
  DownloadApp: "DownloadAppHM",
};

export function mapEventName(eventName: string) {
  return (isHM() && HMEventMapping[eventName]) || eventName;
}
