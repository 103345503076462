import { send } from "@utils/fetch";

export const sendTelehealthFeedbackSurveyResponse = (
  wheelConsultId: string,
  surveyVersionName: string,
  surveyAnswers: object
) => {
  const url = `/api/insurance/v1/feedback/consult/${wheelConsultId}/`;
  return send("PATCH", url, { surveyVersionName, surveyAnswers });
};
