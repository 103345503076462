import goto from "./index";
import getStore from "@utils/redux/store";
import {
  hasPromoCode,
  hasVipPromoCode,
  isAddonStoreEligible,
  isFastForwardEligible,
  isNoomSummitEligible,
  isProbioticsDeliveryEligible,
  isZumbaOfferEligible,
} from "@utils/userSegment/features";
import { isInApp, isUS } from "src/utils/userSegment";
import { updateForwardedParams } from "src/utils/urlParams";
import { CHECK_EMAIL_REMINDER_PARAM } from "src/components/collapsible-header/CollapsibleHeader";
import { GotoEnrollmentParams } from "src/pageDefinitions/goto/enrollment";
import { getSessionState } from "src/pageDefinitions/session";

// This navigates outside of the Noom Premium context
export default {
  async navigateFromNoomPremium(showEmailReminder = true) {
    const { purchasedNoomPremium } = getStore().getState().addOns;

    if (!hasVipPromoCode()) {
      // baselined behavior from ex1058 - en/US promo code users are eligible for ffwd
      if ((!hasPromoCode() || isUS()) && isFastForwardEligible()) {
        goto.fastForwardTrial({
          showEmailReminder:
            purchasedNoomPremium && showEmailReminder ? "noomPremium" : null,
        });
        return;
      }

      if (isProbioticsDeliveryEligible()) {
        goto.probioticsDelivery();
        return;
      }

      if (isZumbaOfferEligible()) {
        goto.zumbaOffer();
        return;
      }

      if (isNoomSummitEligible()) {
        updateForwardedParams({
          [CHECK_EMAIL_REMINDER_PARAM]:
            purchasedNoomPremium && showEmailReminder ? "noomPremium" : null,
        });
        goto.layer("noom-summit");
        return;
      }

      if (isAddonStoreEligible()) {
        goto.addOns({});
        return;
      }
    }

    if (isInApp()) {
      goto.nativePurchaseComplete();
      return;
    }

    const enrollmentParams: GotoEnrollmentParams =
      getSessionState("browser").enrollmentForwardParams;

    goto.enrollment({
      showEmailReminder: purchasedNoomPremium ? "noomPremium" : null,
      ...enrollmentParams,
    });
  },
};
