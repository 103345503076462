import { createSelector } from "@reduxjs/toolkit";
import { CoreReduxState } from "../../internal";
import { getStateCode, StateCode } from "src/utils/usStates";
import { BillingAddress } from "src/utils/redux/slices/checkout";

export const selectResolvedPhysicalAddress = createSelector(
  (state: CoreReduxState) => state.checkout,
  (state: CoreReduxState) => state.checkoutPhysicalAddress,
  (checkoutState, physicalAddressState): BillingAddress => {
    let retAddress = physicalAddressState.address;
    if (physicalAddressState.isSameAsBillingAddress) {
      const { billingAddress } = checkoutState;
      retAddress = {
        ...billingAddress,
      };
    }
    return retAddress;
  }
);

// The backend requires us to pass in a shortened region name eg TX instead of Texas for the physical address
export const selectResolvedPhysicalAddressShortRegion = createSelector(
  selectResolvedPhysicalAddress,
  (address): Omit<BillingAddress, "region"> & { region: StateCode } => {
    return {
      ...address,
      region: getStateCode(address.region),
    };
  }
);
