import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PremiumCancellationReasons,
  PremiumSupportedCounteroffers,
} from "src/components/counter-offer/utils";

type PremiumCounteroffer = {
  selectedCancellationReason: PremiumCancellationReasons | null;
  offeredCounteroffer: PremiumSupportedCounteroffers | null;
};

const initialState: PremiumCounteroffer = {
  selectedCancellationReason: null,
  offeredCounteroffer: null,
};

const premiumCounteroffer = createSlice({
  name: "premiumCounteroffer",
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setSelectedCancellationReason(
      state,
      action: PayloadAction<PremiumCancellationReasons>
    ) {
      state.selectedCancellationReason = action.payload;
    },
    setOfferedCounteroffer(
      state,
      action: PayloadAction<PremiumSupportedCounteroffers>
    ) {
      state.offeredCounteroffer = action.payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { setSelectedCancellationReason, setOfferedCounteroffer } =
  premiumCounteroffer.actions;

export default premiumCounteroffer;
