import { logDebug } from "./monitoring/logging";

/**
 * Lightweight event bus/callback list.
 *
 * Usage:
 * const callbacks = createCallbackList<[MonitoringEvent]>();
 *
 * callbacks.add(handler);
 *
 * callbacks.emit(eventData);
 */
export function createCallbackList<T extends any[]>({
  /** true to log thrown errors rather than failing the list execution */
  ignoreErrors = false,
} = {}) {
  const listeners = new Set<(...params: T) => void | Promise<void>>();

  function handleError(err: Error) {
    if (ignoreErrors) {
      // We want to avoid pulling in sentry as a dep. Console log
      // to not fully squash.
      logDebug("SimpleEventListener error", err);
    } else {
      throw err;
    }
  }

  return {
    add: (listener: (...params: T) => void | Promise<void>) => {
      listeners.add(listener);
      return () => {
        listeners.delete(listener);
      };
    },
    emit: (...params: T) => {
      const promises: Promise<void>[] = [];
      listeners.forEach((handler) => {
        promises.push(Promise.resolve(handler(...params)).catch(handleError));
      });

      return Promise.all(promises);
    },
  };
}
