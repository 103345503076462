import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CheckoutState,
  updateCheckoutState,
  BillingAddress,
} from "../checkout";
import {
  failLocationMedValidation,
  returnedInvalidAddress,
  suggestAddress,
  userAcceptedSuggestedAddress,
  failNoPOBoxValidation,
  formAddressUpdatedWithSuggestedAddress,
} from "./action-creators";

export interface CheckoutPhysicalAddressState {
  address: BillingAddress;
  isSameAsBillingAddress: boolean;
  // TODO (cory): Modal state does not belong in Redux. But the checkout flow is very complicated...
  showSuggestedAddressModal: boolean;
  showInvalidAddressModal: boolean;
  showLocationMedInvalidModal: boolean;
  // The corrected address returned by the validation API
  suggestedAddress: Partial<BillingAddress>;
  chosenModalAddress: Partial<BillingAddress>;
  // Whether or not to show an error message due to failed TR validation
  addressInvalid: boolean;
  // Whether the user accepted one of the choices in the Suggested Address modal. ie, they did not
  // go back to editing
  acceptedSuggestedAddress: boolean;
  shouldUpdateFormAddress: boolean;
  showNoPoBoxModal: boolean;
}

const initialState: CheckoutPhysicalAddressState = {
  address: {} as BillingAddress,
  isSameAsBillingAddress: false,
  showSuggestedAddressModal: false,
  showInvalidAddressModal: false,
  showLocationMedInvalidModal: false,
  suggestedAddress: {},
  chosenModalAddress: {},
  addressInvalid: false,
  acceptedSuggestedAddress: false,
  shouldUpdateFormAddress: false,
  showNoPoBoxModal: false,
};

const checkoutPhysicalAddressSlice = createSlice({
  name: "checkoutPhysicalAddress",
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<BillingAddress>) => ({
      ...state,
      address: action.payload,
      // When the user changes the address, clear the error message
      addressInvalid: false,
    }),
    toggleSameAsBillingAddress: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSameAsBillingAddress: action.payload,
    }),
    closeModal: (state) => ({
      ...state,
      showSuggestedAddressModal: false,
      showInvalidAddressModal: false,
      showNoPoBoxModal: false,
      showLocationMedInvalidModal: false,
      chosenModalAddress: {},
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(failLocationMedValidation, (state) => ({
        ...state,
        showLocationMedInvalidModal: true,
      }))
      .addCase(userAcceptedSuggestedAddress, (state, action) => {
        const ret = { ...state };
        if (!action.payload.isSameAsBillingAddress) {
          ret.address = {
            ...state.address,
            ...action.payload.suggestedAddress,
          };
        }
        ret.chosenModalAddress = action.payload.suggestedAddress;
        ret.showSuggestedAddressModal = false;
        ret.addressInvalid = false;
        ret.acceptedSuggestedAddress = true;
        ret.shouldUpdateFormAddress = action.payload.shouldUpdateFormAddress;
        return ret;
      })
      .addCase(
        suggestAddress,
        (state, action: PayloadAction<Partial<BillingAddress>>) => ({
          ...state,
          showSuggestedAddressModal: true,
          suggestedAddress: action.payload,
          addressInvalid: true,
          acceptedSuggestedAddress: false,
        })
      )
      .addCase(returnedInvalidAddress, (state) => ({
        ...state,
        showInvalidAddressModal: true,
        suggestedAddress: {},
        chosenModalAddress: {},
        addressInvalid: true,
      }))
      .addCase(
        updateCheckoutState,
        // If their physical address is the same as billing address, clear errors when they update
        // billing address
        (state, action: PayloadAction<Partial<CheckoutState>>) => {
          if (!state.isSameAsBillingAddress) {
            return state;
          }
          if (!action.payload.billingAddress) {
            return state;
          }
          return {
            ...state,
            addressInvalid: false,
          };
        }
      )
      .addCase(failNoPOBoxValidation, (state) => ({
        ...state,
        showNoPoBoxModal: true,
      }))
      .addCase(formAddressUpdatedWithSuggestedAddress, (state) => ({
        ...state,
        shouldUpdateFormAddress: false,
      }));
  },
});

export const { setAddress, toggleSameAsBillingAddress, closeModal } =
  checkoutPhysicalAddressSlice.actions;

export default checkoutPhysicalAddressSlice;
