import { createSlice } from "@reduxjs/toolkit";
import { requestPurchase } from "src/utils/api/purchase/checkout";
import { requestFastForwardTrial } from "src/utils/api/fastForwardTrial";
import { updateServerContext } from "./serverContext";

// The initial value can come from a URL parameter. This is the case for links sent via email.
// Also good for rerolling between versions of the code, which may not have written to the store.
const urlParams = new URLSearchParams(window.location.search);
const initialUpid = urlParams.get("upid");

const upidSlice = createSlice({
  name: "upid",
  initialState: initialUpid || "",
  reducers: {
    // Directly sets the upid
    // (cory): Avoid calling this directly when possible; prefer to listen to existing Redux data
    // flows. This was added because ESDE and HM Checkout are just different enough from regular
    // checkout that refactoring in a reusable way proved difficult.
    // TODO: Remove when ESDE and HM checkout merge with regular checkout
    setUpid: (_state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    // Currently there are three places where upid might change.
    // 1. After checkout -- the user is first assigned a upid.
    // 2. After fast-forward -- if the user opts to skip trial, they are assigned a new upid in the
    //    FFT response.
    // 3. From serverContext -- this response sometimes includes a upid. Although I'm not sure if
    //    it can actually change here, or if it just echoes; regardless, we should always trust the
    //    value from the response if present.
    //
    // If any of these responses (for some reason?) don't include a upid, we'll continue using the
    // one we had already.
    builder
      .addCase(
        requestPurchase.fulfilled,
        (state, action) => action.payload?.response?.upid || state
      )
      .addCase(
        requestFastForwardTrial.fulfilled,
        (state, action) => action.payload?.upid || state
      )
      .addCase(
        updateServerContext,
        (state, action) => action.payload?.upid || state
      );
  },
});

export const { setUpid } = upidSlice.actions;

export default upidSlice;
