import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RemoveIndexSignature } from "type-fest";

export type SurveyAnswer = AnyJson;
export interface SurveyAnswersState {
  [key: string]: SurveyAnswer;
}
export type KnownSurveyAnswersState = RemoveIndexSignature<SurveyAnswersState>;

const surveyAnswersSlice = createSlice({
  name: "surveyAnswers",
  initialState: {} as SurveyAnswersState,
  reducers: {
    addSurveyAnswers(state, action: PayloadAction<SurveyAnswersState>) {
      return { ...state, ...action.payload };
    },
    setSurveyAnswers(state, action: PayloadAction<SurveyAnswersState>) {
      return { ...action.payload };
    },
  },
});

export const { addSurveyAnswers, setSurveyAnswers } =
  surveyAnswersSlice.actions;

export default surveyAnswersSlice;
