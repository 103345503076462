import { useEffect, useRef, useState } from "react";
import { toHoursMinutesSeconds } from "src/utils/datetime";

export function useCountdown({
  active = true,
  durationMinutes = 0,
  durationSeconds = 0,
  durationHours = 0,
  onComplete,
}: {
  active?: boolean;
  durationMinutes?: number;
  durationSeconds?: number;
  durationHours?: number;
  onComplete: () => void;
}) {
  const completeRef = useRef(onComplete);
  completeRef.current = onComplete;

  const [secondsRemaining, setSecondsRemaining] = useState(
    durationSeconds + 60 * durationMinutes + 3600 * durationHours
  );

  useEffect(() => {
    const interval =
      active &&
      setInterval(() => {
        setSecondsRemaining((tik) => {
          const tok = tik - 1;

          if (tok <= 0) {
            clearInterval(interval);

            // Dump off the event loop to avoid concurrent update errors
            setTimeout(() => {
              completeRef.current();
            }, 10);
          }

          return tok;
        });
      }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [active]);

  return toHoursMinutesSeconds(secondsRemaining);
}
