/* eslint-disable no-restricted-globals */

import { CHECK_EMAIL_REMINDER_PARAM } from "src/components/collapsible-header/CollapsibleHeader";
import { updateForwardedParams } from "src/utils/urlParams";
import goto from ".";

export type OffersParams = {
  userId?: string;
  acceptedCoursePackOffer?: boolean;
};

export type CollapsibleHeaderUrlParams = {
  showEmailReminder?: "noomPremium" | "noomSummit" | "snackDelivery" | false;
};

export default {
  noomPremium() {
    goto.layer("noom-premium");
  },
  inAppNoomPremium() {
    goto.layer("noom-premium-in-app-upgrade");
  },
  addOns({ showEmailReminder }: CollapsibleHeaderUrlParams) {
    updateForwardedParams({
      [CHECK_EMAIL_REMINDER_PARAM]: showEmailReminder ?? null,
    });
    goto.layer("addons");
  },

  probioticsDelivery() {
    goto.layer("probiotics-delivery");
  },

  zumbaOffer() {
    goto.layer("zumba-offer");
  },

  fastForwardTrial({ showEmailReminder }: CollapsibleHeaderUrlParams) {
    updateForwardedParams({
      [CHECK_EMAIL_REMINDER_PARAM]: showEmailReminder ?? null,
    });
    goto.layer(`fast-forward`);
  },
};
