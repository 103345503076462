import React, { useRef } from "react";

import { useStepper } from "src/hooks/stepper";
import { getDayDelta, getMonthDelta, shift_days } from "src/utils/datetime";
import { getLocale } from "src/utils/meristemContext";
import { isUS } from "src/utils/userSegment";
import { SlideInValue } from "./SlideInValue";

function showYearInHeadline(date: Date) {
  return Math.abs(getMonthDelta(new Date(), date)) >= 12;
}

export function AnimateDate({
  startingDate,
  finalDate,
  shouldAnimate,
  animateFirstStep,
}: {
  startingDate: Date;
  finalDate: Date;
  shouldAnimate?: boolean;
  animateFirstStep?: boolean;
}) {
  const priorDate = useRef<Date>();

  const steps = Math.abs(getDayDelta(startingDate, finalDate));
  const stepDirection = startingDate.getTime() < finalDate.getTime() ? 1 : -1;
  const { currentStep } = useStepper({
    steps,

    // We want there to be approx ~25 total ticks for larger date ranges
    stepSize: Math.ceil(Math.max(steps / 25, 1)),
    duration: 3000,
  });

  const currentDate = shouldAnimate
    ? shift_days(stepDirection * currentStep, startingDate)
    : finalDate;
  if (!priorDate.current) {
    priorDate.current = shift_days(-1, currentDate);
  }

  if (Number.isNaN(currentDate.getTime())) {
    return null;
  }

  const previousDate = priorDate.current;
  const currentDay = currentDate.getDate();
  const previousDay = previousDate.getDate();
  const targetDay = finalDate.getDate();

  const currentMonth = currentDate.toLocaleString(getLocale(), {
    month: "long",
  });
  const previousMonth = previousDate.toLocaleString(getLocale(), {
    month: "long",
  });
  const targetMonth = finalDate.toLocaleString(getLocale(), {
    month: "long",
  });

  const currentYear = currentDate.getFullYear();
  const previousYear = previousDate.getFullYear();
  const targetYear = finalDate.getFullYear();

  const shouldAnimateDay =
    shouldAnimate && (animateFirstStep || currentStep > 1);

  const day = (
    <SlideInValue
      current={currentDay}
      previous={previousDay}
      shouldAnimate={shouldAnimateDay}
    />
  );
  const month = (
    <SlideInValue
      current={currentMonth}
      previous={previousMonth}
      shouldAnimate={shouldAnimateDay}
    />
  );
  const year = showYearInHeadline(finalDate) && (
    <SlideInValue
      current={currentYear}
      previous={previousYear}
      shouldAnimate={shouldAnimateDay}
    />
  );

  return (
    <div
      style={{ whiteSpace: "pre", display: "flex" }}
      aria-label={
        isUS()
          ? `${targetMonth} ${targetDay} ${targetYear}`
          : `${targetDay} ${targetMonth} ${targetYear}`
      }
    >
      {/* For US Markets we render the animated date as Month Day but for international markets we render the animated date as Day Month */}
      {isUS() ? (
        <div aria-hidden>
          {month}
          &nbsp;
          {day}
          {year && ", "}
        </div>
      ) : (
        <div aria-hidden>
          {day}
          &nbsp;
          {month}
          {year && ", "}
        </div>
      )}
      {year}
    </div>
  );
}
