import { isRedesignEnabled } from "src/utils/userSegment/features";

export type BuyFlowDesigns = "DEFAULT" | "REDESIGN";
export enum Design {
  REDESIGN = "REDESIGN",
  DEFAULT = "DEFAULT",
}

export function getCurrentDesign(): BuyFlowDesigns {
  if (isRedesignEnabled()) return Design.REDESIGN;
  return Design.DEFAULT;
}
