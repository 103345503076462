import React, { ReactNode, CSSProperties, useRef } from "react";
import styled from "@emotion/styled";

const Block = styled.div`
  outline: none;
`;

type Props = {
  children: ReactNode;

  // Hold off on focusing while this property is true (e.g. if there's a modal
  // visible over the component, the block should only come into focus once the
  // modal has been dismissed)
  deferFocus?: boolean;

  className?: string;
  style?: CSSProperties;
};

export const PrimaryFocusBlock = ({
  children,
  deferFocus = false,
  ...otherProps
}: Props) => {
  const focusTriggered = useRef(false);

  return (
    <Block
      ref={(node) => {
        if (node && !focusTriggered.current && !deferFocus) {
          node.focus();
          focusTriggered.current = true;
        }
      }}
      tabIndex={-1}
      {...otherProps}
    >
      {children}
    </Block>
  );
};
