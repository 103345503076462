import { trackEvent } from "src/utils/api/tracker";
import { captureException } from "src/utils/error";
import { JsonValue } from "type-fest";

declare module "." {
  export interface WellknownMixpanelEvents {
    BuyflowTaskStarted: BuyflowTaskStarted;
    BuyflowTaskSuccess: BuyflowTaskSuccess;
    BuyflowTaskFailed: BuyflowTaskFailed;
    BuyflowTaskDelayed: BuyflowTaskDelayed;
  }
}

type TaskName = string;

/**
 * Indicates that a particular task has started.
 * Tasks are generally tied to API calls, but can represent
 * any discrete unit of work.
 *
 * These events are intended mostly as diagnostic tools. They
 * provide visibility into the subsystems of the app and ensure
 * that they are functioning. While the can be used to track
 * business performance, it's recommended that critical performance
 * events have their own events for tracking.
 */
export interface BuyflowTaskStarted {
  taskName: TaskName;
  [extra: string]: JsonValue;
}

/**
 * Indicates that a particular task has successfully completed.
 *
 * See {@link BuyflowTaskStarted} for more details on tasks.
 */
export interface BuyflowTaskSuccess {
  taskName: TaskName;
  durationMs: number;
  [extra: string]: JsonValue;
}

/**
 * Indicates that a particular task has completed.
 *
 * See {@link BuyflowTaskStarted} for more details on tasks.
 */
export interface BuyflowTaskFailed {
  taskName: TaskName;
  durationMs: number;
  error: string;
  [extra: string]: JsonValue;
}

/**
 * Indicates that a particular task has taken longer than expected.
 *
 * See {@link BuyflowTaskStarted} for more details on tasks.
 */
export interface BuyflowTaskDelayed {
  taskName: TaskName;
  durationMs: number;
  [extra: string]: JsonValue;
}

interface TaskTracker {
  /**
   * Starts tracking a task, emitting the appropriate event, and optionally
   * kicking off a timeout tracker.
   */
  start(params?: { delayTime?: number; extras?: JsonObject }): void;

  /**
   * Called when a task has successfully completed.
   */
  success(extras?: JsonObject);

  /**
   * Called when a task has failed.
   */
  failed(error?: Error, extras?: JsonObject);

  /**
   * Convenience method that tracks the lifecycle of a promise.
   */
  wrap<T>(exec: (task: TaskTracker) => Promise<T>): Promise<T>;
}

/**
 * Creates a task tracker object that can track the lifecycle of a task.
 */
export function trackTask(
  taskName: string,
  taskExtras?: JsonObject,
  {
    emitStart = true,
    emitSuccess = true,
    emitFailed = true,
    delayTimeout,
  }: {
    extras?: JsonObject;
    emitStart?: boolean;
    emitSuccess?: boolean;
    emitFailed?: boolean;
    delayTimeout?: number;
  } = {}
) {
  let startTime: number;

  let cleanupDelayTracker = () => {};

  const tracker: TaskTracker = {
    wrap(exec) {
      tracker.start();
      return exec(tracker).then(
        (result) => {
          tracker.success();
          return result;
        },
        (err) => {
          tracker.failed(err);
          throw err;
        }
      );
    },

    start({ extras } = {}) {
      startTime = Date.now();
      cleanupDelayTracker = trackTaskDelay(taskName, delayTimeout, taskExtras);
      if (emitStart) {
        trackEvent("BuyflowTaskStarted", {
          taskName,
          ...taskExtras,
          ...extras,
        });
      }
    },

    success(extras) {
      cleanupDelayTracker();
      if (emitSuccess) {
        trackEvent("BuyflowTaskSuccess", {
          taskName,
          durationMs: Date.now() - startTime,
          ...taskExtras,
          ...extras,
        });
      }
    },

    failed(error, extras) {
      cleanupDelayTracker();
      if (error) {
        captureException(error, taskName);
      }
      if (emitFailed) {
        trackEvent("BuyflowTaskFailed", {
          taskName,
          durationMs: Date.now() - startTime,
          error: error?.message,
          ...taskExtras,
          ...extras,
        });
      }
    },
  };

  return tracker;
}

export function trackTaskDelay(
  taskName: string,
  delayTime: number,
  extras?: JsonObject
) {
  if (!delayTime) {
    return () => {};
  }

  const delayTracker = setTimeout(() => {
    trackEvent("BuyflowTaskDelayed", {
      taskName,
      durationMs: delayTime,
      ...extras,
    });
  }, delayTime);

  return () => clearTimeout(delayTracker);
}
