/* eslint-disable */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const gtag: any;
declare const dataLayer: any;
declare const window: Window & {
  gtag: any;
  dataLayer: any;
};

window.dataLayer = window.dataLayer || [];
window.gtag = function gtag() {
  dataLayer.push(arguments);
};
gtag("js", new Date());
// Adwords Tag
gtag("config", "AW-783925782");

// General analytics.
gtag("config", "UA-6218573-30");

export function loadGA() {
  // Unclear if we should be loading both of these or if it's possible to use gtm lib for both.
  // Worth investigating in future.
  return Promise.all([
    // <!-- Global site tag (gtag.js) - Google AdWords: AW-783925782 -->
    loadScript("https://www.googletagmanager.com/gtag/js?id=AW-783925782", [
      LibCategories.targetingCookies,
    ]),
  ]).then(() => {});
}
