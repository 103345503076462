import React, { MouseEvent, MutableRefObject } from "react";
import { useSelector } from "react-redux";
import { useInViewport } from "src/hooks/viewport";
import { CoreReduxState } from "src/utils/redux/store";
import i18n from "@i18n";

import styled from "@emotion/styled";
import { overline, fontP1Regular } from "src/design-system/styles/fonts";
import { compassColors } from "src/utils/styling";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { flexMiddle } from "src/design-system/styles/layout";
import { zIndex } from "src/design-system/styles/zIndices";
import { css } from "@emotion/react";

type Props = {
  headline: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  waypointRef: MutableRefObject<HTMLElement>;
};

const Bar = styled.nav<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  ${flexMiddle};
  background-color: ${compassColors.tarocco};
  z-index: ${zIndex.header + 1};
  transform: translateY(-80px);
  transition: all 0.5s ease;

  display: none;
  ${({ visible }) =>
    visible &&
    css`
      @media ${breakpointMobileExtended} {
        display: flex;
        transform: translateY(0);
      }
    `}
`;

const Title = styled.p`
  ${fontP1Regular};
  color: ${compassColors.white};
  padding: 8px 14px;
`;

const Button = styled.button`
  ${overline};
  font-size: 16px;
  font-weight: 700;
  background-color: ${compassColors.white};
  color: ${compassColors.tarocco};
  border: none;
  border-radius: 8px;
  height: 56px;
  max-width: 400px;
  padding: 20px;

  &:hover {
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.75);
  }
`;

export default function StickyCTAChoosePlan({
  headline,
  onClick,
  waypointRef,
}: Props) {
  const notInViewport = !useInViewport(waypointRef);
  const hasTrialFee = useSelector(
    (state: CoreReduxState) => state.trial.hasTrialFee
  );

  const buttonKey = hasTrialFee ? "continue" : "stickyButton";
  return (
    <Bar visible={notInViewport}>
      <Title>{headline}</Title>
      <Button type="button" onClick={onClick}>
        {i18n.t(`sticky-cta-choose-plan-v2:${buttonKey}`)}
      </Button>
    </Bar>
  );
}
