import { FeatureFlags } from "src/pageDefinitions/featureFlags";
import { SegmentFunction } from ".";
import { routeConstants } from "../constants";
import { getRouteId } from "../meristemContext";
import getStore, { CoreReduxState } from "../redux/store";
import { evalResultWithFeatureFlag } from "./features";
import {
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    oneTimeConditionKeys?: {
      [string: string]: boolean;
    };
  }
}

/**
 * Generates a method that will check a combination of routes and
 * custom segments.
 */
export function checkSegments({
  oneOf,
  allOf,
  noneOf,
  featureFlagOverride,
}: {
  oneOf?: (routeConstants | SegmentFunction)[];
  allOf?: (routeConstants | SegmentFunction)[];
  noneOf?: (routeConstants | SegmentFunction)[];
  featureFlagOverride?: keyof FeatureFlags;
}) {
  const ret: SegmentFunction = (state = getStore().getState()) => {
    const result =
      (oneOf || allOf || noneOf) &&
      (!oneOf || oneOf.some((check) => runCheck(check, state))) &&
      (!allOf || !allOf.find((check) => !runCheck(check, state))) &&
      (!noneOf || !noneOf.find((check) => runCheck(check, state)));
    if (featureFlagOverride) {
      return evalResultWithFeatureFlag(result, featureFlagOverride);
    }
    return result;
  };

  const loaders = [
    ...(oneOf?.map(extractLoader) || []),
    ...(allOf?.map(extractLoader) || []),
    ...(noneOf?.map(extractLoader) || []),
  ].filter(Boolean);

  // Conditionally apply the loader API. If we apply this everywhere, there could be a fair
  // amount of unnecessary load calls.
  if (loaders.length > 0) {
    ret.load = (state): Promise<void> => {
      return Promise.all(loaders.map((loader) => loader(state))).then(() => {});
    };
  }

  return ret;
}

function runCheck(
  check: SegmentFunction | routeConstants,
  state: CoreReduxState
) {
  return typeof check === "function" ? check(state) : check === getRouteId();
}

function extractLoader(check: SegmentFunction | routeConstants) {
  return typeof check === "function" && "load" in check
    ? check.load
    : undefined;
}

/*
 * Creates a segment function that always returns true for future evaluations in the same session
 * as long it passes the condition check once.
 *
 * This is currently useful for the email flow because we want to show
 * questions like /sex and /basicHeight if they're missing the survey answer initially,
 * but still want them to be able to navigate back to these questions after filling them out
 */
export function createOneTimeConditionSegment(
  key: string,
  condition: (state: CoreReduxState) => boolean
) {
  const segmentFunction = (state = getStore().getState()) => {
    const existingOneTimeConditionKeys =
      getSessionState("browser").oneTimeConditionKeys || {};

    if (existingOneTimeConditionKeys[key]) {
      return true;
    }

    const conditionPassed = condition(state);

    // Store the answer key in session state so that even after they answer the question
    // They can still go back to the question afterwards.
    if (conditionPassed) {
      updateSessionState("browser", {
        oneTimeConditionKeys: {
          ...existingOneTimeConditionKeys,
          [key]: true,
        },
      });
    }
    return !!conditionPassed;
  };

  return segmentFunction;
}
