/* eslint-disable no-underscore-dangle */
import { compassColors } from "@utils/styling";

import { drawLabelBox } from "src/graphs/common/chartElements";
import MedicationVial from "src/graphs/images/circle_vial.png";
import { circle } from "src/utils/shapes";

const cachedVialImage = new Image();
let isVialImageLoaded = false;

cachedVialImage.onload = () => {
  isVialImageLoaded = true;
};
cachedVialImage.src = MedicationVial;

export function drawLabel(
  chartInstance,
  text: string,
  tick: number,
  startTick: number,
  endTick: number,
  color = compassColors.tarocco,
  { dataset } = { dataset: 1 },
  paddingLeft = 0
) {
  if (tick < startTick) {
    return;
  }
  const { ctx, width } = chartInstance;
  ctx.save();
  ctx.textAlign = "center";
  ctx.fillStyle = color;

  const fontSize = width <= 350 ? 10 : 12;
  ctx.font = `${fontSize}pt 'Brown LL', sans-serif`;
  const textUpper = text.toUpperCase();

  if (tick > endTick) {
    const textWithArrow = `${textUpper} →`;
    const textMeasurement = ctx.measureText(textWithArrow);
    ctx.fillText(textWithArrow, width - textMeasurement.width / 2 - 20, 30);
  } else {
    const dataPointsOnLine = chartInstance._sortedMetasets[dataset].data;
    const dataPointToDrawRelativeTo = dataPointsOnLine[tick];

    const textMeasurement = ctx.measureText(textUpper);
    const crossBrowserBoundingBoxDescent =
      textMeasurement.fontBoundingBoxDescent ||
      textMeasurement.actualBoundingBoxDescent;
    const crossBrowserBoundingBoxAscent =
      textMeasurement.fontBoundingBoxAscent ||
      textMeasurement.actualBoundingBoxAscent;
    const textHeight =
      crossBrowserBoundingBoxDescent + crossBrowserBoundingBoxAscent;
    const pad = 18; // some hard coded value
    const yAxisWidth = 40;
    const xOffset = textMeasurement.width / 2 + pad + paddingLeft;

    let yCoord = dataPointToDrawRelativeTo.y + 3;

    const endDatapoint = dataPointsOnLine[endTick]; // This gets us the xcoord of the goal label box which we should avoid overlapping with

    const collideX =
      dataPointToDrawRelativeTo.x + textMeasurement.width + pad >
      endDatapoint.x - 30; // The 30 should probably represent the width of the goal rectangle
    const collideY = yCoord + textHeight > endDatapoint.y - 55; // 55 is the rough height of the goal box
    const collideYAxis =
      dataPointToDrawRelativeTo.x - (textMeasurement.width + pad + yAxisWidth) <
      0;
    // Flip the text if it collides with the y and x coordinates of the goal label box
    // and if flipping the text will not collide with the y axis
    const flip = collideX && collideY && !collideYAxis;
    let xCoord = dataPointToDrawRelativeTo.x + (flip ? -xOffset : xOffset);

    // If the text collides with the y axis, flip the text back to normal layout (text pointing towards right)
    // and move it slightly upper-left to prevent it from colliding with the goal label box
    if (collideX && collideY && collideYAxis) {
      xCoord -= 25;
      yCoord -= pad;
    }

    ctx.fillText(textUpper, xCoord, yCoord);
  }
  ctx.restore();
}

export function renderGoal(
  chartInstance,
  topText,
  bottomText,
  tick,
  color = compassColors.tarocco,
  { dataset } = { dataset: 0 }
) {
  const { ctx, width } = chartInstance;
  const fontSize = width <= 350 ? 10 : 12;
  ctx.save();
  ctx.textAlign = "center";
  ctx.fillStyle = color;
  const dataPointsOnLine = chartInstance._sortedMetasets[dataset].data;
  const dataPointToDrawRelativeTo = dataPointsOnLine[tick];

  drawLabelBox({
    ctx,
    text: [topText.toUpperCase(), bottomText.toUpperCase()],
    x: dataPointToDrawRelativeTo.x,
    y: dataPointToDrawRelativeTo.y - 13,
    color,
    fontSize,
    padding: 8,
  });
  ctx.restore();
}

export function renderMedicationVial(
  chartInstance,
  tick,
  { dataset } = { dataset: 0 }
) {
  const { ctx } = chartInstance;
  const dataPointsOnLine = chartInstance._sortedMetasets[dataset].data;
  const dataPoint = dataPointsOnLine[tick];
  const vialWidth = 42;
  const vialHeight = 42;
  const vialRadius = 23;

  ctx.save();

  // Let's give the vial a background color
  circle(ctx, dataPoint.x, dataPoint.y, vialRadius + 1, "#BDDFA2");

  if (isVialImageLoaded) {
    ctx.drawImage(
      cachedVialImage,
      dataPoint.x - vialWidth / 2,
      dataPoint.y - vialHeight / 2,
      vialWidth,
      vialHeight
    );

    // Let's overlay the stroke on top of the image
    circle(
      ctx,
      dataPoint.x,
      dataPoint.y,
      vialRadius,
      null,
      compassColors.white,
      4
    );
  }
  ctx.restore();
}
