import styled from "@emotion/styled";

import { FullWidth } from "./BodyLayout";

import { zIndex } from "src/design-system/styles/zIndices";
import { compassColors } from "src/utils/styling";

export const HeaderToolbar = styled(FullWidth)`
  z-index: ${zIndex.header};
  background-color: ${compassColors.offWhite};
  border-bottom: 1px solid ${compassColors.sand2};
`;
