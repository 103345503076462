import { loadScript } from "@utils/scriptLoader";
import { LibCategories } from "@utils/consent";

declare const window: Window & {
  Rokt?: any;
  roktLauncher?: any;
  rokt_account_id: string;
};

window.rokt_account_id = "2853950934496843223";

export function loadRoktPixel() {
  return Promise.all([
    loadScript(
      "https://apps.rokt.com/wsdk/integrations/launcher.js",
      [LibCategories.targetingCookies],
      {
        enableCORS: true,
        htmlAttr: {
          type: "text/javascript",
          id: "rokt-launcher",
        },
      }
    ),
    loadScript(
      "https://apps.rokt.com/integrations/referral-tag/referral-tag.js",
      [LibCategories.targetingCookies],
      {
        enableCORS: true,
        htmlAttr: {
          type: "text/javascript",
          id: "rokt-referral-tag",
        },
      }
    ),
  ]).then(async () => {
    if (window.Rokt) {
      window.roktLauncher = await window.Rokt.createLauncher({
        accountId: window.rokt_account_id,
      });
    }
  });
}
