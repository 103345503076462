import { shuffleArray, spliceRandomly } from "@utils/arrayMethods";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { getSurveyOptionsWithTranslationKeys } from "src/pageDefinitions/actions/survey/util";
import { getTranslatedStringFromTKey } from "src/utils/stringUtils";
import { isAU, isUK, isUS } from "src/utils/userSegment";

export enum WdyhauOptionSet {
  baselineUS,
  baselineNonUS,
  simpleRadio,
  simpleAudio,
}

/**
 * Downstream Data team (Analytic Engineering / Data Engineering)
 * attributes these Wdyhau answers to HDYHAU channels.
 * Please contact the data team for ANY changes to be made on this ENUM.
 */
export enum WdyhauOption {
  amexrewards = "amexrewards",
  audio = "audio",
  blogOrReview = "blogOrReview",
  cashBackRewards = "cashBackRewards",
  coPromotionFromAnotherBrand = "coPromotionFromAnotherBrand",
  directMail = "directMail",
  discountAndDealSite = "discountAndDealSite",
  doctor = "doctor",
  facebook = "facebook",
  friendorFamily = "friendorFamily",
  helloFresh = "helloFresh",
  influencer = "influencer",
  instagram = "instagram",
  loyaltyAndPointsProgram = "loyaltyAndPointsProgram",
  newsPaperOrMagazine = "newsPaperOrMagazine",
  onlineTV = "onlineTV",
  other = "other",
  otherRadio = "otherRadio",
  pinterest = "pinterest",
  podcast = "podcast",
  posterOrBillboard = "posterOrBillboard",
  printMagazinePoster = "printMagazinePoster",
  publicRadio = "publicRadio",
  radio = "radio",
  reddit = "reddit",
  search = "search",
  siriusXM = "siriusXM",
  snapchat = "snapchat",
  streamingRadio = "streamingRadio",
  tiktok = "tiktok",
  tv = "tv",
  youtube = "youtube",
  zumba = "zumba",
}

export type WdyhauOptionProps = {
  set?: WdyhauOptionSet;
  radioSet?: WdyhauOptionSet;
  tvSet?: WdyhauOptionSet;
  exclude?: WdyhauOption[];
};

const whereDidYouHear = {
  [WdyhauOptionSet.baselineUS]: [
    WdyhauOption.facebook,
    WdyhauOption.instagram,
    WdyhauOption.pinterest,
    WdyhauOption.podcast,
    WdyhauOption.search,
    WdyhauOption.blogOrReview,
    WdyhauOption.youtube,
    WdyhauOption.directMail,
    WdyhauOption.friendorFamily,
    WdyhauOption.printMagazinePoster,
    WdyhauOption.influencer,
    WdyhauOption.tiktok,
    WdyhauOption.snapchat,
    WdyhauOption.reddit,
    WdyhauOption.helloFresh,
    WdyhauOption.amexrewards,
    WdyhauOption.cashBackRewards,
    WdyhauOption.coPromotionFromAnotherBrand,
    WdyhauOption.discountAndDealSite,
    WdyhauOption.loyaltyAndPointsProgram,
    WdyhauOption.doctor,
    WdyhauOption.zumba,
  ],
  [WdyhauOptionSet.baselineNonUS]: [
    WdyhauOption.facebook,
    WdyhauOption.instagram,
    WdyhauOption.pinterest,
    WdyhauOption.podcast,
    WdyhauOption.search,
    WdyhauOption.blogOrReview,
    WdyhauOption.youtube,
    WdyhauOption.directMail,
    WdyhauOption.friendorFamily,
    WdyhauOption.printMagazinePoster,
    WdyhauOption.newsPaperOrMagazine,
    WdyhauOption.posterOrBillboard,
    WdyhauOption.influencer,
    WdyhauOption.tiktok,
    WdyhauOption.snapchat,
    WdyhauOption.reddit,
    WdyhauOption.helloFresh,
    WdyhauOption.coPromotionFromAnotherBrand,
    WdyhauOption.discountAndDealSite,
    WdyhauOption.loyaltyAndPointsProgram,
    WdyhauOption.zumba,
  ],
};

const whereDidYouHearRadio = {
  [WdyhauOptionSet.baselineUS]: [
    WdyhauOption.publicRadio,
    WdyhauOption.siriusXM,
    WdyhauOption.otherRadio,
    WdyhauOption.streamingRadio,
  ],
  [WdyhauOptionSet.baselineNonUS]: [WdyhauOption.radio],
  [WdyhauOptionSet.simpleRadio]: [WdyhauOption.radio],
  [WdyhauOptionSet.simpleAudio]: [WdyhauOption.audio],
};

const whereDidYouHearTV = {
  [WdyhauOptionSet.baselineUS]: [WdyhauOption.tv, WdyhauOption.onlineTV],
  [WdyhauOptionSet.baselineNonUS]: [WdyhauOption.tv, WdyhauOption.onlineTV],
};

let wdyhauOptionMemo: WdyhauOption[];

export function getWdyhauOptionProps() {
  const optionsProps: WdyhauOptionProps = {
    set: isUS() ? WdyhauOptionSet.baselineUS : WdyhauOptionSet.baselineNonUS,
    radioSet: isUS()
      ? WdyhauOptionSet.baselineUS
      : WdyhauOptionSet.baselineNonUS,
    tvSet: WdyhauOptionSet.baselineUS,
    exclude: [],
  };

  if (!isUS()) {
    if (!isAU()) {
      optionsProps.exclude.push(WdyhauOption.helloFresh);
    }

    if (isUK()) {
      optionsProps.exclude.push(WdyhauOption.printMagazinePoster);
    } else {
      optionsProps.exclude.push(WdyhauOption.newsPaperOrMagazine);
      optionsProps.exclude.push(WdyhauOption.posterOrBillboard);
    }
  }

  return optionsProps;
}

export function getWdyhauOptions({
  set,
  radioSet,
  tvSet,
  exclude,
}: WdyhauOptionProps) {
  if (!wdyhauOptionMemo) {
    wdyhauOptionMemo = spliceRandomly(
      shuffleArray(whereDidYouHear[set ?? WdyhauOptionSet.baselineUS]),
      shuffleArray(
        whereDidYouHearRadio[radioSet ?? set ?? WdyhauOptionSet.baselineUS]
      ),
      shuffleArray(
        whereDidYouHearTV[tvSet ?? set ?? WdyhauOptionSet.baselineUS]
      )
    );
    wdyhauOptionMemo.push(WdyhauOption.other);
    if (exclude)
      wdyhauOptionMemo = wdyhauOptionMemo.filter((x) => !exclude.includes(x));
  }
  return wdyhauOptionMemo;
}

export function getWdyhauSurveyOptions() {
  return getSurveyOptionsWithTranslationKeys(
    "whereDidYouHear",
    getWdyhauOptions(getWdyhauOptionProps())
  );
}

export function getWdyhauSurveyAnswersFromKey(
  key: WdyhauOption
): SurveyAnswersState {
  // We need the actual text value of the option here so remove the first two characters (t:)
  // from the i18n key and get the value
  let wdyhAnswer;
  wdyhAnswer = getTranslatedStringFromTKey(getWdyhauSurveyOptions()[key]);
  if (!wdyhAnswer) {
    wdyhAnswer = key;
  }

  // Add value as answer for whereDidYouHear for legacy and backcompat purposes and store key in new field whereDidYouHearKey
  return { whereDidYouHear: [wdyhAnswer], whereDidYouHearKey: [key] };
}
