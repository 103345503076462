import React, {
  ChangeEvent,
  useEffect,
  useRef,
  InputHTMLAttributes,
} from "react";
import INTL_TEL_INPUT from "intl-tel-input/build/js/intlTelInput.min.js";
import "intl-tel-input/build/js/utils.js";
import "intl-tel-input/build/css/intlTelInput.min.css";
import { CountryCode, getCountryCode } from "src/utils/meristemContext";
import { handleFocusBehindBanner } from "src/utils/payment";
import { css, Global } from "@emotion/react";

export function isValidNumber(number: string, countryCode: string) {
  return (window as any)?.intlTelInputUtils.isValidNumber(number, countryCode);
}

export type IntlTelInputProps = {
  id: string;
  className?: string;
  placeholder: string;
  onPhoneNumberUpdated: ({
    phoneNumber,
    phoneNumberCountryCode,
  }: {
    phoneNumber: string;
    phoneNumberCountryCode: string;
  }) => void;
  countryCode?: CountryCode;
  countryCodeDisabled?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const IntlTelInput = ({
  id,
  className,
  placeholder,
  onPhoneNumberUpdated,
  countryCode = getCountryCode(),
  countryCodeDisabled = false,
  ...inputProps
}: IntlTelInputProps) => {
  const inputRef = useRef(null);
  useEffect(() => {
    const intlTelInput = INTL_TEL_INPUT(inputRef.current, {
      preferredCountries: ["us", "ca", "gb"],
      initialCountry: "us",
      autoPlaceholder: countryCode === "US" ? "off" : "aggressive",
      allowDropdown: !countryCodeDisabled,
      showFlags: !countryCodeDisabled,
    });
    try {
      intlTelInput.setCountry(countryCode);
    } catch (error) {
      /* NOP */
    }

    return function cleanup() {
      intlTelInput.destroy();
    };
  }, [countryCode, countryCodeDisabled, id]);

  function changeHandler(e: ChangeEvent<HTMLInputElement>) {
    const instance = (window as any).intlTelInputGlobals.getInstance(
      inputRef.current
    );
    const country = instance.getSelectedCountryData().iso2;
    onPhoneNumberUpdated({
      phoneNumber: e.target.value,
      phoneNumberCountryCode: country,
    });
  }

  return (
    <>
      <Global
        styles={
          countryCodeDisabled
            ? css`
                .iti__flag-container {
                  display: none;
                }
              `
            : null
        }
      />
      <input
        id={id}
        className={className}
        ref={inputRef}
        type="tel"
        placeholder={placeholder}
        onChange={changeHandler}
        onFocus={handleFocusBehindBanner}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
      />
    </>
  );
};

export default IntlTelInput;
