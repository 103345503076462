import React from "react";
import { FlexFrame, MainColumn } from "src/components/core/BodyLayout";
import { useOnce } from "src/hooks/lifecycle";
import { useAppDispatch } from "src/hooks/redux";
import i18n from "src/locales";
import { trackEvent } from "src/utils/api/tracker";
import { changeUser, hasValidAccount } from "src/utils/redux/slices/userData";
import {
  createForwardParams,
  getURLParams,
  updateForwardedParams,
} from "src/utils/urlParams";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "src/utils/noomSessionStorage";
import { SessionStorageKey } from "src/utils/constants";

import Footer from "@components/footer/FooterPayment";
import LoggedInHeader from "@components/header/LoggedInHeader";

import AccountSignup from "./AccountSignup";
import { Body, Title } from "./Account";
import WelcomeBack from "./WelcomeBack";
import goto from "src/pageDefinitions/goto";
import { useAsyncError } from "src/hooks/error";
import { useWelcomeBackData } from "src/hooks/user/welcome-back";
import { getRequestMetadata } from "src/utils/meristemContext";
import { isFromCareFirstEmailBlast } from "src/utils/userSegment/features";

/**
 * When a user is given a link in an email, that link will contain a `nuid` parameter that
 * identifies the user. However, users may try to share that email link with others, so we want to
 * first verify that they are indeed the intended user before letting them continue.
 * If they say they are not, this will return them to landing.
 */
export function VerifyUser({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const asyncError = useAsyncError();

  // Data
  const userIdInParams = getURLParams()?.nuid;

  // Fetch user data from nuid in query params (if present)
  // and use for display on WelcomeBack component.
  const { data: welcomeBackData, isLoading } =
    useWelcomeBackData(userIdInParams);

  // Confirm if we know enough about the user, otherwise, we'll prompt them for more info.
  const isValidAccount = hasValidAccount(welcomeBackData);

  // Skip prompt if the user ID in the URL query params matches the one in the cookie
  const { showWelcomeBack } = getRequestMetadata().userState;
  const alreadyConfirmedIdentity = getNoomSessionStorage(
    SessionStorageKey.userConfirmedIdentity
  );

  const stripNuidParamAndRefresh = () => {
    // should already be removed from params -- we just need to update location.search with the current state
    const params = createForwardParams();
    window.location.search = params.toString();
  };

  const goToLanding = () => {
    if (isFromCareFirstEmailBlast()) {
      goto.bail("mainSurvey", [], "VerifyUserReject");
    } else {
      goto.bail("landing", [], "VerifyUserReject");
    }
  };

  const onUserIdentityConfirmed = async () => {
    try {
      await dispatch(changeUser(userIdInParams));
      setNoomSessionStorage(SessionStorageKey.userConfirmedIdentity, true);
      stripNuidParamAndRefresh();
    } catch (err) {
      trackEvent("FailedToChangeUser");
      asyncError(err);
    }
  };

  useOnce(() => {
    const trackAndCheckSkip = async () => {
      if (showWelcomeBack) {
        await trackEvent("OnVerifyUser", {
          isValidAccount,
          requestedUserId: userIdInParams,
          alreadyConfirmedIdentity,
        });
      }
      // REMOVE NUID AT NEXT NAVIGATION
      // Remove nuid from URL to not trigger welcome-back screen down the funnel
      // (e.g. because of user merging occuring and nuid in URL differing from the one in cookies).
      // Note: If next navigation is a replace and occurs quickly it might not remove the nuid.
      updateForwardedParams({ nuid: null });
    };

    trackAndCheckSkip().catch(asyncError);
  });

  if (showWelcomeBack && !alreadyConfirmedIdentity) {
    let content;
    if (isValidAccount) {
      // Only show "Welcome back" screen when the user is visiting from
      // an email campaign link. This is to ensure the user confirms that
      // the link they're visiting is specifically designed for that user.
      content = (
        <WelcomeBack
          welcomeBackData={welcomeBackData}
          onAccept={onUserIdentityConfirmed}
          onReject={goToLanding}
        />
      );
    } else {
      // No userId or user data associated with account.
      // Prompt user for name and email to continue.
      // This use case can happen if CS gives an anonymous user a direct link to a plan,
      // or if an internal user clicks on any link protected by <AccountRequired> for testing
      content = <AccountSignup onComplete={() => stripNuidParamAndRefresh()} />;
    }

    return (
      <FlexFrame>
        <LoggedInHeader />
        <MainColumn margin={24} maxWidth={600} data-cy="verify-user">
          <Body>
            {isLoading ? (
              <Title>{i18n.t("accountSignup:loading")}</Title>
            ) : (
              content
            )}
          </Body>
          <Footer />
        </MainColumn>
      </FlexFrame>
    );
  }

  // Transparent passthrough if no prompt is needed or can be skipped
  return <>{children}</>;
}
