import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface KudosState {
  supporterName?: string;
}

export const initialState: KudosState = {
  supporterName: null,
};

const kudosSlice = createSlice({
  name: "kudos",
  initialState,
  reducers: {
    updateKudosState(state, action: PayloadAction<Partial<KudosState>>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateKudosState } = kudosSlice.actions;

export default kudosSlice;
