/* eslint-disable no-restricted-globals */
import {
  getAndroidMessageHandlers,
  getIOSMessageHandlers,
} from "src/utils/in-app/messageHandlers";
import getStore from "src/utils/redux/store";
import goto from ".";
import { globalPush, reroll } from "../components/PageSetRouter";
import { getActivePage } from "../pageSets";

export function pageSetPushReroll(path: string) {
  const state = getStore().getState();
  const { activePageSet, activePage } = getActivePage(path, state);
  if (activePage) {
    goto.pageSet(activePageSet.id, path);
    return;
  }

  reroll(path);
}

export function pushReroll(url: string, check: () => boolean) {
  if (check()) {
    globalPush(url);
  } else {
    reroll(url);
  }
}
export function setParam(
  queryParams: URLSearchParams,
  name: string,
  value?: string | number | boolean
) {
  if (value || value === 0) {
    queryParams.set(name, `${value}`);
  }
}

export function isInsideIOSWebKit(): boolean {
  return !!getIOSMessageHandlers();
}

export function isInsideAndroidWebView(): boolean {
  return !!getAndroidMessageHandlers();
}

export function isInsideWebView() {
  return isInsideIOSWebKit() || isInsideAndroidWebView();
}
