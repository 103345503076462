import React from "react";
import i18n from "@i18n";
import { hasPromoCodeAvailable } from "@utils/promo";
import {
  isUS,
  isMantle,
  isWinback,
  isInApp,
  isInAppWinback,
} from "@utils/userSegment";
import Button from "src/design-system/components/Button/Button";
import { getCountryCode } from "src/utils/meristemContext";
import styled from "@emotion/styled";

type Props = {
  trialFeeWaived?: boolean;
  onClick: () => void;
};

const Sizer = styled.div`
  max-width: 400px;
  margin: 30px auto 30px;
`;

export function PlansCTA(props: Props) {
  function getCTAText() {
    if (hasPromoCodeAvailable()) {
      return i18n.t("plans:startTrial");
    }
    if (isUS() && (isMantle() || isWinback())) {
      return i18n.t("plans:continue");
    }

    if (isInApp() && !isInAppWinback()) {
      return props.trialFeeWaived
        ? i18n.t("plans:startTrial")
        : i18n.t("plans:continue");
    }

    switch (getCountryCode()) {
      case "US":
        return i18n.t("plans:claimPlan");
      default:
        return i18n.t("plans:continue");
    }
  }

  return (
    <Sizer>
      <Button onClick={props.onClick} data-cy="choose-plan-cta">
        {getCTAText()}
      </Button>
    </Sizer>
  );
}
