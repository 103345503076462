import zipState from "zip-state";

import {
  MHMDA_STATES,
  NOOM_MED_ADDON_SUBSCRIPTION_PRODUCT_NAME,
  routeConstants,
} from "@utils/constants";
import { StatesLabsNotIncluded } from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";
import { getCourseDuration } from "@utils/calculateGoals";
import { planDurationMonthsToTelehealthNoomPlanId } from "@utils/plansMapping";
import {
  DE_COURSE_PACK_ELIGIBLE_COUNTRIES,
  EN_COURSE_PACK_ELIGIBLE_COUNTRIES,
} from "@utils/userSegment/constants";
import { queryClient } from "@utils/fetch";
import { getMeristemContext, getSubdivision } from "@utils/meristemContext";

import { appConfig } from "src/config";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import {
  FeatureFlags,
  getFeatureFlagValue,
} from "src/pageDefinitions/featureFlags";
import { getPageSet } from "src/pageDefinitions/pageSets";
import { TELEHEALTH_FREE_PLAN_ID } from "src/funnels/payment-en/pages/telehealthRedirects";
import {
  HEALTHY_WEIGHT_CURRICULUM_PREFIX,
  noomMedOfferLayer,
  noomPremiumFitonSurveyLayer,
  noomPremiumSurveyLayer,
  noomSummitSurveyLayer,
  noomWeightMedBranchingLayer,
} from "src/pageDefinitions/goto/constants";
import {
  selectAMCoursepack,
  selectGlp1Coursepack,
  selectMovementCoursepack,
  selectMWCoursepack,
  selectSleepCoursepack,
} from "src/utils/redux/slices/paymentAddon";
import { canUseAndroidShare, canUseNavigatorShare } from "src/hooks/sharingApi";
import { isHolidayPromotion } from "src/utils/holidayPromo";

import getStore, { CoreReduxState } from "../redux/store";
import {
  isEmailWinback,
  isFromEmail,
  isFromNoomWeightEmail,
  isUSEmail,
} from "./email";
import { checkSegments, createOneTimeConditionSegment } from "./util";
import { getTrackingParams } from "../services/VisitTracker";
import { canRetrieveWeight } from "../refactoredSurvey";
import { loadWinbackInfo } from "../redux/slices/winbackInfo";
import {
  isAppAndroid,
  isAppAndroidWinback,
  isAppFreemiumUpsell,
  isAppIos,
  isAppIosBase,
  isAppIOSFreemiumUpsell,
  isAppIosWinback,
} from "./in-app";
import { isMealPlan, isMealWorkoutBundle, isWorkoutPlan } from "../addons";
import { isInExperimentConflictingWithSummitCoursePack } from "../api/addons/addons";
import { getIOSMessageHandlers } from "../in-app/messageHandlers";
import {
  getPromoCodeState,
  isPromoCodePersisted,
} from "../redux/slices/promoCode";
import { loadActiveSubscriptionDataState } from "../redux/slices/activeSubscriptionData";
import { getMeristemConfigValue } from "../meristemConfig";
import { isMultiUserPlan } from "../multiUserPlan";
import {
  GET_PEERS_CACHE_KEY,
  loadPeers,
  MultiUserPlanPeerResponse,
} from "../api/noomTogether";
import { paymentAddonProducts } from "../paymentAddon";
import { isCheckoutOfferTimerExpired } from "../checkoutTimer";
import { isPersonalizedPlanTimerExpired } from "../personalizedPlanTimer";
import { getURLParams } from "../urlParams";
import { hasFallbackPromotionalOfferConfiguration } from "../fallbackPromotionalOffer";
import { getCampaignFromStorage } from "../api/campaign";
import { freeMonthsOfferConfiguration } from "../freeMonthsOffer";
import { getSessionState } from "src/pageDefinitions/session";
import { calculateAge } from "../datetime";
import { ServerContextState } from "../redux/slices/serverContext";
import { isEmail } from "../validate";
import {
  STEP_TRACKING_VIBE_OFFER_ID,
  IDEAL_WEIGHT_VIBE_OFFER_ID,
  shouldSuppressVibeOffer,
} from "../noomVibe";
import {
  isMobile,
  isMantle,
  isAffiliate,
  isAppBaseRoute,
  isCore,
  isCorePaid,
  isWebWinback,
  isEnLanguage,
  isInApp,
  isWinback,
  isEmailMain,
  isUSWinback,
  isCountry,
  isUS,
  isIntl,
  isDeLanguage,
  isCoreDirect,
  isIntlMantle,
  isUSInAppWinback,
  isAffiliateWithoutTrial,
  isDeEmailMain,
  isEsEmailMain,
  isInAppWinback,
  isHM,
  isDE,
  isDPP,
  isAppAndroidBase,
  isReferralsBuyflow,
  isCorePaidWithoutAddWordsSEM,
  isAffiliateWithTrial,
  isReferralsRoute,
  isEsLanguage,
  isAppEmail,
  isPreSignup,
  isStrictCore,
  isInAppEmployerSurvey,
  isEnMantle,
  isUK,
  isBaseline,
  isLegacyAffiliateTrialRoute,
  isCA,
  isAU,
  isEnProgramLocale,
  isCoachReferralsRoute,
  isNZ,
  isMaleGender,
  isFreemiumIdentifiedRoute,
  isFreemiumQuizRoute,
  isSubscriptionInvalid,
  isAdWordsSEM,
  isFreemiumUpsellRoute,
  isFreemiumClinicalRoute,
  isFreemiumWeightRoute,
  isClinicalRoute,
} from ".";
import {
  isMedTierPlanId,
  isPremiumTierPlanId,
  isEligibleFor30DayTrial,
} from "@utils/plans";
import { hasAnsweredToGLP1FollowUpInCurrentSession } from "../glp1";
import {
  OralsMedRolloutUserType,
  setOralsMedRolloutUserType,
} from "../redux/slices/persistentBrowserFlags";
import { loadMedEntitlements } from "../redux/slices/medEntitlements";
import {
  getLoadedSubscriptionStatus,
  loadSubscriptionStatus,
} from "src/hooks/subscriptionStatus";
import {
  getEligibleMedProducts,
  MED_SKU,
  passesMinBMIForMedSwitchingBuyflow,
} from "../medEligibility";
import { getInAppAutoCookie } from "../authCookieParser";

const HW_NON_VIP_BATCH_ID_DEV = 1;
const HW_NON_VIP_BATCH_ID_PROD = 354;
export const DPP_OVERRIDE_PLAN = "dpp-12-month";
export const USD_MONTHLY_PLAN_ID = "option_d_20221201_Ex1084var1email";
export const USD_MONTHLY_PLAN_ID_7_DAY_TRIAL = "option_d_20221222_Ex1084var1";
export const BANDIT_LOCAL_STORAGE_KEY = "mab_key";

const devModeFlagSet =
  new URLSearchParams(window.location.search).get("idm") === "true";

export function isDevMode() {
  return devModeFlagSet;
}

export function isPrerender() {
  // eslint-disable-next-line no-underscore-dangle
  return !!(window as any).__prerender;
}

export function evalResultWithFeatureFlag(
  result: boolean,
  flag: keyof FeatureFlags
) {
  const flagValue = getFeatureFlagValue(flag) as boolean;
  if (flagValue === undefined) {
    return result;
  }
  return flagValue;
}

export const shouldEnrollUser = (state?: CoreReduxState) => {
  return !isInApp(state) && !isAppEmail(state);
};

export const isHMEligible = () => isUS() && isEnLanguage();

export function shouldSuppressIdealWeightVibeOffer() {
  return shouldSuppressVibeOffer(IDEAL_WEIGHT_VIBE_OFFER_ID);
}

export const isIdealWeightVibeOfferEligible = () =>
  isEnLanguage() && !isFreemiumUpsellRoute();

export const shouldShowIdealWeightVibeOffer = () =>
  isIdealWeightVibeOfferEligible() && !shouldSuppressIdealWeightVibeOffer();

const hadPreviousITunesSubscription = () => {
  const urlParams = new URLSearchParams(window.location.search);
  // This parameter is set by the growth backend in the internal app endpoint
  // More details about that here: https://github.com/noom/eng-handbook/blob/master/teams/growth/product/Winbacks.md#more-about-in-app-routes
  return urlParams.get("had_itunes_sub") === "true";
};

const disableLPSurveySegment = checkSegments({
  featureFlagOverride: "disableLPSurvey",
});

export const isSurveyOnLPEligible = checkSegments({
  oneOf: [
    routeConstants.exsf01d,
    routeConstants.exsf01,
    routeConstants.affiliateTrialNPB,
    routeConstants.appEmail,
  ],
  noneOf: [isHM, isInApp, disableLPSurveySegment],
});

export const isBraintreeStripeiOSEligible = checkSegments({
  allOf: [hadPreviousITunesSubscription, routeConstants.app14DayReturning],
});

export const isGooglePlayPlansEligible = () => {
  // TODO (Brandon): enable when in-app is ready for release
  // const hasGooglePlayMessageHandler =
  //   !!getAndroidMessageHandlers()?.buyflowPurchaseProduct ||
  //   ["staging", "development", "local"].includes(appConfig.NOOM_ENV);

  // return isAppAndroidBase() && hasGooglePlayMessageHandler;
  return false;
};

export const isITunesPlansEligible = () => {
  const hasITunesMessageHandler =
    !!getIOSMessageHandlers()?.buyflowPurchaseProduct ||
    ["staging", "development", "local"].includes(appConfig.NOOM_ENV);

  if (isBraintreeStripeiOSEligible()) {
    return false;
  }

  return isAppIos() && hasITunesMessageHandler;
};

export const isITunes1MonthDefaultPlanEligible = () => {
  const hasITunesDefaultPlanMessageHandler =
    !!getIOSMessageHandlers()?.buyflowPurchaseNoDetails ||
    ["staging", "development", "local"].includes(appConfig.NOOM_ENV);

  if (isBraintreeStripeiOSEligible()) {
    return false;
  }

  return isAppIos() && hasITunesDefaultPlanMessageHandler;
};

/**
 * Checks if the user can use the native app in-app purchase flow.
 */
export const isInAppNativePurchaseEligible = checkSegments({
  oneOf: [
    isITunes1MonthDefaultPlanEligible,
    isITunesPlansEligible,
    isGooglePlayPlansEligible,
  ],
  noneOf: [acceptedNoomClinicalOffer],
});

/**
 * Checks if the user can use the web-based in-app purchase flow.
 */
export const isInAppWebPurchaseEligible = checkSegments({
  oneOf: [isAppAndroid, isBraintreeStripeiOSEligible, isFreemiumClinicalRoute],
});

const ffwdEligibleCurrencies = ["USD", "EUR", "GBP", "CAD", "AUD"];
export const isCurrencyFastForwardEligible = () => {
  const { currency = "USD" } = getStore().getState().serverContext;
  return ffwdEligibleCurrencies.includes(currency);
};

const isUSOnlyFastForwardEligibleRoute = checkSegments({
  allOf: [isUS],
  oneOf: [isEmailMain, isEmailWinback, isReferralsRoute],
});

export const hasPromoCodeIntl = checkSegments({
  allOf: [hasPromoCode, isIntl],
});

export const isEx1227NoTrialPlan = (
  { serverContext } = getStore().getState()
) => {
  return !!serverContext.noom_plan_id?.includes("ex1227");
};

export const isFastForwardEligible = checkSegments({
  allOf: [isEnLanguage, isUS],
  noneOf: [isTelehealthPlan, userHasPurchasedNoomClinical, isEx1227NoTrialPlan],
  oneOf: [
    isCore,
    routeConstants.winback,
    isAppAndroidBase,
    isAffiliateWithTrial,
    isUSOnlyFastForwardEligibleRoute,
  ],
});

const isNoomPremiumEligibleUS = checkSegments({
  allOf: [isUS, isEnLanguage],
  oneOf: [
    isMantle,
    isWebWinback,
    routeConstants.emailMain,
    routeConstants.app14DayFtWeb,
  ],
  noneOf: [isCoachReferralsRoute],
});

export const isAffiliateWithLongTrial = () => {
  return isEligibleFor30DayTrial();
};

export const isPremiumTextCoachingSubscriptionEligible = checkSegments({
  allOf: [isUS, isEnProgramLocale, isEnLanguage],
  noneOf: [userHasPurchasedNoomClinical, isAffiliateWithLongTrial],
});

export const isNoomPremiumSubscriptionEligible = checkSegments({
  allOf: [isEnLanguage],
});

export const isFreeSupporterPlanEligible = checkSegments({
  allOf: [isUS, isEnLanguage],
  oneOf: [isCore, isWebWinback, isEmailMain],
});

const isLanguageNoomPremiumEligible = checkSegments({
  oneOf: [isEnLanguage, isDeLanguage, isEsLanguage],
});

const isNoomPremiumEligibleIntl = checkSegments({
  allOf: [isIntl, isLanguageNoomPremiumEligible],
  oneOf: [
    isMantle,
    isWebWinback,
    routeConstants.emailMain,
    routeConstants.freeTrial,
  ],
  noneOf: [isCoachReferralsRoute],
});

export const isInNoomPremiumSurvey = checkSegments({
  allOf: [
    ({ linearBuyflow }) =>
      getPageSet(linearBuyflow.pageSetName)?.layer === noomPremiumSurveyLayer ||
      getPageSet(linearBuyflow.pageSetName)?.layer ===
        noomPremiumFitonSurveyLayer,
  ],
});

export const isAddonStoreEligible = checkSegments({
  allOf: [isUS, isEnLanguage],
  noneOf: [isAppIosBase, isTelehealthPlan, userHasPurchasedNoomClinical],
  oneOf: [
    routeConstants.direct,
    routeConstants.exsf01d,
    routeConstants.exsf01,
    isAffiliateWithoutTrial,
    routeConstants.emailMain,
    isReferralsBuyflow,
    routeConstants.freeTrial,
    isWebWinback,
    isAppAndroidBase,
    routeConstants.affiliateTrialNPB,
    routeConstants.appEmail,
    routeConstants.app14DayFtWeb,
    isCoachReferralsRoute,
  ],
});

export const isWelcomeToNoomEligible = checkSegments({
  oneOf: [isMobile, isMantle, isFromEmail, isWinback, isInAppWinback],
});

export const userHasPurchasedNoomPremium = checkSegments({
  allOf: [(state) => state.addOns.purchasedNoomPremium],
});

export const userHasPurchasedNoomPremiumWithTextCoaching = checkSegments({
  allOf: [(state) => state.addOns.purchasedNoomPremiumWithTextCoaching],
});

export const userHasPurchasedNoomPremiumSubscription = checkSegments({
  allOf: [(state) => state.addOns.purchasedNoomPremiumSubscription],
});

export const isNoomPremiumConsultationSurveyEligible = checkSegments({
  oneOf: [userHasPurchasedNoomPremium, hasBoughtMealAndWorkoutPlan],
});

export const navigateToNoomPremiumConsultationSurvey = checkSegments({
  allOf: [userHasPurchasedNoomPremium],
  oneOf: [
    isMantle,
    routeConstants.affiliateTrialNPB,
    isReferralsBuyflow,
    isEmailWinback,
    isWinback,
    isAppAndroidBase,
  ],
  noneOf: [userHasPurchasedNoomPremiumSubscription],
});

export const shouldNavigateToNoomPremiumFitonConsultationSurvey = checkSegments(
  {
    allOf: [
      userHasPurchasedNoomPremium,
      userHasPurchasedNoomPremiumSubscription,
    ],
    oneOf: [
      isMantle,
      routeConstants.affiliateTrialNPB,
      isReferralsBuyflow,
      isEmailWinback,
      isWinback,
      isAppAndroidBase,
    ],
  }
);

export const isImportantDateEligible = checkSegments({
  allOf: [isEnLanguage],
  oneOf: [isMantle],
});

export const isFivePercentEligible = checkSegments({
  allOf: [isUS],
  oneOf: [isMantle],
});

/**
 * Implements the logic for targeting users for dynamic plan graph(ex417)
 */
export const isDynamicPlanGraphEligible = checkSegments({
  oneOf: [
    isMantle,
    isWinback,
    isUSEmail,
    isDeEmailMain,
    isInAppWinback,
    isUSInAppWinback,
  ],
  allOf: [canRetrieveWeight],
});

export const isFinalGraphEligible = checkSegments({
  oneOf: [isMantle, isUSWinback, isUSEmail, isUSInAppWinback],
});

export const isShowingPaymentGraphExtraContent = checkSegments({
  oneOf: [isMantle, isUSWinback, isInAppWinback],
});

// TODO: Remove isDeLanguage once Noom Premium and Addons can successfully charge SEPA
// https://noomhq.atlassian.net/browse/GT-1256
export const isSEPAEligible = checkSegments({
  allOf: [isDE, isDeLanguage],
});

/**
 * Verifies if the current user should see the final update graph
 */
export const shouldShowFinalUpdateGraph = (state = getStore().getState()) => {
  const surveyAnswers = getSurveyAnswers(state);
  const { weight, idealWeight, weightKg, idealWeightKg } = surveyAnswers;
  const courseDuration = getCourseDuration(surveyAnswers);

  // EnUSBaseline + App14DayBase routes
  if (
    weight &&
    idealWeight &&
    weightKg &&
    idealWeightKg &&
    isFivePercentEligible() &&
    courseDuration <= 8
  ) {
    return true;
  }

  return shouldCondenseOrShowFinalUpdateGraph(courseDuration);
};

function shouldCondenseOrShowFinalUpdateGraph(courseDuration: number) {
  const isCourseDurationEligible =
    courseDuration >= 2 &&
    ((courseDuration <= 8 && isIntlMantle()) || courseDuration <= 6);
  return (
    canRetrieveWeight() && isFinalGraphEligible() && isCourseDurationEligible
  );
}

export const hasNoTrial = checkSegments({
  oneOf: [
    routeConstants.affiliateNoTrial,
    routeConstants.affiliateNoTrialPromo,
    routeConstants.ndpp,
    isGooglePlayPlansEligible,
  ],
});

export const isDirectTraffic = checkSegments({
  oneOf: [
    routeConstants.direct,
    routeConstants.appUpsell,
    routeConstants.hmDirect,
  ],
});

export const isTrialFeeWaived = checkSegments({
  oneOf: [
    isInAppWinback,
    isAppIosBase,
    isAppIOSFreemiumUpsell,
    isReferralsBuyflow,
    routeConstants.freeTrial,
    routeConstants.hmReferrals,
    routeConstants.affiliate,
    routeConstants.phillips,
    routeConstants.humana,
  ],
});

export const isChooseTrialPriceNewStyleEligible = checkSegments({
  oneOf: [isMantle, isEmailMain, isHM, isInApp],
});

export const isTrialEligible = (
  { recommendedPlan } = getStore().getState()
) => {
  return recommendedPlan.has_trial && !hasNoTrial();
};

export function showChooseTrialPage(state = getStore().getState()) {
  if (isEnLanguage()) {
    return (
      !isTrialFeeWaived() &&
      !hasNoTrial() &&
      !shouldShowEx1227() &&
      // Note (Vitza): As the content of this file is quite logically put
      // together by domains, it would need a considerable reorder to have this
      // method be defined above this line.
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      !showMedPaymentBreakdown() &&
      !isEligibleForCoachReferralBuyflow()
    );
  }
  return (
    isCoreDirect(state) ||
    isCorePaid(state) ||
    isDeEmailMain() ||
    isEsEmailMain() ||
    isHM()
  );
}

export const isPromoCodeBoxEnabled = checkSegments({
  noneOf: [
    isInApp,
    isCorePaidWithoutAddWordsSEM,
    isWinback,
    routeConstants.affiliateNoTrial,
  ],
  featureFlagOverride: "togglePromoCode",
});

export const shouldValidateTokenFlag = checkSegments({
  oneOf: [routeConstants.appUpsell],
});

export const shouldFireMedPixel = checkSegments({
  oneOf: [routeConstants.clinical],
});

export const shouldShowOnscreenBackButtons = checkSegments({
  oneOf: [isInApp],
});

export const isPaidTraffic = checkSegments({
  oneOf: [
    isCorePaid,
    routeConstants.experiment,
    routeConstants.affiliateTrialNPB,
    routeConstants.hmPaid,
    routeConstants.hmExsf01,
    routeConstants.hmExsf01d,
    routeConstants.hmAwsem,
    routeConstants.winback,
    routeConstants.hmWinback,
  ],
});

export const shouldShowExplainerLoadingPage = checkSegments({
  oneOf: [isMantle, isWinback],
});

export const shouldUseMobileOptimizedControls = checkSegments({
  oneOf: [isMantle, isWinback, routeConstants.hmPaid, routeConstants.hmDirect],
});

const searchActiveRenewingOrPendingAddonSubscription = (
  serverContext: ServerContextState,
  expectedProductType?: string
) => {
  return (serverContext?.addon_subscriptions || []).find(
    (addOnSubscription) => {
      return (
        addOnSubscription &&
        (!expectedProductType ||
          addOnSubscription.product === expectedProductType) &&
        ((addOnSubscription.status === "ACTIVE" &&
          addOnSubscription.auto_renewal_enabled) ||
          addOnSubscription.status === "PENDING")
      );
    }
  );
};

export const hasActiveMedAddOnSubscription = () => {
  return searchActiveRenewingOrPendingAddonSubscription(
    getStore().getState().serverContext,
    NOOM_MED_ADDON_SUBSCRIPTION_PRODUCT_NAME
  );
};

export const hasActiveAddOnSubscription = (
  serverContext: ServerContextState
) => {
  return searchActiveRenewingOrPendingAddonSubscription(serverContext);
};

export const shouldShowCounterOfferAddonDisclaimerInNoomWeightCounterOffer = (
  serverContext: ServerContextState
) => {
  return isEnLanguage() && isUS() && hasActiveAddOnSubscription(serverContext);
};

export const isEligibleForNX1CourseEnhancements = checkSegments({
  allOf: [isEnLanguage, shouldShowCoursePackCheckoutOffers],
  noneOf: [isUK],
});

export const isEligibleForFreeAgingMetabolismOffer = checkSegments({
  allOf: [isEligibleForNX1CourseEnhancements, isIntl],
});

export const isEligibleForFreemiumWeightBuyflow = checkSegments({
  allOf: [isFreemiumWeightRoute],
});

export const isBMINoomClinicalEligible = () => {
  return (
    getEligibleMedProducts({
      checkBMI: true,
      checkHealthRisks: false,
      checkState: false,
    }).length > 0
  );
};

export const isCurrentHealthRiskNoomClinicalEligible = () => {
  return (
    getEligibleMedProducts({
      checkBMI: false,
      checkHealthRisks: true,
      checkState: false,
    }).length > 0
  );
};

export const isEligibleForMedAfterHealthRiskQuestion = () => {
  return (
    getEligibleMedProducts({
      checkBMI: true,
      checkHealthRisks: true,
      checkState: false,
    }).length > 0
  );
};

export const isSixtyFiveOrOver = (
  { surveyAnswers } = getStore().getState()
) => {
  const { dateOfBirth } = surveyAnswers;
  const birthDate = new Date(dateOfBirth);
  const age = calculateAge(birthDate);

  return age >= 65;
};

export const isAgeEligibleForMed = (
  { surveyAnswers } = getStore().getState()
) => {
  const { dateOfBirth } = surveyAnswers;
  const birthDate = new Date(dateOfBirth);
  const age = calculateAge(birthDate);

  return age >= 18 && age < 80;
};

// Note(James): Any answer to this question will result in the module, so the
// eligibility is really "anyone who can see the question" - but leaving this segment to be explicit
export const willReceiveWomensWeightLossModule = (
  { surveyAnswers } = getStore().getState()
) => {
  const { menopauseStatus } = surveyAnswers;
  return [
    "preMenopause",
    "perimenopause",
    "menopause",
    "postMenopause",
    "preferNotToSay",
  ].includes(menopauseStatus?.[0]);
};

export const willReceiveMenstrualCycleModule = (
  { surveyAnswers } = getStore().getState()
) => {
  const { menstrualCycle } = surveyAnswers;
  return ["yes", "preferNotToSay"].includes(menstrualCycle?.[0]);
};

export const willReceiveSomeWomensHealthModule = checkSegments({
  oneOf: [willReceiveWomensWeightLossModule, willReceiveMenstrualCycleModule],
});

export const isMedLead = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get("utm_source");
  return utmSource === "MedDrip";
};

export const noomClinicalOptedIn = (
  { surveyAnswers } = getStore().getState()
) => {
  const { medOptIn } = surveyAnswers;
  // Note (zhuowei): For med drip users, always assume that they have opted in to med
  return medOptIn?.[0] === "yes" || isMedLead();
};

export const isZipcodeLabsExcluded = (
  { surveyAnswers } = getStore().getState()
) => {
  const { zipcode } = surveyAnswers;
  if (zipcode !== undefined) {
    const state = zipState(zipcode);
    return state in StatesLabsNotIncluded;
  }
  return false;
};

export const isMedicareStatusNoomClinicalEligible = (
  { surveyAnswers } = getStore().getState()
) => {
  return !surveyAnswers.medicare?.includes("yes");
};

export const isGlp1StatusNoomClinicalEligible = (
  { surveyAnswers } = getStore().getState()
) => {
  return surveyAnswers.glp1?.includes("no");
};

export const isAgeRangeStatusNoomClinicalEligible = (
  { surveyAnswers } = getStore().getState()
) => {
  return !surveyAnswers.ageRange?.includes("80s+");
};

export const isPregnantStatusNoomClinicalEligible = (
  { surveyAnswers } = getStore().getState()
) => {
  return !surveyAnswers.pregnant?.includes("noButNursing");
};

export const isMedicareStatusNoomClinicalEligibleAtCheckout = () => {
  return isMedicareStatusNoomClinicalEligible() || hasSelectedCompoundedPlan();
};

export const shouldSeeGlp1Companion = (state = getStore().getState()) => {
  const { surveyAnswers } = state;
  const glp1Answer = surveyAnswers?.glp1?.[0];
  const answeredToGLP1Question =
    hasAnsweredToGLP1FollowUpInCurrentSession("no");
  return (
    answeredToGLP1Question ||
    (!answeredToGLP1Question &&
      (glp1Answer === "yes" || glp1Answer === "prescription"))
  );
};

export const isEligibleForClinicalBuyflow = checkSegments({
  allOf: [isClinicalRoute, isUS, isEnLanguage],
});

export const isUserNoomClinicalEligibleAtCheckout = checkSegments({
  allOf: [
    isEligibleForClinicalBuyflow,
    isBMINoomClinicalEligible,
    isMedicareStatusNoomClinicalEligibleAtCheckout,
    isCurrentHealthRiskNoomClinicalEligible,
  ],
});

export const shouldShowNoomClinicalCheckoutOffer = (
  state = getStore().getState()
): boolean => {
  const { promoCode, recommendedPlan } = state;
  return (
    isUserNoomClinicalEligibleAtCheckout(state) &&
    !promoCode.promoCodeIsVip &&
    recommendedPlan.has_trial &&
    noomClinicalOptedIn(state)
  );
};

export const isInAppMedUpgrade = checkSegments({
  allOf: [
    ({ linearBuyflow }) =>
      getPageSet(linearBuyflow.pageSetName)?.layer === noomMedOfferLayer,
  ],
});

export const isDisabledNWSwitchForMed = () => {
  return (
    isInAppMedUpgrade() ||
    isAppFreemiumUpsell() ||
    getFeatureFlagValue("disableNWSwitchForMed")
  );
};

export const isUserSegmentEligibleForMedSwitchBuyflow = checkSegments({
  allOf: [isUS, isEnLanguage],
  oneOf: [
    isCore,
    isReferralsRoute,
    isAppAndroidBase,
    isAppAndroidWinback,
    isAffiliate,
    shouldShowTelex229,
  ],
});

export const isEligibleForMedSwitchBuyflow = checkSegments({
  allOf: [
    isUserSegmentEligibleForMedSwitchBuyflow,
    passesMinBMIForMedSwitchingBuyflow,
    isGlp1StatusNoomClinicalEligible,
    isAgeRangeStatusNoomClinicalEligible,
    isPregnantStatusNoomClinicalEligible,
  ],
});

export const isNoomWeightMedBranching = checkSegments({
  allOf: [
    ({ linearBuyflow }) =>
      getPageSet(linearBuyflow.pageSetName)?.layer ===
      noomWeightMedBranchingLayer,
  ],
});

export const isWeightToMedRedirect = () => {
  const { isWeightToMedSwitch } = getSessionState("browser");
  return Boolean(isWeightToMedSwitch);
};

export const disableAccountFieldsForMedFlow = () => {
  const { disableAccountFieldsForMed } = getSessionState("browser");
  return Boolean(disableAccountFieldsForMed) && isClinicalRoute();
};

export const showMedPaymentBreakdown = (state = getStore().getState()) => {
  return shouldShowNoomClinicalCheckoutOffer(state) && isClinicalRoute();
};

export function isTelehealthPlan(state = getStore().getState()): boolean {
  const noomPlanId = state?.recommendedPlan?.noom_plan_id;
  const paidTelehealthPlans = Object.values(
    planDurationMonthsToTelehealthNoomPlanId
  );
  return (
    paidTelehealthPlans?.includes(noomPlanId) ||
    noomPlanId === TELEHEALTH_FREE_PLAN_ID
  );
}

export function isRecommendedPlanMed(state = getStore().getState()): boolean {
  const noomPlanId = state?.recommendedPlan?.braintree_id;
  return isMedTierPlanId(noomPlanId);
}

export function userHasPurchasedNoomClinical(state = getStore().getState()) {
  const { purchasedNoomClinical, userData } = state;

  // TODO: Move to using a more reliable way like an API instead of using
  // this userdata flag to determine who purchased Noom Med
  return purchasedNoomClinical || userData.purchasedNoomClinical;
}

const TELEHEALTH_CONTEXT_TYPES = ["telehealth", "telehealth-followup"];

export function isTelehealth() {
  return TELEHEALTH_CONTEXT_TYPES.includes(getMeristemContext().context_type);
}

/**
 * Signals if the current user/experience is subject to HIPPA data protection rules.
 * This can be used to filter logging/etc as appropriate to ensure compliance.
 */
export const isHipaa = (): boolean => {
  return isTelehealth();
};

export const isHmSuperswitchEligible = (): boolean => {
  const { activeSubscriptionData } = getStore().getState();
  return (
    isEnLanguage() &&
    activeSubscriptionData.hasActiveSubscription &&
    activeSubscriptionData.activeSubscriptionCurriculumSlug &&
    activeSubscriptionData.activeSubscriptionCurriculumSlug.endsWith("en_US") &&
    !isMultiUserPlan(activeSubscriptionData?.planId) &&
    ((activeSubscriptionData.batchId === HW_NON_VIP_BATCH_ID_PROD &&
      appConfig.NOOM_ENV === "production") ||
      activeSubscriptionData.batchId === HW_NON_VIP_BATCH_ID_DEV)
  );
};

isHmSuperswitchEligible.load = () => {
  const store = getStore();
  const { email } = store.getState().userData;
  if (email) {
    return getStore()
      .dispatch(loadActiveSubscriptionDataState({ email }))
      .then(() => {});
  }
  return Promise.resolve();
};

export const isGlp1OrMed = (): boolean => {
  const { medEntitlements } = getStore().getState();
  return (
    medEntitlements.hasTelehealthAddOn ||
    medEntitlements.hasGlp1CoursePack ||
    medEntitlements.hasMedCompanion
  );
};

isGlp1OrMed.load = () => {
  return getStore()
    .dispatch(loadMedEntitlements({}))
    .then(() => {});
};

export const userHasAccessCode = (): boolean => {
  return !!getStore().getState().userData.access_code;
};

export const isInHouseSharePageEligible = checkSegments({
  allOf: [isEnLanguage, isUS, userHasAccessCode],
});

export const isEligibleForNoomTogether = checkSegments({
  allOf: [isEnLanguage, userHasAccessCode],
});

isEligibleForNoomTogether.load = async () => {
  await getStore().dispatch(loadActiveSubscriptionDataState({}));
};

export const hasFamilyPlanEligibleSubscription = () => {
  const { isIAPsubscription, hasActiveSubscription, planId } =
    getStore().getState()?.activeSubscriptionData;
  const peers =
    queryClient.getQueryData<MultiUserPlanPeerResponse[]>(
      GET_PEERS_CACHE_KEY
    ) || [];

  return (
    hasActiveSubscription &&
    !isIAPsubscription &&
    !isPremiumTierPlanId(planId) &&
    !isMultiUserPlan(planId) &&
    !userHasPurchasedNoomClinical() &&
    peers.length === 0
  );
};

hasFamilyPlanEligibleSubscription.load = () => {
  return Promise.all([
    getStore().dispatch(loadActiveSubscriptionDataState({})),
    loadPeers(),
  ]);
};

const isNotEnrolledInExR143 = (state: CoreReduxState) => {
  const { userData } = state;
  return !userData?.usedBaselinePriceForMultiUserPlan;
};

export const isFamilyPlanSurveyEligible = checkSegments({
  allOf: [
    isUS,
    isEnLanguage,
    hasFamilyPlanEligibleSubscription,
    isNotEnrolledInExR143,
  ],
});

export const isEligibleForExitIntentModalEmail = () => {
  return isIntl() && isEmailMain() && getTrackingParams().utm_source === "drip";
};

export function hasPromoCode(state?: CoreReduxState) {
  const promoCode = getPromoCodeState(state);
  return !!promoCode?.promoCodeApplied;
}

export function hasVipPromoCode(state?: CoreReduxState) {
  const promoCode = getPromoCodeState(state);

  return hasPromoCode() && promoCode?.promoCodeIsVip;
}

export const isTaxExclusiveCountry = () => isCountry(["US", "CA"]);

export const isNoomPremiumConsultationSurveyDisqualified = (
  state = getStore().getState()
) => {
  const { surveyAnswers } = state;
  return (
    surveyAnswers?.workoutPlanLimitations?.includes("painOrLosingBreath") ||
    surveyAnswers?.fitonWorkoutPlanLimitations?.includes("painOrLosingBreath")
  );
};

export const isNoomPremiumEligible = () => {
  const promoCode = getPromoCodeState();
  const isNoomPremiumEligibleBySegments =
    isNoomPremiumEligibleUS() || isNoomPremiumEligibleIntl();

  return !promoCode?.promoCodeIsVip && isNoomPremiumEligibleBySegments;
};

export const requiresDoubleOptInForEmail = () => {
  return isCountry(["DE", "AT"]);
};

export const isWinbackUserAndNotReup = () => {
  const { winbackInfo } = getStore().getState();

  return (
    ["TRIAL", "ADVANCED"].includes(winbackInfo.winbackStatus) &&
    !winbackInfo.willReup
  );
};

isWinbackUserAndNotReup.load = () =>
  getStore()
    .dispatch(loadWinbackInfo())
    .then(() => {});

export const isWinbackUser = () => {
  const { winbackInfo } = getStore().getState();

  return ["TRIAL", "ADVANCED"].includes(winbackInfo.winbackStatus);
};

isWinbackUser.load = () =>
  getStore()
    .dispatch(loadWinbackInfo())
    .then(() => {});

export const isReUp = (state = getStore().getState()) => {
  const { winbackInfo } = state;
  return winbackInfo.willReup;
};

isReUp.load = () =>
  getStore()
    .dispatch(loadWinbackInfo())
    .then(() => {});

export const showPromoBox = checkSegments({
  noneOf: [routeConstants.exsf01, routeConstants.exsf01d, isWinback],
});

export function determinePlanOverride() {
  if (isDPP()) {
    return DPP_OVERRIDE_PLAN;
  }
  return "";
}

export const isDECoursePackEligible = () => {
  return isCountry(DE_COURSE_PACK_ELIGIBLE_COUNTRIES) && isDeLanguage();
};

const isRouteCoursePackEligible = checkSegments({
  oneOf: [
    isCore,
    isBaseline,
    isReferralsBuyflow,
    isEmailMain,
    isEmailWinback,
    isAppAndroidBase,
    isWinback,
    isAffiliate,
    isLegacyAffiliateTrialRoute,
  ],
});

export function mwCoursepackOptedIn(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return surveyAnswers.noomMoodBundle?.includes("yes");
}

export function amCoursepackOptedIn(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return surveyAnswers.agingMetabolismCoursepack?.includes("yes");
}

export function movementCoursepackOptedIn(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return surveyAnswers.movementCoursepack?.includes("yes");
}

export function sleepCoursepackOptedIn(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return surveyAnswers.sleepCoursepack?.includes("yes");
}

export function glp1CoursepackOptedIn(state = getStore().getState()) {
  const { surveyAnswers } = state;
  return (
    surveyAnswers.glp1?.includes("yes") ||
    surveyAnswers.glp1?.includes("prescription")
  );
}

const isENCoursePackEligible = () => {
  return isCountry(EN_COURSE_PACK_ELIGIBLE_COUNTRIES) && isEnLanguage();
};

const isLocaleCoursePackEligible = () => {
  return isENCoursePackEligible() || isDECoursePackEligible();
};

export function shouldShowCoursePackSurveys(state = getStore().getState()) {
  const { recommendedPlan } = state;
  return (
    isLocaleCoursePackEligible() &&
    isRouteCoursePackEligible() &&
    (recommendedPlan.has_trial || shouldShowEx1227()) &&
    !isReUp(state) &&
    !showMedPaymentBreakdown()
    // Originally we only showed course pack surveys if
    // you were not eligible for Noom Med. However we will support both
    // Moom Med and Mental Wellness, and selectively suppress
    // AM for now while 3 course packs support is built out.
  );
}

shouldShowCoursePackSurveys.load = () =>
  getStore()
    .dispatch(loadWinbackInfo())
    .then(() => {});

export const isRouteEligibleForGLP1CEOfferOnly = checkSegments({
  oneOf: [isClinicalRoute, isAppAndroidWinback],
});

export const isEligibleForGLP1CEOfferOnly = checkSegments({
  allOf: [isRouteEligibleForGLP1CEOfferOnly, shouldSeeGlp1Companion],
});

export function shouldShowCoursePackCheckoutOffers(
  state = getStore().getState()
) {
  const { promoCode } = state;
  return (
    (shouldShowCoursePackSurveys() || isEligibleForGLP1CEOfferOnly()) &&
    !promoCode.promoCodeIsVip &&
    !showMedPaymentBreakdown()
  );
}

export const shouldShowPaymentAddonCheckoutOffers = checkSegments({
  oneOf: [
    shouldShowCoursePackCheckoutOffers,
    shouldShowNoomClinicalCheckoutOffer,
  ],
});

export const shouldPreselectMWCoursepackCheckoutOffer = checkSegments({
  oneOf: [
    // Via buyflow coursepack surveys
    (state) =>
      shouldShowCoursePackCheckoutOffers(state) && mwCoursepackOptedIn(state),
    // Via email route modal
    ({ userData }) =>
      userData?.answerToFreeMWCPModal === "yes" &&
      userData?.checkoutDiscountedMWCPTimerExpirationTimestamp &&
      userData.checkoutDiscountedMWCPTimerExpirationTimestamp > Date.now(),
  ],
  noneOf: [isReUp],
});

export const shouldPreselectAMCoursepackCheckoutOffer = checkSegments({
  allOf: [shouldShowCoursePackCheckoutOffers, amCoursepackOptedIn],
});

export const shouldPreselectSleepCoursepackCheckoutOffer = checkSegments({
  allOf: [shouldShowCoursePackCheckoutOffers, sleepCoursepackOptedIn],
});

export const shouldPreselectMovementCoursepackCheckoutOffer = checkSegments({
  allOf: [shouldShowCoursePackCheckoutOffers, movementCoursepackOptedIn],
});

export function acceptedMWCoursepackOffer(state = getStore().getState()) {
  const { checkout, paymentAddon } = state;
  return (
    checkout.paymentAddonsInCart.includes(
      paymentAddonProducts.mentalWellness
    ) &&
    Boolean(selectMWCoursepack(paymentAddon)) &&
    shouldShowCoursePackCheckoutOffers(state)
  );
}

export function acceptedAMCoursepackOffer(state = getStore().getState()) {
  const { checkout, paymentAddon } = state;
  return (
    checkout.paymentAddonsInCart.includes(
      paymentAddonProducts.agingMetabolism
    ) &&
    Boolean(selectAMCoursepack(paymentAddon)) &&
    shouldShowCoursePackCheckoutOffers(state)
  );
}

export function acceptedNoomClinicalOffer(state = getStore().getState()) {
  const { checkout } = state;
  return (
    isInAppMedUpgrade() ||
    (shouldShowNoomClinicalCheckoutOffer(state) &&
      checkout.acceptedClinicalOffer)
  );
}

export function acceptedSleepCoursepackOffer(state = getStore().getState()) {
  const { checkout, paymentAddon } = state;
  return (
    checkout.paymentAddonsInCart.includes(paymentAddonProducts.sleep) &&
    Boolean(selectSleepCoursepack(paymentAddon)) &&
    shouldShowCoursePackCheckoutOffers(state)
  );
}

export function acceptedMovementCoursepackOffer(state = getStore().getState()) {
  const { checkout, paymentAddon } = state;
  return (
    checkout.paymentAddonsInCart.includes(paymentAddonProducts.movement) &&
    Boolean(selectMovementCoursepack(paymentAddon)) &&
    shouldShowCoursePackCheckoutOffers(state)
  );
}

export function acceptedGlp1CompanionOffer(state = getStore().getState()) {
  const { checkout, paymentAddon } = state;
  return (
    checkout.paymentAddonsInCart.includes(paymentAddonProducts.glp1Companion) &&
    Boolean(selectGlp1Coursepack(paymentAddon))
  );
}

export function isEligibleForPersonalizedPlanTimer() {
  // Note(Pratik) inAppIos is the only route where they saw the original timer
  // but are not eligible for coursepacks to see the new timer due to itunes addon purchase
  // limitations so we will preserve their experience
  // Note (kevinh) DE coursepack users are not eligible for free plans so they will also
  // see the original timer function
  return (
    !isInAppEmployerSurvey() &&
    (isAppIosBase() || isDECoursePackEligible() || isAppIOSFreemiumUpsell())
  );
}

export const shouldHideExistingPurchaseSurveyAnswers = checkSegments({
  allOf: [isPersonalizedPlanTimerExpired],
  noneOf: [isEmailMain, isEmailWinback],
});

export function isEligibleForCheckoutOfferTimer(state = getStore().getState()) {
  return (
    shouldShowCoursePackCheckoutOffers() &&
    !isEligibleForGLP1CEOfferOnly() &&
    Boolean(selectMWCoursepack(state.paymentAddon)) &&
    !isCheckoutOfferTimerExpired()
    // We only check for checkoutTimer expired in the case of checkout offer timer because we don't ever want to
    // re-show the timer once the user already has their offer expired, whereas with the personalized plan,
    // the user is entitled to another round of the timer if they go through the buyfow again
  );
}

export function isEligibleForCheckoutTimer() {
  return (
    isEligibleForPersonalizedPlanTimer() || isEligibleForCheckoutOfferTimer()
  );
}

export function isNoomSummitEligible() {
  return false;
}

export const isProgramLocaleNoomSummitEligible = () => {
  // We should make sure we are getting this from the sever context call
  return isEnProgramLocale();
};

export const isNoomSummitDisplayableInStore = checkSegments({
  allOf: [isUS, isEnLanguage],
  noneOf: [isTelehealthPlan, userHasPurchasedNoomClinical],
});

// Users who purchase Noom Summit can still visit the noom-summit-offer pagesets,
// and will be redirected to the confirmation page
export const isNoomSummitOfferEligible = checkSegments({
  allOf: [isUS, isEnLanguage],
  noneOf: [
    isTelehealthPlan,
    userHasPurchasedNoomClinical,
    isInExperimentConflictingWithSummitCoursePack,
  ],
});

export const isNoomSummitSurvey = checkSegments({
  allOf: [
    ({ linearBuyflow }) =>
      getPageSet(linearBuyflow.pageSetName)?.layer === noomSummitSurveyLayer,
  ],
});

export function hasBoughtMealPlan() {
  const { userData } = getStore().getState();
  const productIds = userData.upsell?.products || [];
  return productIds
    .filter(Boolean)
    .some((p) => isMealPlan(p) || isMealWorkoutBundle(p));
}

export function hasBoughtWorkoutPlan() {
  const { userData } = getStore().getState();
  const productIds = userData.upsell?.products || [];
  return productIds
    .filter(Boolean)
    .some((p) => isWorkoutPlan(p) || isMealWorkoutBundle(p));
}

export function hasBoughtMealAndWorkoutPlan() {
  const { userData } = getStore().getState();
  const productIds = userData.upsell?.products || [];
  return productIds.filter(Boolean).some(isMealWorkoutBundle);
}

/**
 * Verifies if current user is on referral routes and wants a full buyflow
 */
export const isFullPersonalizationBuyflow = checkSegments({
  noneOf: [
    ({ surveyAnswers }: CoreReduxState) => {
      return surveyAnswers.customizationChoice?.[0] === "quickStart";
    },
  ],
});

const isPreSelectedPlanLongerThan1Month = (state: CoreReduxState) => {
  const { planOptions } = state;

  return planOptions?.preSelected?.billing_cycle_in_months > 1;
};

const isPreSelectedPlanLongerThan2Months = (state: CoreReduxState) => {
  const { planOptions } = state;

  return planOptions?.preSelected?.billing_cycle_in_months > 2;
};

const isPreSelectedPlanTrial14Days = (state: CoreReduxState) => {
  const { planOptions } = state;

  return planOptions?.preSelected?.trial_duration === 14;
};

export const isLoadingPromoCodeModalEligible = checkSegments({
  allOf: [isUS, isEnLanguage],
  oneOf: [isEmailMain, isEmailWinback],
});

export function shouldRedirectToEmployerSurvey(
  { surveyAnswers } = getStore().getState()
) {
  const correctSurveyAnswer =
    surveyAnswers.employmentLifestyle?.includes("fullTime");
  const isCorrectTargeting = isEnLanguage() && isUS();
  const meristemConfigValue = getMeristemConfigValue(
    "redirectToEmployerSurvey"
  );

  return correctSurveyAnswer && (meristemConfigValue ?? isCorrectTargeting);
}

export function shouldRedirectToPostCheckoutContext() {
  return !!getMeristemConfigValue("usePostCheckoutContext");
}

export function shouldRedirectToHmAddonsContext() {
  return !!getMeristemConfigValue("useHmAddonsContext");
}

export function shouldRedirectHmReferralsContext() {
  return !!getMeristemConfigValue("useHmReferralsContext");
}

export const shouldShowHealthyPaceHighlight = checkSegments({
  allOf: [isUS],
  oneOf: [isMantle, isWinback],
});

export const shouldShowImportantEventHighlight = checkSegments({
  allOf: [isUS],
  oneOf: [isMantle, isWebWinback],
});

export const isEligibleForNursingOption = checkSegments({
  allOf: [isUS],
  oneOf: [isMantle],
});

// ex472b feature. PR: https://github.com/noom/buyflow-client/pull/1356
export const shouldShowAlternatePostCheckoutHeadersText = checkSegments({
  allOf: [isUS],
  oneOf: [isCore, isAffiliate, isAppBaseRoute, isReferralsBuyflow],
});

export const shouldShowUnloadWarning = checkSegments({
  allOf: [isUS],
  oneOf: [isCore, isAffiliate, isAppBaseRoute, isReferralsBuyflow],
});

export const isEligibleForAgingMetabolismSurvey = (
  state = getStore().getState()
) => {
  if (!isEligibleForNX1CourseEnhancements()) {
    return false;
  }
  const { ageRange, cognitiveExplore } = getSurveyAnswers(state);
  if (ageRange?.includes("20s")) {
    return false;
  }
  if (ageRange?.includes("30s")) {
    return (
      cognitiveExplore?.includes("aging") ||
      cognitiveExplore?.includes("metabolism")
    );
  }
  return true;
};

export const isEligibleForSleepAndMovementSurveys = () => {
  return isEligibleForNX1CourseEnhancements();
};

export const isEligibleForModularBuyflow = checkSegments({
  allOf: [isEnLanguage, isMantle],
  noneOf: [isHM],
});

const isEmailIntl = checkSegments({
  allOf: [isIntl],
  oneOf: [isEmailMain, isEmailWinback],
});

const isEmailWinbackUS = checkSegments({
  allOf: [isUS, isEmailWinback],
});

export const isFreeMonthsPlanOverrideEligible = checkSegments({
  allOf: [isEnLanguage],
  oneOf: [isEmailIntl, isEmailWinbackUS],
});

export const isFreeMonthsPlanSelectEligible = checkSegments({
  allOf: [isEnLanguage, isEmailMain, isUS],
});

export const shouldShowFreeMonthsPlanSelect = () => {
  return !!freeMonthsOfferConfiguration() && isFreeMonthsPlanSelectEligible();
};

// Free month offers are shown to users in 2 different forms:
// 1. Free month plan override that overrides a shorter plan with a longer plan
// of cheaper or equal price when the user applies a promocode
//
// 2. Free month plan select which shows a 3 plan selection UI (monthly, middle length, long length)
// and discounts the long length plan to make it equal to the price of the middle length plan
// when the user applies a promocode
export const isFreeMonthsOfferEligible = checkSegments({
  oneOf: [isFreeMonthsPlanOverrideEligible, isFreeMonthsPlanSelectEligible],
});

export const isPlanSelectionEligible = checkSegments({
  oneOf: [
    shouldShowFreeMonthsPlanSelect,
    checkSegments({
      allOf: [
        isEmailMain,
        isUS,
        isPreSelectedPlanLongerThan1Month,
        isPreSelectedPlanTrial14Days,
      ],
    }),
  ],
});

export const shouldShowPlanSelectionSavings = checkSegments({
  allOf: [isPlanSelectionEligible, isPreSelectedPlanLongerThan2Months],
});

export const hasExistingAcccount = checkSegments({
  oneOf: [isAppEmail, isAppFreemiumUpsell, disableAccountFieldsForMedFlow],
});

const isCurrentPlanForced = (state: CoreReduxState = getStore().getState()) => {
  const { recommendedPlan } = state;
  const { isForcedPlan } = recommendedPlan;
  return !!isForcedPlan;
};

export const isMultiUserPlanEligible = checkSegments({
  allOf: [isUS, isEnLanguage],
  oneOf: [
    isCore,
    isReferralsBuyflow,
    isWinback,
    isAppAndroidBase,
    isAffiliateWithTrial,
  ],
  noneOf: [
    isHolidayPromotion,
    isCurrentPlanForced,
    isEligibleForCoachReferralBuyflow,
    isAffiliateWithLongTrial,
  ],
});

export function multiUserPlanOptedIn(
  state: CoreReduxState = getStore().getState()
) {
  const TWO_USER_PLAN = "twoUserPlan";
  const { nominationFamily } = state.surveyAnswers;

  return nominationFamily?.[0] === TWO_USER_PLAN;
}

export const shouldShowPrimedSupporterExperience = checkSegments({
  allOf: [isUS, isEnLanguage],
  oneOf: [
    isCore,
    isAffiliate,
    isAppBaseRoute,
    isReferralsBuyflow,
    isWebWinback,
    isEmailMain,
    isClinicalRoute,
  ],
});

export const isReferedFromUserPortal = () => {
  return getURLParams()?.redirected_from === "userportal";
};

export const isCampaignFeatureOura = () => {
  return getURLParams()?.feature === "oura";
};

export const isFallbackPromotionalOfferEligible = checkSegments({
  allOf: [isUS, isEnLanguage, isEmailMain],
});

const wasIncorrectPromoCodeEntered = (
  state: CoreReduxState = getStore().getState()
) => {
  return state?.promoCode?.promoCodeErrorType === "IncorrectPromoCode";
};

export const shouldShowFallbackPromotionalOffer = checkSegments({
  allOf: [
    isFallbackPromotionalOfferEligible,
    hasFallbackPromotionalOfferConfiguration,
    wasIncorrectPromoCodeEntered,
  ],
  noneOf: [shouldShowFreeMonthsPlanSelect],
});

export const shouldAllowPromoCodeRemoval = () => {
  if (
    isFallbackPromotionalOfferEligible() &&
    hasFallbackPromotionalOfferConfiguration()
  ) {
    return true;
  }

  return (
    (isPromoCodePersisted() || !!getCampaignFromStorage()) &&
    isEnLanguage() &&
    (isEmailMain() || isEmailWinback())
  );
};

export function canUseShareApi() {
  return canUseNavigatorShare() || canUseAndroidShare();
}

export const isBillingReminderEligible = checkSegments({
  oneOf: [
    isCore,
    isAffiliateWithTrial,
    isAppAndroidBase,
    isReferralsBuyflow,
    isAppIosBase,
  ],
  allOf: [isUS, isEnLanguage],
});

export const isEligibleForPreCheckoutFreeMWCPOffer = checkSegments({
  allOf: [isUS, isEnLanguage, isPreSignup],
  oneOf: [isEmailMain, isEmailWinback],
  noneOf: [isReUp],
});

export const isCareFirstRedirectEligible = () => {
  return (
    isUS() &&
    isEnLanguage() &&
    ["MD", "DC", "DE", "VA"].includes(getSubdivision())
  );
};

export const isMHMDAState = () => {
  return isUS() && MHMDA_STATES.includes(getSubdivision());
};

export const isEligibleForHealthInsuranceProviderQ = (): boolean => {
  const { surveyAnswers } = getStore().getState();
  const { currentBMI } = surveyAnswers;
  return currentBMI > 25;
};

export const isFromCareFirstEmailBlast = () => {
  return (
    isUS() &&
    isEnLanguage() &&
    isFromEmail() &&
    (getURLParams()?.utm_source === "cf_drip" ||
      getURLParams()?.utm_campaign === "cf_blast")
  );
};

export const isUserEnrolledInMABExperiment = () => {
  const mabKey = window.localStorage.getItem(BANDIT_LOCAL_STORAGE_KEY);
  return !!(mabKey && getFeatureFlagValue("enrolledInMABExperiment"));
};

export const isGlpEligible = checkSegments({
  allOf: [isENCoursePackEligible],
  noneOf: [isUK],
  oneOf: [
    isStrictCore,
    isWinback,
    isReferralsBuyflow,
    isAffiliate,
    isClinicalRoute,
    isLegacyAffiliateTrialRoute,
    isAppAndroid,
    isAppEmail,
    isFromNoomWeightEmail,
    isBaseline,
    isAdWordsSEM,
    isFreemiumWeightRoute,
    shouldShowTelex229,
  ],
});

export function isOwnSupporter(state: CoreReduxState = getStore().getState()) {
  const { supporterEmail } = getSurveyAnswers(state);
  const { email } = state.userData;

  return email === supporterEmail && !!email;
}

export const shouldUseYesOrNoQuestion = checkSegments({
  allOf: [isEnMantle],
  noneOf: [isEmailWinback, isEmailMain, isClinicalRoute],
});

export function shouldShowWomensHealthSurveyQuestions() {
  return isUS() || getURLParams()?.feature === "womensHealth";
}

export function shouldPublishToUserModel() {
  return !!appConfig.PUBLISH_TO_USER_MODEL_ENABLED;
}

export function shouldShowEx1226() {
  return !!getMeristemConfigValue("isEx1226Active");
}

export const showPaymentInfoAccordion = checkSegments({
  noneOf: [hasVipPromoCode, isInAppNativePurchaseEligible],
});

export const showAccountInfoAccordion = checkSegments({
  oneOf: [shouldEnrollUser, shouldShowNoomClinicalCheckoutOffer],
});

export const isEligibleForNewEmailFlow = checkSegments({
  allOf: [isFromNoomWeightEmail, isUS, isEnLanguage],
});

export const showSexQuestionOnEmail = createOneTimeConditionSegment(
  "sex",
  (state) => {
    const surveyAnswers = getSurveyAnswers(state);
    return !surveyAnswers.sex;
  }
);

export const showHeightQuestionOnEmail = createOneTimeConditionSegment(
  "basicHeight",
  (state) => {
    const surveyAnswers = getSurveyAnswers(state);
    return !surveyAnswers.heightCm;
  }
);

export const showPregnantQuestionOnEmail = createOneTimeConditionSegment(
  "pregnant",
  (state) => {
    const surveyAnswers = getSurveyAnswers(state);
    return !surveyAnswers.pregnant || surveyAnswers.pregnant?.[0] === "yes";
  }
);

export const showEatingDisorderQuestionOnEmail = createOneTimeConditionSegment(
  "eatingDisorder",
  (state) => {
    const surveyAnswers = getSurveyAnswers(state);
    return (
      !surveyAnswers.eatingDisorder ||
      surveyAnswers.eatingDisorder?.[0] === "yes"
    );
  }
);

export const showEmailQuestionOnEmail = createOneTimeConditionSegment(
  "email",
  (state) => {
    const { userData } = state;
    return !isEmail(userData.email);
  }
);

export function shouldDisplayWLGModal() {
  return (
    isUS() &&
    isChooseTrialPriceNewStyleEligible() &&
    !isHM() &&
    !getFeatureFlagValue("disableWLGModal") &&
    isClinicalRoute()
  );
}

function shouldShowUpsEx310() {
  return !!getMeristemConfigValue("isUpsEx310Active");
}

export function shouldShowExNV52() {
  return !!getMeristemConfigValue("isExNV52Active");
}

export function shouldShowTelex225V2() {
  return !!getMeristemConfigValue("isTelex225V2Active");
}

export function shouldShowTelex229() {
  return (
    !!getMeristemConfigValue("isTelex229Active") &&
    (isAppIosBase() || isAppIosWinback())
  );
}

function isProbioticsDeliveryEnabled() {
  return !!appConfig.PROBIOTICS_DELIVERY_ENABLED;
}

export const isProbioticsDeliveryEligible = checkSegments({
  allOf: [shouldShowUpsEx310, isProbioticsDeliveryEnabled],
});

export function shouldShowEx1227() {
  return (
    !!getMeristemConfigValue("isEx1227Active") &&
    (!multiUserPlanOptedIn() || !isMultiUserPlanEligible()) &&
    !isCurrentPlanForced()
  );
}

export const isCountryEligibleForZumba = checkSegments({
  oneOf: [isUS, isCA, isAU, isNZ],
});

export const isCurrentTrialZumbaEligible = (
  { userData, serverContext } = getStore().getState()
) => {
  const { purchased_fast_forward_trial } = serverContext;
  const { growthTrialEndDateISO, growthProgramStartDateISO } = userData;
  const trialSeconds =
    new Date(growthTrialEndDateISO).getTime() -
    new Date(growthProgramStartDateISO).getTime();
  const trialDays = Math.round(trialSeconds / (1000 * 3600 * 24));

  return purchased_fast_forward_trial || [0, 7, 14].includes(trialDays);
};

function isZumbaOfferEnabled() {
  return !!appConfig.ZUMBA_OFFER_ENABLED;
}

export const isZumbaOfferEligible = checkSegments({
  allOf: [
    isEnLanguage,
    isCountryEligibleForZumba,
    isCurrentTrialZumbaEligible,
    isZumbaOfferEnabled,
  ],
  oneOf: [
    isCore,
    routeConstants.winback,
    isAppAndroidBase,
    isAffiliateWithTrial,
    isEmailMain,
    isEmailWinback,
    isReferralsRoute,
    isCoachReferralsRoute,
  ],
});

export function shouldSuppressStepTrackingVibeOffer() {
  return shouldSuppressVibeOffer(STEP_TRACKING_VIBE_OFFER_ID);
}

export function isEligibleForStepTrackingVibeOffer() {
  const { surveyAnswers } = getStore().getState();

  return (
    ((surveyAnswers.stepTracking?.[0] === "yes" && surveyAnswers.dailySteps) ||
      surveyAnswers.stepTracking?.[0] === "no") &&
    !isClinicalRoute() &&
    isEnLanguage()
  );
}

export function shouldShowStepTrackingVibeOffer() {
  return (
    isEligibleForStepTrackingVibeOffer() &&
    !shouldSuppressStepTrackingVibeOffer()
  );
}

export function isEligibleForNoomPremiumNoTrial() {
  const meristemFlag = getMeristemConfigValue("premiumSubForNoTrialHw");
  return !(
    meristemFlag ||
    shouldShowEx1227() ||
    isPremiumTextCoachingSubscriptionEligible()
  );
}

export function shouldShowFirstNameQuestion() {
  // Apple requires that the firstName question is skipped
  // if users sign in with Apple. Backend will set inAppName in
  // the userData for this flow so we can use it to identify and skip.
  // See https://noomhq.atlassian.net/browse/GTIER-166.
  const { userData } = getStore().getState();
  return !userData.inAppName;
}

export function isReferralLinkFlowEnabled() {
  return !!appConfig.REFERRAL_LINK_FLOW_ENABLED;
}

export const isEligibleForReferralLinkPage = checkSegments({
  oneOf: [isUS, isCA, isUK, isAU, isNZ],
  allOf: [isEnLanguage, isReferralLinkFlowEnabled],
});

export function shouldSuppressGLP1Question() {
  return hasAnsweredToGLP1FollowUpInCurrentSession("no");
}

export function isSubscriptionPremiumTier() {
  const { serverContext } = getStore().getState();
  return isPremiumTierPlanId(serverContext.current_plan_id);
}

export function isSubscriptionMedTier() {
  const { serverContext } = getStore().getState();
  return isMedTierPlanId(serverContext.current_plan_id);
}

export function userHasActivePremiumWithTextCoachingAddon() {
  const { serverContext } = getStore().getState();
  return !!serverContext.addon_subscriptions?.find((addon) => {
    return (
      addon.product === "PREMIUM_WITH_TEXT_COACHING" &&
      addon.status === "ACTIVE"
    );
  });
}

export function userHasActivePremiumBundleAddon() {
  const { serverContext } = getStore().getState();
  return !!serverContext.addon_subscriptions?.find((addon) => {
    return (
      addon.product === "MEAL_WORKOUT_PLANNING" && addon.status === "ACTIVE"
    );
  });
}

export function userHasPremiumPlan() {
  return (
    isSubscriptionPremiumTier() ||
    userHasActivePremiumWithTextCoachingAddon() ||
    userHasActivePremiumBundleAddon()
  );
}

export function userHasActiveSubscription() {
  const { activeSubscriptionData } = getStore().getState();
  return activeSubscriptionData.hasActiveSubscription;
}

userHasActiveSubscription.load = async () => {
  await getStore().dispatch(loadActiveSubscriptionDataState({}));
};

export function isEligibleForCoachReferralBuyflow() {
  const { referralInfo } = getSessionState("browser");
  return (
    isCoachReferralsRoute() && isEligibleForReferralLinkPage() && !!referralInfo
  );
}

function getMedFlagOverride(medFlag: string) {
  const urlParams = getURLParams();
  if (isDevMode() && urlParams && urlParams[medFlag] != null) {
    return urlParams[medFlag] === "true";
  }
  return undefined;
}

export function shouldHideFitonWorkoutPlanIntensity() {
  const { surveyAnswers } = getStore().getState();

  return (
    (isMaleGender() &&
      surveyAnswers.fitonWorkoutPlanDumbbells?.includes("hasNoDumbbells")) ||
    (!isMaleGender() &&
      surveyAnswers.fitonWorkoutPlanDuration?.includes("lessThan20Min"))
  );
}

export function isZipcodeCorrespondingToUSState(
  { surveyAnswers } = getStore().getState()
) {
  const { zipcode } = surveyAnswers;
  if (zipcode !== undefined) {
    const state = zipState(zipcode);
    return state !== null;
  }
  return false;
}

export function isEligibleForMedProducts() {
  return getEligibleMedProducts().length > 0;
}

export function isEligibleForMoreThanOneMedProduct() {
  return getEligibleMedProducts().length > 1;
}

export function hasSelectedCompoundedPlan() {
  return (
    getMedFlagOverride("selectCompoundedPlan") ??
    getSessionState("browser").selectedMedPlanType === "compounded"
  );
}

export function hasSelectedOralsMetforminPlan() {
  return (
    getMedFlagOverride("selectOralsMetforminPlan") ??
    getSessionState("browser").selectedMedPlanType === "oralsMetformin"
  );
}

export function isCoachReferralAcceptingNewUsers() {
  const { referralInfo } = getSessionState("browser");
  return !!referralInfo.acceptingNewUsers;
}

const NMC_CONTEXT_TYPES = ["nmc-landing", "nmc-survey", "nmc-coming-soon"];

export function isNmc() {
  return NMC_CONTEXT_TYPES.includes(getMeristemContext().context_type);
}

export function isNoomPremiumInAppUpgradeEligible() {
  const { planId, activeSubscriptionCurriculumSlug, isIAPsubscription } =
    getLoadedSubscriptionStatus() || {};

  return (
    !isMultiUserPlan(planId) &&
    !isIAPsubscription &&
    !planId?.endsWith("__pause") &&
    activeSubscriptionCurriculumSlug?.startsWith(
      HEALTHY_WEIGHT_CURRICULUM_PREFIX
    )
  );
}

isNoomPremiumInAppUpgradeEligible.load = async () => {
  await loadSubscriptionStatus();
};

export function isCompoundedPlanIneligible() {
  const params = new URLSearchParams(window.location.search);
  const forcedIneligibility = params.get("disableCompoundedOffer") === "true";

  return (
    forcedIneligibility ||
    !getEligibleMedProducts().includes(MED_SKU.COMPOUNDED)
  );
}

export function isPremiumConfigurationWithCoaching() {
  const { planId } = getLoadedSubscriptionStatus() || {};

  return isPremiumTierPlanId(planId);
}

isPremiumConfigurationWithCoaching.load = async () => {
  await loadSubscriptionStatus();
};

export function isPremiumUpgradeSourceBuyflow() {
  const { userData } = getStore().getState();
  return userData?.premiumUpgradeSource === "BUYFLOW";
}

export const isPremiumTierCounterOfferEligible = checkSegments({
  allOf: [isSubscriptionPremiumTier, isPremiumUpgradeSourceBuyflow],
  noneOf: [isSubscriptionMedTier, isSubscriptionInvalid],
});

export function isBrandedPlanIneligible() {
  return !getEligibleMedProducts().includes(MED_SKU.BRANDED);
}

export function isOralsPlanIneligible() {
  return !getEligibleMedProducts().includes(MED_SKU.ORALS_METFORMIN);
}

export const showHeightQuestionOnInAppUpgrade = createOneTimeConditionSegment(
  "basicHeight",
  (state) => {
    const surveyAnswers = getSurveyAnswers(state);
    return !surveyAnswers.heightCm;
  }
);

export const showIdealWeightQuestionOnInAppUpgrade =
  createOneTimeConditionSegment("idealWeightKg", (state) => {
    const surveyAnswers = getSurveyAnswers(state);
    return !surveyAnswers.idealWeightKg;
  });

export const showSexQuestionOnInAppUpgrade = createOneTimeConditionSegment(
  "sex",
  (state) => {
    const surveyAnswers = getSurveyAnswers(state);
    return !surveyAnswers.sex;
  }
);

export function isIneligibleForMedUpgrade() {
  const { serverContext } = getStore().getState();
  return !serverContext.is_eligible_for_med_upgrade;
}

export function isEligibleForNoomPremiumInMedUpgradeFlow() {
  const { serverContext } = getStore().getState();
  return (
    serverContext.is_eligible_for_premium_upgrade && !!getInAppAutoCookie()
  );
}

export const isEligibleForFreemiumIUBuyflow = checkSegments({
  oneOf: [isUS, isCA, isAU],
  allOf: [isFreemiumIdentifiedRoute, isEnLanguage],
});

// checks if any freemium route
// (unidentified user who wants free bodyscan experience should be added here)
export const isEligibleForFreemium = checkSegments({
  oneOf: [isEligibleForFreemiumIUBuyflow],
});

export function isRedesignEnabled() {
  return (
    !isClinicalRoute() &&
    (isEligibleForFreemiumIUBuyflow() || isFreemiumQuizRoute())
  );
}

export function isOralsMedEnabled() {
  return (
    getMedFlagOverride("oralsMedEnabledOverwrite") ??
    !!appConfig.MED_ORALS_ENABLED
  );
}

export function isOralsMedPaidTraffic() {
  const params = new URLSearchParams(window.location.search);
  return params.get("feature") === "orals";
}

export function isAllMedPaidTrafic() {
  const params = new URLSearchParams(window.location.search);
  return params.get("feature") === "allMedications";
}

export function getOralsMedForcedParam() {
  const params = new URLSearchParams(window.location.search);
  return params.get("enableMedOralsFlow");
}

function maybeSetOralsMedRolloutUserType(
  currentRolloutType,
  newRolloutType,
  dispatch
): OralsMedRolloutUserType {
  if (currentRolloutType !== newRolloutType) {
    dispatch(setOralsMedRolloutUserType(newRolloutType));
  }
  return newRolloutType;
}

export function getOralsMedUserRolloutStatusHelper({
  medOralsHoldbackTrafficValue,
  medOralsBuyflowTrafficSplitValue,
}: {
  medOralsHoldbackTrafficValue: number;
  medOralsBuyflowTrafficSplitValue: number;
}) {
  const store = getStore();
  const state = store.getState();
  const { persistentBrowserFlags } = state;
  const { oralsMedRolloutUserType } = persistentBrowserFlags;
  const { dispatch } = store;

  const medOralsForcedParam = getOralsMedForcedParam();
  if (["false", "true"].includes(medOralsForcedParam)) {
    if (medOralsForcedParam === "true") {
      return maybeSetOralsMedRolloutUserType(
        oralsMedRolloutUserType,
        OralsMedRolloutUserType.FORCED_ENABLED,
        dispatch
      );
    }
    return maybeSetOralsMedRolloutUserType(
      oralsMedRolloutUserType,
      OralsMedRolloutUserType.FORCED_DISABLED,
      dispatch
    );
  }

  if (isOralsMedPaidTraffic()) {
    return maybeSetOralsMedRolloutUserType(
      oralsMedRolloutUserType,
      OralsMedRolloutUserType.PAID_TRAFFIC,
      dispatch
    );
  }

  if (isAllMedPaidTrafic()) {
    return maybeSetOralsMedRolloutUserType(
      oralsMedRolloutUserType,
      OralsMedRolloutUserType.ALL_MED_PAID_TRAFFIC,
      dispatch
    );
  }

  if (oralsMedRolloutUserType) {
    // Note(Alex): When we are at 100% rollout, ignore the cached state and serve orals buyflow to everyone
    if (
      medOralsHoldbackTrafficValue === 0.0 &&
      medOralsBuyflowTrafficSplitValue === 1.0
    ) {
      return maybeSetOralsMedRolloutUserType(
        oralsMedRolloutUserType,
        OralsMedRolloutUserType.FORCED_ENABLED,
        dispatch
      );
    }
    return oralsMedRolloutUserType;
  }

  if (Math.random() < medOralsHoldbackTrafficValue) {
    dispatch(setOralsMedRolloutUserType(OralsMedRolloutUserType.HOLDBACK));
    return OralsMedRolloutUserType.HOLDBACK;
  }

  if (Math.random() < medOralsBuyflowTrafficSplitValue) {
    dispatch(setOralsMedRolloutUserType(OralsMedRolloutUserType.VARIATION));
    return OralsMedRolloutUserType.VARIATION;
  }

  dispatch(setOralsMedRolloutUserType(OralsMedRolloutUserType.CONTROL));
  return OralsMedRolloutUserType.CONTROL;
}

export function getOralsMedUserRolloutStatus() {
  // Note(Alex): Using a helper method for easier mocking of appConfig params in jest unit tests
  return getOralsMedUserRolloutStatusHelper({
    medOralsHoldbackTrafficValue: appConfig.MED_ORALS_HOLDBACK_TRAFFIC,
    medOralsBuyflowTrafficSplitValue: appConfig.MED_ORALS_BUYFLOW_TRAFFIC_SPLIT,
  });
}

export function isInPercentRolloutForOralsMedBuyflow() {
  const oralsMedUserRolloutStatus = getOralsMedUserRolloutStatus();
  return [
    OralsMedRolloutUserType.FORCED_ENABLED,
    OralsMedRolloutUserType.VARIATION,
    OralsMedRolloutUserType.PAID_TRAFFIC,
    OralsMedRolloutUserType.ALL_MED_PAID_TRAFFIC,
  ].includes(oralsMedUserRolloutStatus);
}

export const isEligibleForOralsMedBuyflow = checkSegments({
  allOf: [isOralsMedEnabled, isInPercentRolloutForOralsMedBuyflow],
  oneOf: [isEligibleForClinicalBuyflow, isNoomWeightMedBranching],
  noneOf: [isInAppMedUpgrade],
});

export function getSelectedMedPlanType() {
  if (hasSelectedOralsMetforminPlan() && isEligibleForOralsMedBuyflow()) {
    return MED_SKU.ORALS_METFORMIN;
  }

  if (hasSelectedCompoundedPlan()) {
    return MED_SKU.COMPOUNDED;
  }

  return MED_SKU.BRANDED;
}

export function isSelectedMedPlanWithMedication() {
  const selectedMedPlanType = getSelectedMedPlanType();

  return (
    selectedMedPlanType === MED_SKU.COMPOUNDED ||
    selectedMedPlanType === MED_SKU.ORALS_METFORMIN
  );
}

export const isInAppChatLobbyUpsell = () => {
  const urlParams = getURLParams();
  return urlParams?.utm_source === "chatlobby";
};

export const isInAppFitOnUpsell = () => {
  const urlParams = getURLParams();
  return urlParams?.utm_source === "fiton";
};

export function isIdealWeightOnFreemiumLPEligible() {
  const { idealWeightKg } = getSurveyAnswers();
  return (
    (isFreemiumClinicalRoute() || isFreemiumWeightRoute()) && !!idealWeightKg
  );
}

export function showIdealWeightQuestionOnFreemium() {
  const { surveyAnswers } = getStore().getState();
  const { idealWeightConfirmationFreemium } = surveyAnswers;
  return idealWeightConfirmationFreemium?.includes("no");
}

export function showIdealWeightConfirmationQuestionOnFreemium() {
  const { surveyAnswers } = getStore().getState();
  const { idealWeightConfirmationFreemium } = surveyAnswers;

  return !idealWeightConfirmationFreemium;
}

export function isFromMealbot() {
  const { fromMealbot } = getURLParams();
  return fromMealbot === "true";
}

export function isMealPlanAllergyQuestionEnabled() {
  return !!appConfig.MEAL_PLAN_ALLERGY_QUESTION_ENABLED;
}

export function isMealPlanFavoriteCuisineQuestionEnabled() {
  return !!appConfig.MEAL_PLAN_FAVORITE_CUISINE_QUESTION_ENABLED;
}

export function isMealPlanMaxCookingTimeQuestionEnabled() {
  return !!appConfig.MEAL_PLAN_MAX_COOKING_TIME_QUESTION_ENABLED;
}

export const isEligibleForPromoCampaign = checkSegments({
  noneOf: [isClinicalRoute],
});

export function isSideNavNoomMedUpgrade() {
  return isInAppMedUpgrade() && getTrackingParams().utm_source === "sidenav";
}
