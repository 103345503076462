import { useState } from "react";
import { useDebounce } from "src/hooks/lifecycle";

/**
 * Creates a click handler for loading quicksilver via 'qs' query string param.
 * The click function must be invoked HITS_THRESHOLD times to activate quicksilver.
 * The click function has a debounced reset that is invoked after DEBOUNCE_TIME.
 */
export function useQuickSilverClickLoader() {
  const DEBOUNCE_TIME = 2000;
  const HITS_THRESHOLD = 15;

  const [hitsCounter, setHitsCounter] = useState(0);
  const resetHitsCounter = () => setHitsCounter(0);

  // Create debounced reset function
  const { debounced: onReset, cancel: cancelResetTimer } = useDebounce(
    () => setHitsCounter(0),
    DEBOUNCE_TIME
  );

  // Load quicksilver by adding the qs query string param
  const loadQuickSilver = () => {
    // Set query string parameters to load quicksilver
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("qs", "true");
    window.location.search = searchParams.toString();
  };

  // Increment number of hits, activate quicksilver if HITS_THRESHOLD is reached
  const onHit = () => {
    const numHits = hitsCounter + 1;
    if (numHits >= HITS_THRESHOLD) {
      loadQuickSilver();
      resetHitsCounter();
      cancelResetTimer();
    } else {
      setHitsCounter(numHits);
      onReset();
    }
  };

  return onHit;
}
