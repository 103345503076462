// These are all nearly the same, so this is in some fashion for docs only
export interface PoundsValue {
  /** value in pounds. */ mainUnitValue: number;
}
export interface KilosValue {
  /** value in kilograms. */ mainUnitValue: number;
}
export interface StoneValue {
  /** value in strong. */ mainUnitValue: number;
  /** value in stones */ secondaryUnitValue: number;
}

export interface InchValue {
  /** value in inches. */ mainUnitValue: number;
}
export interface FootValue {
  /** value in feet. */ mainUnitValue: number;
  /** value in inches. */ secondaryUnitValue?: number;
}
export interface CentimeterValue {
  /** value in centimeters. */ mainUnitValue: number;
}

/**
 * Converts given value in pounds to be in kilograms.
 */
export function convertLbToKg({ mainUnitValue }: PoundsValue): KilosValue {
  return { mainUnitValue: mainUnitValue / 2.205 };
}

/**
 * Converts given value in kilograms to be in pounds.
 */
export function convertKgToLb({ mainUnitValue }: KilosValue): PoundsValue {
  return { mainUnitValue: mainUnitValue * 2.205 };
}

/**
 * Converts given value in kilograms to be in pounds.
 */
export function convertLbToSt({ mainUnitValue }: PoundsValue): StoneValue {
  const roundedLbValue = Math.round(mainUnitValue); // Round value before applying calculations to avoid off by one errors
  return {
    mainUnitValue: Math.floor(roundedLbValue / 14.0),
    secondaryUnitValue: roundedLbValue % 14.0,
  };
}

/**
 * Converts given value in stones and pounds to be in pounds.
 */
export function convertStToLb({
  mainUnitValue,
  secondaryUnitValue = 0,
}: StoneValue): PoundsValue {
  return {
    mainUnitValue: mainUnitValue * 14.0 + secondaryUnitValue,
  };
}

/**
 * Converts given value in kilograms to be in stones and pounds.
 */
export function convertKgToSt({ mainUnitValue }: KilosValue) {
  const valueInLb = convertKgToLb({ mainUnitValue });
  return convertLbToSt(valueInLb);
}

/**
 * Converts given value in stones and pounds to be in kilogram.
 */
export function convertStToKg({
  mainUnitValue,
  secondaryUnitValue = 0,
}: StoneValue) {
  const valueInLb = convertStToLb({ mainUnitValue, secondaryUnitValue });
  return convertLbToKg(valueInLb);
}

/**
 * Converts given value in inches to be in centimeters.
 *
 * @param {number} mainUnitValue - value in inches.
 */
export function convertInToCm({ mainUnitValue }: InchValue): CentimeterValue {
  return { mainUnitValue: mainUnitValue * 2.54 };
}

/**
 * Converts given value in centimeters to be in inches.
 */
export function convertCmToIn({ mainUnitValue }: CentimeterValue): InchValue {
  return { mainUnitValue: mainUnitValue / 2.54 };
}

/**
 * Converts given value in feet to be in inches.
 */
export function convertFtToIn({
  mainUnitValue,
  secondaryUnitValue = 0,
}: FootValue): InchValue {
  return {
    mainUnitValue: mainUnitValue * 12 + secondaryUnitValue,
  };
}

/**
 * Converts given value in inches to be in feet.
 */
export function convertInToFt({ mainUnitValue }: InchValue): FootValue {
  return {
    mainUnitValue: Math.floor(mainUnitValue / 12.0),
    secondaryUnitValue: Math.floor(mainUnitValue % 12.0),
  };
}

/**
 * Converts given value in feet to be in centimeters.
 */
export function convertFtToCm({
  mainUnitValue,
  secondaryUnitValue = 0,
}: FootValue) {
  const valueInIn = convertFtToIn({ mainUnitValue, secondaryUnitValue });
  return convertInToCm(valueInIn);
}

/**
 * Converts given value in centimeters to be in feet.
 *
 * @param {number} mainUnitValue - value in centimeters.
 */
export function convertCmToFt({ mainUnitValue }: CentimeterValue) {
  const valueInIn = convertCmToIn({ mainUnitValue });
  return convertInToFt(valueInIn);
}
