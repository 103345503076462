import MentalWellnessLogo from "src/checkout/components/pricing/price-breakdown/course-enhancements/images/MentalWellnessLogo.svg";
import SleepLogo from "src/checkout/components/pricing/price-breakdown/course-enhancements/images/SleepLogo.svg";
import AgingMetabolismLogo from "src/checkout/components/pricing/price-breakdown/course-enhancements/images/AgingMetabolismLogo.svg";
import ExerciseLogo from "src/checkout/components/pricing/price-breakdown/course-enhancements/images/ExerciseLogo.svg";
import Glp1Logo from "src/checkout/components/pricing/price-breakdown/product-icon/images/Glp1CompanionProductIon.svg";
import {
  selectMWCoursepack,
  selectAMCoursepack,
  selectSleepCoursepack,
  selectMovementCoursepack,
  selectGlp1Coursepack,
  PaymentAddon,
  selectPaidMWCoursepack,
  selectPaidAMCoursepack,
  selectPaidMovementCoursepack,
  selectPaidSleepCoursepack,
} from "src/utils/redux/slices/paymentAddon";
import {
  CourseEnhancementProduct,
  getCourseEnhancementProducts,
  getAMCoursepackId,
  getGlp1CoursepackId,
  getMWCoursepackId,
  getMovementCoursepackId,
  getSleepCoursepackId,
} from "src/utils/paymentAddon";
import { compassColors } from "src/utils/styling";
import {
  amCoursepackOptedIn,
  sleepCoursepackOptedIn,
  movementCoursepackOptedIn,
  mwCoursepackOptedIn,
  glp1CoursepackOptedIn,
  acceptedMWCoursepackOffer,
  acceptedAMCoursepackOffer,
  acceptedSleepCoursepackOffer,
  acceptedMovementCoursepackOffer,
  acceptedGlp1CompanionOffer,
  shouldShowCoursePackSurveys,
  isEligibleForAgingMetabolismSurvey,
  isEligibleForSleepAndMovementSurveys,
  shouldSeeGlp1Companion,
  shouldPreselectAMCoursepackCheckoutOffer,
  shouldPreselectMovementCoursepackCheckoutOffer,
  shouldPreselectMWCoursepackCheckoutOffer,
  shouldPreselectSleepCoursepackCheckoutOffer,
} from "@utils/userSegment/features";

type CourseEnhancementMapFunctions = {
  select: (...args) => PaymentAddon;
  selectPaid?: (...args) => PaymentAddon;
  getProductId: (...args) => string;
};

type CourseEnhancementFilterFunctions = {
  isOptedIn: (...args) => boolean;
  isAccepted: (...args) => boolean;
  isSurveyEligible: (...args) => boolean;
  shouldPreselect: (...args) => boolean;
};

type CourseEnhancementData = {
  backgroundColor: string;
  logo: string;
  eventNameMap: {
    learnMore: string;
    removed: string;
    added: string;
    modalClosed: string;
  };
  i18nNamespace: string;
} & CourseEnhancementMapFunctions &
  CourseEnhancementFilterFunctions;

export const COURSE_ENHANCEMENT_DATA_MAP: Record<
  CourseEnhancementProduct,
  CourseEnhancementData
> = {
  mentalWellnessCoursepack: {
    backgroundColor: compassColors.conch,
    logo: MentalWellnessLogo,
    eventNameMap: {
      learnMore: "ClickedLearnMoreStressEnhancement",
      removed: "RemovedStressEnhancement",
      added: "AddedStressEnhancement",
      modalClosed: "StressEnhancementModalClosed",
    },
    select: selectMWCoursepack,
    selectPaid: selectPaidMWCoursepack,
    isOptedIn: mwCoursepackOptedIn,
    isAccepted: acceptedMWCoursepackOffer,
    isSurveyEligible: () => {
      return shouldShowCoursePackSurveys();
    },
    shouldPreselect: () => {
      return shouldPreselectMWCoursepackCheckoutOffer();
    },
    getProductId: () => {
      return getMWCoursepackId();
    },
    i18nNamespace: "courseEnhancements:mentalWellnessCoursepack",
  },
  agingMetabolismCoursepack: {
    backgroundColor: compassColors.sky,
    logo: AgingMetabolismLogo,
    eventNameMap: {
      learnMore: "ClickedLearnMoreAMEnhancement",
      removed: "RemovedAMEnhancement",
      added: "AddedAMEnhancement",
      modalClosed: "AMEnhancementModalClosed",
    },
    select: selectAMCoursepack,
    selectPaid: selectPaidAMCoursepack,
    isOptedIn: amCoursepackOptedIn,
    isAccepted: acceptedAMCoursepackOffer,
    isSurveyEligible: () => {
      return isEligibleForAgingMetabolismSurvey();
    },
    shouldPreselect: () => {
      return shouldPreselectAMCoursepackCheckoutOffer();
    },
    getProductId: () => {
      return getAMCoursepackId();
    },
    i18nNamespace: "courseEnhancements:agingMetabolismCoursepack",
  },
  sleep: {
    backgroundColor: compassColors.stream,
    logo: SleepLogo,
    eventNameMap: {
      learnMore: "ClickedLearnMoreSleepEnhancement",
      removed: "RemovedSleepEnhancement",
      added: "AddedSleepEnhancement",
      modalClosed: "SleepEnhancementModalClosed",
    },
    select: selectSleepCoursepack,
    selectPaid: selectPaidSleepCoursepack,
    isOptedIn: sleepCoursepackOptedIn,
    isAccepted: acceptedSleepCoursepackOffer,
    isSurveyEligible: () => {
      return isEligibleForSleepAndMovementSurveys();
    },
    shouldPreselect: () => {
      return shouldPreselectSleepCoursepackCheckoutOffer();
    },
    getProductId: () => {
      return getSleepCoursepackId();
    },
    i18nNamespace: "courseEnhancements:sleep",
  },
  movement: {
    backgroundColor: compassColors.mint,
    logo: ExerciseLogo,
    eventNameMap: {
      learnMore: "ClickedLearnMoreMovementEnhancement",
      removed: "RemovedMovementEnhancement",
      added: "AddedMovementEnhancement",
      modalClosed: "MovementEnhancementModalClosed",
    },
    select: selectMovementCoursepack,
    selectPaid: selectPaidMovementCoursepack,
    isOptedIn: movementCoursepackOptedIn,
    isAccepted: acceptedMovementCoursepackOffer,
    isSurveyEligible: () => {
      return isEligibleForSleepAndMovementSurveys();
    },
    shouldPreselect: () => {
      return shouldPreselectMovementCoursepackCheckoutOffer();
    },
    getProductId: () => {
      return getMovementCoursepackId();
    },
    i18nNamespace: "courseEnhancements:movement",
  },
  glp1CompanionCoursepack: {
    backgroundColor: compassColors.sky,
    logo: Glp1Logo,
    eventNameMap: {
      learnMore: "MedCompanionLearnMore",
      removed: "MedCompanionRemoved",
      added: "MedCompanionAdded",
      modalClosed: "MedCompanionModalClosed",
    },
    select: selectGlp1Coursepack,
    isOptedIn: glp1CoursepackOptedIn,
    isAccepted: acceptedGlp1CompanionOffer,
    isSurveyEligible: () => {
      return shouldSeeGlp1Companion();
    },
    shouldPreselect: () => {
      return glp1CoursepackOptedIn();
    },
    getProductId: () => {
      return getGlp1CoursepackId();
    },
    i18nNamespace: "courseEnhancements:glp1CompanionCoursepack",
  },
};

// Helper function to easily perform filter and map operations across
// course enhancement product ids using the various filter and mapping
// functions defined in each course enhancement entry in COURSE_ENHANCEMENT_DATA_MAP.
// Both filterFunction and mapFunction are optional arguments. If filterFunction
// is not passed in (or passed in as null), then none of the course enhancements
// will be filtered. If mapFunction is not passed in, then the function
// will just return the CE IDs as is.
export const filterAndMapCourseEnhancements = (
  filterFunction?: {
    filterFunctionKey: keyof CourseEnhancementFilterFunctions;
    filterFunctionArgs?: any[];
  },
  mapFunction?: {
    mapFunctionKey: keyof CourseEnhancementMapFunctions;
    mapFunctionArgs?: any[];
  }
) => {
  return getCourseEnhancementProducts()
    .filter((id) =>
      filterFunction
        ? COURSE_ENHANCEMENT_DATA_MAP[id]?.[filterFunction.filterFunctionKey]?.(
            ...(filterFunction.filterFunctionArgs || [])
          )
        : true
    )
    .map((id) => {
      const mappedValue = mapFunction
        ? COURSE_ENHANCEMENT_DATA_MAP[id]?.[mapFunction.mapFunctionKey]?.(
            ...(mapFunction.mapFunctionArgs || [])
          )
        : id;
      return mappedValue;
    })
    .filter(Boolean);
};
