/*
 * Ensure telehealth feedback survey copy is defined here and used as an eum where
 * needed. This enables consistent record keeping between what users see and what
 * we save to the DB.
 * */

import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { getNoomSessionStorage } from "@utils/noomSessionStorage";
import { SessionStorageKey } from "@utils/constants";

export enum QNamesToQText {
  howWasYourVisit = "How was your visit with your practitioner?",
  whyRating = "Tell us why you chose that rating?",
  whyDidYouSelectHigh = "Which of the following best represent your experience?",
  whyDidYouSelectLow = "Which of the following best represent your experience?",
}

export enum QTypes {
  singleChoice = "singleChoice",
  multipleChoiceWithOtherText = "multipleChoiceWithOtherText",
  openText = "openText",
}

export const whyDidYouSelectOptionsGoodExperience = {
  learned: "Learned something new",
  length: "Length of visit was good",
  personalized: "Visit was personalized to my needs",
  clinician: "I liked my clinician",
  none: "None of the above",
  other: "Other",
};

export const whyDidYouSelectOptionsPoorExperience = {
  didntLearn: "Didn't learn anything new",
  tooShort: "Visit was too short",
  notPersonalized: "Visit wasn't personalized enough",
  clinician: "Didn't feel comfortable with the clinician",
  none: "None of the above",
  other: "Other",
};

export const handleTelehealthAnswers = (
  currentQuestionId: keyof SurveyAnswersState,
  surveyAnswers: SurveyAnswersState
): SurveyAnswersState => {
  // Pass through all non-telehealth feedback survey answers
  if (!Object.keys(QNamesToQText).includes(currentQuestionId as string)) {
    return surveyAnswers;
  }

  // Answers are added as properties, so add a property for TH questions that is
  // the patientType.
  const currentAnswers: SurveyAnswersState = {
    patientType: getNoomSessionStorage(SessionStorageKey.patientType),
  };

  switch (currentQuestionId) {
    case "howWasYourVisit":
      currentAnswers[currentQuestionId] = surveyAnswers[currentQuestionId];
      break;
    case "whyDidYouSelectLow":
    case "whyDidYouSelectHigh":
      // Do not save events for potentially sensitive freeform answers to telehealth feedback survey
      if (surveyAnswers[currentQuestionId]) {
        currentAnswers[currentQuestionId] = surveyAnswers[
          currentQuestionId
        ].filter((v) => !v?.other);
      }
      break;
    case "whyRating":
      if (surveyAnswers[currentQuestionId] === "") {
        currentAnswers[currentQuestionId] = "SKIPPED";
      } else if (surveyAnswers[currentQuestionId]) {
        currentAnswers[currentQuestionId] = "REDACTED";
      }
      break;
    default:
      if (surveyAnswers[currentQuestionId]) {
        currentAnswers[currentQuestionId] = "REDACTED";
      }
  }

  return currentAnswers;
};
