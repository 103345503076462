import { sendBeacon } from "../fetch";
import { getRequestMetadata } from "../meristemContext";
import { addMonitoringEventHandler } from "./bus";
import { generateMonitoringProps } from "./prop-registry";
import camelCase from "lodash/camelCase";
import { NOOM_BUYFLOW_EVENT_SHARED_PROPERTIES } from "./events";
import getStore from "../redux/store";
import uuid from "uuid";
import { maybeTrackExperimentStarted } from "@utils/monitoring/experiment-started";

export type ExtraTrackingOptions = {
  blockRoutingToMixpanel?: boolean;
  /**
   * Properties produced as part of an experiment.
   * This field exists to reduce the need to modify event contracts as part of an experiment.
   * Baselined code should not use this field. Instead, introduce a field onto the appropriate event.
   */
  experimentalProperties?: Record<string, string>;
};

const uncapitalize = (str: string) =>
  str.charAt(0).toLocaleLowerCase() + str.slice(1);

addMonitoringEventHandler((event) => {
  if (event.type === "buyflowEvent") {
    sendBuyflowEvent(event.eventName, event.properties, event.extras);
  }
});

addMonitoringEventHandler((event) => {
  if (event.type === "banditEvent") {
    sendBanditEvent(event.eventName, event.properties);
  }
});

const camelCasePropertyRenameMap = {
  browserMajor: "browserMajorVersion",
  city: "geoIpCity",
  continent: "geoIpContinent",
  country: "geoIpCountry",
  subdivision: "geoIpSubdivision",
  fullStorySession: "fullStorySessionUrl",
  gitSha: "buyflowGitSha",
  utmCampaignCurrent: "utmCampaign",
  utmContentCurrent: "utmContent",
  utmMediumCurrent: "utmMedium",
  utmSourceCurrent: "utmSource",
  utmTermCurrent: "utmTerm",
};

export function transformAndFilterProperties(
  sharedProperties: Record<string, string>
) {
  // Some properties sent to mixpanel contain whitespaces. This isn't supported
  // in protobuf so we need to transform them to camelCase.
  const sharedPropertiesCamelCase: Record<string, string> = {};
  Object.keys(sharedProperties).forEach((key) => {
    const camelCaseKey = camelCase(key);
    const camelCaseRenamedKey =
      camelCasePropertyRenameMap[camelCaseKey] || camelCaseKey;

    // Filter out unsupported properties
    if (NOOM_BUYFLOW_EVENT_SHARED_PROPERTIES[camelCaseRenamedKey]) {
      sharedPropertiesCamelCase[camelCaseRenamedKey] = sharedProperties[key];

      // transform any empty strings to null values for easier coalescing. sometimes
      // device info will return empty strings if it can't determine a value.
      if (sharedPropertiesCamelCase[camelCaseRenamedKey] === "") {
        sharedPropertiesCamelCase[camelCaseRenamedKey] = null;
      }
    }
  });

  return sharedPropertiesCamelCase;
}

function sendBuyflowEvent(
  eventName: string,
  properties: JsonObject,
  extras?: ExtraTrackingOptions
) {
  const { meristemTrackingProperties } = getRequestMetadata();

  // Still hacky but to speed up the initial implementation - this will contain
  // all of the properties we are currently sending to mixpanel. Event
  // ingestion lambda will drop all the properties that are not specified in
  // NoomBuyflowEventSharedProperties interface
  const sharedProperties: Record<string, string> = {
    ...meristemTrackingProperties,
    ...generateMonitoringProps(),
    growthUserId: getStore().getState().userData.user_id,
  };

  const sharedPropertiesCamelCase =
    transformAndFilterProperties(sharedProperties);

  // If we want to track myCustomEvent event, expected object that will pass
  // validation in event ingestion lambda is:
  // {
  //   sharedProperties: {
  //     eventTimestamp: ...,
  //   },
  //   noomBuyflowEvent: {
  //     sharedProperties: { ... },
  //     <eventName>: {...},
  //   }
  // }

  const blockRoutingToMixpanel =
    extras && "blockRoutingToMixpanel" in extras
      ? extras.blockRoutingToMixpanel
      : false;

  // Do not track experiment started events for a Noom Event that is being logged to Mixpanel directly
  if (!blockRoutingToMixpanel) {
    // Both shared and event properties can be used for experiment targeting
    const allProperties = {
      ...sharedPropertiesCamelCase,
      ...properties,
    };
    maybeTrackExperimentStarted(eventName, allProperties);
  }

  const eventNameKey = uncapitalize(eventName);
  sendBeacon("/pixel/v1/i/dataStreamEventTracking/", {
    sharedProperties: {
      eventTimestamp: new Date(),
      eventUuid: uuid.v4(),
      blockRoutingToMixpanel,
      experimentalProperties: extras?.experimentalProperties,
    },
    noomBuyflowEvent: {
      sharedProperties: sharedPropertiesCamelCase,
      [eventNameKey]: properties,
    },
  });
}

function sendBanditEvent(eventName: string, properties: JsonObject) {
  const eventNameKey = uncapitalize(eventName);
  sendBeacon("/pixel/v1/i/dataStreamEventTracking/", {
    sharedProperties: {
      eventTimestamp: new Date(),
      eventUuid: uuid.v4(),
    },
    [eventNameKey]: {
      ...properties,
    },
  });
}
