import { trackEvent, trackBuyflowEvent } from "@utils/api/tracker";
import { NoomBuyflowEvents } from "@utils/monitoring/events";

type NoomEventParams = {
  params: {
    eventName: keyof NoomBuyflowEvents;
    properties: NoomBuyflowEvents[keyof NoomBuyflowEvents];
  };
};

/**
 * A simple way to track events via Actions. If you need to pass custom properties
 * into your event, fire the event in a component.
 */
export const mixpanelTrackAction = ({
  params,
}: {
  params: { eventName: string };
}): void => {
  trackEvent(params.eventName);
};

export const noomEventTrackAction = ({
  params: { eventName, properties },
}: NoomEventParams) => {
  trackBuyflowEvent(eventName, properties);
};
