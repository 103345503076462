import { calculateTargetMonthFromDate } from "src/choosePlan/components/plans/Plans";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { calculateBMI } from "src/utils/calculateGoals";
import { getPersonalizedTargetDate } from "src/utils/pace";
import { getIndexFromGraphId } from "src/utils/pace/graphIndex";
import { savePlanToBraze } from "src/utils/plans";
import getStore from "src/utils/redux/store";

export const saveRecommendedPlanToCRM = async () => {
  const state = getStore().getState();
  const surveyAnswers = getSurveyAnswers(state);
  const { currentBMI, targetBMI } = calculateBMI(surveyAnswers);
  const { recommendedPlan } = state;

  const targetDate = getPersonalizedTargetDate(getIndexFromGraphId("medOptIn"));
  const { targetMonthNameFull } = calculateTargetMonthFromDate(targetDate);

  await savePlanToBraze({
    currentBMI,
    targetBMI,
    targetMonthNameFull,
    recommendedPlan,
  });
};
