import { JsonObject } from "type-fest";
import { USER_IS_BOT } from "../botDetector";
import { createCallbackList } from "../callback-list";

type MonitoringEvent =
  | {
      type: "track";
      eventName: string;
      properties: JsonObject;
    }
  | {
      type: "buyflowEvent";
      eventName: string;
      extras?: PropertyBag;
      properties: JsonObject;
    }
  | {
      type: "banditEvent";
      eventName: string;
      properties: JsonObject;
    }
  | {
      type: "mmpEvent";
      eventName: string;
      properties: JsonObject;
    }
  | {
      type: "people_set";
      properties: JsonObject;
    }
  | {
      type: "errorMessage";
      message: string;
      extras?: PropertyBag;
      fingerprint?: string[];
    }
  | {
      type: "exception";
      exception: any;
      extras?: PropertyBag;
      fingerprint?: string[];
    }
  | { type: "flush" };

export type MonitoringEventHandler = (event: MonitoringEvent) => void;

const callbacks = createCallbackList<[MonitoringEvent]>({ ignoreErrors: true });

/**
 * Event bus for allowing for IoC on monitoring events.
 *
 * Different monitoring services can register handlers here to send data
 * for trackEvent, captureException, etc. calls.
 */
export const addMonitoringEventHandler = callbacks.add;

/**
 * Triggers all registered handlers on the event bus.
 *
 * Generally application code should't need to interact with this directly
 * and instead should use the higher-level functions like trackEvent, etc.
 */
export function emitMonitoringEvent(event: MonitoringEvent) {
  if (USER_IS_BOT) {
    return Promise.resolve();
  }

  return callbacks.emit(event);
}

export function flushMonitoring() {
  return emitMonitoringEvent({ type: "flush" });
}

export function addMonitoringFlushHooks() {
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      flushMonitoring();
    }
  });
}
