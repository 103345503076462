import { appConfig } from "src/config";
import { isDevMode } from "./userSegment/features";

export const getMeristemConfigValue = (key: string) => {
  const { meristemConfig } = appConfig;
  // If in dev mode, also look for the key in the url for easier testing
  if (isDevMode()) {
    return (
      new URLSearchParams(window.location.search).get(key) ||
      meristemConfig[key]
    );
  }

  return meristemConfig[key];
};
