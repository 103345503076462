import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, CoreReduxState } from "./store";

/**
 * Provides a typed wrapper for createAsyncThunk, typed to our app and utilizing
 * type inference for parameters and return values.
 */
export function createAppAsyncThunk<Params, Returned>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    Params,
    {
      dispatch: AppDispatch;
      state: CoreReduxState;
    }
  >
) {
  return createAsyncThunk<
    Returned,
    Params,
    {
      dispatch: AppDispatch;
      state: CoreReduxState;
    }
  >(typePrefix, payloadCreator);
}
