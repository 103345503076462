import { css } from "@emotion/react";
import { compassColors } from "src/utils/styling";

// Headline styles.
export const fontHeadline = css`
  font-family: "Untitled Serif", serif;
`;

export const headline = css`
  ${fontHeadline}
  font-weight: 500;
  font-style: normal;
  /* TODO(claire): move to global styles as default font color across app. */
  color: ${compassColors.black};
`;

export const fontXlHeadline = css`
  ${headline};
  font-size: 40px;
  line-height: 50px;
`;

export const fontLargeHeadline = css`
  ${headline};
  font-size: 28px;
  line-height: 36px;
`;

export const fontMediumHeadline = css`
  ${headline};
  font-size: 24px;
  line-height: 32px;
`;

export const fontSmallHeadline = css`
  ${headline};
  font-size: 20px;
  line-height: 26px;
`;

export const fontMiniHeadline = css`
  ${headline};
  font-size: 16px;
  line-height: 24px;
`;

// Body styles.
export const mediumLight = css`
  font-weight: 400;
`;
export const medium = css`
  font-weight: 500;
`;

// TODO(claire): Define the base properties of these styles as the
// global defaults across the app.
export const fontBody = css`
  font-family: "Untitled Sans", sans-serif;
`;

const base = css`
  ${fontBody}
  color: ${compassColors.black};
  font-style: normal;
  font-weight: normal;

  // Bold text should always be 500 (medium)
  & b,
  & strong {
    ${medium}
  }
`;

export const fontBase = base;

export const fontP1Regular = css`
  ${base};
  font-size: 20px;
  line-height: 28px;
`;

export const fontP1Medium = css`
  ${fontP1Regular};
  ${medium};
`;

export const fontP2Regular = css`
  ${base};
  font-size: 18px;
  line-height: 26px;
`;

export const fontP2Medium = css`
  ${fontP2Regular};
  ${medium};
`;

export const fontP3Regular = css`
  ${base};
  font-size: 16px;
  line-height: 24px;
`;

export const fontP3Medium = css`
  ${fontP3Regular};
  ${medium};
`;

export const fontP4Regular = css`
  ${base};
  font-size: 14px;
  line-height: 20px;
`;

export const fontP4Medium = css`
  ${fontP4Regular};
  ${medium};
`;

export const fontP5 = css`
  ${base};
  font-size: 10px;
  line-height: 15px;
`;

export const fontP5Regular = css`
  ${base};
  font-size: 10px;
  line-height: 15px;
`;

// Overline styles.
export const fontOverline = css`
  font-family: "Brown LL", sans-serif;
`;

export const overline = css`
  ${fontOverline}
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03em;
  /* TODO(claire): move to global styles as default font color across app. */
  color: ${compassColors.black};
`;

export const fontTitle = css`
  ${overline};
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
`;

export const fontLabel = css`
  ${overline};
  font-size: 12px;
  line-height: 18px;
`;

export const fontCaption = css`
  ${overline};
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
`;
