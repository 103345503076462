import React from "react";

import { getWeightInformation } from "@utils/calculateGoals";

import { getImportantEventSubtitle } from "src/graphs/weightGraph/personalizedPaceWeightGraph";

import i18n from "@i18n";
import {
  getCachedPaceFromGraphId,
  getIndexFromGraphId,
  getPreviousGraphId,
} from "@utils/pace/graphIndex";
import { isImportantDateEligible } from "src/utils/userSegment/features";
import { getPersonalizedTargetDate } from "src/utils/pace";
import { formatUnit } from "src/utils/Unit";
import { AnimateDate } from "src/components/weight-graph/AnimateDate";
import { WeightGraphTitle } from "src/components/weight-graph/WeightGraphTitle";
import { PersonalizedPaceWeightGraph } from "src/components/weight-graph/PersonalizedPaceWeightGraphComponent";
import { useSurveyAnswers } from "src/hooks/survey/answers";

export function WeightGraph({
  questionId,
  highlight,
  addFivePercentMarker,
  showIncreasedPace,
  ariaLabel,
  ariaHidden = false,
}: {
  questionId: string;
  highlight?: string;
  addFivePercentMarker?: boolean;
  showIncreasedPace?: boolean;
  ariaLabel?: string;
  ariaHidden?: boolean;
}) {
  const surveyAnswers = useSurveyAnswers();

  // Weight
  const {
    weightValueKg,
    weightDisplayUnit,
    targetWeightKg,
    targetWeightDisplay,
  } = getWeightInformation(surveyAnswers);

  const weightText = formatUnit(targetWeightDisplay, weightDisplayUnit);

  // Date
  const targetDateFromPace = getPersonalizedTargetDate(
    getIndexFromGraphId(questionId),
    surveyAnswers
  );
  const previousTargetDateFromPace = getPersonalizedTargetDate(
    Math.max(getIndexFromGraphId(questionId) - 1, 0),
    surveyAnswers
  );

  // Animate the date if the user has seen the prior graph
  const shouldTickDate = !!getCachedPaceFromGraphId(
    getPreviousGraphId(questionId),
    surveyAnswers
  );

  const headerText = i18n.t(
    showIncreasedPace !== false
      ? "survey:questions:weightGraph:headerIncreasedPace"
      : "survey:questions:weightGraph:headerPersonalizedPace"
  );

  const subtitle =
    isImportantDateEligible() &&
    getImportantEventSubtitle(
      weightValueKg,
      targetWeightKg,
      targetDateFromPace,
      surveyAnswers
    );

  return (
    <>
      <WeightGraphTitle
        highlight={highlight}
        header={headerText}
        targetWeight={weightText}
        date={
          <AnimateDate
            startingDate={previousTargetDateFromPace}
            finalDate={targetDateFromPace}
            animateFirstStep={showIncreasedPace}
            shouldAnimate={shouldTickDate}
          />
        }
        subtitle={subtitle}
      />
      <div css={{ paddingTop: "24px" }}>
        <PersonalizedPaceWeightGraph
          questionId={questionId}
          startWeightKg={weightValueKg}
          idealWeightKg={targetWeightKg}
          surveyAnswers={surveyAnswers}
          addFivePercentMarker={addFivePercentMarker}
          ariaLabel={ariaLabel}
          ariaHidden={ariaHidden}
        />
      </div>
    </>
  );
}
