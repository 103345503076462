import Cookies from "js-cookie";
import { getSubdivision } from "../meristemContext";
import { isAppIos, isEU, isUS } from "../userSegment";
import { bootstrapOneTrust } from "./one-trust";
import {
  bootstrapInAppIOS,
  bootstrapOptOut,
  OPT_OUT_COOKIE_NAME,
} from "./opt-out";

export enum LibCategories {
  strictlyNecessary = "C0001",
  performanceCookies = "C0002",
  functionalCookies = "C0003",
  targetingCookies = "C0004",
  socialMediaCookies = "C0005",
}

const waiting = new Set<() => void>();

const CONSENT_KEY = "noom_consent";
let consent: LibCategories[] = (() => {
  try {
    return JSON.parse(localStorage.getItem(CONSENT_KEY));
  } catch (err) {
    return undefined;
  }
})();

export const optOutStates = [
  "CA",
  "CO",
  "CT",
  "MT",
  "NV",
  "OR",
  "TX",
  "UT",
  "VA",
  "WA",
];
/** Which consent framework should be applied for a given user. */
export function consentFramework() {
  if (isAppIos()) {
    return "inAppIos";
  }

  if (isEU()) {
    return "gdpr";
  }

  if (
    (isUS() && optOutStates.includes(getSubdivision())) ||
    // honor past opt-out cookie set even if the user is no longer in the affected
    // jurisdiction. does not cover the case where we need to check the submitted
    // email to determine opt-out status.
    Cookies.get(OPT_OUT_COOKIE_NAME) === "true"
  ) {
    return "optOut";
  }

  return "none";
}

let consentLoader: Promise<void> | undefined;

export function bootstrapConsent() {
  if (consentLoader) {
    return consentLoader;
  }

  if (consentFramework() === "inAppIos") {
    setConsent([LibCategories.strictlyNecessary]);
    consentLoader = bootstrapInAppIOS();
  } else if (consentFramework() === "optOut") {
    consentLoader = bootstrapOptOut();
  } else if (consentFramework() === "gdpr") {
    consentLoader = bootstrapOneTrust(consent);
  } else {
    // No consent framework, grant implicit consent
    setConsent([
      LibCategories.strictlyNecessary,
      LibCategories.performanceCookies,
      LibCategories.functionalCookies,
      LibCategories.targetingCookies,
      LibCategories.socialMediaCookies,
    ]);
    consentLoader = Promise.resolve();
  }
  return consentLoader;
}

export function setConsent(categories: LibCategories[]) {
  consent = categories;

  // Store consent in local storage for immediate init on future visits.
  // This lets us short-circuit lib loading once user has consented.
  localStorage.setItem(CONSENT_KEY, JSON.stringify(categories));

  for (const waiter of waiting) {
    waiter();
  }
}

export function getConsent(): readonly LibCategories[] {
  return consent;
}

export function shouldIOSUserSeeOptOut() {
  return (
    consentFramework() === "inAppIos" &&
    isUS() &&
    optOutStates.includes(getSubdivision())
  );
}

/**
 * Checks if all of the given categories currently have consent.
 */
export function allowCategories(categories: LibCategories[]) {
  return categories
    .filter((category) => category !== LibCategories.strictlyNecessary)
    .every((category) => consent?.includes(category));
}

export function waitForConsent(categories: LibCategories[]): Promise<void> {
  return new Promise((resolve) => {
    if (allowCategories(categories)) {
      resolve();
    } else {
      waiting.add(function waiter() {
        if (allowCategories(categories)) {
          waiting.delete(waiter);
          resolve();
        }
      });
    }
  });
}
