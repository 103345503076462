import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const purchasedNoomClinical = createSlice({
  name: "purchasedNoomClinical",
  initialState: false,
  reducers: {
    setPurchasedNoomClinical(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setPurchasedNoomClinical } = purchasedNoomClinical.actions;

export default purchasedNoomClinical;
