import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// NOTE: If you are looking for Commitment Contract data for
// the counter offer flow, you need to use CommitmentContractForServerContext.
// This is solely for the opt in.
export type CommitmentContract = {
  accessCode: string;
  commitmentEndDate: string;
  isPersonalizedCommitmentEndDate: boolean;
  personalizedPlanDuration: number;
  currency: string;
  currencySymbol: string;
  subscriptionId: string;
  cancellationFee?: number;
};

const initialState: CommitmentContract = {
  accessCode: null,
  commitmentEndDate: null,
  isPersonalizedCommitmentEndDate: true,
  personalizedPlanDuration: null,
  currency: null,
  currencySymbol: null,
  subscriptionId: null,
  cancellationFee: null,
};

const commitmentContractOptInSlice = createSlice({
  name: "commitmentContractOptIn",
  initialState,
  reducers: {
    setCommitmentContractDetails(
      state,
      action: PayloadAction<
        Omit<CommitmentContract, "cancellationFee" | "loadingData">
      >
    ) {
      return { ...state, ...action.payload };
    },
    setCancellationFee(
      state,
      action: PayloadAction<Pick<CommitmentContract, "cancellationFee">>
    ) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setCommitmentContractDetails, setCancellationFee } =
  commitmentContractOptInSlice.actions;

export default commitmentContractOptInSlice;
