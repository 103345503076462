import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AddOnsState = {
  purchasedNoomPremium: boolean;
  purchasedNoomPremiumWithTextCoaching: boolean;
  purchasedNoomPremiumSubscription: boolean;
  purchasedFastForward: boolean;
  purchasedNoomSummit: boolean;
  skippedNoomSummit: boolean;
  skippedProbioticsDelivery: boolean;
  skippedZumbaOffer: boolean;
};

const initialState: AddOnsState = {
  purchasedNoomPremium: false,
  purchasedNoomPremiumWithTextCoaching: false,
  purchasedNoomPremiumSubscription: false,
  purchasedFastForward: false,
  purchasedNoomSummit: false,
  skippedNoomSummit: false,
  skippedProbioticsDelivery: false,
  skippedZumbaOffer: false,
};

const addOnsSlice = createSlice({
  name: "addOns",
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setPurchasedNoomPremium(state, action: PayloadAction<boolean>) {
      state.purchasedNoomPremium = action.payload;
    },
    setPurchasedNoomPremiumWithTextCoaching(
      state,
      action: PayloadAction<boolean>
    ) {
      state.purchasedNoomPremiumWithTextCoaching = action.payload;
    },
    setPurchasedNoomPremiumSubscription(state, action: PayloadAction<boolean>) {
      state.purchasedNoomPremiumSubscription = action.payload;
    },
    setPurchasedFastForward(state, action: PayloadAction<boolean>) {
      state.purchasedFastForward = action.payload;
    },
    setPurchasedNoomSummit(state, action: PayloadAction<boolean>) {
      state.purchasedNoomSummit = action.payload;
    },
    setSkippedNoomSummit(state, action: PayloadAction<boolean>) {
      state.skippedNoomSummit = action.payload;
    },
    setSkippedProbioticsDelivery(state, action: PayloadAction<boolean>) {
      state.skippedProbioticsDelivery = action.payload;
    },
    setSkippedZumbaOffer(state, action: PayloadAction<boolean>) {
      state.skippedZumbaOffer = action.payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const {
  setPurchasedNoomPremium,
  setPurchasedNoomPremiumWithTextCoaching,
  setPurchasedNoomPremiumSubscription,
  setPurchasedFastForward,
  setPurchasedNoomSummit,
  setSkippedNoomSummit,
  setSkippedProbioticsDelivery,
  setSkippedZumbaOffer,
} = addOnsSlice.actions;

export default addOnsSlice;
