import { ReferralMethod, referralMethods } from "../monitoring/events";
import { getURLParams } from "@utils/urlParams";

export type ReferralShareSource = typeof referralMethods[number];

const SHARE_SOURCES_IDENTIFIERS: Record<ReferralShareSource, string> = {
  sms: "S",
  link: "L",
  email: "E",
  buyflow: "B",
  shareApi: "A",
  familyPlan: "F",
};

const SHARE_SOURCES_MAPPING: Record<string, ReferralShareSource> = Object.keys(
  SHARE_SOURCES_IDENTIFIERS
).reduce((prev, curr) => {
  return { ...prev, [SHARE_SOURCES_IDENTIFIERS[curr]]: curr };
}, {});

export function getTrackingIdentifierForShareSource(
  shareSource: ReferralShareSource
) {
  return SHARE_SOURCES_IDENTIFIERS[shareSource];
}

export function getReferralLinkWithTracking(
  referralCode: string,
  shareSource: ReferralShareSource
) {
  const sourceTrackingIdentifier =
    getTrackingIdentifierForShareSource(shareSource);
  const trackingTag = getURLParams().refTag;

  let url = `https://noom.com/r/${referralCode}${sourceTrackingIdentifier}`;
  if (trackingTag) {
    url += `?${trackingTag}`;
  }

  return url;
}

/**
 * In order to keep the link as short and pretty as possible,
 * we've opted to optionally append the share source
 * as a single character after the 8 characters of the referral code
 */
export function extractShareSourceFromReferralCode(
  referralCodeWithSource = ""
) {
  const withShareSource = referralCodeWithSource.length > 8;
  const referralCode = referralCodeWithSource.slice(0, 8);

  const shareSource = withShareSource
    ? SHARE_SOURCES_MAPPING[referralCodeWithSource.slice(-1)]
    : undefined;

  return { referralCode, shareSource };
}

/**
 * This function will make sure that share source contains a valid
 * value so that RefereeSignup event does not get dropped by event
 * ingestion lambda
 */
export function getReferralMethodFromShareSource(
  shareSource: string
): ReferralMethod {
  if (referralMethods.includes(shareSource as any)) {
    return shareSource.toUpperCase() as ReferralMethod;
  }
  return null;
}
