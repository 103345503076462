import React, { MutableRefObject } from "react";
import i18n from "@i18n";

import styled from "@emotion/styled";
import { fontP3Regular } from "src/design-system/styles/fonts";
import { compassColors } from "src/utils/styling";
import { pad } from "src/utils/datetime";
import { useInViewport } from "src/hooks/viewport";
import { OverlayFooter } from "src/components/footer/OverlayFooter";
import { getCoursePackTextPrefix } from "src/utils/paymentAddon";

/** In pixels. */
export const PURCHASE_BANNER_HEIGHT = 60;

const PurchaseTimerBanner = styled(OverlayFooter)`
  display: flex;
  height: ${PURCHASE_BANNER_HEIGHT}px;
  background-color: ${compassColors.lagoon};
  color: ${compassColors.white};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
  transition: all 500ms ease;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  ${fontP3Regular};
  text-align: center;
  color: ${compassColors.white};
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.63);
`;

const Time = styled.p`
  ${fontP3Regular};
  color: ${compassColors.white};
  margin-left: 1rem;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.63);
`;

type Props<T extends HTMLElement = HTMLElement> = {
  text?: string;
  opacity?: number;
  minutes: number;
  seconds: number;
  waypointRef: MutableRefObject<T>;
};

export default function StickyCountdown<T extends HTMLElement = HTMLElement>({
  opacity = 0.8,
  waypointRef,
  minutes,
  seconds,
  text = i18n.t(
    `${getCoursePackTextPrefix("reservationTimer")}:stickyCountdown`
  ),
}: Props<T>) {
  const active = !useInViewport(waypointRef);

  return (
    <PurchaseTimerBanner css={active ? { opacity } : { opacity: 0 }}>
      <Inner>
        <Text>{text}</Text>
        <Time>
          <span>{pad(minutes, 2)}</span>:<span>{pad(seconds, 2)}</span>
        </Time>
      </Inner>
    </PurchaseTimerBanner>
  );
}
