/* eslint-disable no-param-reassign */
import type { Event, EventHint } from "@sentry/types";

export class NoomError extends Error {
  tags: Record<string, string>;
  context: Record<string, unknown>;
  fingerprint: string[];

  private delegateMetric: boolean;

  constructor(
    message: string,
    options: {
      cause?: Error;
      tags?: Record<string, string>;
      context?: Record<string, unknown>;
      fingerprint?: string[];
      delegateMetric?: boolean;
    } = {}
  ) {
    super(message);

    // Polyfill the new spec until have critical mass to switch
    // https://2ality.com/2021/06/error-cause.html
    if (options.cause) {
      const { cause } = options;
      this.cause = cause;
      if ("stack" in cause && __NODE_ENV__ !== "production") {
        // Only augment the stack in development as this creates confusing traces
        // in sentry
        this.stack = `${this.stack}\nCAUSE: ${cause.stack}`;
      }
    }

    this.tags = options.tags;
    this.context = options.context;
    this.fingerprint = options.fingerprint;
    this.delegateMetric = !!options.delegateMetric;
  }

  override toString() {
    const baseMessage = super.toString();
    const cause = this.cause ? `\nCaused by: ${this.cause}` : "";
    const tags = this.tags ? `\nTags: ${JSON.stringify(this.tags)}` : "";
    const context = this.context
      ? `\nContext: ${JSON.stringify(this.context)}`
      : "";
    const fingerprint = this.fingerprint
      ? `\nFingerprint: ${JSON.stringify(this.fingerprint)}`
      : "";
    return `${baseMessage}${cause}${tags}${context}${fingerprint}`;
  }

  /**
   * Subclasses can override to redirect from sentry to mixpanel.
   *
   * @returns true if error was logged and should not be sent to mixpanel.
   */
  trackMetric() {
    if (this.delegateMetric && (this.cause as NoomError)?.trackMetric?.()) {
      return true;
    }
    return false;
  }

  /**
   * Adds error specific data to the sentry scope. Override as necessary.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  extendSentry(event: Event, hint: EventHint) {
    if (this.tags) {
      Object.assign(event.tags, this.tags);
    }
    if (this.fingerprint && !event.fingerprint) {
      event.fingerprint = this.fingerprint;
    }
    if (this.context) {
      event.contexts.NoomError = this.context;
    }
  }
}
