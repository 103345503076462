import { ShippingInfo, ShippingInfoError } from "src/funnels/offers/models";
import { typedKeys } from "./typeWrappers";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import { getCountryCode } from "./meristemContext";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { UnitType } from "src/utils/Unit";

/**
 * Check if value is a valid email address
 * @see https://www.w3resource.com/javascript/form/email-validation.php
 * @see https://www.jochentopf.com/email/chars.html
 */
export function isEmail(val: string) {
  // uncomment for loose mode
  // return val && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(val.trim());

  // strict mode:
  // * allow any valid characters in personal data
  // * ~> except "." cannot start or end personal data
  // * domain segments may repeat (no TLD validation)
  // * disallow "_" because hostnames prevent it
  if (!val || !val.length) return false;
  let [user, domain] = val.trim().split("@");

  try {
    // handle non-english domains
    const tmp = new URL(`http://${domain || ""}`).hostname;
    domain = tmp;
  } catch (e) {
    //
  }

  return (
    user &&
    domain &&
    user.length <= 64 &&
    domain.length <= 253 &&
    !/(^\.|\.{2}|[.,();]$)/.test(user) &&
    /^[\w!#$%&'*+-<>/=?^_`{|}~."\\()]{1,64}$/.test(user) &&
    /^[a-z0-9]+(?:[a-z0-9-.]{0,61}[a-z0-9])?(\.([a-z0-9]([-]{2})?){2,})$/i.test(
      domain
    )
  );
}

const forbiddenDomains = [
  "@gmail.com",
  "@yahoo.com",
  "@icloud.com",
  "@hotmail.com",
  "@aol.com",
  "@outlook.com",
];

/**
 * Check if given email value contains a forbidden domain
 */
export function containsForbiddenDomain(email: string) {
  return forbiddenDomains.some((domain) => email?.endsWith(domain));
}

/**
 * Check if a value is an acceptable work email, excluding certain common domains
 * @param input value to be checked
 */
export function isWorkEmail(input: string) {
  return isEmail(input) && !containsForbiddenDomain(input);
}

/**
 * Super simple validation for a name.
 * TODO(patrick): We should add stronger validation for names.
 */
export function isName(name: string) {
  return !!name;
}

/**
 * Validate shipping address for upsell purchases. Only validates
 * if the field is empty or not.
 */
export function validateAddress(shippingInfo: ShippingInfo) {
  const error: ShippingInfoError = {};

  typedKeys(shippingInfo).forEach((field) => {
    switch (field) {
      case "firstName":
      case "lastName":
      case "phone":
        error[field] = !shippingInfo[field];
        break;
      case "address":
        Object.keys(shippingInfo.address).forEach((addressField) => {
          switch (addressField) {
            case "street":
            case "city":
            case "zipcode":
              error[addressField] = !shippingInfo.address[addressField];
              break;
            default:
            // do nothing
          }
        });
        break;
      default:
      // do nothing
    }
  });

  return error;
}

export function isValidZipCode(
  zipcode: string,
  countryCode: string = getCountryCode()
): boolean {
  // if we don't know how to validate, trust the user
  return (
    !postcodeValidatorExistsForCountry(countryCode) ||
    postcodeValidator(zipcode, countryCode)
  );
}

export const MIN_WEIGHT = 0;
export const MAX_WEIGHT = 2000;
export const MIN_HEIGHT_IN = 0;
export const MAX_HEIGHT_IN = 12;
export const MIN_HEIGHT_FT = 0;
export const MAX_HEIGHT_FT = 99;
const MIN_HEIGHT_CM = 0;
const MAX_HEIGHT_CM = 300;
const MIN_WEIGHT_KG = 0;
const MAX_WEIGHT_KG = 1000;

export function validateHeight(_, answers: SurveyAnswersState) {
  const { heightCm } = answers;

  if (!heightCm || heightCm < MIN_HEIGHT_CM || heightCm > MAX_HEIGHT_CM) {
    return false;
  }

  return true;
}

export function validateWeight(_, answers: SurveyAnswersState) {
  const { weightKg } = answers;

  if (!weightKg || weightKg < MIN_WEIGHT_KG || weightKg > MAX_WEIGHT_KG) {
    return false;
  }

  return true;
}

/**
 * Function for validating height and weight values that can be used with
 * useSurvey. Only validates metric units.
 * @param {string[]} _ The current saved survey answer, based on the question ID.
 * Passed by default to the useSurvey custom validator function, but not useful here.
 * @param {SurveyAnswerState} answers Current saved survey answers.
 */
export function validateHeightWeight(_, answers: SurveyAnswersState) {
  return validateHeight(_, answers) && validateWeight(_, answers);
}

/**
 * Function that determines whether or not we think a user has finished typing
 * in their weight
 * We determine this based on the number of digits typed into the input.
 */
export function weightInputCanShowCongrats(
  weightUnit: UnitType,
  weightImp: number,
  weightKg: number
) {
  switch (weightUnit.id) {
    case "STONE":
      return !!weightKg && weightKg > 0;
    case "POUND":
      return !!weightImp && weightImp >= 100;
    case "KILOGRAM":
      return !!weightKg && weightKg >= 10;
    default:
      return false;
  }
}

export function validatePositiveNumber(value: any) {
  const num = parseFloat(value);
  return !isNaN(num) && isFinite(Number(value)) && num > 0;
}
