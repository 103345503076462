import { convertLbToKg } from "src/primitive/UnitUtils";

/**
 * CALCULATING ADJUSTED TARGET DATE
 * Source: https://docs.google.com/document/d/1VayIiTAk2E3QjyJ6AuvngLUeTcyoGNnZd3CgN94oRgc/edit#
 *
 * 1. Calculate the max/min time based on user's weight loss target. The
 *    calculation is based on a function that generates a "base" time and max/min windows
 *    added to the base time. For a given target weight loss these represent the possible
 *    range for a user's weight loss date.
 * 2. Find the ratio along the 1-2lb pace range that the user is in
 * 3. Apply the ratio to the adjustment window to determine where on the range the user falls
 */

const DAY_OFFSET_PER_GRAPH = [30, 20, 10, 0]; // Max days added to each weight graph as a buffer

function getDayOffsetForGraph(graphIndex: number, targetInKg: number) {
  const confidenceOffset = DAY_OFFSET_PER_GRAPH[graphIndex];
  return confidenceOffset * (0.5 + (0.5 * targetInKg) / toKg(80));
}

function toKg(lbs: number) {
  return convertLbToKg({ mainUnitValue: lbs }).mainUnitValue;
}

function getBaseDays(targetInKg: number) {
  if (targetInKg < toKg(10)) {
    return 30 + (targetInKg / 10) * 30;
  }

  const mod = 0.0;
  const maxDays = 280;
  const pace = 1 + mod * (1 - Math.max(toKg(60) - targetInKg, 0) / toKg(60));
  const extra = targetInKg - toKg(10);
  const time = extra / pace;
  return time / (toKg(70) / (1 + mod) / (maxDays - 2)) + 45;
}

function getMaxAdjWindow(targetInKg: number) {
  return Math.min((targetInKg / toKg(80)) * 70 + 10, 40);
}

function getMinAdjWindow(targetInKg: number) {
  const timeFloor = (targetInKg * 7) / toKg(2) - getBaseDays(targetInKg);

  if (targetInKg < toKg(10)) {
    return -5;
  }

  return Math.max(timeFloor, -5 - (30 * (targetInKg - toKg(10))) / toKg(50));
}

export function getAdjustedTime(
  pace: number,
  graphIndex: number,
  targetInKg: number
) {
  const maxAdjWindow = getMaxAdjWindow(targetInKg);
  const minAdjWindow = getMinAdjWindow(targetInKg);
  const baseTime = getBaseDays(targetInKg);

  // Start at max time
  const maxTime = baseTime + maxAdjWindow;

  // Find % along the 1-2lbs pace range
  const paceRatio = (Math.max(pace, toKg(1)) - toKg(1)) / toKg(1);

  // Derive adjustment
  const targetRange = maxAdjWindow - minAdjWindow;
  const adjustment = targetRange * paceRatio;

  return maxTime - adjustment + getDayOffsetForGraph(graphIndex, targetInKg);
}
