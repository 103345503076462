import { ActionHandler, ActionHandlerParams } from "..";
import {
  trackNoomVibeOfferSuppressed,
  NoomVibeOfferId,
} from "src/utils/noomVibe";

type Params = {
  offerId: NoomVibeOfferId;
};

export const trackVibeOfferSuppressed: ActionHandler = (
  actionParams: {
    params: Params;
  } & ActionHandlerParams
): void => {
  trackNoomVibeOfferSuppressed(actionParams?.params?.offerId);
};
