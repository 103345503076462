import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { SessionStorageKey } from "@utils/constants";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "@utils/noomSessionStorage";

export type LabData = {
  contentType: string;
  title: string;
  url: string;
};

export type PharmacyAddress = {
  address: string;
  city: string;
  name: string;
  primaryPhone: string;
  primaryFax: string;
  specialty1: string;
  specialty2: string;
  state: string;
  zipCode: string;
};

export type LabsStatus =
  | "ORDER"
  | "SKIP"
  | "CONFIRM_SKIP"
  | "CONFIRM_ORDER"
  | "UPLOAD"
  | "";
export type LabProvider = "Labcorp" | "Quest Diagnostics";

export type PatientIntakeSessionStorage = {
  address1: string;
  address2: string;
  city: string;
  stateCode: string;
  postalCode: string;
  searchPharmacyPostalCode: string;
  searchPharmacyName: string;
  choosePharmacyDoseSpotId: string;
  choosePharmacyAddress: PharmacyAddress;
  phoneNumber: string;
  heightFt: string;
  heightIn: string;
  weightLb: string;
  conditions: string[];
  a1c: string;
  a1cDate: string;
  useMedications: string;
  medications: string;
  hasMedicationAllergies: string;
  medicationAllergies: string;
  useDrugs: string;
  useTobacco: string;
  alcoholFrequency: string;
  selfAssessedHealth: string;
  patientAcknowledgement: string;
  pregnantOrBreastfeeding: string;
  familyHistory: string[];
  eatingDisorder: string;
  thoughtsSuicideOrSelfHarm: string;
  heartRate: string;
  heartRateDate: string;
  systolicBloodPressure: string;
  diastolicBloodPressure: string;
  bloodPressureDate: string;
  historyBariatricSurgery: string;
  hasOtherSurgeries: string;
  otherSurgeries: string;
  useAltoPharmacy: string;
  labData: LabData;
  hasRecentLabs: string;
  // SteadyMD Intake changes
  familyHistoryThyroidCancer: string;
  conditionsExisting: string[];
  bloodPressureSimple: string;
  heartRateSimple: string;
  lifestyleImpacts: string[];
  suicideOrSelfHarm1: string;
  suicideOrSelfHarm2: string;
  telehealthSignupDate: string;
  // SteadyMD Insurance Changes
  insuranceCoverage: string;
  insurancePayer: string;
  insuranceIsPrimarySubscriber: string;
  insurancePrimarySubscriberFirstName: string;
  insurancePrimarySubscriberLastName: string;
  insurancePrimarySubscriberDob: string;
  insuranceMemberId: string;
  insuranceGroupId: string;
  insuranceRxBin: string;
  insuranceRxPcn: string;
  insuranceRxGroup: string;
  labsStatus: LabsStatus;
  labProvider: LabProvider;
  hasLabs: boolean;
  hasOrderedLabs: boolean;
  hasUploadedLabs: boolean;
  patientInformationChanged: boolean | undefined;
};

type Context = [
  PatientIntakeSessionStorage,
  React.Dispatch<React.SetStateAction<PatientIntakeSessionStorage>>
];

const PatientIntakeSessionStorageContext = createContext<Context>(null);

export const getPatientIntakeSessionStorage = () => {
  const stored = getNoomSessionStorage(SessionStorageKey.patientIntake);
  return (stored ?? {
    address1: "",
    address2: "",
    city: "",
    stateCode: "",
    postalCode: "",
    searchPharmacyPostalCode: "",
    searchPharmacyName: "",
    choosePharmacyDoseSpotId: "",
    choosePharmacyAddress: null,
    phoneNumber: "",
    heightFt: "",
    heightIn: "",
    weightLb: "",
    conditions: [],
    a1c: "",
    a1cDate: "",
    useMedications: "",
    medications: "",
    hasMedicationAllergies: "",
    medicationAllergies: "",
    useDrugs: "",
    useTobacco: "",
    alcoholFrequency: "",
    selfAssessedHealth: "",
    patientAcknowledgement: "",
    pregnantOrBreastfeeding: "",
    familyHistory: [],
    eatingDisorder: "",
    thoughtsSuicideOrSelfHarm: "",
    heartRate: "",
    heartRateDate: "",
    systolicBloodPressure: "",
    diastolicBloodPressure: "",
    bloodPressureDate: "",
    historyBariatricSurgery: "",
    hasOtherSurgeries: "",
    otherSurgeries: "",
    useAltoPharmacy: "",
    labData: null,
    hasRecentLabs: "",
    // SteadyMD Intake
    familyHistoryThyroidCancer: "",
    conditionsExisting: [],
    bloodPressureSimple: "",
    heartRateSimple: "",
    lifestyleImpacts: [],
    suicideOrSelfHarm1: "",
    suicideOrSelfHarm2: "",
    telehealthSignupDate: "",
    // SteadyMD Insurance
    insuranceCoverage: "",
    insurancePayer: "",
    insuranceIsPrimarySubscriber: "",
    insurancePrimarySubscriberFirstName: "",
    insurancePrimarySubscriberLastName: "",
    insurancePrimarySubscriberDob: "",
    insuranceMemberId: "",
    insuranceGroupId: "",
    insuranceRxBin: "",
    insuranceRxPcn: "",
    insuranceRxGroup: "",
    labsStatus: "",
    labProvider: "",
    hasLabs: undefined,
    hasOrderedLabs: false,
    hasUploadedLabs: false,
    patientInformationChanged: undefined,
  }) as PatientIntakeSessionStorage;
};

export const setPatientIntakeSessionStorage = (
  newValue: PatientIntakeSessionStorage
) => setNoomSessionStorage(SessionStorageKey.patientIntake, newValue);

export const PatientIntakeSessionStorageProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [patientIntakeState, setPatientIntakeState] = useState(
    getPatientIntakeSessionStorage
  );

  const context = useMemo<Context>(
    () => [patientIntakeState, setPatientIntakeState],
    [patientIntakeState, setPatientIntakeState]
  );

  return (
    <PatientIntakeSessionStorageContext.Provider value={context}>
      {children}
    </PatientIntakeSessionStorageContext.Provider>
  );
};

export const usePatientIntakeSessionStorage = (): [
  PatientIntakeSessionStorage,
  any
] => {
  const [patientIntakeState, setPatientIntakeState] =
    useContext(PatientIntakeSessionStorageContext) || [];

  if (!patientIntakeState && __NODE_ENV__ !== "production") {
    throw new Error(
      `patientIntakeSessionStorage must be used within PatientIntakeSessionStorageContext`
    );
  }

  const getPatientIntakeSetter =
    <
      K extends keyof PatientIntakeSessionStorage,
      V extends PatientIntakeSessionStorage[K]
    >(
      key: K
    ) =>
    (value: V) => {
      // sets both the patient intake context and session storage
      setPatientIntakeState((previousPatientIntakeState) => {
        const newPatientIntakeState = { ...previousPatientIntakeState };
        newPatientIntakeState[key] = value;
        setPatientIntakeSessionStorage(newPatientIntakeState);
        return newPatientIntakeState;
      });
    };

  return [
    patientIntakeState,
    {
      setAddress1: getPatientIntakeSetter("address1"),
      setAddress2: getPatientIntakeSetter("address2"),
      setCity: getPatientIntakeSetter("city"),
      setStateCode: getPatientIntakeSetter("stateCode"),
      setPostalCode: getPatientIntakeSetter("postalCode"),
      setSearchPharmacyPostalCode: getPatientIntakeSetter(
        "searchPharmacyPostalCode"
      ),
      setSearchPharmacyName: getPatientIntakeSetter("searchPharmacyName"),
      setChoosePharmacyDoseSpotId: getPatientIntakeSetter(
        "choosePharmacyDoseSpotId"
      ),
      setChoosePharmacyAddress: getPatientIntakeSetter("choosePharmacyAddress"),
      setPhoneNumber: getPatientIntakeSetter("phoneNumber"),
      setHeightFt: getPatientIntakeSetter("heightFt"),
      setHeightIn: getPatientIntakeSetter("heightIn"),
      setWeightLb: getPatientIntakeSetter("weightLb"),
      setConditions: getPatientIntakeSetter("conditions"),
      setA1c: getPatientIntakeSetter("a1c"),
      setA1cDate: getPatientIntakeSetter("a1cDate"),
      setUseMedications: getPatientIntakeSetter("useMedications"),
      setMedications: getPatientIntakeSetter("medications"),
      setHasMedicationAllergies: getPatientIntakeSetter(
        "hasMedicationAllergies"
      ),
      setMedicationAllergies: getPatientIntakeSetter("medicationAllergies"),
      setUseDrugs: getPatientIntakeSetter("useDrugs"),
      setUseTobacco: getPatientIntakeSetter("useTobacco"),
      setAlcoholFrequency: getPatientIntakeSetter("alcoholFrequency"),
      setSelfAssessedHealth: getPatientIntakeSetter("selfAssessedHealth"),
      setPatientAcknowledgement: getPatientIntakeSetter(
        "patientAcknowledgement"
      ),
      setPregnantOrBreastfeeding: getPatientIntakeSetter(
        "pregnantOrBreastfeeding"
      ),
      setFamilyHistory: getPatientIntakeSetter("familyHistory"),
      setEatingDisorder: getPatientIntakeSetter("eatingDisorder"),
      setThoughtsSuicideOrSelfHarm: getPatientIntakeSetter(
        "thoughtsSuicideOrSelfHarm"
      ),
      setHeartRate: getPatientIntakeSetter("heartRate"),
      setHeartRateDate: getPatientIntakeSetter("heartRateDate"),
      setSystolicBloodPressure: getPatientIntakeSetter("systolicBloodPressure"),
      setDiastolicBloodPressure: getPatientIntakeSetter(
        "diastolicBloodPressure"
      ),
      setBloodPressureDate: getPatientIntakeSetter("bloodPressureDate"),
      setHistoryBariatricSurgery: getPatientIntakeSetter(
        "historyBariatricSurgery"
      ),
      setHasOtherSurgeries: getPatientIntakeSetter("hasOtherSurgeries"),
      setOtherSurgeries: getPatientIntakeSetter("otherSurgeries"),
      setUseAltoPharmacy: getPatientIntakeSetter("useAltoPharmacy"),
      setLabData: getPatientIntakeSetter("labData"),
      setHasRecentLabs: getPatientIntakeSetter("hasRecentLabs"),
      // SteadyMD
      setFamilyHistoryThyroidCancer: getPatientIntakeSetter(
        "familyHistoryThyroidCancer"
      ),
      // SteadyMD Intake
      setConditionsExisting: getPatientIntakeSetter("conditionsExisting"),
      setBloodPressureSimple: getPatientIntakeSetter("bloodPressureSimple"),
      setHeartRateSimple: getPatientIntakeSetter("heartRateSimple"),
      setLifestyleImpacts: getPatientIntakeSetter("lifestyleImpacts"),
      setSuicideOrSelfHarm1: getPatientIntakeSetter("suicideOrSelfHarm1"),
      setSuicideOrSelfHarm2: getPatientIntakeSetter("suicideOrSelfHarm2"),
      setTelehealthSignupDate: getPatientIntakeSetter("telehealthSignupDate"),
      // SteadyMD Insurance
      setInsuranceCoverage: getPatientIntakeSetter("insuranceCoverage"),
      setInsurancePayer: getPatientIntakeSetter("insurancePayer"),
      setInsuranceIsPrimarySubscriber: getPatientIntakeSetter(
        "insuranceIsPrimarySubscriber"
      ),
      setInsurancePrimarySubscriberFirstName: getPatientIntakeSetter(
        "insurancePrimarySubscriberFirstName"
      ),
      setInsurancePrimarySubscriberLastName: getPatientIntakeSetter(
        "insurancePrimarySubscriberLastName"
      ),
      setInsurancePrimarySubscriberDob: getPatientIntakeSetter(
        "insurancePrimarySubscriberDob"
      ),
      setInsuranceMemberId: getPatientIntakeSetter("insuranceMemberId"),
      setInsuranceGroupId: getPatientIntakeSetter("insuranceGroupId"),
      setInsuranceRxBin: getPatientIntakeSetter("insuranceRxBin"),
      setInsuranceRxPcn: getPatientIntakeSetter("insuranceRxPcn"),
      setInsuranceRxGroup: getPatientIntakeSetter("insuranceRxGroup"),
      setLabsStatus: getPatientIntakeSetter("labsStatus"),
      setLabProvider: getPatientIntakeSetter("labProvider"),
      setHasLabs: getPatientIntakeSetter("hasLabs"),
      setHasOrderedLabs: getPatientIntakeSetter("hasOrderedLabs"),
      setHasUploadedLabs: getPatientIntakeSetter("hasUploadedLabs"),
      setPatientInformationChanged: getPatientIntakeSetter(
        "patientInformationChanged"
      ),
    },
  ];
};
