import { emitMonitoringEvent } from "../monitoring/bus";
import { NoomError } from "./NoomError";

export function captureException(
  exception: (Error & { error?: unknown }) | NoomError | any,
  fingerprint?: string[] | string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  extras?: {}
) {
  let fingerprintValue: string[];
  if (fingerprint) {
    fingerprintValue = Array.isArray(fingerprint) ? fingerprint : [fingerprint];
  }

  emitMonitoringEvent({
    type: "exception",
    exception,
    extras,
    fingerprint: fingerprintValue,
  });
}

/**
 * Send a custom message to Sentry with attached properties.
 */
export function captureMessageWithExtras(
  message: string,
  extras: PropertyBag,
  fingerprint?: string[]
) {
  emitMonitoringEvent({
    type: "errorMessage",
    message,
    extras,
    fingerprint,
  });
}

export function isChunkLoadError(err: any) {
  return err.name === "ChunkLoadError" || err.code === "CSS_CHUNK_LOAD_FAILED";
}
