import { css } from "@emotion/react";

/** Creates a flex row parent */
export const flexRow = css`
  display: flex;
  flex-direction: row; /* Explicitly stated for composition cases */
  align-items: center;
`;

/** Creates a flex column parent */
export const flexColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/** Creates a flex parent that centers content horizontally. */
export const flexHorizontalCenter = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

/** Creates a flex parent that pushes row contents apart from each other */
export const flexApart = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/** Creates a flex parent that pushes all row contents to the right */
export const flexRight = css`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

/** Creates a flex parent that pushes all column contents to the left */
export const flexColumnLeft = css`
  display: flex;
  align-items: flex-start; /* Explicitly stated for composition cases */
  flex-direction: column;
`;

/** Creates a flex parent that pushes all column contents to the right */
export const flexColumnRight = css`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

/** Creates a flex parent that centers content on both axises. */
export const flexMiddle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/** Creates a flex parent that centers content on both axises. */
export const flexColumnMiddle = css`
  ${flexColumn}
  align-items: center;
`;

export const flexGrower = css`
  flex-grow: 1;
`;

export const fixedFullSize = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

/** Center CTA and limit width to 400px. */
export const ctaStyles = css`
  max-width: 400px;
  margin: 0 auto;
`;
