export type AddressModel = {
  firstName?: string;
  lastName?: string;
  address1: string;
  address2?: string;
  city: string;
  region: string;
  postcode: string;
  country: string;
  phone?: string;
};

// Used to define where each component of the billing address should be placed for a given country
export const addressRowCountryMap: Record<string, (keyof AddressModel)[][]> = {
  US: [
    ["country"],
    ["address1"],
    ["address2"],
    ["city"],
    ["region", "postcode"],
  ],
  CA: [
    ["country"],
    ["address1"],
    ["address2"],
    ["city"],
    ["region", "postcode"],
  ],
  // GB/IE/NZ don't have region, since the region selector we use doesn't match the data from HERE,
  // and it's redundant for those countries anyway.
  //
  // Per @julia: For most International Countries OneSource tax calculation is based on the Country.
  // The region and postal codes are nice to have for the UK, however for tax calculation, we only
  // need the Country.  However there are some Countries like Canada and India that do require
  // regional data because the VAT return is broken down by region.
  //
  // Potential work item: Do we want to find a component that works better with HERE? HERE doesn't
  // seem to have an API to get all regions/counties, so wouldn't be able to do it manually...
  GB: [["country"], ["address1"], ["address2"], ["city", "postcode"]],
  IE: [["country"], ["address1"], ["address2"], ["city", "postcode"]],
  NZ: [["country"], ["address1"], ["address2"], ["city", "postcode"]],
  AU: [["country"], ["address1"], ["address2"], ["postcode", "city", "region"]],
  default: [
    ["country"],
    ["address1"],
    ["address2"],
    ["city", "region", "postcode"],
  ],
};

export function countryHasRegionField(countryCode: string) {
  const countryConfig =
    addressRowCountryMap[countryCode?.toUpperCase()] ||
    addressRowCountryMap.default;

  return countryConfig.some((fields) => fields.includes("region"));
}
