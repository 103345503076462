import React, { useState } from "react";
import styled from "@emotion/styled";

import Button from "src/design-system/components/Button/Button";

import { compassColors } from "src/utils/styling";
import { flexColumnMiddle } from "src/design-system/styles/layout";
import { useSurveyAnswers } from "src/hooks/survey/answers";
import {
  fontLabel,
  fontMediumHeadline,
  fontP3Regular,
} from "src/design-system/styles/fonts";
import { useAppDispatch, useUserData } from "src/hooks/redux";
import {
  UserDataState,
  setUserProperties,
} from "src/utils/redux/slices/userData";
import { shift_minutes } from "src/utils/datetime";
import { trackEvent } from "src/utils/api/tracker";
import { useOnce } from "src/hooks/lifecycle";
import { FlexFrame } from "src/components/core/BodyLayout";
import { ModalHero } from "./ModalHero";
import { getOfferTimerDuration } from "src/utils/checkoutTimer";
import { CountdownTimer } from "src/components/countdown-timer/CountdownTimer";
import i18n from "src/locales";
import { Modal } from "src/design-system/components/Modal/Modal";

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
`;

const Headline = styled.div`
  ${fontMediumHeadline};
  font-family: "Untitled Serif", serif;
  text-align: start;
`;

const Paragraph = styled.div`
  ${fontP3Regular};
  font-family: "Untitled Sans", sans-serif;
  color: ${compassColors.grey4};
  text-align: start;
  margin-top: 10px;
`;

const OfferExpirationDisclaimer = styled.div`
  ${fontLabel}
  color: ${compassColors.grey3};
  margin-top: 5px;
`;

const ButtonsContainer = styled.div`
  ${flexColumnMiddle};
  margin: 0 10px 20px;
`;

const OfferTimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  justify-content: end;
  align-items: center;
`;

const CeneterElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

function initializeTimer(userData: Partial<UserDataState>) {
  if (
    userData?.checkoutDiscountedMWCPTimerExpirationTimestamp &&
    userData?.initialExpirationDurationForFreeMWCP === getOfferTimerDuration()
  ) {
    return userData.checkoutDiscountedMWCPTimerExpirationTimestamp;
  }
  return shift_minutes(getOfferTimerDuration()).getTime() - 1_000; // minus 1 second to start at 59:59
}

// MWCP = Mental Wellness Course Pack
export function FreeMWCPModal({ onClose }: { onClose: () => void }) {
  const surveyAnswers = useSurveyAnswers();
  const userData = useUserData();
  const dispatch = useAppDispatch();

  const [expirationTimestamp] = useState(initializeTimer(userData));

  useOnce(() => {
    trackEvent("FreeNMCPModalShown");
    dispatch(
      setUserProperties({
        checkoutDiscountedMWCPTimerExpirationTimestamp: expirationTimestamp,
        initialExpirationDurationForFreeMWCP: getOfferTimerDuration(),
      })
    );
  });

  const onAnswer = async (answer: string) => {
    trackEvent("FreeMWCPAnswered", { answer });
    await dispatch(
      setUserProperties({
        answerToFreeMWCPModal: answer,
      })
    );
    onClose();
  };

  return (
    <Modal css={{ padding: 0 }}>
      <FlexFrame>
        <ModalHero />
        <Contents>
          <Headline>
            {i18n.t("freeMWCPModal:contents:headline", {
              firstName: surveyAnswers.firstName
                ? surveyAnswers.firstName
                : "you",
            })}
          </Headline>
          <Paragraph>{i18n.t("freeMWCPModal:contents:paragraph")}</Paragraph>
          <OfferTimerContainer>
            <CeneterElement>
              <CountdownTimer
                expirationTimestamp={expirationTimestamp}
                onClose={onClose}
              />
            </CeneterElement>
            <OfferExpirationDisclaimer>
              {i18n.t("freeMWCPModal:contents:offerExpiration")}
            </OfferExpirationDisclaimer>
          </OfferTimerContainer>
        </Contents>
        <ButtonsContainer>
          <Button
            css={{ maxWidth: "400px" }}
            onClick={() => onAnswer("yes")}
            data-cy="accept-free-mwcp-modal"
            designStyle="primaryCta"
          >
            {i18n.t("freeMWCPModal:buttons:accept")}
          </Button>
          <Button
            css={{ marginTop: "5px", maxWidth: "400px" }}
            onClick={() => onAnswer("no")}
            designStyle="secondaryCta"
            data-cy="reject-mwcp-modal"
          >
            {i18n.t("freeMWCPModal:buttons:reject")}
          </Button>
        </ButtonsContainer>
      </FlexFrame>
    </Modal>
  );
}
