import { send } from "@utils/fetch";
import saveToBraze from "src/utils/brazeUploader";
import { captureException } from "src/utils/error";

export function getAndMaybeSavePendingDoubleOptInStatus() {
  return send("GET", `/userdata/api/v4/user/gdpr/getDoubleOptInStatus/`)
    .then((json) => {
      const { status } = json;
      // If user status is null or pending set their double opt in status to pending and inform Braze
      if (!status || status === "PENDING") {
        return savePendingDoubleOptInStatus("PENDING");
      }
      return Promise.resolve();
    })
    .catch((error) => {
      captureException(error, "GDPRDoubleOptIn");
    });
}

export function savePendingDoubleOptInStatus(status: "PENDING" | "CONFIRMED") {
  return send("POST", `/userdata/api/v4/user/gdpr/setDoubleOptInStatus/`, {
    status,
  }).then(() =>
    saveToBraze({ user: { pendingDoubleOptIn: status === "PENDING" } })
  );
}
