import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { send } from "src/utils/fetch";
import { captureException } from "src/utils/error";
import { createAppAsyncThunk } from "../async-thunk";

export interface MedEntitlements {
  hasTelehealthAddOn?: boolean;
  hasGlp1CoursePack?: boolean;
  hasMedCompanion?: boolean;
}

const medEntitlementsSlice = createSlice({
  name: "medEntitlements",
  initialState: {} as MedEntitlements,
  reducers: {
    setMedEntitlements(state, action: PayloadAction<MedEntitlements>) {
      return { ...action.payload };
    },
  },
});

export const { setMedEntitlements } = medEntitlementsSlice.actions;

export default medEntitlementsSlice;

export const loadMedEntitlements = createAppAsyncThunk(
  "medEntitlements/loadMedEntitlements",
  async (_, thunkAPI) => {
    const URL = "/api/usermodel/v1/getMedEntitlements/";

    send("GET", URL)
      .then((data) => {
        thunkAPI.dispatch(setMedEntitlements(data));
        return data;
      })
      .catch((e) => {
        captureException(e, "loadMedEntitlements");
      });
  }
);
