import { send } from "@utils/fetch";
import { ActiveSubscriptionDataState } from "@utils/redux/slices/activeSubscriptionData";

const URL = "/visitor/api/v2/getVisitorSubscriptionStatus/";

export type SubscriptionStatusData = ActiveSubscriptionDataState & {
  planPrice?: number;
};

export function getSubscriptionStatus() {
  return send<SubscriptionStatusData>("GET", URL);
}
