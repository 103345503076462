import { ActionHandlerParams } from "..";
import saveToBraze from "@utils/brazeUploader";
import { saveAnswers } from "./saveAnswers";
import { getSurveyAnswers } from "src/hooks/survey/answers";

type Params = {
  lead?: string;

  dateKey?: string;
  extraProps?: JsonObject;
};

export const onCompleteEmployerSurvey = async (
  actionParams: {
    params?: Params;
  } & ActionHandlerParams
) => {
  // Have to retrieve this survey answer differently because it's stored in the main survey
  const { employmentLifestyle } = getSurveyAnswers();

  const {
    employerName,
    noomForEmployerRole,
    noomForEmployerInterest,
    noomForEmployerWorkEmail,
    noomForEmployerOrgSize,
    employerReferralRole,
    employerReferralEmail,
  } = await saveAnswers({
    ...actionParams,
  });

  await saveToBraze({
    event: {
      event_name: "finished_employer_survey",
      event_data: {
        employmentLifestyle,
        employerName,
        noomForEmployerRole,
        noomForEmployerInterest,
        noomForEmployerWorkEmail,
        noomForEmployerOrgSize,
        employerReferralRole,
        employerReferralEmail,
      },
    },
  });
};
