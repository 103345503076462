/**
 * Methods for tracking events on the client. Currently tracks to Mixpanel.
 *
 * Mixpanel is tracked on the server-side using the growth pixel.
 *
 * Copyright (C) 2018 Noom, Inc.
 * @author sumin, patrick
 */
import Levenshtein from "levenshtein";

import { logInfo } from "../monitoring/logging";
import { emitMonitoringEvent } from "../monitoring/bus";
import { CoreReduxState } from "../redux/internal";
import {
  WellknownMixpanelEvents,
  NoomBuyflowEvents,
  NoomBanditEvents,
  NoomMmpEvents,
} from "../monitoring/events";
import { ExtraTrackingOptions } from "../monitoring/buyflow-event";

export function updateUserContext(properties: JsonObject) {
  logInfo("EventTracker", "updateUserContext", properties);
  emitMonitoringEvent({
    type: "people_set",
    properties,
  });
}

/**
 * Track a mixpanel event.
 */
export function trackEvent<K extends keyof WellknownMixpanelEvents>(
  eventName: K,
  properties: WellknownMixpanelEvents[K]
);
export function trackEvent(eventName: string, properties?: JsonObject);
export function trackEvent(eventName: string, properties: JsonObject = {}) {
  logInfo("EventTracker", "trackEvent", eventName, properties);
  return emitMonitoringEvent({
    type: "track",
    eventName,
    properties,
  });
}

export function trackBuyflowEvent<K extends keyof NoomBuyflowEvents>(
  eventName: K | Capitalize<K>,
  properties: NoomBuyflowEvents[K],
  extras?: ExtraTrackingOptions
) {
  logInfo(
    "BuyflowEventTracker",
    "trackBuyflowEvent",
    eventName,
    properties,
    extras
  );
  return emitMonitoringEvent({
    type: "buyflowEvent",
    eventName,
    extras,
    properties: properties as JsonObject,
  });
}

export function trackMmpEvent<K extends keyof NoomMmpEvents>(
  eventName: K | Capitalize<K>,
  properties: NoomMmpEvents[K]
) {
  return emitMonitoringEvent({
    type: "mmpEvent",
    eventName,
    properties: properties as JsonObject,
  });
}

/**
 * Helper function to track experiment events. When baselining, create new event
 * in NoomBuyflowEvents and use `trackBuyflowEvent` function instead.
 */
export function trackExperimentalEvent(
  eventName: string,
  properties?: Record<string, string | number | boolean>
) {
  // Convert all values to string to conform with proto definition of Map<string, string>
  const propertiesWithStringValues = Object.keys(properties || {}).reduce(
    (acc, curr) => ({ ...acc, [curr]: `${properties[curr]}` }),
    {}
  );

  return trackBuyflowEvent(
    "ExperimentalBuyflowEvent",
    {
      experimentalEventType: eventName,
    },
    {
      experimentalProperties: propertiesWithStringValues,
    }
  );
}

export function trackBanditEvent<K extends keyof NoomBanditEvents>(
  eventName: K,
  properties: NoomBanditEvents[K]
) {
  logInfo("BanditEventTracker", "trackBanditEvent", eventName, properties);
  return emitMonitoringEvent({
    type: "banditEvent",
    eventName,
    properties: properties as JsonObject,
  });
}

export function trackGotEmail(email: string) {
  // TODO(sumin): Remove this since we add email and name to people properties on the server-side.
  trackEvent("GotEmail", {
    EnteredEmail: email,
  });
}

export function trackEmailChanged(
  location: string,
  email: string,
  state: CoreReduxState
) {
  const { userData, winbackInfo } = state;

  if (userData.email && userData.email !== email) {
    const [oldUserName, oldDomain] = userData.email.split("@");
    const [newUserName, newDomain] = email.split("@");

    trackEvent("UserEmailChanged", {
      location,
      oldEmail: userData.email,
      newEmail: email,

      oldDomain,
      newDomain,

      userNameChanged:
        oldUserName?.toLowerCase() !== newUserName?.toLowerCase(),
      domainChanged: oldDomain?.toLowerCase() !== newDomain?.toLowerCase(),
      levensteinDistance: new Levenshtein(oldUserName, newUserName).distance,

      oldWinbackStatus: winbackInfo.previousWinbackStatus,
      newWinbackStatus: winbackInfo.winbackStatus,
      mainSurveyCompleteDateISO: userData.mainSurveyCompleteDateISO,
      hasAccessCode: !!userData.access_code,
    });
  }
}
