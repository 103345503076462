import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";

type CurrentAnswersState = {
  questionAnswers: Record<string, SurveyAnswersState>;
};

const initialState: CurrentAnswersState = {
  questionAnswers: {},
};

// Persistent storage key for current survey answers
// This allows us to retain questions on refresh, without needing to persist
// via the server API.
//
// WARNING: This is intended to be private slice. Look at `getSurveyAnswers`
// or `useSurveyAnswers` over directly interacting with this slice.
const currentAnswersSlice = createSlice({
  name: "currentAnswers",
  initialState,
  reducers: {
    seedCurrentAnswers(
      state,
      action: PayloadAction<{ namespace: string; answers: SurveyAnswersState }>
    ) {
      const { namespace, answers: externallyStoredAnswers } = action.payload;
      const locallyStoredAnswers = state.questionAnswers?.[namespace] || {};

      return {
        questionAnswers: {
          ...state.questionAnswers,
          [namespace]: {
            ...externallyStoredAnswers,
            ...locallyStoredAnswers,
          },
        },
      };
    },
    updateCurrentAnswers(
      state,
      action: PayloadAction<{ namespace: string; answers: SurveyAnswersState }>
    ) {
      const { namespace, answers: newAnswers } = action.payload;
      const oldAnswers = state.questionAnswers?.[namespace] || {};

      const changed =
        !newAnswers ||
        Object.keys(newAnswers).some(
          (key) => oldAnswers[key] !== newAnswers[key]
        );

      // Only update if values have changed. This prevents infinite update loops
      // from identity updates.
      if (changed) {
        return {
          questionAnswers: {
            ...state.questionAnswers,
            [namespace]: {
              ...oldAnswers,
              ...newAnswers,
            },
          },
        };
      }
      return state;
    },
    resetCurrentAnswers(
      state,
      action: PayloadAction<{
        namespace: string;
        answers: SurveyAnswersState;
      }>
    ) {
      const { namespace, answers: newAnswers } = action.payload;
      return {
        namespace,
        questionAnswers: {
          ...state.questionAnswers,
          [namespace]: { ...newAnswers },
        },
      };
    },
  },
});

export const { seedCurrentAnswers, updateCurrentAnswers, resetCurrentAnswers } =
  currentAnswersSlice.actions;

export default currentAnswersSlice;
