import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { fadeOutAnimation } from "../animation/fade";

const dateIn = keyframes`
  from {
    transform: translateY(-30px);
  }
  to {
  }
  `;

const CurrentValue = styled.div<{ shouldAnimate: boolean }>`
  display: inline-block;
  white-space: pre;

  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation-name: ${dateIn};
      animation-duration: 0.25s;
    `};
`;

const PreviousValue = styled.div<{ shouldAnimate: boolean }>`
  white-space: pre;
  position: absolute;
  opacity: 0%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      ${fadeOutAnimation}
      animation-duration: 0.17s;
      animation-fill-mode: forwards;
    `};
`;

export function SlideInValue({
  current,
  previous,
  shouldAnimate,
}: {
  current: number | string;
  previous: number | string;
  shouldAnimate: boolean;
}) {
  return (
    // Marking these fields are aria-hidden, as a comprehensive screen reader
    // label is applied to the whole date field in AnimateDate instead.
    <span css={{ position: "relative" }}>
      <CurrentValue
        key={`current-${current}`}
        shouldAnimate={shouldAnimate}
        aria-hidden
      >
        {current}
      </CurrentValue>
      <PreviousValue
        key={`previous-${previous}`}
        shouldAnimate={shouldAnimate}
        aria-hidden
      >
        {previous}
      </PreviousValue>
    </span>
  );
}
