import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDataState } from "./userData";

const surveyNameSpaceSlice = createSlice({
  name: "surveyNameSpace",
  initialState: "personaSurveyUS" as UserDataState["surveyNameSpace"],
  reducers: {
    setSurveyNameSpace(
      state,
      action: PayloadAction<UserDataState["surveyNameSpace"]>
    ) {
      return action.payload || state;
    },
  },
});

export const { setSurveyNameSpace } = surveyNameSpaceSlice.actions;

export default surveyNameSpaceSlice;
