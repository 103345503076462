import { ShippingInfo, ShippingInfoWithName } from "src/funnels/offers/models";
import { send } from "src/utils/fetch";
import type { BillingAddress } from "src/utils/redux/slices/checkout";

export type AddOnSalesTaxParam = {
  user_id: string;
  email: string;
  currency: string;
  productIds: string[];
  shippingInfo?: ShippingInfo;
  billing_address?: BillingAddress;
  home_address?: BillingAddress;
  isVip?: boolean;
  discountCode?: string;
  alwaysUseInclusiveTaxes?: boolean;
};

type SalesTaxRequest = {
  user_id: string;
  email: string;
  currency: string;
  productIds: string[];
  shippingInfo?: ShippingInfoWithName;
  billing_address?: BillingAddress;
  home_address?: BillingAddress;
  isVip?: boolean;
  discountCode?: string;
  alwaysUseInclusiveTaxes: boolean;
};

type TaxLineItem = {
  bundle_id: string;
  product_id: string;
  sales_tax_amount: number;
  sales_tax_rate: number;
  trial_sales_tax_amount?: number;
  trial_sales_tax_rate?: number;
  trial_is_tax_inclusive?: boolean;
};

export type TaxBreakdown = {
  taxRate: number;
  taxType: string;
  taxAmount: number;
};

type SalesTaxResponse = {
  currency: string;
  is_tax_inclusive: boolean;
  line_items: TaxLineItem[];
  sales_tax_amount: number;
  wavg_tax_rate: number;
  requires_tax_breakdown: boolean;
  sales_tax_breakdown: TaxBreakdown[];
};

export function generateSalesTaxRequest({
  email,
  currency,
  user_id,
  productIds,
  billing_address,
  home_address,
  shippingInfo,
  isVip,
  discountCode,
  alwaysUseInclusiveTaxes = false,
}: AddOnSalesTaxParam) {
  const request: SalesTaxRequest = {
    email,
    currency,
    user_id,
    productIds,
    alwaysUseInclusiveTaxes,
  };

  if (billing_address) {
    request.billing_address = billing_address;
  }

  if (home_address) {
    request.home_address = home_address;
  }

  if (shippingInfo) {
    request.shippingInfo = {
      ...shippingInfo,
      name: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
    };
  }
  if (isVip) request.isVip = isVip;
  if (discountCode) request.discountCode = discountCode;

  return request;
}

export function getSalesTax(params: AddOnSalesTaxParam) {
  const request = generateSalesTaxRequest(params);
  return send<SalesTaxResponse>("POST", "/api/tax/add_on", request);
}
