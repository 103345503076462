import { createCallbackList } from "src/utils/callback-list";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "src/utils/noomSessionStorage";
import {
  createForwardParams,
  QueryParametersState,
  updateForwardedParams,
} from "src/utils/urlParams";
import invariant from "tiny-invariant";
import { ReadonlyDeep } from "type-fest";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PageSetSessionState {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BrowserSessionState {}

const PAGE_SET_KEY = "lbfPageSetSession";
const BROWSER_SET_KEY = "lbfBrowserSession";

const pageSetSessionStore: PageSetSessionState =
  getNoomSessionStorage(PAGE_SET_KEY) || {};
let browserSessionStore: BrowserSessionState =
  getNoomSessionStorage(BROWSER_SET_KEY) || {};

const sessionListeners = createCallbackList<
  [
    | {
        type: "pageSet";
        updates: PageSetSessionState;
        newState: PageSetSessionState;
      }
    | {
        type: "browser";
        updates: BrowserSessionState;
        newState: BrowserSessionState;
      }
  ]
>();

export const addSessionUpdateHandler = sessionListeners.add;

export function getSessionState(
  type: "forwardParam"
): ReadonlyDeep<QueryParametersState>;
export function getSessionState(
  type: "pageSet",
  layer: string
): ReadonlyDeep<PageSetSessionState>;
export function getSessionState(
  type: "browser"
): ReadonlyDeep<BrowserSessionState>;
export function getSessionState(
  type: "forwardParam" | "pageSet" | "browser",
  layer?: string
): ReadonlyDeep<
  QueryParametersState | PageSetSessionState | BrowserSessionState
> {
  switch (type) {
    case "forwardParam":
      return Object.fromEntries(createForwardParams().entries());
    case "pageSet":
      return pageSetSessionStore[layer] || {};
    case "browser":
      return browserSessionStore;
    default:
      throw new Error(`Unknown session type: ${type}`);
  }
}

export function updateSessionState(
  type: "forwardParam",
  state: QueryParametersState
);
export function updateSessionState(
  type: "pageSet",
  layer: string,
  state: Partial<PageSetSessionState>
);
export function updateSessionState(
  type: "browser",
  state: Partial<BrowserSessionState>
);
export function updateSessionState(
  type: "forwardParam" | "pageSet" | "browser",
  layerOrState:
    | string
    | QueryParametersState
    | Partial<PageSetSessionState>
    | Partial<BrowserSessionState>,
  stateWithLayer?: Partial<PageSetSessionState>
): void {
  const layer = typeof layerOrState === "string" ? layerOrState : undefined;
  const state =
    typeof layerOrState === "string" ? stateWithLayer : layerOrState;

  switch (type) {
    case "forwardParam":
      updateForwardedParams(state as QueryParametersState);
      break;
    case "pageSet":
      pageSetSessionStore[layer] = { ...pageSetSessionStore[layer], ...state };
      setNoomSessionStorage(PAGE_SET_KEY, pageSetSessionStore);

      sessionListeners.emit({
        type,
        updates: state,
        newState: pageSetSessionStore[layer],
      });
      break;
    case "browser":
      browserSessionStore = { ...browserSessionStore, ...state };
      setNoomSessionStorage(BROWSER_SET_KEY, browserSessionStore);

      sessionListeners.emit({
        type,
        updates: state,
        newState: browserSessionStore,
      });
      break;
    default:
      invariant(false, `Invalid session type: ${type}`);
  }
}
