import { loadScript } from "@utils/scriptLoader";
import { LibCategories } from "@utils/consent";

declare const window: Window & {
  AddShoppersWidgetOptions?: any;
};

export function loadSafeOptPixel() {
  window.AddShoppersWidgetOptions = {
    loadCss: false,
    pushResponse: false,
  };
  return loadScript(
    "https://shop.pe/widget/widget_async.js#6283f08a93f3c3378e93ccdc",
    [LibCategories.targetingCookies],
    {
      enableCORS: false,
      htmlAttr: {
        type: "text/javascript",
        id: "AddShoppers",
      },
    }
  );
}
