import { send } from "@utils/fetch";
import saveToBraze from "@utils/brazeUploader";
import { captureException } from "@utils/error";
import {
  getReferralLinkWithTracking,
  ReferralShareSource,
} from "@utils/referrals/shareSourceTracking";
import { getMeristemExperimentsString } from "@utils/experiment";

export const GET_REFERRAL_CODE_CACHE_KEY = ["referralCode"];

export const getReferralCode = async (): Promise<string> => {
  return send("GET", `/api/referrals/v1/user_referral_code`).then(
    (response) => response.referral_code
  );
};

export async function validateReferralCode(
  referralCode: string
): Promise<boolean> {
  try {
    const encodedReferralCode = encodeURIComponent(referralCode);
    await send(
      "GET",
      `/api/referrals/v1/referrer?referral_code=${encodedReferralCode}`
    );
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
}

export async function submitReferralEmails(
  referrerEmail: string,
  referredEmails: string[],
  source: ReferralShareSource,
  referralCode?: string,
  extraEventParams: Record<string, any> = {}
) {
  const eventParams: Record<string, any> = {
    source,
    referrer_email: referrerEmail,
    referral_emails: referredEmails,
    experiments: getMeristemExperimentsString(),
  };

  if (referralCode) {
    eventParams.referral_link = getReferralLinkWithTracking(
      referralCode,
      source
    );
    eventParams.referral_code = referralCode;
  } else {
    eventParams.referral_link = `https://noom.com/referral?ref_email=${referrerEmail}`;
  }

  await saveToBraze({
    event: {
      event_name: "submittedReferralEmail",
      event_data: { ...eventParams, ...extraEventParams },
    },
  });
}
