import styled from "@emotion/styled";
import React from "react";

export const REGION_IDS = {
  polite: "polite-live-region",
  assertive: "assertive-live-region",
  alert: "alert-live-region",
};

const VisuallyHidden = styled.div`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip: rect(1px 1px 1px 1px);
  clip-path: inset(50%);
`;

/* Visually hidden area used to propogate messages to screen readers */
const LiveRegions = () => {
  return (
    <VisuallyHidden>
      <div aria-live="polite" id={REGION_IDS.polite} />
      <div aria-live="assertive" id={REGION_IDS.assertive} />
      <div role="alert" id={REGION_IDS.alert} />
    </VisuallyHidden>
  );
};

export default LiveRegions;
