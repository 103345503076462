import { useHistory } from "react-router";
import throttle from "lodash/throttle";

import { trackEvent } from "src/utils/api/tracker";
import { getNavType } from "src/utils/timing";
import { useGlobalEventListener } from "./dom";
import { useOnce } from "./lifecycle";
import {
  getNoomSessionStorage,
  setNoomSessionStorage,
} from "src/utils/noomSessionStorage";

let clickCounter = 0;
let touchCounter = 0;
let bootClickCounter = 0;
let bootTouchCounter = 0;
let bootPages = 1;

function loadStats() {
  return {
    ...getNoomSessionStorage("backTracker"),
    currentPath: window.location.pathname,
  };
}
const storeStats = throttle(() => {
  setNoomSessionStorage("backTracker", {
    lastClickCounter: clickCounter,
    lastBootClickCounter: bootClickCounter,
    lastTouchCounter: touchCounter,
    lastBootTouchCounter: bootTouchCounter,
    lastPath: window.location.pathname,
  });
}, 100);

export function useNavTracker() {
  const history = useHistory();

  useGlobalEventListener(
    "mousedown",
    () => {
      clickCounter += 1;
      bootClickCounter += 1;
      storeStats();
    },
    { capture: true, passive: true }
  );
  useGlobalEventListener(
    "touchstart",
    () => {
      touchCounter += 1;
      bootTouchCounter += 1;
      storeStats();
    },
    { capture: true, passive: true }
  );

  useOnce(() => {
    const navigationType = getNavType();
    trackEvent("OnPageLoad", {
      navigationType,
      ...loadStats(),
    });

    // Save path
    storeStats();

    return history.listen((location, action) => {
      // Warn: The counters in here might not be 100% accurate.
      // Use caution when analyzing using these values.
      trackEvent("OnNavigation", {
        navigationType: action?.toLowerCase(),
        bootPages,
        clickCounter,
        touchCounter,
        bootClickCounter,
        bootTouchCounter,
      });

      clickCounter = 0;
      touchCounter = 0;
      bootPages += 1;
      storeStats();
    });
  });
}
