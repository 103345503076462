import { trackEvent } from "src/utils/api/tracker";

declare module "." {
  export interface WellknownMixpanelEvents {
    BuyflowPageBail: BuyflowPageBail;
  }
}

/**
 * Signals that the user's page or funnel was in an invalid state and
 * that a navigation occurred in an attempt to correct it.
 */
export interface BuyflowPageBail {
  /** Action performed to bail. This may be a goto string or any other diagnostic token */
  action: string;

  /** Diagnostic error message. This may be a unique string or an error code. */
  error?: string;
}

export function trackPageBail(action: string, error?: string) {
  trackEvent("BuyflowPageBail", {
    action,
    error,
  });
}
