import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { flexMiddle } from "src/design-system/styles/layout";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { css } from "@emotion/react";

export const FlexFrame = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  // Needed for proper layout in flex parents.
  flex-grow: 1;
`;

export const CenteredBody = styled.section`
  width: 100%;
  ${flexMiddle};
  flex-direction: column;
  box-sizing: border-box;
`;

export const DEFAULT_COLUMN_MARGIN = "16px";
export const MainColumn = styled.div<{
  maxWidth?: number | string;
  margin?: number;
  marginBottom?: boolean;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: content-box;

  ${({ maxWidth }) => {
    // If we are passed a specific width, save to a var so it
    // can be used for nested children.
    if (maxWidth) {
      const widthValue =
        typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;
      return `--main-column-width: ${widthValue}`;
    }
    return "";
  }};

  ${({ margin }) => (margin ? `--main-column-margin: ${margin}px` : "")};

  max-width: var(--main-column-width, 400px);
  padding: 0 var(--main-column-margin, ${DEFAULT_COLUMN_MARGIN});
  padding-bottom: ${({ marginBottom }) =>
    marginBottom ? `var(--main-column-margin, ${DEFAULT_COLUMN_MARGIN})` : 0};
  width: calc(100% - 2 * var(--main-column-margin, ${DEFAULT_COLUMN_MARGIN}));
  flex: 1;

  margin: 0 auto;
`;

/**
 * Allows children of MainColumn to fill with width of the page.
 * This is useful when it would be a pain to stack layouts with and
 * without the MainColumn.
 *
 * https://css-tricks.com/full-width-containers-limited-width-parents/
 */
export const Spreader = styled.div`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;
export function FullWidth({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  // The wrapper div here removes us from the layout constraints of
  // flex, grid, etc layouts that may not honor our explicit margins.
  // The outer div follows those and the inner is free to fill the screen.
  return (
    <div css={{ width: "100%" }}>
      <Spreader className={className}>{children}</Spreader>
    </div>
  );
}

export const FullScreen = styled.section<{
  disclaimerBelowFold?: boolean;
}>`
  width: 100%;
  height: 100vh;
  min-height: 800px;
  object-fit: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media ${breakpointMobileExtended} {
    justify-content: flex-start;
  }

  ${({ disclaimerBelowFold }) =>
    disclaimerBelowFold &&
    css`
      height: 90vh;

      @media ${breakpointMobileExtended} {
        height: calc(100vh + 100px);
      }
    `}
`;

export function VScroller({ children }: { children: ReactNode }) {
  // The nested divs here prevent issues with clipping when the flex is applied
  // directly to the overflow scroll element.
  return (
    <div css={{ overflowY: "auto", width: "100%" }} data-cy="discount-modal">
      <div css={[flexMiddle, { flexDirection: "column" }]}>{children}</div>
    </div>
  );
}
