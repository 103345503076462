import React from "react";
import styled from "@emotion/styled";

import i18n from "src/locales";
import { trackEvent, trackBuyflowEvent } from "src/utils/api/tracker";

import { useFooterOverride } from "./SingleUseFooter";
import { consentFramework, shouldIOSUserSeeOptOut } from "src/utils/consent";
import Privacy from "src/ccpa/images/privacy.webp";
import { isNmc } from "src/utils/userSegment/features";

const Container = styled.div<{ padding: number }>`
  a {
    color: inherit;
    padding-right: ${({ padding }) => padding}px;

    &:after {
      display: inline-block;
      content: "|";
      padding-left: ${({ padding }) => padding}px;
    }

    &:last-child {
      padding-right: 0px;
      padding-left: 0px;
    }

    &:last-child:after {
      content: "";
      padding-left: 0px;
    }
  }
`;

const PrivacyIcon = styled.img`
  margin-left: 8px;
  height: 16px;
  display: inline-block;
  vertical-align: bottom;
`;

const PaidTrafficExtraFooterLinks = () => {
  return (
    <>
      <a
        href="https://web.noom.com/about-us/"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackEvent("ClickedAboutUsFooterLink")}
      >
        {i18n.t("footer:aboutUs")}
      </a>
      <a
        href="https://web.noom.com/support/faqs/question-topics/my-account/2021/04/noom-phone-number-and-phone-support/"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackEvent("ClickedContactUsFooterLink")}
      >
        {i18n.t("footer:contactUs")}
      </a>
      <a
        href="https://www.trustpilot.com/review/noom.com"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackEvent("ClickedReviewsFooterLink")}
      >
        {i18n.t("footer:reviews")}
      </a>
    </>
  );
};

const TermsAndPrivacy = () => {
  if (isNmc()) {
    return (
      <>
        <a
          href="https://noom.com/coach-license-agreement"
          onClick={() =>
            trackBuyflowEvent("BuyflowLinkClicked", {
              linkName: "ClickedCoachLicenseAgreementFooterLink",
              pageId: "nmcLanding",
            })
          }
        >
          {i18n.t("footer:coachLicenseAgreement")}
        </a>
        <a
          href="https://noom.com/coach-privacy-policy/"
          onClick={() =>
            trackBuyflowEvent("BuyflowLinkClicked", {
              linkName: "ClickedCoachPrivacyPolicyFooterLink",
              pageId: "nmcLanding",
            })
          }
        >
          {i18n.t("footer:coachPrivacyPolicy")}
        </a>
      </>
    );
  }

  return (
    <>
      <a
        href={i18n.t("footer:termsAndConditionsLink")}
        onClick={() => trackEvent("ClickedTermsAndConditionsFooterLink")}
      >
        {i18n.t("footer:termsAndConditions")}
      </a>
      <a
        href={i18n.t("footer:privacyPolicyLink")}
        onClick={() => trackEvent("ClickedPrivacyPolicyFooterLink")}
      >
        {i18n.t("footer:privacyPolicy")}
      </a>
    </>
  );
};

export function LegalLinks({
  padding = 10,
  removeSupport,
  className,
  includeExtraFooterLinks,
}: {
  padding?: number;
  removeSupport?: boolean;
  className?: string;
  includeExtraFooterLinks?: boolean;
}) {
  // Caller will have to do override to ensure legal requirements if not including
  // support link.
  useFooterOverride(!removeSupport);

  return (
    <Container padding={padding} className={className}>
      {includeExtraFooterLinks && <PaidTrafficExtraFooterLinks />}
      <a
        href={i18n.t("navigation:loginLink")}
        onClick={() => trackEvent("ClickedLoginFooterLink")}
      >
        {i18n.t("footer:login")}
      </a>
      <a
        href={i18n.t("footer:cookiePolicyLink")}
        onClick={() => trackEvent("ClickedCookiePolicyFooterLink")}
      >
        {i18n.t("footer:cookiePolicy")}
      </a>
      <a
        href={i18n.t("footer:HIPAALink")}
        onClick={() => trackEvent("ClickedHIPAAFooterLink")}
      >
        {i18n.t("footer:HIPAANotice")}
      </a>
      <TermsAndPrivacy />
      <a
        href={i18n.t("footer:accessibilityLink")}
        onClick={() => trackEvent("ClickedAccessibilityFooterLink")}
      >
        {i18n.t("footer:accessibility")}
      </a>
      {!removeSupport && (
        <a
          href={i18n.t("footer:customerSupportLink")}
          onClick={() => trackEvent("ClickedSupportFooterLink")}
        >
          {i18n.t("footer:support")}
        </a>
      )}
      {(consentFramework() === "optOut" || shouldIOSUserSeeOptOut()) && (
        <a
          href={`${i18n.t("footer:privacyChoicesLink")}${
            window.location.search
          }`}
          onClick={() => trackEvent("ClickedPrivacyChoicesFooterLink")}
        >
          {i18n.t("footer:yourPrivacyChoices")}
          <PrivacyIcon src={Privacy} alt="" />
        </a>
      )}
    </Container>
  );
}
