import { appConfig } from "src/config";
import { reroll } from "../components/PageSetRouter";
import { trackEvent } from "src/utils/api/tracker";
import {
  CounterOfferOutcome,
  updateCounterOffer,
  updateCounterOfferOutcome,
} from "src/utils/redux/slices/counterOffer";
import getStore from "src/utils/redux/store";
import {
  createForwardParams,
  updateForwardedParams,
} from "src/utils/urlParams";
import { getPageSetFromPath } from "../pageSets";
import { pathIsInSubscriptionCancelContext } from "src/hooks/counterOffer";
import goto from ".";
import { getSessionState } from "src/pageDefinitions/session";

function getActivePageSet() {
  return getPageSetFromPath(window.location.pathname, getStore().getState());
}

export function isMedUpgradeFromCounterOffer() {
  return getSessionState("browser").medUpgradeFromCounterOffer;
}

export default {
  userPortalHomePage() {
    window.location.href = appConfig.USERPORTAL_URL;
  },

  cancelSubscription(isHM: boolean, isInTrial: boolean, isInUpsEx233: boolean) {
    if (isInUpsEx233) {
      trackEvent("UpsEx233CancelSubscriptionRedirect");
      updateForwardedParams({
        ee: "1258",
      });
    }
    const params = createForwardParams();

    let nextLayer;
    if (isHM) {
      nextLayer = isInTrial ? "hm-cancel-trial" : "hm-cancel-subscription";
    } else {
      nextLayer = isInTrial ? "cancel-trial" : "cancel-subscription";
    }

    reroll(`/ps/${nextLayer}`, params, true);
  },

  async processOutcome(outcome: string) {
    // note: CounterOfferOutcome is an enum, but we can't use it in the header
    // because of a `yarn lint:pageset` failure
    if (!Object.values(CounterOfferOutcome).includes(outcome as any)) {
      throw new Error(`Invalid outcome: ${outcome}`);
    }
    getStore().dispatch(
      updateCounterOfferOutcome(outcome as CounterOfferOutcome)
    );

    const pageSet = await getActivePageSet();

    if (!pageSet || !pathIsInSubscriptionCancelContext()) {
      return this.counterOfferError("no-confirmation-page");
    }
    const confirmationPagePathname = `/subscription/${pageSet.layer}/confirmation`;
    return goto.pageSet(pageSet.id, confirmationPagePathname);
  },

  async counterOfferError(reason?: string) {
    const params = createForwardParams();
    getStore().dispatch(
      updateCounterOffer({
        error: {
          sourcePage: window.location.pathname,
          reason,
        },
      })
    );
    const pageSet = await getActivePageSet();
    const counterOfferLayer = !!pageSet && pathIsInSubscriptionCancelContext();
    const errorUrl = counterOfferLayer
      ? `/subscription/${pageSet.layer}/error`
      : "/subscription/error";

    reroll(errorUrl, params, false);
  },

  exitCounterOffer() {
    if (!goto.nativeWebviewExit()) {
      this.userPortalHomePage();
    }
  },
};
