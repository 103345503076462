import { calculateAdjustedPace, getPace } from "./index";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";

const graphIndexMap = {
  choosePlan: 0,
  medOptIn: 0,
  firstUpdateGraph: 1,
  secondUpdateGraph: 2,
  finalUpdateGraph: 3,
  clinicalCheckout: 3,
};
export function getIndexFromGraphId(questionId: string) {
  return graphIndexMap[questionId];
}
export function getGraphIdByIndex(index: number) {
  return Object.keys(graphIndexMap).find((key) => graphIndexMap[key] === index);
}

/*
  Helper function for retrieving the id of the previous weight loss graph
  */
export function getPreviousGraphId(questionId: string) {
  const graphIndex = getIndexFromGraphId(questionId);
  return getGraphIdByIndex(graphIndex - 1) || "";
}

export function getPaceIdFromGraphId(questionId: string) {
  return `${questionId}Pace`;
}

export function getCachedPaceFromGraphId(
  questionId: string,
  surveyAnswers: SurveyAnswersState
) {
  return surveyAnswers[getPaceIdFromGraphId(questionId)];
}

export function getPaceFromGraphId(
  questionId: string,
  surveyAnswers: SurveyAnswersState
) {
  return calculateAdjustedPace(getIndexFromGraphId(questionId), surveyAnswers);
}

/*
  Function for determining whether or not the user's pace has increased from the last time they saw an update graph
  */
export function hasIncreasedPace(
  questionId: string,
  surveyAnswers: SurveyAnswersState
) {
  const pace = getPace(getIndexFromGraphId(questionId), surveyAnswers);
  const previousGraphId = getPreviousGraphId(questionId);
  return (
    previousGraphId &&
    pace > getCachedPaceFromGraphId(previousGraphId, surveyAnswers)
  );
}
