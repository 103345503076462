/* eslint-disable prefer-rest-params */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  ire_o: string;
  ire: any;
};

window.ire_o = "ire";
window.ire =
  window.ire ||
  function ire() {
    (window.ire.a = window.ire.a || []).push(arguments);
  };
window.ire("identify", { customerId: "", customerEmail: "" });

export function loadImpact() {
  return loadScript(
    "//d.impactradius-event.com/A1264905-0109-4989-91e2-e8a8aaca9b161.js",
    [LibCategories.targetingCookies],
    { enableCORS: false }
  );
}
