import { RecommendedPlanState } from "src/utils/redux/slices/recommendedPlan";
import {
  showChooseTrialPage,
  isTrialFeeWaived,
} from "src/utils/userSegment/features";

export function getTrialInfo(recommendedPlan: RecommendedPlanState) {
  const showChooseTrial = showChooseTrialPage();

  const trialFeeEligible = !isTrialFeeWaived();
  const trialFeeExists = !!recommendedPlan.trial_fee;
  const planHasTrial = recommendedPlan.has_trial;
  return {
    hasTrial: showChooseTrial || planHasTrial,
    trackingData: {
      showChooseTrial,
      trialFeeEligible,
      trialFeeExists,
      planHasTrial,
    },
  };
}
