import styled from "@emotion/styled";
import React from "react";
import GreenLeafBackground from "./green_leaf.jpeg";
import WelcomeBackOffer from "../images/welcome-back-offer.gif";

const Hero = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${GreenLeafBackground});
  display: flex;
  flex-direction: column;
  height: 150px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 8px 8px 0 0;
`;

export function ModalHero() {
  return (
    <Hero>
      <Image src={WelcomeBackOffer} alt="" />
    </Hero>
  );
}
