import { isHM, isMantle } from "src/utils/userSegment";
import { registerMonitorProvider } from "../prop-registry";

/**
 * Collect client specific properties.
 */
registerMonitorProvider("both", () => {
  return {
    isHM: isHM(),
    isMantle: isMantle(),
  };
});
