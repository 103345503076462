import { css } from "@emotion/react";

// Removes default styling from any form element.
export const resetFormElement = css`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  appearance: none;
`;
