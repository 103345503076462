import { Survey } from "src/pageDefinitions/types";
import { trackBuyflowEvent } from "src/utils/api/tracker";
import { resetCurrentAnswers } from "src/utils/redux/slices/currentAnswers";
import {
  saveMainSurveyAnswers,
  saveSurveyAnswers,
  UserDataState,
} from "src/utils/redux/slices/userData";
import { isHM, isUS } from "src/utils/userSegment";
import invariant from "tiny-invariant";
import { ActionHandlerParams } from "..";
import { getPageSetSurvey } from "./util";
import { send } from "@utils/fetch";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { captureException } from "src/utils/error";
import { KnownSurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { isNoomPremiumConsultationSurveyDisqualified } from "src/utils/userSegment/features";
import { loadSubscriptionStatus } from "src/hooks/subscriptionStatus";
import { getInAppAutoCookie } from "@utils/authCookieParser";
import { patchUserModelForUser } from "@utils/api/userModel";

type Params = {
  dateKey?: string;
  extraProps?: JsonObject;
};

export const saveAnswers = async ({
  params = {},
  store,
  pageSet,
  page,
}: {
  params?: Params;
} & Omit<ActionHandlerParams, "params">) => {
  const { dateKey, extraProps } = params;

  const survey = getPageSetSurvey(pageSet, page);

  const { surveyNameSpace } = survey;
  const { currentAnswers } = store.getState();

  const internalNamespaceName = getInternalSurveyNamespace(surveyNameSpace);

  let surveyAnswers = currentAnswers.questionAnswers[surveyNameSpace];

  if (surveyNameSpace === "mainSurvey") {
    surveyAnswers = await store.dispatch(
      saveMainSurveyAnswers(surveyAnswers, {
        surveyNameSpace: internalNamespaceName,
        dateKey,
        extraProps,
      })
    );
  } else {
    surveyAnswers = await store.dispatch(
      saveSurveyAnswers(surveyAnswers, {
        surveyNameSpace: internalNamespaceName,
        overwrite: false,
        dateKey,
        extraProps,
      })
    );
  }

  store.dispatch(
    resetCurrentAnswers({
      namespace: surveyNameSpace,
      answers: {},
    })
  );

  trackBuyflowEvent("BuyflowSurveyFinished", {
    surveyName: survey.name,
  });

  return surveyAnswers;
};

/**
 * Sends the relevant responses from the premium post-purchase survey to the backend to configure
 * premium features for the given user.
 */
export const saveNoomPremiumAnswers = async ({
  store,
}: ActionHandlerParams) => {
  const state = store.getState();
  const surveyAnswers = getSurveyAnswers(state);
  const isDisqualifiedFromWorkoutPlan =
    isNoomPremiumConsultationSurveyDisqualified(state);

  // NOTE(alexl): Here the intention is to prevent errors in submitting the Noom Premium config data from derailing
  // the buyflow. Potential error causes would be renaming or removing the NP post-purchase survey responses which are
  // submitted to the backend. Such errors are captured and accounted for by the backend, so here it's acceptable to
  // ignore and proceed. Long-term the plan is to remove this submit-config logic from the buyflow and port it to the
  // app.
  try {
    await submitNoomPremiumAnswers(
      state.userData,
      surveyAnswers,
      isDisqualifiedFromWorkoutPlan
    );
  } catch (error) {
    captureException(error, "saveNoomPremiumAnswers");
  }

  // Skip if the user is not eligible for premium subscription, and is on the one-time purchase.
  // This means the user is still on the older version of the premium consultation survey.
  if (state.addOns.purchasedNoomPremiumSubscription) {
    try {
      await submitFitonWorkoutPlanAnswers(surveyAnswers);
      await saveUserDietaryPreferences(surveyAnswers);
    } catch (error) {
      captureException(error, "saveNoomPremiumAnswers");
    }
  }
};

export const savePremiumMealPlanAnswers = async ({
  store,
}: ActionHandlerParams) => {
  const state = store.getState();

  const surveyAnswers = getSurveyAnswers(state);

  try {
    const payload = {
      language: state.userData.language,
      country: state.userData.country,
      mealPlanSlug: surveyAnswers.mealPlanSlug,
    };

    await send("POST", "/api/premium/v1/features/meal_plan/", payload);
  } catch (error) {
    captureException(error, "savePremiumMealPlanAnswers");
  }

  try {
    await saveUserDietaryPreferences(surveyAnswers);
  } catch (error) {
    captureException(error, "savePremiumMealPlanAnswers");
  }
};

export const saveCoachingSurveyAnswers = async ({
  store,
}: ActionHandlerParams) => {
  if (!getInAppAutoCookie()) {
    return;
  }

  const state = store.getState();

  const { accessCode } = await loadSubscriptionStatus();

  const {
    coachOpportunities,
    coachMotivation,
    coachOtherOpportunities,
    coachEatingHabits,
    coachPhysicalActivity,
  } = getSurveyAnswers(state);

  const requiredAnswers = {
    motivationAccountability: coachMotivation,
    nutritionEatingHabits: coachEatingHabits,
    physicalActivity: coachPhysicalActivity,
    other: coachOtherOpportunities,
    notSureYet: [true], // This will always pass the length check
  };

  const hasOnlyNotSureYet =
    coachOpportunities.length === 1 && coachOpportunities[0] === "notSureYet";

  const missingAnswers =
    hasOnlyNotSureYet ||
    coachOpportunities.some(
      (opportunity) => requiredAnswers[opportunity]?.length === 0
    );

  if (!missingAnswers) {
    try {
      await patchUserModelForUser(accessCode, {
        baselined: {
          experimentNamesAndVariations: [],
          features: {
            coaching: {
              upgradedCoaching: {
                completedCoachingSurvey: true,
              },
            },
          },
        },
      });
    } catch (error) {
      captureException(error, "saveCoachingSurveyAnswers");
    }
  }
};

export const savePremiumWorkoutAnswers = async ({
  store,
}: ActionHandlerParams) => {
  const state = store.getState();

  const surveyAnswers = getSurveyAnswers(state);

  try {
    await submitFitonWorkoutPlanAnswers(surveyAnswers);
  } catch (error) {
    captureException(error, "savePremiumWorkoutAnswers");
  }

  const isDisqualifiedFromWorkoutPlan =
    isNoomPremiumConsultationSurveyDisqualified(state);

  let workoutFrequency = "FITON";
  if (isDisqualifiedFromWorkoutPlan) {
    workoutFrequency = "DISQUALIFIED";
  } else if (surveyAnswers.workoutPlanFrequency) {
    workoutFrequency = surveyAnswers.workoutPlanFrequency?.[0];
  }
  let workoutEquipment = "FITON";
  if (isDisqualifiedFromWorkoutPlan) {
    workoutEquipment = "DISQUALIFIED";
  } else if (surveyAnswers.workoutPlanEquipment) {
    workoutEquipment = surveyAnswers.workoutPlanEquipment?.[0];
  }

  try {
    const payload = {
      language: state.userData.language,
      country: state.userData.country,
      workoutLimitations: surveyAnswers.workoutPlanLimitations
        ? surveyAnswers.workoutPlanLimitations?.[0]
        : surveyAnswers.fitonWorkoutPlanLimitations?.[0],
      workoutFrequencyPreference: workoutFrequency,
      workoutEquipmentPreference: workoutEquipment,
    };

    await send("POST", "/api/premium/v1/features/workout_plan/", payload);
  } catch (error) {
    captureException(error, "savePremiumWorkoutAnswers");
  }
};

function saveUserDietaryPreferences(surveyAnswers: KnownSurveyAnswersState) {
  const payload = {
    dietaryRestrictions: surveyAnswers.mealPlanDietaryRestrictions?.[0],
    allergiesOrDislikedFoods: surveyAnswers.mealPlanAllergiesOrDislikedFoods,
    favoriteCuisines: surveyAnswers.mealPlanFavoriteCuisines,
    maxCookingTime: surveyAnswers.mealPlanMaxCookingTime?.[0],
  };

  return send(
    "POST",
    "/api/premium/v1/meal_plan/dietary_preferences/",
    payload
  );
}

function submitFitonWorkoutPlanAnswers(surveyAnswers: KnownSurveyAnswersState) {
  const payload = {
    physicalLimitations: surveyAnswers.fitonWorkoutPlanLimitations,
    workoutEquipment: surveyAnswers.fitonWorkoutPlanDumbbells?.[0],
    workoutDuration: surveyAnswers.fitonWorkoutPlanDuration?.[0],
    workoutIntensity: surveyAnswers.fitonWorkoutPlanIntensity?.[0],
  };

  return send("POST", "/api/fitness/v1/fiton/workout_plans/", payload);
}

function submitNoomPremiumAnswers(
  userData: UserDataState,
  surveyAnswers: KnownSurveyAnswersState,
  isDisqualifiedFromWorkoutPlan: boolean
) {
  // NOTE(alexl): for Noom Premium add-ons which are offered as a one-time purchase (opposed to a subscription)
  // the backend does not enable in-app Noom Premium features. Attempting to submit survey answers for users who
  // purchase these add-ons will result in an error, so instead we short-circuit.
  const ineligibleProductIds = [
    "meal-workout-49",
    "custom-meal-plan-49",
    "custom-workout-plan-29",
  ];
  if (
    ineligibleProductIds.some((id) => userData.upsell?.products?.includes(id))
  ) {
    return Promise.resolve();
  }

  // NOTE(yanqi): We have updated workout frequency/equipment preferences to be optional parameters in the backend.
  // However, this API is not on noom-contracts, since noom-platform-sdk is deprecated, the updates in backend is no longer
  // reflected here, so we provide dummy values here until we can migrate Premium related API in Coach Server to noom-contracts.
  let workoutFrequency = "FITON";
  if (isDisqualifiedFromWorkoutPlan) {
    workoutFrequency = "DISQUALIFIED";
  } else if (surveyAnswers.workoutPlanFrequency) {
    workoutFrequency = surveyAnswers.workoutPlanFrequency?.[0];
  }
  let workoutEquipment = "FITON";
  if (isDisqualifiedFromWorkoutPlan) {
    workoutEquipment = "DISQUALIFIED";
  } else if (surveyAnswers.workoutPlanEquipment) {
    workoutEquipment = surveyAnswers.workoutPlanEquipment?.[0];
  }

  const payload = {
    language: userData.language,
    country: userData.country,
    dietaryRestriction: surveyAnswers.mealPlanDietaryRestrictions?.[0],
    workoutLimitations: surveyAnswers.workoutPlanLimitations
      ? surveyAnswers.workoutPlanLimitations?.[0]
      : surveyAnswers.fitonWorkoutPlanLimitations?.[0],
    workoutFrequencyPreference: workoutFrequency,
    workoutEquipmentPreference: workoutEquipment,
  };

  return send("POST", "/api/premium/v1/features/", payload);
}

function getInternalSurveyNamespace(
  pageSetSurveyNamespace: Survey["surveyNameSpace"]
) {
  // saveSurveyAnswers stores in userData slice. This check ensures that the JSON config
  // will properly load that data in the future/on refresh.
  invariant(
    pageSetSurveyNamespace.startsWith("userData.") ||
      pageSetSurveyNamespace === "mainSurvey",
    "surveyNameSpace must start with userData for this action."
  );

  let internalNamespaceName: keyof UserDataState | "mainSurvey" =
    pageSetSurveyNamespace.replace("userData.", "") as any;
  if (internalNamespaceName === "mainSurvey") {
    if (isHM()) {
      internalNamespaceName = "personaSurveyHM";
    } else if (!isUS()) {
      internalNamespaceName = "personaSurveyNonUS";
    } else {
      internalNamespaceName = "personaSurveyUS";
    }
  }
  return internalNamespaceName;
}
