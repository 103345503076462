import {
  getReferralCode,
  GET_REFERRAL_CODE_CACHE_KEY,
} from "@utils/api/referrals";
import { useQuery } from "react-query";
import { userHasAccessCode } from "@utils/userSegment/features";

export const useReferralCode = () => {
  const {
    data: referralCode,
    isLoading,
    error,
  } = useQuery(GET_REFERRAL_CODE_CACHE_KEY, getReferralCode, {
    enabled: userHasAccessCode(),
  });

  return { referralCode, isLoading, error };
};
