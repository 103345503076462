/* eslint-disable no-underscore-dangle */
import React from "react";
import { Helmet } from "react-helmet";
import i18n from "src/locales";
import ogImage from "src/components/intro/images/compass-background.webp";
import ogHMImage from "src/mood/images/LandingOgBackground.webp";
import { isHM } from "src/utils/userSegment";

const title = isHM() ? i18n.t("og:mood:title") : i18n.t("og:title");

const description = isHM()
  ? i18n.t("og:mood:description")
  : i18n.t("og:description");

const url = isHM()
  ? "https://www.noom.com/programs/mood/"
  : "https://www.noom.com";

const image = isHM() ? ogHMImage : ogImage;

const OpenGraph = () => (
  <Helmet>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:locale" content={i18n.language.replace("-", "_")} />
    {/* List of locales this website is available in. See https://developers.facebook.com/blog/post/2013/11/11/605/ */}
    {Object.keys(i18n.store.data).map((language) => (
      <meta
        key={language}
        property="og:locale:alternate"
        content={language.replace("-", "_")}
      />
    ))}
    <meta property="og:image" content={image} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:site_name" content="Noom, Inc." />
  </Helmet>
);

export default OpenGraph;
