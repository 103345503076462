import goto from "./index";
import {
  isAddonStoreEligible,
  isNoomSummitEligible,
  isZumbaOfferEligible,
} from "@utils/userSegment/features";
import { isInApp } from "src/utils/userSegment";

export default {
  navigateFromProbioticsDeliveryCheckout() {
    if (isZumbaOfferEligible()) {
      goto.zumbaOffer();
      return;
    }

    if (isNoomSummitEligible()) {
      goto.layer("noom-summit");
      return;
    }

    if (isAddonStoreEligible()) {
      goto.addOns({
        showEmailReminder: false,
      });
      return;
    }

    if (isInApp()) {
      goto.nativePurchaseComplete();
      return;
    }

    goto.enrollment({
      showEmailReminder: false,
    });
  },
};
