import saveToBraze from "../brazeUploader";
import { addMonitoringEventHandler } from "./bus";
import { getRouteId } from "@utils/meristemContext";
import { routeConstants } from "@utils/constants";
import { captureException } from "../error";

const PROMO_CODE_ENTERED_EVENTS = [
  "InvalidPromoCode",
  "PromoCodeRedemptionFailed",
  "PromoCodeRedeemed",
  "PromoCodeExpired",
  "PromoCodeRedemptionBlocked",
];

function transformAbandonedCartExperimentEvent(event) {
  const monitoredEvents = ["ClickedChoosePlanCTA", "BuyflowPageViewed"];

  const monitoredPageIds = {
    welcomeToNoom: "OnWelcomeToNoom",
    chooseTrialPrice: "OnChooseTrialPrice",
    customPlan: "OnCustomPlanPage",
    enterPaymentInfo: "OnEnterPaymentInfoPage",
  };

  const monitoredRoutes = [routeConstants.emailMain];

  const newEventName = "abandonedCartExperimentEvent";

  if (
    !monitoredEvents.includes(event.eventName) ||
    !monitoredRoutes.includes(getRouteId())
  ) {
    return null;
  }

  let { eventName, properties } = event;
  let propertiesToSend = {};

  if (eventName === "BuyflowPageViewed") {
    const { pageId } = properties;

    if (!Object.keys(monitoredPageIds).includes(pageId)) return null;

    eventName = monitoredPageIds[pageId];
  } else {
    // Only include event properties for ClickedChoosePlanCTA.
    // BuyflowPageViewed only has a pageId property which we don't
    // need to send.
    propertiesToSend = event.properties;
  }

  return {
    event_name: newEventName,
    event_data: {
      routeId: getRouteId(),
      ...propertiesToSend,
      type: eventName,
    },
  };
}

// Send promo code lifecycle events to braze as well.
addMonitoringEventHandler((event) => {
  if (event.type === "track") {
    if (PROMO_CODE_ENTERED_EVENTS.includes(event.eventName)) {
      try {
        saveToBraze({
          event: {
            event_name: "promoCodeEntered",
            event_data: {
              result: event.eventName,
              ...event.properties,
            },
          },
        });
      } catch (err) {
        captureException(Error("Error in tracking promo code event in braze."));
      }
    }
  }
});

addMonitoringEventHandler((event) => {
  if (event.type === "track" || event.type === "buyflowEvent") {
    const newEvent = transformAbandonedCartExperimentEvent(event);
    // eslint-disable-next-line no-unused-expressions
    newEvent && saveToBraze({ event: newEvent });
  }
});
