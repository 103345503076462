/* eslint-disable no-underscore-dangle */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  _tfa: any;
};

window._tfa = window._tfa || [];
window._tfa.push({ notify: "event", name: "page_view", id: 1101615 });

export function loadTaboola() {
  return loadScript(
    "//cdn.taboola.com/libtrc/unip/1101615/tfa.js",
    [LibCategories.targetingCookies],
    {
      enableCORS: false,
    }
  );
}
