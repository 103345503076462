export function capitalizeFirstLetter<T extends string>(
  input: T
): Capitalize<T> {
  if (!input) {
    return input as Capitalize<T>;
  }
  return (input.charAt(0).toUpperCase() + input.slice(1)) as Capitalize<T>;
}

export function removeNonAlphabetic(input: string) {
  if (!input) {
    return input;
  }
  return input.replace(/[^a-zA-Z]/g, "");
}

export function removeNonNumeric(input: string) {
  if (!input) {
    return input;
  }
  return input.replace(/\D/g, "");
}
