import i18n from "@i18n";

import { SurveyAnswersState } from "src/utils/redux/slices/surveyAnswers";

const OPTION_KEYS = [
  "vacation",
  "wedding",
  "holiday",
  "summer",
  "reunion",
  "birthday",
];

export function getImportantEventText(surveyAnswers: SurveyAnswersState) {
  let answer: string = surveyAnswers.importantDateDetails?.[0];
  if (!OPTION_KEYS.includes(answer)) {
    answer = "unknown";
  }

  return i18n.t(`survey:questions:importantDate:options:${answer}`);
}

export function getImportantEventTextLong(surveyAnswers: SurveyAnswersState) {
  let answer: string = surveyAnswers.importantDateDetails?.[0];
  if (!OPTION_KEYS.includes(answer)) {
    answer = "unknown";
  }

  return i18n.t(`survey:questions:importantDate:importantDateLong:${answer}`);
}
