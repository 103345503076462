import {
  EffectCallback,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

/**
 * Hook to run a callback on mount and unmount.
 */
export function useOnce(cb: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cb, []);
}

export function useOnceAsync(
  cb: (renderedRef: RefObject<boolean>) => Promise<unknown>
) {
  const renderedRef = useRef(false);
  useEffect(() => {
    renderedRef.current = true;
    cb(renderedRef);
    return () => {
      renderedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/**
 * Returns a callback that will be invoked once during the component lifecycle.
 * The invoked function will be the most recent callback passed to the hook at call time.
 * Useful when _.once behavior is needed within a functional component.
 */
export function useCallbackOnce<T extends (...args: any[]) => any>(cb: T) {
  const hasExecuted = useRef(false);
  const callback = useCallbackRef(cb);

  return useCallback((...args) => {
    if (!hasExecuted.current) {
      const ret = callback(...args);
      hasExecuted.current = true;
      return ret;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/**
 * Returns a debounced callback and a cancel function to clear any pending callbacks.
 * The callback will be invoked debounceTime after the last time debounced callback is invoked.
 * Useful when _.debounce behavior is needed within a functional component.
 */
export function useDebounce<T extends (...args: any[]) => unknown>(
  cb: T,
  debounceTime: number
) {
  const pendingTimerRef = useRef<NodeJS.Timeout | undefined>();
  const callback = useCallbackRef(cb);

  const cancel = useCallback(() => {
    clearTimeout(pendingTimerRef.current);
  }, []);

  const debounced = useCallback(
    (...args: Parameters<T>) => {
      cancel();
      pendingTimerRef.current = setTimeout(
        () => callback(...args),
        debounceTime
      );
    },
    [callback, debounceTime, cancel]
  );

  return {
    debounced,
    cancel,
  };
}

/**
 * Provides a singleton callback that will call the most recent version of the
 * given callback. This is useful for components and hooks which need to avoid
 * issues due to passing callback props to deps arrays.
 */
export function useCallbackRef<T extends (...args: any[]) => any>(cb: T) {
  const ref = useRef(cb);
  ref.current = cb;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args: any[]) => ref.current(...args)) as T, []);
}

/**
 * Hook for keeping track of a "loading state". Call `load` with a promise and this will remain
 * `loading` until that promise resolves or rejects.
 */
export function useLoader() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const load = useCallback(<T,>(loader: Promise<T>) => {
    setLoading(true);
    setError(null);

    return loader.then(
      (result) => {
        setLoading(false);
        return result;
      },
      (err) => {
        setLoading(false);
        setError(err);
        throw err;
      }
    );
  }, []);

  return {
    loading,
    error,
    load,
  };
}
