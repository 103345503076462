const NOOM_SESSION_STORAGE_PREFIX = "noom_";

export function getNoomSessionStorage<T extends AnyJson>(key: string): T {
  try {
    return JSON.parse(
      sessionStorage.getItem(NOOM_SESSION_STORAGE_PREFIX + key)
    );
  } catch (e) {
    return undefined;
  }
}

export function setNoomSessionStorage(key: string, value: AnyJson) {
  sessionStorage.setItem(
    NOOM_SESSION_STORAGE_PREFIX + key,
    JSON.stringify(value)
  );
}

let didTestSessionStorage = null;

export function isSessionStorageAvailable() {
  if (didTestSessionStorage === null) {
    try {
      sessionStorage.setItem("noom_s_test", "noom");
      const value = sessionStorage.getItem("noom_s_test");
      if (value !== "noom") {
        didTestSessionStorage = false;
        return false;
      }
      sessionStorage.removeItem("noom_s_test");
      didTestSessionStorage = true;
    } catch (e) {
      didTestSessionStorage = false;
    }
  }
  return didTestSessionStorage;
}
