import US_STATES from "@utils/usStates";
import getStore from "@utils/redux/store";
import { isUS } from "@utils/userSegment/locale";
import { getSubdivision } from "@utils/meristemContext";

export function isInUSState(
  usState: keyof typeof US_STATES,
  state = getStore().getState()
) {
  const { serverContext } = state;
  return (
    (isUS() && getSubdivision() === usState) ||
    serverContext?.billing_state === US_STATES[usState] ||
    serverContext?.billing_state === usState
  );
}

export const isCalifornia = (state = getStore().getState()) => {
  return isInUSState("CA", state);
};

export const isColorado = (state = getStore().getState()) => {
  return isInUSState("CO", state);
};
