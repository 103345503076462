import { shift_minutes } from "src/utils/datetime";
import getStore, { CoreReduxState } from "@utils/redux/store";
import { setUserProperties } from "@utils/redux/slices/userData";
import { appConfig } from "src/config";

const checkoutOfferTimestampField =
  "checkoutDiscountedMWCPTimerExpirationTimestamp";

// Returns length of checkout timer in minutes
export const getBaselineTimerDuration = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const checkoutTimerFromUrl = parseInt(urlParams?.get("checkoutTimer"), 10);
  if (checkoutTimerFromUrl && appConfig.NOOM_ENV !== "production") {
    return checkoutTimerFromUrl;
  }
  return 15;
};

// Returns length of checkout timer in minutes
export const getOfferTimerDuration = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const checkoutTimerFromUrl = parseInt(
    urlParams?.get("checkoutOfferTimer"),
    10
  );
  if (checkoutTimerFromUrl && appConfig.NOOM_ENV !== "production") {
    return checkoutTimerFromUrl;
  }
  return 60;
};

// Checks to see that the timestamp saved in the db exists and that the current date
// is past the timestamp
export const isCheckoutOfferTimerExpired = (
  userData = getStore()?.getState()?.userData
) => {
  const timestamp = userData?.checkoutDiscountedMWCPTimerExpirationTimestamp;
  return timestamp && Date.now() >= timestamp;
};

export const trySetCheckoutOfferTimerExpirationCookie = async () => {
  const store = getStore();
  const { userData } = store.getState();
  if (!userData.checkoutDiscountedMWCPTimerExpirationTimestamp) {
    await store.dispatch(
      setUserProperties({
        checkoutDiscountedMWCPTimerExpirationTimestamp: shift_minutes(
          getBaselineTimerDuration()
        ).getTime(),
        initialExpirationDurationForFreeMWCP: getBaselineTimerDuration(),
      })
    );
  }
};

export const clearCheckoutOfferTimerExpirationCookie = () => {
  return getStore().dispatch(
    setUserProperties({
      checkoutDiscountedMWCPTimerExpirationTimestamp: false,
      initialExpirationDurationForFreeMWCP: undefined,
    })
  );
};

export function getInitialTimerDuration(
  state: CoreReduxState = getStore().getState()
) {
  // default to 15 for production backwards compatibility
  return (
    state.userData?.initialExpirationDurationForFreeMWCP ??
    getBaselineTimerDuration()
  );
}

export const getRemainingDuration = (userDataTimestampField) => {
  const timerDuration = getInitialTimerDuration();
  const timestamp = getStore().getState().userData[userDataTimestampField];
  return timestamp
    ? Math.max(Math.ceil((timestamp - Date.now()) / (60 * 1000)), 0)
    : timerDuration;
};

export const getRemainingDurationInSeconds = (userDataTimestampField) => {
  const timerDuration = getInitialTimerDuration();
  const timestamp = getStore().getState().userData[userDataTimestampField];
  return timestamp
    ? Math.max(Math.ceil((timestamp - Date.now()) / 1000), 0)
    : timerDuration * 60; // in minutes
};

export const getCheckoutOfferTimerRemainingDuration = () => {
  return getRemainingDuration(checkoutOfferTimestampField);
};

export const getCheckoutOfferTimerRemainingDurationInSeconds = () => {
  return getRemainingDurationInSeconds(checkoutOfferTimestampField);
};
