/* eslint-disable */
import { LibCategories } from "src/utils/consent";
import { captureMessageWithExtras } from "src/utils/error";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  tkrc: any;
};

window.tkrc = window.tkrc || [];
let tkrc = window.tkrc;

if (!tkrc.initialize) {
  if (tkrc.invoked) {
    // Log an error if tkrc has already been invoked
    captureMessageWithExtras("Traakr initialization error", {});
  } else {
    tkrc.invoked = true;
    tkrc.methods = ["event"];

    tkrc.factory = function (t) {
      return function () {
        for (var e = arguments.length, n = new Array(e), o = 0; o < e; o++)
          n[o] = arguments[o];
        return n.unshift(t), tkrc.push(n), tkrc;
      };
    };
    for (let t = 0; t < tkrc.methods.length; t++) {
      let e = tkrc.methods[t];
      tkrc[e] = tkrc.factory(e);
    }
    tkrc.options = {
      cookieDomain: "noom.com",
    };
  }
}

export function loadTraakr() {
  return loadScript(
    "https://cdn.tkrconnector.com/js/tkrc.js",
    [LibCategories.targetingCookies],
    {
      enableCORS: false,
    }
  );
}
