import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { useEffect } from "react";
import { useOnce } from "src/hooks/lifecycle";
import { useRecommendedPlan } from "src/hooks/recommended-plan";
import { useSurvey } from "src/hooks/survey";
import { useSurveyAnswers, getSurveyAnswers } from "src/hooks/survey/answers";
import {
  calculatePaceFromTargetDate,
  getPace,
  getPersonalizedRecommendedPlanDuration,
  getPersonalizedTargetDate,
} from ".";
import { trackEvent } from "../api/tracker";
import saveToBraze from "../brazeUploader";
import { getWeightInformation } from "../calculateGoals";
import { getDayDelta } from "../datetime";
import { getNoomPlanIdFromKey } from "../plans";
import { RecommendedPlanState } from "../redux/slices/recommendedPlan";
import { determinePlanOverride } from "../userSegment/features";
import {
  getCachedPaceFromGraphId,
  getIndexFromGraphId,
  getPaceIdFromGraphId,
  getPreviousGraphId,
} from "./graphIndex";
import { getPaceFromWeightLoss } from "src/utils/weight/weightPacing";
import Unit, { getWeightDisplayUnit, convertUnits } from "src/utils/Unit";

export function useWeightGraphCache(questionId: string) {
  const surveyAnswers = useSurveyAnswers();

  const { addAnswers } = useSurvey(questionId);

  const currentPace = getPace(getIndexFromGraphId(questionId), surveyAnswers);

  useEffect(() => {
    const answer = { [getPaceIdFromGraphId(questionId)]: currentPace };
    addAnswers(answer, true);
  }, [questionId, currentPace, addAnswers]);
}

export function useWeightGraphTracking(questionId: string) {
  const surveyAnswers = useSurveyAnswers();

  const recommendedPlan = useRecommendedPlan();
  const { weightValueKg, targetWeightKg } = getWeightInformation(surveyAnswers);

  useEffect(() => {
    trackWeightGraph(questionId, surveyAnswers);

    // Tracking derived properties only to avoid over tracking.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, weightValueKg, targetWeightKg]);

  useOnce(() => {
    sendPaceToBraze(surveyAnswers, recommendedPlan);
  });
}

export function trackWeightGraph(
  questionId: string,
  surveyAnswers: SurveyAnswersState
) {
  const { weightValueKg, targetWeightKg } = getWeightInformation(surveyAnswers);

  // Track pace information
  const currentPace = getPace(getIndexFromGraphId(questionId), surveyAnswers);
  const previousPace = getCachedPaceFromGraphId(
    getPreviousGraphId(questionId),
    surveyAnswers
  );

  const targetDateFromPace = getPersonalizedTargetDate(
    getIndexFromGraphId(questionId),
    surveyAnswers
  );
  const previousTargetDateFromPace = getPersonalizedTargetDate(
    Math.max(getIndexFromGraphId(questionId) - 1, 0),
    surveyAnswers
  );

  const choosePlanTargetDate = getPersonalizedTargetDate(
    getIndexFromGraphId("choosePlan"),
    surveyAnswers
  );

  trackEvent(getPaceIdFromGraphId(questionId), {
    CurrentProjectedSpeed: currentPace,
    CurrentProjectedDays: getDayDelta(new Date(), targetDateFromPace),
    PreviousProjectedSpeed: previousPace,
    PreviousProjectedDays: getDayDelta(new Date(), previousTargetDateFromPace),
    FirstProjectDays: getDayDelta(new Date(), choosePlanTargetDate),
    RecalculatedPace: calculatePaceFromTargetDate(
      choosePlanTargetDate,
      Math.max(weightValueKg - targetWeightKg, 0)
    ),
  });
}

export function sendPaceToBraze(
  surveyAnswers: SurveyAnswersState,
  recommendedPlan: RecommendedPlanState
) {
  const recommendedPlanDuration = getPersonalizedRecommendedPlanDuration(
    recommendedPlan.trial_duration,
    surveyAnswers
  );

  if (recommendedPlanDuration && !determinePlanOverride()) {
    saveToBraze({
      user: {
        recommended_plan_url: getNoomPlanIdFromKey(
          `${recommendedPlanDuration}`
        ),
      },
    });
  }
}

export function trackMilestoneWeightLossPace(
  milestone: string,
  weightLossKg: number,
  targetDate: Date,
  questionId: string,
  surveyAnswers = getSurveyAnswers()
) {
  const weightDisplayUnit = getWeightDisplayUnit(surveyAnswers);
  let weightLoss: number;
  let unit;
  if (weightDisplayUnit.id === Unit.KILOGRAM.id) {
    weightLoss = Math.round(weightLossKg);
    unit = Unit.KILOGRAM;
  } else {
    const weightLossInLbs = convertUnits(
      { mainUnitValue: weightLossKg },
      Unit.KILOGRAM,
      Unit.POUND
    );
    weightLoss = Math.round(weightLossInLbs.mainUnitValue);
    unit = Unit.POUND;
  }
  trackEvent(`${milestone}WeightLossPace`, {
    currentDate: new Date(),
    targetDate,
    weightLoss,
    unit: unit.id,
    questionId,
    paceInLbsPerWeek: getPaceFromWeightLoss(weightLoss, unit, targetDate),
  });
}
