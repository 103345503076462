/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ProgressSectionState = number[];

export type ProgressEntry = {
  sourceLayer: string;
  progressInLayer: ProgressSectionState;
  pagesInSurveyLayer: ProgressSectionState;
  childLocations: {
    index: number; // index of subsurvey relative to section
    location: number[]; // indicies of subsurvey relative to pageset and potentially block
    layer: string;
    section: number;
  }[][];
  seenLayers: { [layer: string]: boolean };
  combineProgress?: string;
};

export type LayersProgress = { [layer: string]: ProgressEntry };

type SurveyProgressState = {
  /**
   * Records ths progress of each survey layer that has been fully resolved, i.e. we've rerolled
   * into the final experiment that serves this particular survey.
   *
   * This is functionally a derived state within a given sha, but we need to track it in a serialized
   * location to ensure that we are aware of what the user has seen regardless of the sha that led
   * them to this location.
   */
  knownLayerProgress: LayersProgress;
};

const initialState: SurveyProgressState = {
  knownLayerProgress: {},
};

const surveyProgress = createSlice({
  name: "surveyProgress",
  initialState,
  reducers: {
    setLayerProgress(
      state,
      action: PayloadAction<{
        sourceLayer?: string;
        layer: string;
        progressInLayer: ProgressSectionState;
        pagesInSurveyLayer: ProgressSectionState;
        childLocations: ProgressEntry["childLocations"];
        seenLayers: ProgressEntry["seenLayers"];
        combineProgress?: string;
      }>
    ) {
      const {
        sourceLayer,
        layer,
        progressInLayer,
        pagesInSurveyLayer,
        childLocations,
        combineProgress,
        seenLayers,
      } = action.payload;
      const { knownLayerProgress } = state;

      knownLayerProgress[layer] = {
        sourceLayer,
        progressInLayer,
        pagesInSurveyLayer,
        childLocations,
        combineProgress,
        seenLayers,
      };
    },
  },
});

export const { setLayerProgress } = surveyProgress.actions;

export default surveyProgress;
