import { isHM, isMobile } from "@utils/userSegment";
import { MutableRefObject } from "react";
import { PURCHASE_BANNER_HEIGHT } from "src/components/reservation-timer/sticky-countdown/StickyCountdown";
import { getMeristemContext } from "./meristemContext";
import { acceptedNoomClinicalOffer } from "./userSegment/features";

export function shouldAskForPhoneNumber() {
  return !isMobile() || acceptedNoomClinicalOffer();
}

/** Check for pages that are currently using the ReservationBanner, which is NW checkout pages. */
function isUsingReservationBanner() {
  return !isHM() && getMeristemContext().context_type === "payment-checkout";
}

/** Ensures that payment page elements are never behind the checkout timer banner
 * when they receive focus.
 *
 * Pass the focus event when using on our own components. Pass a ref when using
 * Stripe elements, since we don't have access to the focus events within the iframes.
 * Braintree's implementation requires the use of MutationRecord. */
export function handleFocusBehindBanner(
  eventOrRef:
    | React.FocusEvent<HTMLElement>
    | MutationRecord
    | MutableRefObject<HTMLElement>
) {
  if (!isUsingReservationBanner()) return;

  let element = null;

  if ("target" in eventOrRef) {
    element = eventOrRef.target;
  } else if (eventOrRef.current) {
    element = eventOrRef.current;
  } else {
    return;
  }

  const bottomOfElement = element.getBoundingClientRect()?.bottom;
  const topOfBanner = window.innerHeight - PURCHASE_BANNER_HEIGHT;

  if (bottomOfElement > topOfBanner) {
    window.scrollBy(0, bottomOfElement - topOfBanner);
  }
}

export function scrollAboveBanner() {
  if (isUsingReservationBanner()) {
    return { scrollMarginBottom: `${PURCHASE_BANNER_HEIGHT}px` };
  }

  return {};
}
