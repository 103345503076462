import {
  ExperimentDefinition,
  getExperimentState,
  getExperimentsJourney,
} from "./meristemContext";

/**
 * Fetch the experiment assignment from the embedded Meristem Context.
 */
export function getStoredExperiments(): ExperimentDefinition[] | null {
  let experimentStates = getExperimentState();
  if (experimentStates.length) {
    if (typeof experimentStates === "string") {
      experimentStates = JSON.parse(experimentStates);
    }
    return [].concat(experimentStates);
  }
  return null;
}

export function getECTExperiments() {
  // Provide experiments as GET query parameters for experiment overlap testing
  const params = new URLSearchParams(window.location.search);
  const experiments = params.getAll("experiment").map((item) => {
    const [name, variation] = item.split(".");
    return { name, variation };
  });
  if (experiments != null && experiments.length > 0) {
    return experiments;
  }
  return [];
}

export const getMeristemExperimentsString = (): string => {
  const visitedExperiments = Object.values(getExperimentsJourney())
    .filter(([, , visited]) => visited)
    .map(
      ([experimentName, experimentVar]) => `${experimentName}--${experimentVar}`
    );

  const uniqueVisitedExperiments = [...new Set(visitedExperiments)];

  if (uniqueVisitedExperiments.length === 0) {
    return undefined;
  }
  return uniqueVisitedExperiments.join();
};
