import React, { useState } from "react";

import i18n from "@i18n";
import { trackEvent } from "@utils/api/tracker";
import Button from "src/design-system/components/Button/Button";

import { fontLargeHeadline } from "src/design-system/styles/fonts";
import styled from "@emotion/styled";
import { isFromEmail } from "src/utils/userSegment";
import { flexMiddle } from "src/design-system/styles/layout";
import { usePageView } from "src/hooks/tracking";
import { WelcomeBackData } from "src/hooks/user/welcome-back";

type Props = {
  onAccept: () => Promise<void>;
  onReject: () => void;
  welcomeBackData: WelcomeBackData;
};

const Title = styled.h1`
  ${fontLargeHeadline};
  margin: 0;
  font-weight: normal;
  word-break: break-word;
`;

const ButtonWrapper = styled.div`
  ${flexMiddle};
  width: 100%;
  margin-top: 50px;
`;

/**
 * This component is shown when we know the users identity (via nuid query params)
 * and want to ensure the user explicitly acknowledges that the URL is designed for
 * the user with the nuid. This helps reduce instances of users forwarding their email
 * campaign links to their friends/family, etc. and unknowingly assuming the original
 * user's survey answers.
 */
export default function WelcomeBack({
  onAccept,
  onReject,
  welcomeBackData,
}: Props) {
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const { displayName } = welcomeBackData;
  const fromEmail = isFromEmail();

  usePageView("welcomeBack");

  const onAcceptClicked = async () => {
    setButtonIsDisabled(true); // not resetting to false as the outcomes will be either redirect or fail
    await trackEvent("WelcomeBackAccepted", { isFromEmail: fromEmail });
    await onAccept();
  };

  const onRejectClicked = async () => {
    await trackEvent("WelcomeBackRejected", { isFromEmail: fromEmail });
    onReject();
  };

  return (
    <>
      <Title data-cy="account-welcome-back-title">
        {displayName
          ? i18n.t("accountSignup:welcomeBackName", { name: displayName })
          : i18n.t("accountSignup:welcomeBack")}
      </Title>
      <ButtonWrapper>
        <Button
          type="button"
          data-cy="account-accept"
          onClick={onAcceptClicked}
          disabled={buttonIsDisabled}
        >
          {i18n.t("accountSignup:continue")}
        </Button>
      </ButtonWrapper>
      {displayName && (
        <ButtonWrapper>
          <Button
            type="button"
            designStyle="secondaryCta"
            data-cy="account-reject"
            onClick={onRejectClicked}
            disabled={buttonIsDisabled}
          >
            {i18n.t("accountSignup:notName", { name: displayName })}
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
}
