import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: Window & {
  rdt?: any;
};

if (!window.rdt) {
  window.rdt = function rdt() {
    if (window.rdt.sendEvent) {
      // eslint-disable-next-line
      window.rdt.sendEvent.apply(window.rdt, arguments);
    } else {
      // eslint-disable-next-line prefer-rest-params
      window.rdt.callQueue.push(arguments);
    }
  };
  window.rdt.callQueue = [];
}

(window as any).rdt("init", "t2_7dyem6x1");
(window as any).rdt("track", "PageVisit");

export function loadReddit() {
  return loadScript("https://www.redditstatic.com/ads/pixel.js", [
    LibCategories.targetingCookies,
  ]);
}
