import { setParam } from "src/pageDefinitions/goto/utils";
import { ReadonlyDeep } from "type-fest";
import { getUrlParamsPii, appendPiiParams } from "./urlParamsPii";

export type QueryParametersState = Record<
  string,
  string | number | boolean | null
>;

/* eslint-disable no-restricted-globals */
type EmailAndType = {
  email?: string;
  name?: string;
};
/**
 * Get the email and name fields from url parameters.
 *
 * WARN: This function must omit keys when missing rather than pass undefined, which
 * can overwrite stored values elsewhere in the system.
 */
export function getEmailAndNameFromURLParams() {
  const urlParams = getURLParams();
  const obj: EmailAndType = {};

  if (urlParams.email) {
    obj.email = urlParams.email;
  }
  if (urlParams.name) {
    obj.name = urlParams.name;
  }

  return obj;
}

/**
 * Get all url parameters.
 * NOTE(Patrick): This implementation uses the last key in the search string if
 * a key shows up more than once. While not ideal, this is the implementation used
 * by Conversion Tracker. If you need an implementation that supports search params
 * that appear more than once, please see
 * https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object.
 */
export function getURLParams() {
  const params = new URLSearchParams(window.location.search);
  const piiParams = getUrlParamsPii();
  // Object.create(null) creates an object with no prototype to prevent prototype pollution.
  // https://github.com/Kirill89/prototype-pollution-explained
  const urlParams: Record<string, string> = Object.create(null);
  Object.keys(piiParams).forEach((key) => {
    urlParams[key] = `${piiParams[key]}`;
  });
  params.forEach((value, key) => {
    urlParams[key] = value;
  });
  return urlParams;
}

export function getSearchQuery(params: URLSearchParams) {
  params?.sort();

  // If we have any parameters, output the query string
  if (params && !params.keys().next().done) {
    return `?${params}`;
  }
  return "";
}

export function stripURLParams(url: string) {
  return url.replace(/\?.*/, "");
}

export function updateURLParams(newParams: Record<string, string | number>) {
  const url = new URL(location.href);

  Object.entries(newParams).forEach(([key, value]) => {
    if (value == null) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, `${value}`);
    }
  });

  history.replaceState(history.state, null, url.toString());
}

export function removeURLParam(name: string) {
  const url = new URL(location.href);
  url.searchParams.delete(name);

  history.replaceState(history.state, null, url.toString());
}

const updatedForwardParams: QueryParametersState = {};

export function getForwardParams(): ReadonlyDeep<QueryParametersState> {
  return updatedForwardParams;
}

/**
 * This lets us remove parameters when the next navigation occurs,
 * without having to manage the current url directly.
 *
 * Passing `null` to a parameter will drop it from the url on
 * the next navigation.
 */
export function updateForwardedParams(newParams: QueryParametersState) {
  Object.keys(newParams).forEach((key) => {
    if (newParams[key] !== undefined) {
      updatedForwardParams[key] = newParams[key];
    }
  });
}

export function createForwardParams() {
  const queryParams = new URLSearchParams(location.search);
  appendPiiParams(queryParams);

  Object.entries(updatedForwardParams).forEach(([key, value]) => {
    if (value == null) {
      queryParams.delete(key);
    } else {
      setParam(queryParams, key, value);
    }
  });

  return queryParams;
}
